const PhotographicStudiosIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 16C13.6569 16 15 14.6569 15 13C15 11.3431 13.6569 10 12 10C10.3431 10 9 11.3431 9 13C9 14.6569 10.3431 16 12 16Z"
      stroke="black" strokeWidth="0.5"/>
    <path
      d="M2 13.364C2 10.299 2 8.76705 2.749 7.66705C3.07457 7.18904 3.49118 6.77991 3.975 6.46305C4.695 5.99005 5.597 5.82105 6.978 5.76105C7.637 5.76105 8.204 5.27105 8.333 4.63605C8.43158 4.17092 8.68773 3.75408 9.05815 3.456C9.42857 3.15791 9.89055 2.99686 10.366 3.00005H13.634C14.622 3.00005 15.473 3.68505 15.667 4.63605C15.796 5.27105 16.363 5.76105 17.022 5.76105C18.402 5.82105 19.304 5.99105 20.025 6.46305C20.51 6.78105 20.927 7.19005 21.251 7.66705C22 8.76705 22 10.299 22 13.364C22 16.429 22 17.96 21.251 19.061C20.9254 19.5391 20.5088 19.9482 20.025 20.265C18.904 21 17.343 21 14.222 21H9.778C6.657 21 5.096 21 3.975 20.265C3.49144 19.9478 3.07517 19.5383 2.75 19.06C2.53274 18.7364 2.37274 18.3779 2.277 18M19 10H18"
      stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
  </svg>
);

export default PhotographicStudiosIcon;
