const DigitalGoodsIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.2582 3H4.3956C4.02547 3 3.67049 3.14704 3.40876 3.40876C3.14704 3.67049 3 4.02547 3 4.3956V16.2582C3 16.6284 3.14704 16.9833 3.40876 17.2451C3.67049 17.5068 4.02547 17.6538 4.3956 17.6538H16.2582C16.6284 17.6538 16.9833 17.5068 17.2451 17.2451C17.5068 16.9833 17.6538 16.6284 17.6538 16.2582V4.3956C17.6538 4.02547 17.5068 3.67049 17.2451 3.40876C16.9833 3.14704 16.6284 3 16.2582 3Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M20.956 7.00591V19.5663C20.956 19.9365 20.809 20.2914 20.5473 20.5532C20.2855 20.8149 19.9306 20.9619 19.5604 20.9619H7M7.3489 12.7837V7.50833C7.34784 7.41826 7.37101 7.32956 7.416 7.25152C7.46098 7.17348 7.52612 7.10896 7.60459 7.06474C7.68306 7.02051 7.77198 6.99819 7.86204 7.00012C7.95209 7.00204 8.03998 7.02815 8.11648 7.07569L12.6801 9.71338C12.7553 9.7578 12.8177 9.82106 12.861 9.89693C12.9043 9.9728 12.9271 10.0587 12.9271 10.146C12.9271 10.2334 12.9043 10.3192 12.861 10.3951C12.8177 10.471 12.7553 10.5342 12.6801 10.5787L8.11648 13.2861C8.03486 13.3385 7.9398 13.3662 7.84279 13.3658C7.74579 13.3654 7.65097 13.3369 7.5698 13.2838C7.48864 13.2306 7.42458 13.1551 7.38538 13.0664C7.34618 12.9777 7.33351 12.8795 7.3489 12.7837Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default DigitalGoodsIcon;
