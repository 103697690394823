import React, {useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import KYCModalScreen from '../../../KYCModalScreen';
import {alertActions} from '../../../../../../state/actions/alert.actions';
import {bankingActions} from '../../../../../../state/actions/banking.actions';
import LegalRepresentativeInformation from '../../substeps/LegalRepresentativeInformation';
import LegalRepresentativeVerifyIdentity from '../../substeps/LegalRepresentativeVerifyIdentity';
import LegalRepresentativeDocuments from '../../substeps/LegalRepresentativeDocuments';
import {StyledKYCModalSpin} from '../../../KYCModalScreen/StyledKYCModalScreen';
import {bankingUserTypesConstants} from '../../../../../../constants';
import {cardsHelpers, kycHelpers, objectHelpers} from '../../../../../../utils/helpers';

const {LEGAL_REPRESENTATIVE} = bankingUserTypesConstants;

const LegalRepresentative = ({
  dispatch,
  companyState,
  invitedUser,

  createDocument,
  createUser,
  getCompanyState,
  getInvitationDetails,
  getUsers,

  onFinish,
  ...rest
}) => {
  const [trans] = useTranslation(['main', 'quickStart']);

  const [step, setStep] = useState(1);
  const [confirmError, setConfirmError] = useState('');
  const [isCompanyLR, setIsCompanyLR] = useState(false);
  const [documentType, setDocumentType] = useState(undefined);
  const [user, setUser] = useState(null);
  const [disabledOk, setDisabledOk] = useState(false);
  const [loading, setLoading] = useState(true);

  const informationFormRef = useRef(null); // step 1
  const documentsFormRef = useRef(null); // step 3

  const enabledOk = useMemo(() => ![2, 4].includes(step), [step]);

  const t = (key) => trans(`quickStart:kyc.${key}`);

  const finishLoading = () => setLoading(false);

  const getUser = () => {
    getUsers(
      {user_type: LEGAL_REPRESENTATIVE},
      (users) => {
        users.length > 0 && setUser(users[0]);
        finishLoading();
      },
      finishLoading
    );
  }

  const enabledCancel = useMemo(() => Boolean(step === 4 && confirmError), [confirmError, step]);

  useEffect(() => {
    const state = objectHelpers.getObjProp(companyState, 'state');
    if (state) {
      const states = {
        'lr_created': 2,
        'lr_submitting_documents': 2,
        'lr_documents_submitted': 4
      }
      const activeStep = states[state] || 1;

      if (activeStep !== step) setStep(activeStep);
      if (activeStep > 1 && !isCompanyLR) setIsCompanyLR(true);
    }
  }, [companyState]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (step !== 1 && user === null) getUser();
  }, [step]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => getUser(), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user && invitedUser === null) {
      setLoading(true);
      getInvitationDetails(
        user.id,
        (invitation) => {
          const accepted = invitation.is_accepted;
          if (accepted) {
            setStep(4);
            setIsCompanyLR(true);
          }
          finishLoading();
        },
        finishLoading
      );
    }
  }, [invitedUser, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const onOk = () => {
    const actions = {
      1: () => informationFormRef.current.click(),
      3: () => documentsFormRef.current.submit()
    }
    if (actions.hasOwnProperty(step)) actions[step]();
  }

  const onCancel = () => {
    step !== 1 && setStep(1);
    confirmError && setConfirmError('');
  }

  const increaseStep = () => setStep(step + 1);

  const handleDocumentsSubmit = (fields, successCallback, errorCallback) => {
    const notEmptyFields = Object.keys(fields).filter(key => fields[key] !== undefined).map(key => ({name: key, file: fields[key]}));
    if (notEmptyFields.length === 0) {
      alertActions.error(t('validation.pleaseUploadDocument'));
      errorCallback && errorCallback();
      return;
    }

    const documentTypeId = kycHelpers.getDocumentTypeId(documentType);
    notEmptyFields.forEach(async (field, index) => {
      const data = await cardsHelpers.getUploadedFilePayload(user.id, field.file.originFileObj, documentTypeId);
      const latest = index === notEmptyFields.length - 1;
      createDocument(
        data,
        () => {
          if (latest) {
            successCallback && successCallback();
            onFinish && onFinish();
          }
        },
        (response) => {
          const errors = kycHelpers.getFileFormErrors({name: field.name, response, t: trans});
          errorCallback && errorCallback(errors);
        }
      )
    });
  }

  const handleLRInformationFinish = () => increaseStep();

  const handleSelectDocumentType = ({key}) => {
    setDocumentType(key);
    increaseStep();
  };

  const handleUpdateLROkEnable = (enable) => {
    if (step === 1 && enable === disabledOk) setDisabledOk(!enable);
  }

  const getStepContent = () => {
    const screens = {
      1: (
        <LegalRepresentativeInformation
          className='column-form-content'
          onFinish={handleLRInformationFinish}
          handleUpdateOkEnable={handleUpdateLROkEnable}
          handleLoadUser={getUser}
          user={user}
          ref={informationFormRef}
        />
      ),
      2: (
        <LegalRepresentativeVerifyIdentity onSelect={handleSelectDocumentType} />
      ),
      3: (
        <LegalRepresentativeDocuments
          onSubmit={handleDocumentsSubmit}
          ref={documentsFormRef}
          variant={documentType}
        />
      )
    }
    return screens[step] || null;
  }

  const stepContent = getStepContent();

  return (
    <KYCModalScreen
      enabledCancel={enabledCancel}
      enabledOk={enabledOk}
      okProps={{disabled: disabledOk}}
      onCancel={onCancel}
      onOk={onOk}
      {...rest}
    >
      <StyledKYCModalSpin
        size='small'
        spinning={loading}
      >
        {stepContent}
      </StyledKYCModalSpin>
    </KYCModalScreen>
  );
}

LegalRepresentative.propTypes = {
  onFinish: PropTypes.func
}

const mapStateToProps = state => {
  const {companyState, invitedUser} = state.banking;

  return {
    companyState,
    invitedUser,
  }
}

const mapDispatchToProps = {
  createDocument: bankingActions.createDocument,
  getCompanyState: bankingActions.getCompanyState,
  getUsers: bankingActions.getCardsUsers,
  getInvitationDetails: bankingActions.getInvitationDetails,
}

export default connect(mapStateToProps, mapDispatchToProps)(LegalRepresentative);
