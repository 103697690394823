import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'antd';
import {StyledInvoiceMissingModal} from './StyledInvoiceMissingModal';
import AntdModal from '../../../AntdModal';
import {AddInvoiceIcon} from '../../../../icons';

const InvoiceMissingModal = ({
  onOk,
  open,
  ...rest
}) => {
  const [trans] = useTranslation('transactions');

  const t = (key, props) => trans(`transactions:modal.invoiceMissing.${key}`, props);

  return (
    <AntdModal
      footer={[
        <Button type='primary' size='large' key='okButton' onClick={onOk}>
          {t('okButtonText')}
        </Button>
      ]}
      open={open}
      title={t('title')}
      {...rest}
    >
      <StyledInvoiceMissingModal>
        <p>{t('description1')} (<AddInvoiceIcon />) {t('description2')}</p>
      </StyledInvoiceMissingModal>
    </AntdModal>
  );
}

InvoiceMissingModal.propTypes = {
  open: PropTypes.bool,
}

export default InvoiceMissingModal;
