import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import {Alert} from 'antd';
import AntdModal from '../../../AntdModal';
import CopyField from '../../../CopyField';
import {StyledCopyFieldsContainer, StyledWireDetailsModal} from './StyledWireDetailsModal';
import {alertActions} from '../../../../state/actions/alert.actions';
import {transactionsHelpers} from '../../../../utils/helpers';

const WireDetailsModal = ({
  open,
  companyName,
  details,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'transactions']);

  const trans = (key, props) => t(`transactions:modal.wireDetails.${key}`, props);

  const {bic, iban}  = useMemo(() => transactionsHelpers.getWireDetails(details), [details]);

  const copyFieldProps = {
    buttonType: 'icon',
    onCopy: () => alertActions.success(`${t('copied')}!`)
  }

  const content = (iban || bic) ? (
    <>
      <p>{trans('sendYourFunds')}:</p>
      <StyledCopyFieldsContainer>
        <CopyField
          {...copyFieldProps}
          label={`${t('accountName')}:`}
          value={companyName}
          data-testid='account-name'
        />
        <CopyField
          {...copyFieldProps}
          label='IBAN:'
          value={iban}
          data-testid='iban'
        />
        <CopyField
          {...copyFieldProps}
          label='SWIFT / BIC:'
          value={bic}
          data-testid='bic'
        />
      </StyledCopyFieldsContainer>

      <Alert
        message={trans('warningMessage', {name: companyName})}
        type='warning'
        showIcon
      />
    </>
  ) : (
    <>
      <p>{trans('notAvailableKYC1')}</p>
      <p>{trans('notAvailableKYC2')}</p>
    </>
  );

  return (
    <AntdModal
      footer={false}
      open={open}
      title={trans('title')}
      {...rest}
    >
      <StyledWireDetailsModal>
        {content}
      </StyledWireDetailsModal>
    </AntdModal>
  );
}

WireDetailsModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func,
  open: PropTypes.bool.isRequired,
  details: PropTypes.shape({
    bic: PropTypes.string,
    iban: PropTypes.string
  })
}


const mapStateToProps = state => {
  const {name: companyName} = state.company;

  return {
    companyName
  }
}

export default connect(mapStateToProps, null)(WireDetailsModal);
