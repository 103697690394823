import dayjs from 'dayjs';
import moment from 'moment';
import React from 'react';
import DatePickerPanel from '../../components/DatePickerPanel';

export const dateHelpers = {
  getCurrentDate: () => {
    const date = moment(new Date());
    const year = parseInt(date.format('YYYY'));
    const month = parseInt(date.format('M'));
    const day = parseInt(date.format('D'));
    return { date, year, month, day };
  },
  getDate: (date, format) => moment(date, 'YYYY-MM-DD').format(format),
  getDateFromISO: (date, format) => moment(date, 'YYYY-MM-DDTHH:mm:ssZ').format(format),
  getDateWithMonth: (date, format = 'DD MMM YYYY') => moment(date, 'YYYY-MM-DD').format(format),
  getDateWithGMT: (date) => {
    let newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
    return newDate;
  },
  getDateWithGMTFormat: (date, format) => dateHelpers.getDate(dateHelpers.getDateWithGMT(date), format),
  getTimestampDate: (date, format) => {
    date = String(date).split('.')[0];
    if (date.length === 10) date = date * 1000;
    return moment(date).format(format);
  },
  getTimestampDateObject: (date) => {
    date = String(date).split('.')[0];
    if (date.length === 10) date = date * 1000;
    return moment(date).toDate();
  },
  getMomentUnixTimestamp: (date) => (date && date.unix instanceof Function ? date.unix() : undefined),
  getSecondsTime: (seconds) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return moment(date).format('mm:ss');
  },
  getDatePickerPanelRender: (panelNode) => (
    <DatePickerPanel>
      {panelNode}
    </DatePickerPanel>
  ),
  getNextWorkingDay: (date) => {
    if (!date) return undefined;

    let result = date;

    if (result.day() === 6) {
      result = result.add(2, 'day');
    } else if (result.day() === 0) {
      result = result.add(1, 'day');
    }

    return result;
  },
  getFullMonthsFromToday: (targetDate) => {
    const today = dayjs().startOf('day');
    const target = dayjs(targetDate).startOf('day');

    // Calculate full months difference and add 2 to include current & target months
    return today.diff(target, 'month') + 2;
  }
}
