import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';
import {Avatar} from 'antd';
import {
  StyledMainNavigation,
  StyledMainNavigationMenuIcon,
} from './StyledMainNavigation';
import Navigation from '../Navigation';
import {
  CreditCardRoundedIcon,
  FolderFilledIcon,
  HomeIcon,
  InvoiceIcon,
  TeamsIcon,
  TransactionOrderIcon
} from '../../icons';
import routesList from '../../routes/routes.json';
import {cardsHelpers, subscriptionsHelpers, systemHelpers, textHelpers} from '../../utils/helpers';
import {
  availableModulesConstants,
  subscriptionPaymentTypesConstants,
  subscriptionViewTypesConstants
} from '../../constants';
import {cardActions} from '../../state/actions/card.actions';
import {mainActions} from '../../state/actions/main.actions';
import {subscriptionActions} from '../../state/actions/subscription.actions';
import {useIsEmployeeChanged, useIsEmployeeLoaded, useIsRequiredCardVerification} from '../../hooks';

const {CARDS, TRANSACTIONS, SUBSCRIPTIONS} = availableModulesConstants;

const isAvailableSubscriptionsModule = systemHelpers.checkIfAvailableFeature(SUBSCRIPTIONS);
const isAvailableTransactionsModule = systemHelpers.checkIfAvailableFeature(TRANSACTIONS);

const availableTransactions = isAvailableSubscriptionsModule && isAvailableTransactionsModule;


const MainNavigation = ({
  dispatch,
  availableModules,
  isAdmin,
  isEnabledBanking,
  isExistsBankingUser,
  navigationMenuProps,
  getCards,
  getSubscriptions,
  setNavigationLoading,
  setNavigationMenuProps,
  ...rest
}) => {
  const [t] = useTranslation('main');
  const [userCards, setUserCards] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [navigationProps, setNavigationProps] = useState({dividedKeys: [], items: []});

  const location = useLocation();
  const navigate = useNavigate();

  const isEmployeeLoaded = useIsEmployeeLoaded();

  const isEmployeeChanged = useIsEmployeeChanged();

  const {
    checked: isCheckedBankingDetails,
    required: isRequiredCardVerification
  } = useIsRequiredCardVerification();

  const isDisabledModule = (name) => !availableModules.includes(name);

  const finishLoading = () => setLoading(false);

  const storeNavigationProps = ({dividedKeys = [], items = []} = {}) => {
    setNavigationProps({dividedKeys, items});
    setNavigationMenuProps({dividedKeys, items: items.map(({ icon, ...rest }) => rest)});
  }

  const formatNavigationItem = (item) => ({
    disabled: item.disabled,
    icon: item.icon,
    key: item.title,
    label: t(`navigation.${item.title}`),
    path: item.path
  });

  const loadCards = () => {
    getCards(
      undefined,
      (response) => {
        setUserCards(response?.results || []);
        finishLoading();
      },
      finishLoading
    );
  }

  const loadSubscriptions = () => {
    getSubscriptions(
      {view: subscriptionViewTypesConstants.AUTHORIZED},
      (response) => {
        setUserSubscriptions(response?.subscriptions || []);
        finishLoading();
      },
      finishLoading
    );
  }

  const navigationItems = useMemo(() => {
    let items = [...navigationProps.items];
    if (!isAdmin) {
      items = [
        formatNavigationItem({
          available: availableTransactions,
          disabled: !isExistsBankingUser,
          icon: <TransactionOrderIcon />,
          path: routesList.transactionsList,
          title: 'allTransactions',
        }),
        ...items,
      ]
    }
    return items;
  }, [isAdmin, isExistsBankingUser, navigationProps.items]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoading(true);
  }, [isEmployeeChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isEmployeeLoaded && !isAdmin && isEnabledBanking) {
      setLoading(true);
      loadCards();
      loadSubscriptions();
    }
  }, [isEmployeeLoaded, isEnabledBanking, isRequiredCardVerification]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const disabledTransactions = isEnabledBanking ? isDisabledModule(TRANSACTIONS) : true;
    let items = [];
    let dividedKeys = [];
    if (isAdmin) {
      items = [
        {
          icon: <HomeIcon />,
          path: routesList.overview,
          title: 'overview'
        },
        {
          available: isAvailableSubscriptionsModule,
          disabled: isDisabledModule(SUBSCRIPTIONS),
          icon: <FolderFilledIcon />,
          path: routesList.subscriptionsList,
          title: 'subscriptions',
        },
        {
          available: isAvailableSubscriptionsModule,
          disabled: isDisabledModule(CARDS),
          icon: <CreditCardRoundedIcon />,
          path: routesList.cardsList,
          title: 'cards',
        },
        {
          available: availableTransactions,
          disabled: disabledTransactions,
          icon: <TransactionOrderIcon />,
          path: routesList.transactionsList,
          title: 'transactions'
        },
        {
          available: availableTransactions,
          disabled: disabledTransactions,
          icon: <InvoiceIcon />,
          path: routesList.invoicesList,
          title: 'invoices'
        },
        {
          icon: <TeamsIcon />,
          title: 'team',
          path: routesList.team
        },
      ];
      dividedKeys = ['invoices'];
      setLoading(false);

      // clear previous state is needed
      userCards.length && setUserCards([]);
      userSubscriptions.length && setUserSubscriptions([]);

      // show only available features
      items = items
        .filter(i => i.available !== false)
        .map(i => formatNavigationItem(i));
      storeNavigationProps({dividedKeys, items});
    }
  }, [isAdmin, t, isRequiredCardVerification, availableModules]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let items = [];
    let dividedKeys = [];
    if (!isAdmin && isAvailableSubscriptionsModule) {
      let id, label, logo, key, ownerId;
      const isDisabledSubscriptionPage = isDisabledModule(SUBSCRIPTIONS) || isRequiredCardVerification;
      const isDisabledCardPage = isDisabledModule(CARDS) || isRequiredCardVerification;
      // create menu array from subscriptions
      const subscriptionsItems = userSubscriptions.map(subscription => {
        id = subscription.id;
        key = `subscription-${id}`;
        label = subscriptionsHelpers.getSubscriptionName(subscription);
        logo = subscription?.service?.logo || undefined;
        return {
          disabled: isDisabledSubscriptionPage && subscription.payment_type === subscriptionPaymentTypesConstants.CARD,
          icon: (
            <Avatar src={logo} size={18}>
              {textHelpers.getInitials(label)}
            </Avatar>
          ),
          key,
          label,
          path: `${routesList.subscriptionsList}/${id}`,
          variant: 'subscription'
        }
      }).sort((a, b) => a.label.localeCompare(b.label));
      // create menu array from cards
      userCards.forEach((card, index) => {
        id = card.card_id;
        key = `card-${id}`;
        ownerId = card?.owner?.id;
        items.push({
          disabled: isDisabledCardPage,
          icon: (
            <StyledMainNavigationMenuIcon>
              {cardsHelpers.getCardIcon(card)}
            </StyledMainNavigationMenuIcon>
          ),
          key,
          label: `${card?.masked_pan ? '**' : ''}${cardsHelpers.getLast4Digits(card?.masked_pan)}`,
          path: ownerId && `${routesList.cardsList}/${ownerId}/${id}`,
          variant: 'card'
        });
        // redirect to first card available page if current page is overview
        if (location.pathname === routesList.overview && !isDisabledCardPage && isCheckedBankingDetails && !isRequiredCardVerification && !Boolean(index) && ownerId) {
          navigate(`${routesList.cardsList}/${ownerId}/${id}`);
        }
      });
      // finish loading if banking details is checked
      if (isCheckedBankingDetails) {
        finishLoading();
        setNavigationLoading(false);
      }

      subscriptionsItems.forEach(item => {
        items.push(item);
        // divide subscription menu items if cards list is not empty
        if (Boolean(userCards.length)) dividedKeys.push(item.key);
      });

      storeNavigationProps({dividedKeys, items});
    }
  }, [isCheckedBankingDetails, userCards, userSubscriptions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledMainNavigation {...rest}>
      <Navigation
        dividedKeys={navigationProps.dividedKeys}
        items={navigationItems}
        loading={loading}
      />
    </StyledMainNavigation>
  )
}

const mapStateToProps = state => {
  const {banking, main, user} = state;

  return {
    availableModules: main.availableModules?.modules || [],
    isAdmin: user.isAdmin,
    isEnabledBanking: banking.isEnabledBanking,
    isExistsBankingUser: banking?.verification?.isCreatedUser,
    navigationMenuProps: main?.navigationMenuProps
  }
}

const mapDispatchToProps = {
  getCards: cardActions.getCards,
  getSubscriptions: subscriptionActions.getSubscriptionsList,
  setNavigationLoading: mainActions.setNavigationLoading,
  setNavigationMenuProps: mainActions.setNavigationMenuProps
}

export default connect(mapStateToProps, mapDispatchToProps)(MainNavigation);