const navExpandWidth = 236;
const navCollapseWidth = 72;
const containerExpandWidth = `calc(100% - ${navExpandWidth}px)`;
const containerCollapseWidth = `calc(100% - ${navCollapseWidth}px)`;

const getDuration = (value) => ({ transition: `width ${value}ms ease-in-out` });

export const containerStyles = (duration) => {
  const transition = getDuration(duration);

  return {
    container: {
      entering: { ...transition, width: containerCollapseWidth },
      entered: { ...transition, width: containerCollapseWidth },
      exiting: { ...transition, width: containerExpandWidth },
      exited: { ...transition, width: containerExpandWidth }
    },
    navigation: {
      entering: { ...transition, width: navExpandWidth },
      entered: { ...transition, width: navExpandWidth },
      exiting: { ...transition, width: navCollapseWidth },
      exited: { ...transition, width: navCollapseWidth }
    }
  };
};