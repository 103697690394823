// Built-in & External Libraries
import React, { useMemo, useRef, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Toaster } from 'react-hot-toast';
import { Transition } from 'react-transition-group';
import { LastLocationProvider } from 'react-router-dom-last-location';

import './App.css';

import HashWindowManager from '../components/HashWindowManager';
import MainNavigation from '../components/MainNavigation';
import ScreenExtensionBlockerWindow from '../components/ScreenExtensionBlockerWindow';
import BalanceLoading from '../components/pages/TransactionsPage/BalanceLoading';
import SystemModals from '../components/SystemModals';
import SystemDataPreloader from '../components/SystemDataPreloader';
import UserCompanyInitializer from '../components/UserCompanyInitializer';
import { useIsEmployeeLoaded, useWindowExtensionBlock } from '../hooks';
import { systemHelpers } from '../utils/helpers';
import { history } from '../state/history';
import routesList from '../routes/routes.json';
import { StyledApp, StyledContentContainer, StyledNavContainer, StyledSpin } from './StyledApp';
import { containerStyles } from './containerStyles';
import Routes from '../routes/Routes';
import { SYSTEM_ANIMATION_DURATION } from '../constants';


const publicPaths = systemHelpers.getPublicPaths();

const pageContainerStyles = containerStyles(SYSTEM_ANIMATION_DURATION);

const isEnablePath = () => {
  const paths = [...publicPaths, routesList.invite, routesList.verification];
  let isEnable = false;

  paths.forEach(path => {
    if (history.location.pathname.startsWith(path)) {
      isEnable = true;
    }
  });
  return isEnable;
}


const App = ({
  loading,
  isAuthenticated,
  initialLoading,
  isAdmin,
  isOpenMenu,
}) => {
  const [isDisabledDataLoading, setIsDisabledDataLoading] = useState(false);
  const isEmployeeLoaded = useIsEmployeeLoaded();
  const navigationNodeRef = useRef(null);
  const containerNodeRef = useRef(null);

  const { isBlocked: isBlockedScreen } = useWindowExtensionBlock(767);
  const [t] = useTranslation('main');

  const mainLoading = useMemo(() => {
    return !(isDisabledDataLoading ? true : !initialLoading && (isAuthenticated ? isEmployeeLoaded : true))
  }, [isDisabledDataLoading, initialLoading, isAuthenticated, isEmployeeLoaded]);

  const isEnabledBlockedScreen = useMemo(() => isBlockedScreen && !isEnablePath(), [isBlockedScreen]);

  const handleDisableDataLoading = () => setIsDisabledDataLoading(true);

  if (isEnabledBlockedScreen) {
    let description = (
      <>
        {t('messages.screenExtensionBlockerDescription1')}<br/>
        {t('messages.screenExtensionBlockerDescription2')}<br/>
        {t('messages.screenExtensionBlockerDescription3')}
      </>
    );
    return (
      <ScreenExtensionBlockerWindow
        description={description}
        title={t('messages.screenExtensionBlockerTitle')}
      />
    );
  }

  const routes = <Routes isAuth={isAuthenticated} />;

  return (
    <StyledApp className='App'>
      <StyledSpin
        className='white'
        spinning={mainLoading}
        wrapperClassName='full-width-spin'
      >
        <StyledSpin
          spinning={loading}
          wrapperClassName='full-width-spin'
        >
          <Router history={history}>
            <LastLocationProvider>

              {/* Routes for not auth user */}

              {!isAuthenticated && routes}

              {/* View, Navigation & Routes for auth user */}

              {isAuthenticated && (
                <div className='d-flex flex-row'>
                  <Transition nodeRef={navigationNodeRef} in={isOpenMenu} timeout={SYSTEM_ANIMATION_DURATION}>
                    {state => (
                      <StyledNavContainer
                        ref={navigationNodeRef}
                        style={pageContainerStyles.navigation[state]}
                      >
                        <MainNavigation />
                      </StyledNavContainer>
                    )}
                  </Transition>
                  <Transition nodeRef={containerNodeRef} in={!isOpenMenu} timeout={SYSTEM_ANIMATION_DURATION}>
                    {state => (
                      <StyledContentContainer
                        id='app-content-container'
                        ref={containerNodeRef}
                        style={pageContainerStyles.container[state]}
                      >
                        {routes}
                      </StyledContentContainer>
                    )}
                  </Transition>

                  <Toaster position='bottom-right' />

                </div>
              )}

              <HashWindowManager />

            </LastLocationProvider>
          </Router>
        </StyledSpin>
      </StyledSpin>

      {isAuthenticated && (
        <>
          {/* Component with modal windows  */}
          <SystemModals />

          {isAdmin && <BalanceLoading />}
        </>
      )}

      {/* Load information to correct system work */}
      <SystemDataPreloader />

      {/* Initialize company and user */}
      <UserCompanyInitializer handleDisableDataLoading={handleDisableDataLoading} />

    </StyledApp>
  );
}

const mapStateToProps = ({ main, user }) => ({
  initialLoading: main.initialLoading,
  isAdmin: user.isAdmin,
  isAuthenticated: user.isAuth,
  isOpenMenu: main.isOpenMenu,
  loading: main.loading,
});

export default connect(mapStateToProps, null)(App);
