import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import {Button} from 'antd';
import {StyledUnmatchedInvoicesModal} from './StyledUnmatchedInvoicesModal';
import AntdModal from '../../../AntdModal';
import {AddInvoiceIcon} from '../../../../icons';

const UnmatchedInvoicesModal = ({
  onOk,
  open,
  ...rest
}) => {
  const [trans] = useTranslation('invoices');

  const t = (key, props) => trans(`invoices:modal.actionRequired.${key}`, props);

  return (
    <AntdModal
      footer={[
        <Button type='primary' size='large' key='okButton' onClick={onOk}>
          {t('okButtonText')}
        </Button>
      ]}
      open={open}
      title={t('title')}
      {...rest}
    >
      <StyledUnmatchedInvoicesModal>
        <p>{t('description1')} (<AddInvoiceIcon />) {t('description2')}</p>
      </StyledUnmatchedInvoicesModal>
    </AntdModal>
  );
}

UnmatchedInvoicesModal.propTypes = {
  open: PropTypes.bool,
}

export default UnmatchedInvoicesModal;
