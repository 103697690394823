import styled from 'styled-components';

const StyledInvoiceTypeSelect = styled.div`
  flex-direction: column;
  display: flex;
  margin-bottom: 16px;
  
  label.ant-radio-wrapper-checked {
    font-weight: 400;
  }
`;

const StyledInvoiceTypeSelectLabel = styled.label`
  display: inline-block;
  margin-bottom: 8px;
`;


export {
  StyledInvoiceTypeSelect,
  StyledInvoiceTypeSelectLabel
}
