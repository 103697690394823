import { userConstants } from '../constants';

const defaultNotificationData = {
  notificationCount: {
    read: 0,
    unread: 0
  },
  notifications: {
    notifications: [],
    pagination: {
      next_page: null,
      cursor: null
    }
  },
}

const userState = {
  user: {},
  employee: {},
  availableBudgets: [],
  companies: [],
  notificationSettings: {},
  popupSettings: {},
  settings: {},
  companiesWithNotifications: [],
  isAuth: false,
  checkedAuth: false,
  isSupport: false,
  isAdmin: false,
  isKnownEmployee: false,
  updateUserPropsErrors: null,
  disabledCompany: null,
  ...defaultNotificationData
};


const userReducer = (state = userState, action) => {
  const {type, data, user, companies, isAuth} = action;
  switch (type) {
    case userConstants.GET_COMPANIES:
      return {
        ...state,
        companies
      }
    case userConstants.GET_EMPLOYEE_PROFILE:
      return {
        ...state,
        employee: user,
        isSupport: action.isSupport,
        isAdmin: action.isAdmin,
        isKnownEmployee: action.isKnownEmployee
      }
    case userConstants.GET_EMPLOYEE_SETTINGS:
      return {
        ...state,
        settings: data
      }
    case userConstants.GET_EMPLOYEE_NOTIFICATION_SETTINGS:
      return {
        ...state,
        notificationSettings: data
      }
    case userConstants.GET_EMPLOYEE_POPUP_SETTINGS:
      return {
        ...state,
        popupSettings: data
      }
    case userConstants.GET_USER_PROFILE:
      return {
        ...state,
        isAuth: true,
        checkedAuth: true,
        user
      }
    case userConstants.SET_CHECKED_AUTH:
      return {
        ...state,
        checkedAuth: true
      }
    case userConstants.SET_AUTH:
      return {
        ...state,
        isAuth
      }
    case userConstants.GET_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notificationCount: data
      }
    case userConstants.GET_NOTIFICATIONS_LIST:
      return {
        ...state,
        notifications: data
      }
    case userConstants.SUCCESS_GET_UNREAD_NOTIFICATIONS_BY_COMPANY:
      return {
        ...state,
        companiesWithNotifications: data
      }
    case userConstants.ERROR_GET_UNREAD_NOTIFICATIONS_BY_COMPANY:
      return {
        ...state,
        companiesWithNotifications: []
      }
    case userConstants.DISABLED_FROM_COMPANY:
      return {
        ...state,
        disabledCompany: data
      }
    case userConstants.CLEAR_USER_DATA:
      return {
        ...state,
        isAdmin: false,
        isSupport: false,
        isKnownEmployee: false,
        companies: [],
        disabledCompany: null,
        user: {},
        employee: {},

        // clear settings
        notificationSettings: {},
        popupSettings: {},
        settings: {},

        ...defaultNotificationData
      }
    case userConstants.READ_ALL_NOTIFICATIONS:
    case userConstants.READ_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications,
        notificationCount: action.notificationCount
      }
    case userConstants.CLEAR_NOTIFICATIONS:
      return {
        ...state,
        ...defaultNotificationData
      }
    default:
      return state
  }
};

export default userReducer;
