const ComputerProgrammingDataProcessingAndIntegratedSystemDesignServicesIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 16L19.84 17.586C20.613 18.253 21 18.586 21 19C21 19.414 20.613 19.747 19.84 20.414L18 22M14 16L12.16 17.586C11.387 18.253 11 18.586 11 19C11 19.414 11.387 19.747 12.16 20.414L14 22"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M20 13.003V7.82C20 6.126 20 5.28 19.732 4.603C19.302 3.516 18.39 2.658 17.235 2.253C16.517 2 15.617 2 13.818 2C10.67 2 9.096 2 7.838 2.441C5.818 3.151 4.223 4.652 3.468 6.555C3 7.74 3 9.221 3 12.185V14.731C3 17.801 3 19.336 3.848 20.403C4.091 20.708 4.378 20.979 4.703 21.208C5.615 21.851 6.85 21.976 9 22"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M3 12C3 11.116 3.35115 10.2683 3.97621 9.64321C4.60127 9.01815 5.44903 8.667 6.333 8.667C6.999 8.667 7.784 8.783 8.431 8.61C8.71371 8.53392 8.97145 8.38485 9.17838 8.17774C9.38531 7.97064 9.53416 7.71277 9.61 7.43C9.783 6.783 9.667 5.998 9.667 5.332C9.66726 4.44821 10.0185 3.6007 10.6436 2.97586C11.2686 2.35102 12.1162 2 13 2"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default ComputerProgrammingDataProcessingAndIntegratedSystemDesignServicesIcon;
