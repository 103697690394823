import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { KYCModalModeTypes, localStorageKeysConstants } from '../../constants';
import CompanyInviteModal from '../quickStart/CompanyInviteModal/CompanyInviteModal';
import EmailVerificationModal from '../quickStart/EmailVerificationModal/EmailVerificationModal';
import CompanyDisableModal from '../quickStart/CompanyDisableModal/CompanyDisableModal';
import LightKYCModal from '../quickStart/LightKYCModal/LightKYCModal';
import WelcomeModal from '../quickStart/WelcomeModal';
import KYCModal from '../quickStart/KYCModal/KYCModal';

import { mainActions } from '../../state/actions/main.actions';
import { userActions } from '../../state/actions/user.actions';


const {SHOW_WELCOME} = localStorageKeysConstants;

const SystemModals = ({
  companyInviteToken,
  disabledCompany,
  emailVerificationToken,
  isAuthenticated,
  showWelcome,

  changeCompany,
  disableEmployeeFromCompany,
  getCompanies,
  openKycWindow,
}) => {
  const [isOpenCompanyInviteModal, setIsOpenCompanyInviteModal] = useState(false);
  const [isOpenWelcomeModal, setIsOpenWelcomeModal] = useState(false);
  const [isOpenVerificationModal, setIsOpenVerificationModal] = useState(false);
  const [companyDisableModalProps, setCompanyDisableModalProps] = useState({open: false, company: null});

  const showWelcomeModal = useMemo(() => {
    return !isOpenVerificationModal && isAuthenticated && isOpenWelcomeModal && !isOpenCompanyInviteModal;
  }, [isAuthenticated, isOpenVerificationModal, isOpenWelcomeModal, isOpenCompanyInviteModal])

  useEffect(() => {
    if (emailVerificationToken) setIsOpenVerificationModal(true);
  }, [emailVerificationToken]);

  useEffect(() => {
    if (disabledCompany) {
      const {isDisabled, company} = disabledCompany;
      setCompanyDisableModalProps({...companyDisableModalProps, open: isDisabled, company});
    }
  }, [disabledCompany]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showWelcome) setTimeout(() => setIsOpenWelcomeModal(true), 500);
  }, [showWelcome]);

  useEffect(() => {
    if (companyInviteToken) setIsOpenCompanyInviteModal(true);
  }, [companyInviteToken]);

  useEffect(() => {
    if (isAuthenticated) {
      if (localStorage.getItem(SHOW_WELCOME) === 'true') {
        setIsOpenWelcomeModal(true);
      }

      getCompanies(
        (company) => disableEmployeeFromCompany(true, company),
        (data) => !Boolean(data.length) && setIsOpenWelcomeModal(true)
      );
    }
  }, [getCompanies, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps


  const removeShowVerification = () => {
    setIsOpenWelcomeModal(false);
    localStorage.removeItem(SHOW_WELCOME);
  }

  const handleCloseCompanyInviteModal = () => {
    setIsOpenCompanyInviteModal(false);
    removeShowVerification();
  }

  const handleCancelVerificationModal = () => {
    setIsOpenVerificationModal(false);
    removeShowVerification();
  }

  const handleCloseDisableModal = () => {
    setCompanyDisableModalProps({...companyDisableModalProps, open: false});
    disableEmployeeFromCompany();
  }

  const handleOkDisableModal = () => {
    handleCloseDisableModal();
    openKycWindow({closeIcon: false});
  }

  const handleOkWelcomeModal = () => {
    setIsOpenWelcomeModal(false);
    openKycWindow({mode: KYCModalModeTypes.COMPANY});
  }

  const handleOkCompanyCreationModal = (createdCompanyData) => {
    const {id: companyId, isNeedToChange} = createdCompanyData;
    if (isNeedToChange) changeCompany(companyId);
  }

  return (
    <>
      <WelcomeModal
        enabledCompanyCreation={false}
        open={showWelcomeModal}
        handleOk={handleOkWelcomeModal}
      />

      <LightKYCModal />

      <KYCModal onOk={handleOkCompanyCreationModal} />

      <EmailVerificationModal
        open={isOpenVerificationModal}
        handleCancel={handleCancelVerificationModal}
      />

      <CompanyInviteModal
        open={isOpenCompanyInviteModal}
        handleClose={handleCloseCompanyInviteModal}
      />

      <CompanyDisableModal
        {...companyDisableModalProps}
        handleClose={handleCloseDisableModal}
        handleOk={handleOkDisableModal}
      />
    </>
  );
};

const mapStateToProps = ({ auth, user }) => ({
  companyInviteToken: auth.companyInviteToken,
  disabledCompany: user.disabledCompany,
  emailVerificationToken: auth.emailVerificationToken,
  isAuthenticated: user.isAuth,
  showWelcome: auth.showWelcome,
});

const mapDispatchToProps = {
  changeCompany: userActions.changeCompany,
  disableEmployeeFromCompany: userActions.disableEmployeeFromCompany,
  getCompanies: userActions.getCompanies,
  openKycWindow: mainActions.openKycWindow,
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemModals);
