import { InputNumber } from 'antd';
import Card from '../../../../Card';
import styled from 'styled-components';

const StyledCompanyInfo = styled(Card)`
  .ant-card-head {
    flex-direction: unset;
    padding: 24px;
  }

  .company-info-details {
    padding: 0;
  }
  
  .title-error-message {
    color: ${props => props.theme.colors.functional_danger} !important;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
  }
`;

const StyledCompanyInfoAmountInput = styled(InputNumber)`
  width: 100%;
  height: 38px;
  
  &.ant-input-number-group-wrapper-status-error {
    .ant-input-number-group-addon {
      &:first-child {
        border-color: ${props => props.theme.colors.functional_danger};
      }
    }
  }
  
  .ant-input-number-group-addon {
    background-color: transparent;
    svg {
      width: 16px;

      path {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }
  }
`;

const StyledCompanyInfoTitle = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
`;

export {
  StyledCompanyInfo,
  StyledCompanyInfoAmountInput,
  StyledCompanyInfoTitle
}
