const DepartmentStoresIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.25 4V3.75H6H5.5C5.36193 3.75 5.25 3.63807 5.25 3.5V2.5C5.25 2.36193 5.36193 2.25 5.5 2.25H18.5C18.6381 2.25 18.75 2.36193 18.75 2.5V3.5C18.75 3.63807 18.6381 3.75 18.5 3.75H18H17.75V4V7.5C17.75 7.63807 17.6381 7.75 17.5 7.75H6.5C6.36193 7.75 6.25 7.63807 6.25 7.5V4ZM13.252 3.998V3.748H13.002H10.998H10.748V3.998V6.002V6.252H10.998H13.002H13.252V6.002V3.998ZM10.25 21.75V17.25H13.75V21.75H10.25Z"
      stroke="black" strokeWidth="0.5"/>
    <path
      d="M16.25 15V14.75H16H8H7.75V15V21.5C7.75 21.6381 7.63807 21.75 7.5 21.75H2.5C2.36193 21.75 2.25 21.6381 2.25 21.5V20.5C2.25 20.3619 2.36193 20.25 2.5 20.25H3H3.25V20V12V11.75H3H2.5C2.36193 11.75 2.25 11.6381 2.25 11.5V10.5C2.25 10.3619 2.36193 10.25 2.5 10.25H21.5C21.6381 10.25 21.75 10.3619 21.75 10.5V11.5C21.75 11.6381 21.6381 11.75 21.5 11.75H21H20.75V12V20V20.25H21H21.5C21.6381 20.25 21.75 20.3619 21.75 20.5V21.5C21.75 21.6381 21.6381 21.75 21.5 21.75H16.5C16.3619 21.75 16.25 21.6381 16.25 21.5V15Z"
      stroke="black" strokeWidth="0.5"/>
  </svg>
);

export default DepartmentStoresIcon;
