const TaxIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 10V18.5M8 10V18.5M11 18.5H5C4.20435 18.5 3.44129 18.8161 2.87868 19.3787C2.31607 19.9413 2 20.7044 2 21.5C2 21.6326 2.05268 21.7598 2.14645 21.8536C2.24021 21.9473 2.36739 22 2.5 22H11M21.5 14.5L14.5 21.5M2 8.57C2 7.373 2.482 6.64 3.48 6.084L7.59 3.797C9.743 2.6 10.82 2 12 2C13.18 2 14.257 2.6 16.41 3.797L20.52 6.084C21.517 6.64 22 7.373 22 8.57C22 8.894 22 9.057 21.965 9.19C21.779 9.89 21.144 10.001 20.531 10.001H3.469C2.856 10.001 2.222 9.891 2.035 9.19C2 9.056 2 8.894 2 8.57Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M15.25 16C15.6642 16 16 15.6642 16 15.25C16 14.8358 15.6642 14.5 15.25 14.5C14.8358 14.5 14.5 14.8358 14.5 15.25C14.5 15.6642 14.8358 16 15.25 16Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M20.75 21.5C21.1642 21.5 21.5 21.1642 21.5 20.75C21.5 20.3358 21.1642 20 20.75 20C20.3358 20 20 20.3358 20 20.75C20 21.1642 20.3358 21.5 20.75 21.5Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default TaxIcon;
