import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Alert, Avatar, Button, Form, Select, Space} from 'antd';
import {connect} from 'react-redux';
import {Collapse} from 'react-collapse';
import {
  StyledPaymentDetails,
  StyledPaymentDetailsCard,
  StyledPaymentDetailsDropdownButton,
  StyledPaymentDetailsInput,
  StyledPaymentDetailsSwitch,
} from './StyledPaymentDetails';
import DetailsTable from '../../../../TransactionsPage/DetailsTable';
import CardDetails from '../CardDetails';
import IncreaseLimitAlert from '../../../../SubscriptionsPage/IncreaseLimitAlert';
import {EditLineIcon, EuroIcon} from '../../../../../../icons';
import {
  subscriptionPaymentTypesConstants,
  subscriptionStatusesConstants,
  subscriptionFormFields,
  subscriptionFormValues
} from '../../../../../../constants';
import {
  cardsHelpers,
  CardLimitPeriodTypeOptions,
  subscriptionsHelpers,
  formHelpers,
  objectHelpers,
  textHelpers,
  PaymentFrequencyTypeOptions
} from '../../../../../../utils/helpers';
import {StyledBudgetDetailsFormSpace} from '../BudgetDetails/StyledBudgetDetails';
import SpinSmall from '../../../../../SpinSmall';

const {cardLimitFieldName, cardLimitPeriodFieldName} = subscriptionFormFields;
const {defaultCardLimitPeriod} = subscriptionFormValues;

const gObjProp = objectHelpers.getObjProp;

const {Item} = Form;

const {ACTIVE, CARD_ERROR, DELETED, INACTIVE, PENDING, RESTRICTED,
  TERMINATED, TRIAL, UNMANAGED} = subscriptionStatusesConstants;

const {CARD, WIRE} = subscriptionPaymentTypesConstants;

const PaymentDetails = ({
  cardDetails,
  cardHold,
  cardHoldMessage,
  className,
  edit,
  subscription,
  dispatch,
  employee,
  employees,
  isAdmin,
  isEnabledBanking,
  subscriptionCardLimit,
  handleAddCard,
  handleRecreateCard,
  handleChangeCardStatus,
  handleChangeMethod,
  onFormSubmit,
  isOpenAuthModal,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [initialFormValues, setInitialFormValues] = useState({
    [cardLimitFieldName]: '',
    [cardLimitPeriodFieldName]: defaultCardLimitPeriod,
    payment_frequency: undefined
  });
  const [switchProps, setSwitchProps] = useState({checked: false, loading: false});
  const [cardLimitPeriodTypeOptions] = useState(CardLimitPeriodTypeOptions());
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [paymentFrequencyOptions, ] = useState(PaymentFrequencyTypeOptions());

  const gDataProp = (subscription, key, defaultValue) => gObjProp(subscription, key, defaultValue);

  const trans = (key) => t(`subscriptions:${key}`);

  const placeholderT = (key) => trans(`placeholders.${key}`);

  const getCardOwner = (id) => {
    const employee = employees.find(e => e.employee_id === id);
    return employee ? employee : null;
  }

  const cardOwner = getCardOwner(gDataProp(cardDetails.data, 'user_id'));
  const paymentType = gDataProp(subscription, 'payment_type');
  const statusCode = gDataProp(subscription, 'status');
  const cardId = gDataProp(subscription, 'card_id', null);

  // enable edit if subscription status is not deleted/terminated/restricted/unmanaged
  const isEnabledEdit = edit && ![DELETED, TERMINATED, RESTRICTED, UNMANAGED].includes(statusCode);
  const isActive = statusCode === ACTIVE;
  const isTrial = statusCode === TRIAL;
  const isPaused = statusCode === INACTIVE;
  const isPending = statusCode === PENDING;
  const isCardError = statusCode === CARD_ERROR;
  const isHoldCard = cardHold || (cardId === null && isPending);
  const isCardPaymentType = paymentType === CARD;
  const isTerminatedCard = cardsHelpers.getStatus(cardDetails.data) === TERMINATED && isCardPaymentType;
  const isNeedUpgrade = !isCardPaymentType && isEnabledEdit;
  const enableChangeCardAction = isActive && isCardPaymentType;

  const cardLimit = Form.useWatch(cardLimitFieldName, {form});

  const requiredRules = [{required: true, message: t('validation.fieldIsRequired')}];

  const isCardOwner = useMemo(() => {
    const cardOwnerId = gObjProp(cardDetails.data, 'user_id');
    const employeeId = gObjProp(employee, 'id');
    return employeeId === cardOwnerId;
  }, [employee, cardDetails]);

  const isTooHighLimit = useMemo(() => {
    let isHigh = false;
    if (isEditMode && isCardPaymentType && cardLimit) {
      isHigh = cardsHelpers.isTooHighLimit({
        limit: cardLimit,
        maxLimit: subscriptionCardLimit
      });
    }
    return isHigh;
  }, [isEditMode, isCardPaymentType, cardLimit, subscriptionCardLimit]);

  const displayProvideAccessMessage = useMemo(() => {
    return !isCardOwner && cardDetails.data;
  }, [isCardOwner, cardDetails]);

  const disabledSaveButton = useMemo(() => isTooHighLimit, [isTooHighLimit]);

  useEffect(() => {
    const ownerId = gDataProp(cardDetails.data, 'user_id');
    const owner = getCardOwner(ownerId);
    const {
      option: cardLimitPeriod,
      value: cardLimit
    } = cardsHelpers.getCardLimit(cardDetails.data);
    const fieldValues = {
      ...initialFormValues,
      [cardLimitFieldName]: cardLimit,
      [cardLimitPeriodFieldName]: cardLimitPeriod,
      card_owner: owner ? owner.email : ownerId,
      payment_frequency: subscription?.payment_frequency
    };
    setInitialFormValues(fieldValues);
    form.setFieldsValue(fieldValues);
    isEditMode && setIsEditMode(false);
  }, [subscription, cardDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const options = formHelpers.getEmployeeOptions({employees, employeeEmail: employee.email, t});
    setOwnerOptions(options);
  }, [employees]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSwitchProps({
      checked: gDataProp(subscription, 'status') === subscriptionStatusesConstants.ACTIVE,
      loading: false
    });
  }, [cardDetails, subscription]);

  useEffect(() => {
    if (!isOpenAuthModal && switchProps.loading) setSwitchProps({...switchProps, loading: false});
  }, [isOpenAuthModal]); // eslint-disable-line react-hooks/exhaustive-deps

  const cardOwnerAvatarData = {
    label: gObjProp(cardOwner, 'full_name'),
    src: gObjProp(cardOwner, 'logo') || undefined
  }

  const isKnownPaymentType = !objectHelpers.isNaV(paymentType) && paymentType !== '';

  const tooltip = (key) => subscriptionsHelpers.getLabelTooltip({key, translation: t});

  const getTableData = () => {
    let items;
    if (paymentType === CARD) {
      items = [
        {
          key: 'paymentFrequency',
          label: trans('paymentFrequency'),
          value: t(subscriptionsHelpers.getPaymentFrequencyType(subscription?.payment_frequency))
        },
        {
          key: 'limit',
          label: `${t('card')} ${t('limit')}`,
          value: t(subscriptionsHelpers.getCardLimitType(initialFormValues[cardLimitPeriodFieldName]))
        },
        {
          key: 'owner',
          label: `${t('card')} ${t('Owner')}`,
          value: (
            <Space>
              {cardOwnerAvatarData.label}
              <Avatar src={cardOwnerAvatarData.src}>
                {textHelpers.getInitials(cardOwnerAvatarData.label)}
              </Avatar>
            </Space>
          )
        },
      ];
    } else {
      let paymentTypeLabel = isKnownPaymentType && t(`subscriptionPaymentTypes.${paymentType}`);
      if (paymentType === WIRE) paymentTypeLabel = `${paymentTypeLabel} ${t('transfer')}`;
      items = [
        {
          key: 'method',
          label: t('method'),
          value: paymentTypeLabel
        }
      ];
    }
    return items;
  }

  const handleDropdownClick = ({key}) => {
    const events = {
      'edit': () => enableChangeCardAction && setIsEditMode(true),
      'change-method': () => handleChangeMethod && handleChangeMethod(),
    }
    if (events.hasOwnProperty(key)) events[key]();
  }

  const handleOnCancel = () => setIsEditMode(false);

  const handleOnSave = () => !disabledSaveButton && form.submit();

  const handleSubmit = async (fields) => {
    let data = formHelpers.getUpdatedFormValues({initialValues: initialFormValues, submittedValues: fields});
    let limitFields = [cardLimitFieldName, cardLimitPeriodFieldName];
    // add both limit field if any of them was changed
    if (limitFields.some(key => Object.keys(data).includes(key))) {
      limitFields.forEach(key => {
        data = {...data, [key]: fields[key]}
      });
    }

    if (Object.keys(data).length > 0 && onFormSubmit) {
      onFormSubmit(
        data,
        handleOnCancel
      );
    } else {
      handleOnCancel();
    }
  }

  const onChangeCardStatus = (checked) => {
    if (!edit) return;
    handleChangeCardStatus(checked);
    setSwitchProps({...switchProps, loading: true});
  }

  const tableData = getTableData();

  const cardsTableData = isCardPaymentType ? [
    {
      key: 'active',
      label: 'Active',
      value: (
        <StyledPaymentDetailsSwitch
          {...switchProps}
          disabled={!edit}
          onChange={onChangeCardStatus}
        />
      )
    }
  ] : [];

  const error = isCardPaymentType && cardId === null && isCardError;

  const extra = (!error && isEnabledEdit) && (
    <>
      {isEditMode ? (
        <Space size='middle'>
          <Button
            onClick={handleOnCancel}
          >
            {t('cancel')}
          </Button>
          <Button
            disabled={disabledSaveButton}
            onClick={handleOnSave}
            type='primary'
          >
            {t('save')}
          </Button>
        </Space>
      ) : (
        <StyledPaymentDetailsDropdownButton
          size='small'
          buttonsRender={([_, rightButton]) => [
            null,
            rightButton,
          ]}
          icon={<EditLineIcon />}
          menu={{
            items: [
              {
                key: 'edit',
                label: t('edit'),
                disabled: (isEditMode || !enableChangeCardAction)
              },
              {
                key: 'change-method',
                label: t('changeMethod')
              },
            ],
            onClick: handleDropdownClick
          }}
          type='text'
        />
      )}
    </>
  );

  const warningAlert = (errorKey) => (
    <Alert
      message={trans(`errors.${errorKey}`)}
      type='warning'
      showIcon
    />
  );

  const recreateCardButton = (
    <Button
      onClick={() => handleRecreateCard && handleRecreateCard()}
      size='large'
      type='primary'
    >
      {t('tryAgain')}
    </Button>
  );

  const getCardActions = () => {
    let actions = [];
    if (isActive || isTrial) {
      if (isNeedUpgrade) {
        actions = [
          <Button
            onClick={() => handleChangeMethod && handleChangeMethod()}
            size='large'
          >
            {t('changeMethod')}
          </Button>
        ];
      }
      if (isAdmin && isEnabledBanking && !isCardPaymentType) {
        actions.push(
          <Button
            onClick={() => handleAddCard && handleAddCard()}
            size='large'
            type='primary'
          >
            {t('add')} {t('card')}
          </Button>
        );
      }
      if (error && !isNeedUpgrade) {
        actions.push(recreateCardButton);
      }
    } else if ((isPaused && !isCardPaymentType) || !isEnabledEdit) {
      // Display activate payment type to change payment method if subscription is paused and payment type is not card
      actions.push(warningAlert('activateSubscriptionToChangePaymentMethod'))
    }
    if (isCardPaymentType && isCardError) {
      actions.push(recreateCardButton);
    }
    return actions;
  }

  const isOpenedDetailsTable = isEditMode ? false : !isEditMode && isCardPaymentType ? (!isTerminatedCard && !isHoldCard) : true;

  return (
    <StyledPaymentDetails
      {...rest}
    >
      <StyledPaymentDetailsCard
        actions={isKnownPaymentType && getCardActions()}
        extra={extra}
        title={t('paymentMethod')}
      >
        {isCardPaymentType && (
          <SpinSmall spinning={cardDetails.loading}>

            {displayProvideAccessMessage && warningAlert('disabledAccessToTheCardDetails')}

            <CardDetails
              className='card-details'
              cardErrorMessage={cardHoldMessage}
              cardVariant='dark'
              error={error}
              hold={isHoldCard}
              cardDetails={cardDetails.data}
            />

            {(!isTerminatedCard && !isHoldCard) && (
              <DetailsTable
                data={cardsTableData}
              />
            )}
          </SpinSmall>
        )}

        <Collapse isOpened={isOpenedDetailsTable}>
          <DetailsTable
            data={tableData}
          />
        </Collapse>

        <Collapse isOpened={isEditMode}>
          <Form
            className='pt-20'
            initialValues={initialFormValues}
            form={form}
            layout='vertical'
            onFinish={handleSubmit}
            requiredMark={false}
          >
            <Item
              label={`${t('card')} ${t('Owner')}`}
              name='card_owner'
              required
            >
              <Select
                disabled
                options={ownerOptions}
                size='large'
              />
            </Item>
            <Item
              label={trans('paymentFrequency')}
              name='payment_frequency'
              tooltip={tooltip('paymentFrequency')}
            >
              <Select
                options={paymentFrequencyOptions}
                placeholder={placeholderT('paymentFrequency')}
                size='large'
              />
            </Item>
            <StyledBudgetDetailsFormSpace size='middle'>
              <Item
                label={`${t('card')} ${t('limit')}`}
                name={cardLimitFieldName}
                required
              >
                <StyledPaymentDetailsInput
                  addonBefore={<EuroIcon />}
                  min={1}
                  size='large'
                  type='number'
                />
              </Item>
              <Item
                label=' '
                name={cardLimitPeriodFieldName}
                rules={requiredRules}
              >
                <Select
                  options={cardLimitPeriodTypeOptions}
                  size='large'
                />
              </Item>
            </StyledBudgetDetailsFormSpace>
            <Collapse isOpened={isTooHighLimit}>
              <IncreaseLimitAlert
                limit={subscriptionCardLimit}
              />
            </Collapse>
          </Form>
        </Collapse>
      </StyledPaymentDetailsCard>
    </StyledPaymentDetails>
  );
}

PaymentDetails.propTypes = {
  cardDetails: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      data: PropTypes.shape({
        id: PropTypes.string,
        user_id: PropTypes.string
      })
    })
  }),
  edit: PropTypes.bool,
  subscription: PropTypes.shape({
    payment_frequency: PropTypes.number
  }),
  handleAddCard: PropTypes.func,
  handleChangeCardStatus: PropTypes.func,
  handleChangeMethod: PropTypes.func,
  handleRecreateCard: PropTypes.func,
  onFormSubmit: PropTypes.func,
  isOpenAuthModal: PropTypes.bool
}

PaymentDetails.defaultProps = {
  edit: true,
  isOpenAuthModal: false
}

const mapStateToProps = state => {
  const {cardHold, cardHoldMessage} = state.subscription;
  const {employee, isAdmin} = state.user;
  const {employees} = state.company;
  const {isEnabledBanking} = state.banking;
  const {subscriptionCardLimit} = cardsHelpers.getPaymentCardLimit(state);
  return {
    cardHold,
    cardHoldMessage,
    employee,
    employees,
    isAdmin,
    isEnabledBanking,
    subscriptionCardLimit
  }
}

export default connect(mapStateToProps, null)(PaymentDetails);
