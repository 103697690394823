import dayjs from 'dayjs';
import moment from 'moment/moment';
import {colorHelpers, dateHelpers, objectHelpers, subscriptionsHelpers} from '../../utils/helpers';

const dateFilterRanges = {
  CURRENT_MONTH: 'current-month',
  PREVIOUS_MONTH: 'previous-month',
  YEARLY: 'yearly'
}

const expendituresTypes = {
  ALL: 'all',
  EXPENSES: 'expenses',
  SUBSCRIPTIONS: 'subscriptions'
}

const {CURRENT_MONTH, YEARLY} = dateFilterRanges;

const {getSpendingServicesAmount, getSpendingServicesList} = subscriptionsHelpers;

const getFormattedSpending = (item) => {
  let isPersonalSpending = item?.card_id;
  return {
    ...item,
    actual: item.amount || 0,
    budget: item.budget || 0,
    service: isPersonalSpending
      ? { logo: item?.owner?.logo, name: item?.owner?.full_name}
      : { logo: item.service_logo, name: item.service }
  }
};

const dayFilter = (i, day) => i.day === day;

const dateFormat = 'YYYY-MM';


const overviewPageUtils = {
  adjustPreviousQueryForYearly: (query) => ({
    ...query,
    start_date: dayjs(query.start_date).subtract(1, 'year').format(dateFormat),
    end_date: dayjs(query.end_date).subtract(1, 'year').format(dateFormat),
  }),
  adjustPreviousQueryForMonthly: (query) => {
    const adjustedDate = dayjs()
      .set('month', query.month - 1)
      .set('year', query.year)
      .subtract(1, 'month');

    return {
      ...query,
      month: adjustedDate.month() + 1,
      year: adjustedDate.year(),
    };
  },
  calculateSubscriptionsChart: ({range, actualSpending, expectedSpending, expenditure}) => {
    const {day: currentDayNum} = dateHelpers.getCurrentDate();
    const daisInMonth = dayjs().daysInMonth();
    let expected = 0, current = 0;
    if (range === YEARLY) {
      const today = moment();
      let monthIndex = today.month();
      let currentMonthInformation, currentMonthSpending, expectedMonthSpending, label;
      return objectHelpers.range(1, 12).map(i => {
        monthIndex = monthIndex === 12 ? 1 : monthIndex + 1;
        today.set('month', monthIndex);
        label = today.format('MMM');
        currentMonthInformation = {
          label,
          name: label
        };
        currentMonthSpending = actualSpending.find(d => d.month === monthIndex);
        expectedMonthSpending = expectedSpending.find(d => d.month === monthIndex);
        current += currentMonthSpending?.amount || 0;
        expected += expectedMonthSpending?.amount || currentMonthSpending?.expected_amount || 0;
        return {
          ...currentMonthInformation,
          current,
          expected,
          isActive: i === 12
        };
      });
    } else {
      const isCurrentMonthSpending = range === CURRENT_MONTH;
      const monthDate = isCurrentMonthSpending ? moment() : moment().subtract(1, 'month');
      return objectHelpers.range(1, daisInMonth).map(day => {
        let date = monthDate.set('date', day);
        let currentDayInformation = {
          date: date.format('YYYY-MM-DD'),
          label: date.format('Do MMMM'),
          name: day
        };
        let currentDaySpending = actualSpending.find(i => dayFilter(i, day));
        let expectedDaySpending = expectedSpending.find(i => dayFilter(i, day));
        if (currentDaySpending) {
          current += getSpendingServicesAmount(currentDaySpending);
        }

        if (expectedDaySpending) {
          expected += getSpendingServicesAmount(expectedDaySpending);
        } else {
          expected += getSpendingServicesAmount(currentDaySpending, 'expected_amount');
        }

        currentDayInformation = {...currentDayInformation, expected};
        if (isCurrentMonthSpending) {
          if (day <= currentDayNum) currentDayInformation = {...currentDayInformation, current};
          if (day === currentDayNum) currentDayInformation = {...currentDayInformation, isActive: true};
        } else {
          currentDayInformation = {...currentDayInformation, current};
        }
        return currentDayInformation;
      });
    }
  },
  formatCategories: (data, t) => {
    let categories = data.map(i => ({name: i.category?.name, value: i.amount || 0})).sort((a, b) => b.value - a.value);
    const visibleCount = 5;
    if (categories.length > visibleCount) {
      const otherCategories = [...categories].splice(visibleCount);
      categories = [
        ...categories.slice(0, visibleCount),
        {
          name: t('other'),
          value: otherCategories.reduce((n, {value}) => n + value, 0),
        }
      ];
    }
    // add colors to pie chart item
    categories = categories.map((c, index) => ({...c, color: colorHelpers.getColorByIndex(index)}))
    return categories;
  },
  formatCostCenter: (data) => {
    data = data.map(i => ({actual: i.amount || 0, expected: i.budget || 0, name: i.cost_center}));
    let overBudgetSpending = data.filter(d => d.actual - d.expected > 0).sort((a, b) => b.actual - a.actual);
    let inBudgetSpending = data.filter(d => d.expected - d.actual >= 0).sort((a, b) => b.actual - a.actual);
    return [...overBudgetSpending, ...inBudgetSpending];
  },
  formatChartDataServices: ({
    actualSpending = [],
    expectedSpending = [],
  } = {}) => {
    const daysInMonth = dayjs().daysInMonth();

    return objectHelpers.range(1, daysInMonth).map(day => {
      const currentDaySpending = actualSpending.find(i => dayFilter(i, day));
      const expectedDaySpending = expectedSpending.find(i => dayFilter(i, day));

      let actual = [];
      let expected = [];
      let actualSpend = 0;
      let expectedSpend = 0;

      if (currentDaySpending) {
        // Actual spending
        actual = getSpendingServicesList(currentDaySpending);
        actualSpend = getSpendingServicesAmount(currentDaySpending);

        // Expected spending
        if (expectedDaySpending) {
          expected = getSpendingServicesList(expectedDaySpending);
          expectedSpend = getSpendingServicesAmount(expectedDaySpending);
        } else {
          expected = getSpendingServicesList(currentDaySpending, 'expected_amount');
          expectedSpend = getSpendingServicesAmount(currentDaySpending, 'expected_amount');
        }
      }

      return {
        day,
        actual,
        actualSpend,
        expected,
        expectedSpend,
      };
    });
  },
  formatYearlyByServicesSpending: (actualSpending = [], expectedSpending = []) => {
    if (!actualSpending.length) return [];

    return actualSpending
      .map((actualItem) => {
        // Format the spending item and calculate the expected amount
        return {
          ...getFormattedSpending(actualItem),
          expected: actualItem?.expected_amount ?? 0,
        };
      })
      .sort((a, b) => b.actual - a.actual)
      .slice(0, 15);
  },
  formatYearlySpending: (actualSpending, expectedSpending) => {
    const startDate = dayjs().subtract(11, 'month'); // Starting month
    const spending = [];

    objectHelpers.range(0, 11).forEach(offset => {
      const currentDate = startDate.add(offset, 'month');
      const currentMonth = currentDate.month() + 1; // Month is zero-based in dayjs

      const actualSpendingData = actualSpending.find(({ month }) => month === currentMonth);
      const expectedSpendingData = expectedSpending.find(({ month }) => month === currentMonth);

      spending.push({
        label: currentDate.format('MMM'), // Format month label (e.g., "Jan")
        actual: actualSpendingData?.amount || 0,
        expected: expectedSpendingData?.amount || actualSpendingData?.expected_amount || 0,
      });
    });

    return spending;
  },
  formatVariations: (data) => data
    .map(i => ({...getFormattedSpending(i), expected: i.expected_amount || 0, subscription_id: i.subscription_id}))
    .filter(i => i.expected !== i.actual)
    .sort((a, b) => (a.expected - a.actual) - (b.expected - b.actual)),
  formatUpcoming: (data, currentMonth) => {
    if (currentMonth === undefined) currentMonth = dayjs();
    return data
      .map(i => ({...getFormattedSpending(i), day: i.day, date: currentMonth.date(i.day).format('DD/MM/YY')}))
      .sort((a, b) => a.day - b.day);
  },
  getSpendingData: (data) => {
    const gDataP = (name) => data?.[name] || [];

    const spending = [...gDataP('spending'), ...gDataP('personal_spending')];
    const yearlyByServices = [...gDataP('spending_by_service'), ...gDataP('personal_spending_by_card')];

    return {
      spending: overviewPageUtils.mergeSpendingByDay(spending),
      upcoming: gDataP('upcoming'),
      yearly: overviewPageUtils.mergeSpendingByMonth(spending),
      yearlyByServices
    }
  },
  mergeSpendingByDay: (data) => {
    return Object.values(
      data.reduce((merged, { day, services = [], expenses = [] }) => {
        merged[day] ??= { day, services: [], expenses: [] };
        merged[day].services.push(...services);
        merged[day].expenses.push(...expenses);
        return merged;
      }, {})
    );
  },
  mergeSpendingByMonth: (data) => {
    return Object.values(
      data.reduce((merged, { month, amount = 0, expected_amount = 0 }) => {
        merged[month] ??= { month, amount: 0, expected_amount: 0 };
        merged[month].amount += amount;
        merged[month].expected_amount += expected_amount;
        return merged;
    }, {})
    );
  }
};

export {
  dateFilterRanges,
  expendituresTypes,
  overviewPageUtils,
}