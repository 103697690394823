const RealEstateAgentsAndManagersRentalsIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 22V7.48497C22 6.38697 22 5.83897 21.684 5.37497C21.369 4.91197 20.788 4.60797 19.625 3.99897L18.145 3.22497C16.321 2.26897 15.408 1.79097 14.705 2.08697C14 2.38297 14 3.24497 14 4.96697V8.99997M23 22H3M22 7.99997H20M22 11H20M22 14H20"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M13.5 13H14.5M13.5 16H14.5M10 13C10 11.114 10 10.172 10.586 9.586C11.172 9 12.114 9 14 9C15.886 9 16.828 9 17.414 9.586C18 10.172 18 11.114 18 13V22H10V13Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8 15V22" stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 15.5H4" stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8 15.5H6V18H4V15.5H3" stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
  </svg>
);

export default RealEstateAgentsAndManagersRentalsIcon;
