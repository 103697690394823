export const legalPersonOptions = {
    annualTurnover:[
    {label: '€0 - €39.000', value: '0-39'},
    {label: '€40.000 - €99.000', value: '40-99'},
    {label: '€100.000 - €249.000', value: '100-249'},
    {label: '€250.000 - €999.000', value: '250-999'},
    {label: '€1.000.000 - €2.999.000', value: '1000-2999'},
    {label: '€3.000.000 - €9.999.000', value: '3000-9999'},
    {label: '€10.000.000 - €99.999.000', value: '10000-99999'},
    {label: '€100.000.000 - *', value: '100000-*'},
  ],
  income: [
    {label: '€0 - €4.000', value: '0-4'},
    {label: '€5.000 - €9.000', value: '5-9'},
    {label: '€10.000 - €49.000', value: '10-49'},
    {label: '€50.000 - €149.000', value: '50-149'},
    {label: '€150.000 - €499.000', value: '150-499'},
    {label: '€500.000 - *', value: '500-*'},
  ],
  employees: [
    {label: '0', value: '0'},
    {label: '1-9', value: '1-9'},
    {label: '10-99', value: '10-99'},
    {label: '100-249', value: '100-249'},
    {label: '250-*', value: '250-*'},
  ],
  // sanctions & embargo questionnaire
  entitySanctionsQuestionnaire: [
    {label: 'Only the legal entity', value: 1},
    {label: 'The legal entity and all its subsidiaries held at 100%', value: 2},
    {label: 'The legal entity and all its subsidiaries held at 50% or more', value: 3},
    {label: 'The legal entity and a list of subsidiaries', value: 4},
  ],activityOutsideEu: [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ],
  economicSanctions: [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ],
  residentCountriesSanctions: [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ],
  involvedSanctions: [
    {label: 'Yes', value: true},
    {label: 'No', value: false},
  ]
}