import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {StyledInvoiceRetrieval, StyledCopyField} from './StyledInvoiceRetrieval';
import CompanyInfo from '../../tabComponents/CompanyInfo';
import {alertActions} from '../../../../../state/actions/alert.actions';

const InvoiceRetrieval = ({
  email,
  ...rest
}) => {
  const [trans] = useTranslation(['main', 'settings']);
  const t = (key) => trans(`settings:tabs.invoiceRetrieval.${key}`);

  const onCopy = () => alertActions.success(`${trans('copied')}!`);

  return (
    <StyledInvoiceRetrieval
      title={<CompanyInfo />}
      {...rest}
    >
      {email ? (
        <>
          <p>{t('description')}</p>
          <StyledCopyField
            onCopy={onCopy}
            label={trans('address')}
            value={email}
          />
        </>
      ) : (
        <p>{trans('invoiceEmailEmptyDescription')}</p>
      )}
    </StyledInvoiceRetrieval>
  );
}

InvoiceRetrieval.propTypes = {
  email: PropTypes.string
}

export default InvoiceRetrieval;
