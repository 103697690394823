import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {Button, Form, Input, Select, Space} from 'antd';
import {EditFilled} from '@ant-design/icons';
import {UnmountClosed} from 'react-collapse';
import VerifyModal from '../../../../quickStart/VerifyModal/VerifyModal';
import CompanyInfoDetails from '../../tabComponents/CompanyInfo';
import {StyledCompanyInfo, StyledCompanyInfoAmountInput, StyledCompanyInfoTitle} from './StyledCompanyInfo';
import DetailsTable from '../../../TransactionsPage/DetailsTable';
import {companyActions} from '../../../../../state/actions/company.actions';
import {userActions} from '../../../../../state/actions/user.actions';
import {useContentLoaded} from '../../../../../hooks';
import EditableAvatar from '../../../../EditableAvatar';
import SpinSmall from '../../../../SpinSmall';
import {EuroIcon} from '../../../../../icons';
import {firebaseEvents} from '../../../../../snippets/firebase';
import {
  amountHelpers,
  elementHelpers,
  fileHelpers,
  formHelpers,
  localizationHelpers,
  objectHelpers,
  subscriptionsHelpers,
  systemHelpers,
  teamsHelpers,
  textHelpers
} from '../../../../../utils/helpers';
import {EmailRegexp, ZipRegexp} from '../../../../../utils/regexp';

const {Item} = Form;

const gOP = objectHelpers.getObjProp;

const countryOptions = localizationHelpers.getSupportedCountries().map(c => ({label: c.name, value: c.name}));

const CompanyInfo = ({
  dispatch,
  edit,
  currencies,
  settings,
  sendEmailVerification,
  companyId,
  getCompanies,
  getSettings,
  updateCompanyInformation,
  isEnabledEmptyState,
  ...rest
}) => {
  const [trans] = useTranslation(['main', 'settings']);
  const isLoaded = useContentLoaded(settings);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState(null);
  const [formValues, setFormValues] = useState({
    address: '',
    city: '',
    country: '',
    currency: '',
    email: '',
    low_balance_threshold: '',
    name: '',
    vat: '',
    zip: ''
  });
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState(undefined);

  const t = (key) => trans(`settings:tabs.companyInfo.${key}`);

  const balanceFieldRules = useMemo(() => subscriptionsHelpers.getBudgetFieldRules(trans), [trans]);

  const requiredRules = useMemo(() => [{required: true, message: trans('validation.fieldIsRequired')}], [trans]);

  const currencyOptions = useMemo(() => currencies.map(c => ({label: c, value: c})), [currencies]);

  useEffect(() => {
    if (objectHelpers.isEmptyObject(settings)) return;

    const {company_logo_url: logo} = settings;
    const formValues = {
      address: settings.address,
      city: settings.city,
      country: settings.country,
      currency: settings.company_currency,
      email: settings.email,
      logo: settings.company_logo_url,
      low_balance_threshold: settings.low_balance_threshold,
      company_name: settings.company_name,
      vat_id: settings.vat_id,
      zip_code: settings.zip_code
    }

    setFormValues(formValues);
    form.setFieldsValue(formValues);

    setLogo(logo);
  }, [settings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => clearError(), [isEditMode]); // eslint-disable-line react-hooks/exhaustive-deps

  const mainT = (key, options) => trans(`main:${key}`, options);

  const handleOnCancel = () => setIsEditMode(false);

  const handleOnSave = () => form.submit();

  const finishLoading = () => setLoading(false);

  const handleSubmit = (fields) => {
    let { currency, ...data } = fields;

    // update or remove company logo
    if (formValues.logo !== logo) {
      data = {
        ...data,
        company_logo_url: logo ? fileHelpers.cutFileData(logo) : null
      }
    }

    if (errorMessage) return;

    setLoading(true);
    updateCompanyInformation(
      data,
      () => {
        getCompanies(undefined, () => {
          getSettings(() => {
            finishLoading();
            setIsEditMode(false);
          }, finishLoading);
        });
        systemHelpers.logEvent(firebaseEvents.COMPANY_SETTINGS_EDIT_DETAILS);
      },
      (error) => {
        const fields = formHelpers.getFormServerErrorFields(error);
        const errorMessage = error?.message || null;

        form.setFields(fields);
        setErrorMessage(errorMessage);
        finishLoading();
      }
    );
  }

  const clearError = () => errorMessage && setErrorMessage(undefined);

  const handleVerify = () => {
    setVerificationLoading(true);
    sendEmailVerification(
      email,
      () => {
        setShowVerifyModal(true);
        setVerificationLoading(false);
      },
      () => setVerificationLoading(false)
    )
  }

  const handleChangeLogo = ({file}) => {
    setLogo(file);
    clearError();
  }

  const onEditClick = () => setIsEditMode(true);

  const {company_name: name, currency, vat_id: vat, email} = formValues;

  const lowBalanceThreshold = useMemo(() => {
    let value = formValues?.low_balance_threshold || 0;
    return amountHelpers.getAmountWithCurrencyCode(value);
  }, [formValues?.low_balance_threshold]);

  const isVerifiedEmail = gOP(settings, 'email_verified', false) || false;

  const showContent = (content, loader) => elementHelpers.showContentWithLoader(isLoaded, content, loader);

  const tableData = [
    {
      key: 'company-name',
      label: `${mainT('company')} ${mainT('name')}`,
      value: showContent(name)
    },
    {
      key: 'address',
      label: t('address'),
      value: showContent(teamsHelpers.getCompanyAddress(formValues))
    },
    {
      key: 'vat-number',
      label: t('vatNumber'),
      value: showContent(vat)
    },
    {
      key: 'email',
      label: mainT('email'),
      value: (
        <Space size='middle'>
          {showContent(email)}
          {isLoaded && !isVerifiedEmail && (
            <Button
              loading={verificationLoading}
              onClick={handleVerify}
              size='middle'
              type='primary'
            >
              {mainT('verify')} {mainT('email')}
            </Button>
          )}
        </Space>
      )
    },
    {
      key: 'currency',
      label: t('currency'),
      value: showContent(currency)
    },
    {
      key: 'company-id',
      label: `${mainT('company')} ID`,
      value: showContent(companyId)
    },
    {
      key: 'low_balance_threshold',
      label: t('lowBalanceThreshold'),
      value: showContent(lowBalanceThreshold)
    },
  ];

  const title = isEditMode ? (
    <>
      <StyledCompanyInfoTitle>
        <EditableAvatar
          disabled={loading}
          defaultValue={formValues.logo}
          initials={textHelpers.getInitials(name)}
          onChange={handleChangeLogo}
          onError={setErrorMessage}
          showError={false}
        />
        <Space size='middle'>
          <Button
            disabled={loading}
            onClick={handleOnCancel}
            size='large'
            type='default'
          >
            {mainT('cancel')}
          </Button>
          <Button
            disabled={loading}
            onClick={handleOnSave}
            size='large'
            type='primary'
          >
            {mainT('save')}
          </Button>
        </Space>
      </StyledCompanyInfoTitle>
      <UnmountClosed isOpened={Boolean(errorMessage)}>
        <span className='title-error-message'>
          {errorMessage}
        </span>
      </UnmountClosed>
    </>
  ) : (
    <StyledCompanyInfoTitle>
      <CompanyInfoDetails className='company-info-details' />
      {edit && (
        <Button
          icon={<EditFilled />}
          onClick={onEditClick}
          size='large'
          type='default'
        >
          {mainT('edit')}
        </Button>
      )}
    </StyledCompanyInfoTitle>
  );

  const lengthFieldMessage = (value, type = 'min') => mainT(`validation.${type}LengthValidation`, {value});

  return (
    <StyledCompanyInfo
      {...rest}
      title={title}
    >

      <DetailsTable
        className={isEditMode && 'd-none'}
        data={tableData}
      />

      <SpinSmall spinning={loading}>
        <Form
          className={!isEditMode && 'd-none'}
          initialValues={formValues}
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Item
            label={`${mainT('company')} ${mainT('name')}`}
            name='company_name'
            rules={[
              ...requiredRules,
              {min: 2, message: lengthFieldMessage(2, 'min')}
            ]}
          >
            <Input
              minLength={2}
              maxLength={50}
              size='large'
            />
          </Item>
          <Item
            label={mainT('address')}
            name='address'
            rules={requiredRules}
          >
            <Input size='large' />
          </Item>
          <Item
            label={mainT('city')}
            name='city'
            rules={requiredRules}
          >
            <Input size='large' />
          </Item>
          <Item
            label={mainT('postCode')}
            name='zip_code'
            rules={[
              ...requiredRules,
              {min: 4, message: lengthFieldMessage(4, 'min')},
              {max: 10, message: lengthFieldMessage(10, 'max')},
              {pattern: ZipRegexp, message: mainT('validation.zipCodeInvalid')}
            ]}
          >
            <Input
              maxLength={10}
              size='large'
            />
          </Item>
          <Item
            label={mainT('country')}
            name='country'
            rules={requiredRules}
          >
            <Select
              options={countryOptions}
              size='large'
              showSearch
            />
          </Item>
          <Item
            label={t('vatNumber')}
            name='vat_id'
            rules={[
              ...requiredRules,
              {min: 4, message: lengthFieldMessage(4, 'min')},
              {max: 15, message: lengthFieldMessage(15, 'max')}
            ]}
          >
            <Input
              maxLength={15}
              size='large'
            />
          </Item>
          <Item
            label={mainT('email')}
            name='email'
            rules={[
              ...requiredRules,
              {pattern: EmailRegexp, message: mainT('validation.enterValidEmail')}
            ]}
          >
            <Input
              size='large'
              type='email'
            />
          </Item>
          <Item
            label={mainT('currency')}
            name='currency'
          >
            <Select
              disabled
              readOnly
              options={currencyOptions}
              size='large'
            />
          </Item>
          <Item
            label={t('lowBalanceThreshold')}
            name='low_balance_threshold'
            rules={balanceFieldRules}
          >
            <StyledCompanyInfoAmountInput
              addonBefore={<EuroIcon />}
              size='large'
              type='number'
            />
          </Item>
        </Form>
      </SpinSmall>

      <VerifyModal
        open={showVerifyModal}
        email={email}
        onCancel={() => setShowVerifyModal(false)}
      />

    </StyledCompanyInfo>
  );
}

CompanyInfo.propTypes = {
  edit: PropTypes.bool
}

CompanyInfo.defaultProps = {
  edit: true
}

const mapStateToProps = state => {
  const {id, settings} = state.company;
  const {currencies} = state.main;

  return {
    currencies,
    companyId: id,
    settings
  }
}

const mapDispatchToProps = {
  getCompanies: userActions.getCompanies,
  getSettings: companyActions.getCompanySettings,
  sendEmailVerification: companyActions.sendEmailVerification,
  updateCompanyInformation: companyActions.updateCompanyInformation
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyInfo);
