import {bankingRequest} from '../services/request';
import {bankingConstants} from '../constants';
import {alertActions} from './alert.actions';
import {cardVerificationLightStatusesConstants as lightStatus} from '../../constants';
import {apiHelpers, cardsHelpers, objectHelpers} from '../../utils/helpers';
import {bankingEndpoints} from '../../api';

const {getUrl} = apiHelpers;

const errorHandlerProps = {disableAlert: true};
const hideKeylessErrorHandlerProps = {hideKeylessError: true};

const getCardsList = (query = null, success, error) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      url: bankingEndpoints.GET_CARDS,
      query,
      successCallback: (data) => {
        dispatch({
          type: bankingConstants.GET_CARD_LIST,
          data
        });
        success && success(data);
      },
      errorCallback: (data) => error && error(data)
    });
  }
}

const getUserCardList = (userId, query = null, success, error) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      url: getUrl(bankingEndpoints.GET_USER_CARDS, {userId}),
      query,
      successCallback: (data) => {
        dispatch({
          type: bankingConstants.GET_USER_CARD_LIST,
          data
        });
        success && success(data);
      },
      errorCallback: (data) => error && error(data)
    });
  }
}

const getCardsUsers = (query = null, success, error) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      url: bankingEndpoints.GET_USERS,
      query,
      successCallback: (data) => {
        dispatch({
          type: bankingConstants.GET_CARD_USERS,
          data
        })
        success && success(data);
      },
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}


const getUserAccessToCards = (id, success, error) => {
  return (dispatch) => {
    const updateVerificationState = (isCreatedUser, isRequired, status = null) => {
      dispatch({
        type: bankingConstants.SET_VERIFICATION_STATUS,
        verification: {
          isCreatedUser,
          isRequired,
          status
        }
      })
    }

    bankingRequest.get({
      dispatch,
      url: getUrl(bankingEndpoints.GET_USER, {id}),
      successCallback: (data) => {
        const {kyc_level: level, kyc_review: review, user_type: userType} = data;
        let status = cardsHelpers.getKYCStatus(review, level);
        let isValidated = status === lightStatus.VALIDATED;

        // check validation for employee - isValid if the user state is light_none
        if (
          userType === 'employee' &&
          !isValidated &&
          status === lightStatus.NONE
        ) {
          isValidated = true;
        }

        const updateCreatedUserVerificationState = (status, isRequired) => {
          updateVerificationState(true, isRequired, status);
          success && success(data);
        }

        // check documents list if light status is pending
        if (status === lightStatus.PENDING) {
          dispatch(
            getUserDocuments(
              id,
              (documents) => {
                // mark that user already uploaded the document
                if (documents.length > 0) status = lightStatus.PENDING_FILE_UPLOADED;
                updateCreatedUserVerificationState(status, true);
              },
              () => updateCreatedUserVerificationState(status, true)
            )
          )
        } else {
          updateCreatedUserVerificationState(status, !isValidated);
        }
      },
      errorCallback: (data) => {
        updateVerificationState(false, true);
        error && error(data);
      },
      errorHandlerProps
    });
  }
}

const getUserWallets = ({headers, id, successCallback, errorCallback}) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      headers,
      url: getUrl(bankingEndpoints.GET_USER_WALLETS, {id}),
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const getUserWalletsMinimal = ({headers, id, successCallback, errorCallback}) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      headers,
      url: getUrl(bankingEndpoints.GET_USER_WALLETS_MINIMAL, {id}),
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const getUserWalletBalance = ({headers, userId, walletId, successCallback, errorCallback}) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      headers,
      url: getUrl(bankingEndpoints.GET_USER_WALLET_TREEZOR_BALANCE, {userId, walletId}),
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const getUserCardDetails = (userId, cardId, success, error) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      url: getUrl(bankingEndpoints.GET_USER_CARD_DETAILS, {cardId, userId}),
      successCallback: (data) => {
        dispatch({
          type: bankingConstants.GET_USER_CARD_DETAILS,
          data
        });
        success && success(data);
      },
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const createUser = (data, success, error) => {
  return (dispatch) => {
    bankingRequest.post({
      dispatch,
      url: bankingEndpoints.POST_USER_CREATE,
      data,
      successCallback: (data) => {
        dispatch({
          type: bankingConstants.CREATE_USER,
          data
        });
        success && success(data);
      },
      errorCallback: (err) => errorHandler(err, error),
      errorHandlerProps
    });
  }
}

const updateUser = (id, data, success, error) =>
  simpleRequestTemplate('put', getUrl(bankingEndpoints.PUT_USER_UPDATE, {id}), data, success, error);

const createDocument = (data, success, error) =>
  simpleRequestTemplate('post', bankingEndpoints.POST_DOCUMENT_CREATE, data, success, error, errorHandlerProps);

const updateDocument = (userId, documentId, data, success, error) =>
  simpleRequestTemplate('put', getUrl(bankingEndpoints.PUT_USER_DOCUMENT_UPDATE, {userId, documentId}), data, success, error);

const getUserDocuments = (id, success, error) =>
  simpleRequestTemplate('get', getUrl(bankingEndpoints.GET_USER_DOCUMENTS, {id}), {}, success, error);

const createWallet = (data, success, error) =>
  simpleRequestTemplate('post', bankingEndpoints.POST_WALLET_CREATE, data, success, error);

const updateCardStatus = (userId, cardId, status, success, error) => {
  return (dispatch) => {
    bankingRequest.put({
      dispatch,
      url: getUrl(bankingEndpoints.PUT_USER_CARD_UPDATE_STATUS, {userId, cardId}),
      data: {lock_status: status},
      successCallback: (data) => success && success(data),
      errorCallback: (err) => errorHandler(err, error)
    });
  }
}

const updateCardLimits = ({userId, cardId, data, successCallback, errorCallback, headers}) => {
  return (dispatch) => {
    bankingRequest.put({
      dispatch,
      headers,
      url: getUrl(bankingEndpoints.PUT_USER_CARD_UPDATE_LIMITS, {userId, cardId}),
      data,
      successCallback,
      errorCallback,
      errorHandlerProps: hideKeylessErrorHandlerProps
    });
  }
}

const getUserCardImages = ({userId, cardId, successCallback, errorCallback, headers}) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      headers,
      url: getUrl(bankingEndpoints.GET_USER_CARD_IMAGES, {userId, cardId}),
      successCallback,
      errorCallback,
      errorHandlerProps
    });
  }
}

const clearCards = () => {
  return (dispatch) => {
    dispatch({
      type: bankingConstants.CLEAR_CARDS,
    });
  }
}

const updateCardVerificationStatus = (isRequired = false, status = null, isCreatedUser = true) => {
  return (dispatch) => {
    dispatch({
      type: bankingConstants.SET_VERIFICATION_STATUS,
      verification: {
        isCreatedUser,
        isRequired,
        status
      }
    });
  }
}

const getCompanyState = (success, error) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      url: bankingEndpoints.GET_COMPANY_STATE,
      successCallback: (data) => {
        dispatch({
          type: bankingConstants.GET_COMPANY_STATE,
          data
        });
        success && success(data);
      },
      errorCallback: (data) => error && error(data)
    });
  }
}

const getCompanyDetails = (success, error) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      url: bankingEndpoints.GET_COMPANY_DETAILS,
      successCallback: (data) => {
        dispatch({
          type: bankingConstants.GET_COMPANY_DETAILS,
          data
        });
        success && success(data);
      },
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const sendJoinInvitation = (data, success, error) => {
  return (dispatch) => {
    bankingRequest.post({
      dispatch,
      data,
      url: bankingEndpoints.POST_SEND_JOIN_INVITATION,
      successCallback: (data) => {
        dispatch({
          type: bankingConstants.SET_INVITED_USER,
          data
        });
        success && success(data);
      },
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const getInvitationDetails = (id, success, error) => {
  return (dispatch) => {
    bankingRequest.get({
      dispatch,
      url: getUrl(bankingEndpoints.GET_USER_INVITATION_DETAILS, {id}),
      successCallback: (data) => {
        dispatch({
          type: bankingConstants.SET_INVITED_USER,
          data
        });
        success && success(data);
      },
      errorCallback: (data) => error && error(data),
      errorHandlerProps
    });
  }
}

const getPublicUserState = (success, error) =>
  simplePublicRequestTemplate('get', bankingEndpoints.GET_PUBLIC_USER_STATE, {}, success, error);

const acceptUserInvitation = (success, error) =>
  simplePublicRequestTemplate('put', bankingEndpoints.PUT_ACCEPT_JOIN_INVITATION, {}, success, error);

const updatePublicUser = (data, success, error) =>
  simplePublicRequestTemplate('put', bankingEndpoints.PUT_PUBLIC_USER_UPDATE, data, success, error);

const createPublicUserDocument = (data, success, error) =>
  simplePublicRequestTemplate('post', bankingEndpoints.POST_PUBLIC_USER_DOCUMENT_CREATE, data, success, error);

const completePublicUserVerification = (success, error) =>
  simplePublicRequestTemplate('put', bankingEndpoints.PUT_PUBLIC_USER_COMPLETE_VERIFICATION, {}, success, error);

const createPublicUserTaxResidence = (data, success, error) =>
  simplePublicRequestTemplate('post', bankingEndpoints.POST_PUBLIC_USER_TAX_RESIDENCE_CREATE, data, success, error);

const updatePublicUserTaxResidence = (data, success, error) =>
  simplePublicRequestTemplate('put', bankingEndpoints.PUT_PUBLIC_USER_TAX_RESIDENCE_UPDATE, data, success, error);

const getPublicUserTaxResidence = (success, error) =>
  simplePublicRequestTemplate('get', bankingEndpoints.GET_PUBLIC_USER_TAX_RESIDENCE, {}, success, error);

const getPublicUserDocuments = (success, error) =>
  simplePublicRequestTemplate('get', bankingEndpoints.GET_PUBLIC_USER_DOCUMENTS, {}, success, error);

const updatePublicUserDocument = (id, data, success, error) =>
  simplePublicRequestTemplate('put', getUrl(bankingEndpoints.PUT_PUBLIC_USER_DOCUMENT_UPDATE, {id}), data, success, error);

const setKYCCompanyState = (trigger, success, error) => {
  return (dispatch) => {
    bankingRequest.put({
      dispatch,
      url: bankingEndpoints.PUT_SET_COMPANY_STATE,
      data: {trigger},
      successCallback: (data) => {
        dispatch({
          type: bankingConstants.GET_COMPANY_STATE,
          data
        });
        success && success(data);
      },
      errorCallback: (data) => error && error(data)
    });
  }
}

const createTaxResidence = (data, success, error) =>
  simpleRequestTemplate('post', bankingEndpoints.POST_TAX_RESIDENCE_CREATE, data, success, error, errorHandlerProps);

const getTaxResidence = (id, success, error) =>
  simpleRequestTemplate('get', getUrl(bankingEndpoints.GET_TAX_RESIDENCE, {id}), null, success, error, errorHandlerProps);

const getUserDetails = (id, success, error) =>
  simpleRequestTemplate('get', getUrl(bankingEndpoints.GET_USER, {id}), null, success, error, errorHandlerProps);


const storeLegalPersonDetails = (user) => {
  return (dispatch) => {
    dispatch({
      type: bankingConstants.SET_LEGAL_PERSON_DETAILS,
      data: {
        user
      }
    });
  }
}

const simpleRequestTemplate = (method, url, data, success, error, errorHandlerProps = {}, headers) => {
  return (dispatch) => {
    bankingRequest[method]({
      dispatch,
      url,
      [method === 'get' ? 'query' : 'data']: data,
      successCallback: (data) => success && success(data),
      errorCallback: (err) => errorHandler(err, error, errorHandlerProps),
      loading: false,
      errorHandlerProps,
      headers
    });
  }
}

const simplePublicRequestTemplate = (method, url, data, success, error) => {
  return (dispatch, getState) => {
    const {uboInviteKey} = getState().auth;
    bankingRequest[method]({
      dispatch,
      url: `${url}?key=${uboInviteKey}`,
      [method === 'get' ? 'query' : 'data']: data,
      successCallback: (data) => success && success(data),
      errorCallback: (err) => errorHandler(err, error, errorHandlerProps),
      loading: false,
      errorHandlerProps
    });
  }
}

const errorHandler = (response, errorFunc, addProps = {}) => {
  const disableAlert = objectHelpers.getObjProp(addProps, 'disableAlert', false);
  if (response.response) {
    const {data} = response.response;
    errorFunc && errorFunc(data);
    if (data && data.detail && typeof data.detail === 'string' && !disableAlert) {
      alertActions.error(data.detail);
    }
  } else {
    errorFunc && errorFunc(response);
  }
}


export const bankingActions = {
  getCardsList,
  getCardsUsers,
  getUserWallets,
  getUserWalletsMinimal,
  getUserCardDetails,
  getUserCardImages,
  createUser,
  updateUser,
  updateCardStatus,
  updateCardLimits,
  getUserCardList,
  clearCards,
  getCompanyDetails,
  getCompanyState,
  createDocument,
  updateDocument,
  createWallet,
  getUserDocuments,
  getUserWalletBalance,
  getUserAccessToCards,
  getUserDetails,

  sendJoinInvitation,
  getInvitationDetails,
  acceptUserInvitation,
  updatePublicUser,
  getPublicUserState,
  createPublicUserDocument,
  completePublicUserVerification,
  setKYCCompanyState,

  createTaxResidence,
  getTaxResidence,
  createPublicUserTaxResidence,
  updatePublicUserTaxResidence,
  getPublicUserTaxResidence,
  getPublicUserDocuments,
  updatePublicUserDocument,

  updateCardVerificationStatus,
  storeLegalPersonDetails,
};
