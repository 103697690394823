import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Space} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import SelectedServiceForm from '../SelectedServiceForm';
import {
  StyledSelectedServiceList,
  StyledSelectedServiceListTable,
  StyledSelectedServiceListColumn,
  StyledSelectedServiceListHeaderRow,
  StyledSelectedServiceListInfoSpace
} from './StyledSelectedServiceList';
import {objectHelpers, teamsHelpers} from '../../../../../utils/helpers';

const requiredFields = ['owner', 'payment_type', 'status'];


const SelectedServicesList = React.forwardRef(({
  dispatch,
  employee,
  onChange,
  onRemove,
  onSubmit,
  optionPropName,
  services,
  ...rest
}, ref) => {
  const [t] = useTranslation(['main']);
  const [servicesList, setServicesList] = useState([]);
  const [serviceForms, setServiceForms] = useState([]);
  const [serviceFormsErrors, setServiceFormsErrors] = useState([]);

  const requiredFieldMessage = t('validation.fieldIsRequired');

  useEffect(() => {
    setServicesList(services);
    setServiceForms(services.map(service => {
      let formData = {
        ...service,
        payment_type: undefined,
        status: 1
      }
      const isSupportUser = teamsHelpers.isSupport(employee?.role);
      const previousForm = serviceForms.find(form => form.id === service.id);
      if (!isSupportUser) formData = {...formData, owner: employee?.email};
      if (previousForm) formData = {...formData, ...previousForm};
      return formData;
    }));
  }, [services, employee]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDeleteService = (service) => {
    const index = servicesList.findIndex(s => s.id === service.id);
    if (index >= 0) {
      const arrayCopy = [...servicesList];
      arrayCopy.splice(index, 1);
      setServicesList(arrayCopy);
    }
    onRemove && onRemove(service);
  }

  const handleUpdateService = (service, form) => {
    const index = serviceForms.findIndex(s => s[optionPropName] === service[optionPropName]);
    const formDetails = {...service, ...form};
    if (index >= 0) {
      setServiceForms(serviceForms.map(s => s[optionPropName] === service[optionPropName] ? formDetails : s));
    } else {
      setServiceForms([...serviceForms, formDetails]);
    }
  }

  const handleOnSubmit = (e) => {
    let isValid;
    const formErrors = [];
    e.preventDefault();
    servicesList.forEach(service => {
      let serviceForm = serviceForms.find(s => s[optionPropName] === service[optionPropName]);
      let errors = {};
      if (serviceForm) {
        requiredFields.forEach(key => {
          if (!(serviceForm.hasOwnProperty(key) && !objectHelpers.isNaV(serviceForm[key] && serviceForm[key] !== ''))) {
            errors[key] = requiredFieldMessage
          }
        });
      } else {
        requiredFields.forEach(key => {
          errors[key] = requiredFieldMessage
        });
      }
      formErrors.push({
        [optionPropName]: service[optionPropName],
        errors
      });
    });
    setServiceFormsErrors(formErrors);

    isValid = !formErrors.some(e => !objectHelpers.isEmptyObject(e.errors));
    if (isValid) {
      onSubmit && onSubmit(serviceForms);
    }
  }

  const getServiceFormErrors = (prop) => {
    const formErrors = serviceFormsErrors.find(s => s[optionPropName] === prop);
    return formErrors ? formErrors?.errors || null : null;
  }

  const columns = [
    {title: t('product')},
    {title: t('Owner')},
    {title: t('status')},
    {title: t('payment')}
  ];

  return (
    <StyledSelectedServiceList
      {...rest}
      onSubmit={handleOnSubmit}
    >
      <Space
        direction='vertical'
        size='middle'
        style={{width: '100%'}}
      >
        <StyledSelectedServiceListInfoSpace align='start'>
          <InfoCircleOutlined />
          {t('subscriptions:modal.addMultipleSubscription.placeholder.card')}
        </StyledSelectedServiceListInfoSpace>
        <StyledSelectedServiceListTable>
          <StyledSelectedServiceListHeaderRow>
            {columns.map((c, key) => (
              <StyledSelectedServiceListColumn key={key}>
                {c.title}
              </StyledSelectedServiceListColumn>
            ))}
          </StyledSelectedServiceListHeaderRow>
          {servicesList.map((service, key) => {
            const form = serviceForms.find(form => form.id === service.id);
            return (
              <StyledSelectedServiceListColumn key={key}>
                <SelectedServiceForm
                  errors={getServiceFormErrors(service[optionPropName])}
                  onChange={handleUpdateService}
                  onDelete={handleDeleteService}
                  service={service}
                  formValues={form}
                />
              </StyledSelectedServiceListColumn>
            )
          })}
        </StyledSelectedServiceListTable>
      </Space>
      <div className='d-flex'>
        <input type='submit' ref={ref} className='d-none' />
      </div>
    </StyledSelectedServiceList>
  );
});

SelectedServicesList.propTypes = {
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onSubmit: PropTypes.func,
  optionPropName: PropTypes.string,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      category: PropTypes.string,
      logo: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string.isRequired,
    })
  )
}

SelectedServicesList.defaultProps = {
  optionPropName: 'name',
  services: []
}

const mapStateToProps = state => {
  const {employee} = state.user;
  return {
    employee
  }
}

export default connect(mapStateToProps, null, null,{forwardRef: true})(SelectedServicesList)
