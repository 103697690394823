// Default number of transactions loaded initially
export const INITIAL_LOADED_TRANSACTION_MAX_COUNT = 25;

// DOM element IDs
export const TRANSACTION_TABLE_ELEMENT_ID = 'transactions-page-table-content';

// Search parameter keys for transaction pages
export const TRANSACTION_PAGE_SEARCH_KEYS = Object.freeze({
  SELECTED_TRANSACTION_ID: 'selectedTransactionId',
  SELECTED_TRANSACTION_DATE: 'selectedTransactionDate'
});