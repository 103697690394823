import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Space, Tooltip} from 'antd';
import {StyledVendorDetails, StyledVendorDetailsLink} from './StyledVendorDetails';
import {ExternalLinkIcon} from '../../../../icons';
import {objectHelpers, subscriptionsHelpers, textHelpers} from '../../../../utils/helpers';

const VendorDetails = ({
  category,
  customUrl,
  data,
  maxDescriptionCharacters,
  maxTitleCharacters,
  onLinkClick,
  ...rest
}) => {
  const getServiceProp = (key) => objectHelpers.getObjProp(data?.service, key);

  const prepareTextPreview = (text, maxCharacters) => {
    if (!maxCharacters) {
      return { enabledFull: false, short: text, full: text };
    }

    const enabledFull = text.length > maxCharacters;
    let short = text.slice(0, maxCharacters);

    if (enabledFull) short += '...';

    return {
      enabledFull,
      short,
      full: text,
    };
  }

  const textPreview = (previewTextObj) => {
    return previewTextObj.enabledFull ? (
      <Tooltip
        overlayStyle={{fontSize: 12, maxWidth: 400}}
        title={previewTextObj.full}
      >
        {previewTextObj.short}
      </Tooltip>
    ) : previewTextObj.full;
  }

  const description = useMemo(() => {
    const reason = objectHelpers.getObjProp(data, 'reason');
    const categoryFallback = objectHelpers.getObjProp(data?.service, 'category') || '';

    const text = reason || (category ? categoryFallback : '');

    return prepareTextPreview(text, maxDescriptionCharacters);
  }, [category, data, maxDescriptionCharacters]);

  const title = useMemo(() => {
    const text = subscriptionsHelpers.getSubscriptionName(data);

    return prepareTextPreview(text, maxTitleCharacters);
  }, [data, maxTitleCharacters]);

  const serviceUrl = getServiceProp('url');

  const vendorDetails = {
    label: subscriptionsHelpers.getSubscriptionName(data),
    src: getServiceProp('logo') || getServiceProp('logo_url'),
    url: `https://${serviceUrl}`,
  }

  const isEnabledUrl = customUrl || serviceUrl;

  const detailsHref = customUrl ? customUrl : onLinkClick ? '#' : vendorDetails.url;

  const onClick = (e) => {
    onLinkClick && onLinkClick(e);
    e.stopPropagation()
  }

  return (
    <StyledVendorDetails {...rest}>
      <Avatar src={vendorDetails.src}>
        {textHelpers.getInitials(vendorDetails.label)}
      </Avatar>
      <div className='vendor-details'>
        <Space align='center' size={6}>
          <div className='vendor-name'>
            {textPreview(title)}
          </div>
          {isEnabledUrl && (
            <StyledVendorDetailsLink
              href={detailsHref}
              onClick={onClick}
              rel='noreferrer'
              target={onLinkClick || customUrl ? '' : '_blank'}
            >
              <ExternalLinkIcon />
            </StyledVendorDetailsLink>
          )}
        </Space>
        {description.short && (
          <div className='vendor-category'>
            {textPreview(description)}
          </div>
        )}
      </div>
    </StyledVendorDetails>
  );
}

VendorDetails.propTypes = {
  category: PropTypes.bool,
  data: PropTypes.shape({
    reason: PropTypes.string,
    service: PropTypes.shape({
      logo: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string
    })
  }),
  maxDescriptionCharacters: PropTypes.number,
  maxTitleCharacters: PropTypes.number,
  onLinkClick: PropTypes.func,
}

VendorDetails.defaultProps = {
  category: true,
  maxDescriptionCharacters: 45,
  maxTitleCharacters: 30
}

export default VendorDetails;
