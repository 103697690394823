import {authHeaderConfig} from './authHeaderConfig';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {alertActions} from '../actions/alert.actions';
import {mainActions} from '../actions/main.actions';
import {helpers as servicesHelpers} from '../services/helpers';
import {localStorageKeysConstants} from '../../constants';
import {objectHelpers, scaHelpers} from '../../utils/helpers';
import {backendEndpoints} from '../../api';

const {COMPANY_ID, PHONE_NUMBER, SESSION_ID, TOKEN} = localStorageKeysConstants;

// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest => {
  const {url: endpointUrl} = failedRequest.response.config;

  if (endpointUrl.includes('freshdesk.com/api')) return Promise.reject(failedRequest);

  const token = JSON.parse(localStorage.getItem(TOKEN));
  const phoneNumber = localStorage.getItem(PHONE_NUMBER);
  const url = request.getRequestUrl(backendEndpoints.GET_AUTH_TOKEN_REFRESH);
  if (token && token.refreshToken) {
    const data = {
      phone_number: atob(phoneNumber),
      refresh_token: token.refreshToken
    };
    return axios.post(url, data)
      .then(tokenRefreshResponse => {
        servicesHelpers.saveJWT(tokenRefreshResponse.data);
        failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.AccessToken;
        return Promise.resolve();
      })
      .catch(err => {
        if (err.hasOwnProperty('response') && err.response.hasOwnProperty('status')) {
          if (err.response.status === 400) {
            servicesHelpers.clearUserStoredData();
            scaHelpers.clearStoredData();
          }
        }
      }) ;
  }
}

// Instantiate the interceptor
createAuthRefreshInterceptor(axios, refreshAuthLogic);

axios.interceptors.request.use(
  request => {
    const token = JSON.parse(localStorage.getItem(TOKEN));
    const company = localStorage.getItem(COMPANY_ID);
    const sessionId = localStorage.getItem(SESSION_ID);
    const apiType = request.params ? request.params.apiType : false;

    if (token && token.accessToken) request.headers['Authorization'] = `Bearer ${token.accessToken}`;
    if (sessionId) request.headers['SessionId'] = sessionId;
    if (company) {
      if (apiType) {
        request.headers[apiType === 'backend' ? 'companyid' : 'company-id'] = company; // 'companyid' for API && 'company-id' for Banking API
      } else {
        request.headers['companyid'] = company;
      }
    }
    if (apiType) delete request.params.apiType;
    return request;
  },
  error => Promise.reject(error)
);

const endpoints = {
  BACKEND: process.env.REACT_APP_API_ENDPOINT,
  BANKING: process.env.REACT_APP_BANKING_API_ENDPOINT,
  BILLING: process.env.REACT_APP_BILLING_API_ENDPOINT,
  SERVICE_STATS: process.env.REACT_APP_SERVICE_STATS_API_ENDPOINT
}

export const errorHandler = (error, errorFunc, props) => {
  const disableAlert = objectHelpers.getObjProp(props, 'disableAlert', false);
  const hideKeylessError = objectHelpers.getObjProp(props, 'hideKeylessError', false);

  try {
    let errorMessage;
    const {status} = error.response;
    let disableAlertStatuses = [401, 403];
    if (hideKeylessError) disableAlertStatuses = [...disableAlertStatuses, 406, 408];
    if (error.response) {
      const {data} = error.response;
      if (data) {
        if (data.hasOwnProperty('message')) {
          errorMessage = data.message;
        } else if (data.hasOwnProperty('detail')) {
          const {detail} = data;
          errorMessage = typeof (detail) === 'object' && detail.length > 0 ? detail[0].msg : detail.toString();
        }
      } else {
        errorMessage = error.toString();
      }
    } else {
      errorMessage = error.toString();
    }
    if (errorFunc) errorFunc(error);
    if (!disableAlertStatuses.includes(status) && !disableAlert) alertActions.error(errorMessage);
  } catch (e) {
    if (errorFunc) errorFunc(error);
    !disableAlert && alertActions.error(error.toString());
  }
}

const successHandler = ({result, successCallback, errorHandlerProps} = {}) => {
  const hideKeylessError = objectHelpers.getObjProp(errorHandlerProps, 'hideKeylessError', false);
  let {data} = result;
  if (data && data.hasOwnProperty('errors') && !hideKeylessError){
    alertActions.error(data.errors.toString());
  } else {
    successCallback && successCallback(data);
  }
}

const finallyHandler = ({loading = false, dispatch} = {}) => loading && mainActions.setLoading(false, dispatch)

class Request {
  constructor(endpoint, apiType) {
    this.endpoint = endpoint;
    this.apiType = apiType;
  }
  getAuthHeaderConfig = ({query = {}, shipAuthRefresh = false, ...rest}) => {
    return authHeaderConfig({
      params: {apiType: this.apiType, ...query},
      shipAuthRefresh,
      ...rest
    });
  };
  getRequestUrl = url => `${this.endpoint.replace(/\/$/, '')}${url}`;
  get = ({
     dispatch,
     url,
     query,
     successCallback,
     errorCallback,
     loading = false,
     errorHandlerProps = {},
     headers = {}
   }) => {
    if (loading) mainActions.setLoading(true, dispatch);
    axios
      .get(
        this.getRequestUrl(url),
        this.getAuthHeaderConfig({headers, query})
      )
      .then((result) => successHandler({result, successCallback, errorHandlerProps}))
      .catch((err) => errorHandler(err, errorCallback, errorHandlerProps))
      .finally(() => finallyHandler({loading, dispatch}));
  }
  post = ({
    dispatch,
    url,
    data,
    successCallback,
    errorCallback,
    loading = false,
    errorHandlerProps = {},
    headers = {},
    skipAuthRefresh
  }) => {
    if (loading) mainActions.setLoading(true, dispatch);
    axios
      .post(
        this.getRequestUrl(url),
        data,
        this.getAuthHeaderConfig({headers, skipAuthRefresh})
      )
      .then((result) => successHandler({result, successCallback, errorHandlerProps}))
      .catch((err) => errorHandler(err, errorCallback, errorHandlerProps))
      .finally(() => finallyHandler({loading, dispatch}));
  }
  patch = ({
    dispatch,
    url,
    data,
    successCallback,
    errorCallback,
    loading = false,
    errorHandlerProps,
    headers = {}
  }) => {
    if (loading) mainActions.setLoading(true, dispatch);
    axios
      .patch(
        this.getRequestUrl(url),
        data,
        this.getAuthHeaderConfig({headers})
      )
      .then((result) => successHandler({result, successCallback, errorHandlerProps}))
      .catch((err) => errorHandler(err, errorCallback, errorHandlerProps))
      .finally(() => finallyHandler({loading, dispatch}));
  }
  put = ({
    dispatch,
    url,
    data,
    successCallback,
    errorCallback,
    loading = false,
    query = null,
    errorHandlerProps = {},
    headers = {}
  }) => {
    if (loading) mainActions.setLoading(true, dispatch);
    axios
      .put(
        this.getRequestUrl(url),
        data,
        this.getAuthHeaderConfig({headers, query})
      )
      .then((result) => successHandler({result, successCallback, errorHandlerProps}))
      .catch((err) => errorHandler(err, errorCallback, errorHandlerProps))
      .finally(() => finallyHandler({loading, dispatch}));
  }
  delete = ({
    dispatch,
    url,
    successCallback,
    errorCallback,
    loading = false,
    query = null,
    data = undefined,
    headers = {},
    errorHandlerProps = {},
  }) => {
    if (loading) mainActions.setLoading(true, dispatch);
    axios
      .delete(
        this.getRequestUrl(url),
        {...this.getAuthHeaderConfig({headers, query}), data}
      )
      .then((result) => successHandler({result, successCallback, errorHandlerProps}))
      .catch((err) => errorHandler(err, errorCallback, errorHandlerProps))
      .finally(() => finallyHandler({loading, dispatch}));
  }
}

export const request = new Request(endpoints.BACKEND, 'backend');

export const bankingRequest = new Request(endpoints.BANKING, 'banking');

export const billingRequest = new Request(endpoints.BILLING, 'billing');

export const serviceStatsRequest = new Request(endpoints.SERVICE_STATS, 'service_stats');
