const TelecommunicationServicesIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3643 18.3641C21.8789 14.8495 21.8789 9.15053 18.3643 5.63594C14.8497 2.12135 9.15078 2.12135 5.63619 5.63594C2.1216 9.15053 2.1216 14.8495 5.63619 18.3641M15.8186 15.8184C17.9278 13.7096 17.9278 10.2904 15.8186 8.18166C13.7098 6.07245 10.2907 6.07245 8.1819 8.18166C6.0727 10.2904 6.0727 13.7096 8.1819 15.8184"
      stroke="#07132B" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M13.8003 12C13.8003 12.4774 13.6106 12.9352 13.2731 13.2728C12.9355 13.6104 12.4776 13.8 12.0002 13.8C11.5228 13.8 11.065 13.6104 10.7274 13.2728C10.3898 12.9352 10.2002 12.4774 10.2002 12C10.2002 11.5226 10.3898 11.0647 10.7274 10.7272C11.065 10.3896 11.5228 10.2 12.0002 10.2C12.4776 10.2 12.9355 10.3896 13.2731 10.7272C13.6106 11.0647 13.8003 11.5226 13.8003 12Z"
      stroke="#07132B" strokeWidth="0.5"/>
    <path
      d="M12.0002 13.8C12.4776 13.8 12.9355 13.6104 13.2731 13.2728C13.6106 12.9352 13.8003 12.4774 13.8003 12C13.8003 11.5226 13.6106 11.0647 13.2731 10.7272C12.9355 10.3896 12.4776 10.2 12.0002 10.2C11.5228 10.2 11.065 10.3896 10.7274 10.7272C10.3898 11.0647 10.2002 11.5226 10.2002 12C10.2002 12.4774 10.3898 12.9352 10.7274 13.2728C11.065 13.6104 11.5228 13.8 12.0002 13.8ZM12.0002 13.8V21.0002M12.0002 21.0002H13.8003M12.0002 21.0002H10.2002"
      stroke="#07132B" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default TelecommunicationServicesIcon;
