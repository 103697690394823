import styled from 'styled-components';
import {Space, Radio} from 'antd';
import AntdModal from '../../../AntdModal';
import {bordersStyles} from '../../../../utils/styles';

const StyledAddTransactionModal = styled(AntdModal)`
  width: 100%;;
  
  .ant-modal-body {
    max-height: 60vh;
    overflow: auto;
    padding: 0 20px 24px 20px !important;
  }
  
  .load-more-btn {
    min-height: unset;
    padding-top: 0;
  }
`;

const StyledAddTransactionModalSpace = styled.div`
  width: 100%;
  
  & > div {
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }
  
  .price {
    font-weight: 600;
    
    &.danger-text {
      color: ${props => props.theme.colors.functional_danger};
    }
  }
  
  .ant-radio-group {
    width: 100%;
    
    & > .ant-space {
      width: 100%;
    }
  }
`;

const StyledAddTransactionModalAttachmentPreview = styled.div`
  ${bordersStyles.default};
  padding: 16px;
  
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    
    & > div {
      max-height: 250px;
      overflow: auto;
    }
  }
  
  &:hover {
    cursor: pointer;
  }
`;

const StyledAddTransactionModalTransactionRadio = styled(Radio)`
  ${bordersStyles.default};
  
  color: ${props => props.theme.colors.neutral_10};
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-inline-end: 0;
  padding: 16px;
  
  & > span {
    &:not(.ant-radio) {
      padding-inline-start: 0;
    }
  }
  
  &:after {
    content: none;
  }
`;

const StyledAddTransactionModalInvoiceDetailsHeaderSpace = styled(Space)`
  color: ${props => props.theme.colors.neutral_10};
  font-size: 14px;
  width: 100%;
  
  .date {
    color: ${props => props.theme.colors.neutral_7};
    font-weight: 300;
    margin-bottom: 4px;
  }
`;

const StyledAddTransactionModalInvoiceDetailsHeader = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0 !important;
  position: sticky;
  padding-top: 24px;
  padding-bottom: 16px;
  top: 0;
  
  z-index: 2;
  width: 474px;
`;

const StyledAddTransactionModalStickyContainer = styled.div`
  background-color: #fff;
  position: sticky;
  top: 84px;
  transition: .2s ease;
  z-index: 2;
`;

const StyledAddTransactionModalRadioDescription = styled(StyledAddTransactionModalStickyContainer)`
  color: ${props => props.theme.colors.neutral_6};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 16px;
  margin-bottom: 8px !important;
  
  &.second-sticky {
    padding: 8px;
    top: calc(84px + 68px);
  }
  
  &:before,&:after {
    background-color: ${props => props.theme.colors.neutral_4};
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 26%;
  }
  &:before {
    right: 0.5em;
  }
  &:after {
    left: 0.5em;
  }
`;

export {
  StyledAddTransactionModal,
  StyledAddTransactionModalTransactionRadio,
  StyledAddTransactionModalInvoiceDetailsHeaderSpace,
  StyledAddTransactionModalInvoiceDetailsHeader,
  StyledAddTransactionModalSpace,
  StyledAddTransactionModalRadioDescription,
  StyledAddTransactionModalAttachmentPreview,
  StyledAddTransactionModalStickyContainer,
};
