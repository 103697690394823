import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import MenuCollapseButton from '../../MenuCollapseButton';
import {mainActions} from '../../../state/actions/main.actions';
import Notifications from '../../Notifications/Notifications';
import BreadCrumbs from '../../BreadCrumbs';
import ProfileDropdown from '../../ProfileDropdown/ProfileDropdown';
import {
  StyledHeaderButton,
  StyledHeaderButtons,
  StyledHeaderContent,
  StyledHeaderToolbar,
  StyledPageHeader
} from './StyledPageHeader';
import {objectHelpers} from '../../../utils/helpers';

const PageHeader = ({
  breadcrumbs,
  buttons,
  dispatch,
  leftSideContent,
  onTitleClick,
  collapseNavigationMenu,
  notificationCount,
  isOpenMenu,
  currentUser,
  ...rest
}) => {
  const handleOnMenuClick = () => collapseNavigationMenu();

  const user = {
    logo: objectHelpers.getObjProp(currentUser, 'logo'),
    name: objectHelpers.getObjProp(currentUser, 'full_name')
  }

  return (
    <StyledPageHeader {...rest}>
      <StyledHeaderContent>
        <MenuCollapseButton
          onClick={handleOnMenuClick}
          variant={isOpenMenu ? 'expand' : 'collapse'}
        />
        {breadcrumbs.length > 0 && <BreadCrumbs items={breadcrumbs} />}
        {leftSideContent && leftSideContent}
      </StyledHeaderContent>
      <StyledHeaderContent>
        <StyledHeaderButtons>
          {buttons && buttons.map((button, key) =>
            <StyledHeaderButton key={key}>{button}</StyledHeaderButton>)}
        </StyledHeaderButtons>
        <StyledHeaderToolbar
          notificationCount={notificationCount}
          notificationDropdownRender={() => <Notifications />}
          profileDropdownRender={() => <ProfileDropdown />}
          user={user}
        />
      </StyledHeaderContent>
    </StyledPageHeader>
  );
}

PageHeader.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.any,
      title: PropTypes.string.isRequired,
    })
  ),
  buttons: PropTypes.array,
  leftSideContent: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onTitleClick: PropTypes.func
}

PageHeader.defaultProps = {
  breadcrumbs: []
}

const mapStateToProps = state => {
  const {isOpenMenu} = state.main;
  const {user: currentUser, notificationCount} = state.user;
  return {
    currentUser,
    isOpenMenu,
    notificationCount: notificationCount.unread
  }
}

const mapDispatchToProps = {
  collapseNavigationMenu: mainActions.collapseNavigationMenu
}

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader);
