const TaxicabsIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.20007 18.0729C4.20007 18.0729 4.10312 18.8284 4.20007 19.1872C4.26742 19.4362 4.33207 19.8557 4.59007 19.8557H6.03862C6.29662 19.8557 6.36127 19.4362 6.42862 19.1872C6.52557 18.8286 6.42862 18.0729 6.42862 18.0729"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M19.5215 10.83H4.47855C3.24776 10.83 2.25 11.8277 2.25 13.0585V15.8443C2.25 17.0751 3.24776 18.0728 4.47855 18.0728H19.5215C20.7522 18.0728 21.75 17.0751 21.75 15.8443V13.0585C21.75 11.8277 20.7522 10.83 19.5215 10.83Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M17.5714 18.0729C17.5714 18.0729 17.4745 18.8284 17.5714 19.1872C17.6388 19.4362 17.7034 19.8557 17.9614 19.8557H19.41C19.668 19.8557 19.7326 19.4362 19.8 19.1872C19.8969 18.8286 19.8 18.0729 19.8 18.0729"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M5.31425 15.8443C5.92966 15.8443 6.42855 15.3454 6.42855 14.73C6.42855 14.1146 5.92966 13.6157 5.31425 13.6157C4.69884 13.6157 4.19995 14.1146 4.19995 14.73C4.19995 15.3454 4.69884 15.8443 5.31425 15.8443Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M4.47852 10.83L5.75997 5.81574C5.97897 4.95884 6.93667 4.14429 7.82142 4.14429H16.1786C17.0633 4.14429 18.021 4.95884 18.24 5.81574L19.5215 10.83"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M18.6856 15.8443C19.301 15.8443 19.7999 15.3454 19.7999 14.73C19.7999 14.1146 19.301 13.6157 18.6856 13.6157C18.0702 13.6157 17.5713 14.1146 17.5713 14.73C17.5713 15.3454 18.0702 15.8443 18.6856 15.8443Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default TaxicabsIcon;
