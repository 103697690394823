const SubscriptionMerchantIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 22H10C6.229 22 4.343 22 3.172 20.828C2.001 19.656 2 17.771 2 14V12C2 8.229 2 6.343 3.172 5.172C4.344 4.001 6.229 4 10 4H14C17.771 4 19.657 4 20.828 5.172C21.999 6.344 22 8.229 22 12V14M7 4V2.5M17 4V2.5M21.5 9H10.75M2 9H5.875"
      stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
    <path
      d="M19.9955 20.5221C19.8641 20.6719 19.6999 20.7924 19.5147 20.8749C19.3294 20.9575 19.1275 21.0002 18.9231 21C18.5516 21 18.1952 20.8587 17.9325 20.6071C17.6698 20.3556 17.5222 20.0144 17.5222 19.6586V18.3414C17.5222 17.9856 17.6698 17.6444 17.9325 17.3929C18.1952 17.1413 18.5516 17 18.9231 17C19.1287 16.9998 19.3317 17.043 19.5179 17.1265C19.704 17.21 19.8686 17.3318 20 17.4831M17 18.5139H18.5667M17 19.4956H18.5667"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M19 23C21.2091 23 23 21.2091 23 19C23 16.7909 21.2091 15 19 15C16.7909 15 15 16.7909 15 19C15 21.2091 16.7909 23 19 23Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <circle cx="5" cy="13" r="0.75" stroke="black" strokeWidth="0.5"/>
    <circle cx="10" cy="13" r="0.75" stroke="black" strokeWidth="0.5"/>
    <circle cx="5" cy="18" r="0.75" stroke="black" strokeWidth="0.5"/>
    <circle cx="10" cy="18" r="0.75" stroke="black" strokeWidth="0.5"/>
    <circle cx="15" cy="13" r="0.75" stroke="black" strokeWidth="0.5"/>
  </svg>
);

export default SubscriptionMerchantIcon;
