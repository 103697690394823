const MiscellaneousAndSpecialtyRetailStoresIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 5C18.3284 5 19 5.67157 19 6.5C19 7.32843 18.3284 8 17.5 8C16.6716 8 16 7.32843 16 6.5C16 5.67157 16.6716 5 17.5 5Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M2.77389 11.144C1.77089 12.264 1.74989 13.954 2.66989 15.144C4.4569 17.4638 6.5361 19.543 8.85589 21.33C10.0459 22.25 11.7359 22.229 12.8559 21.226C15.8835 18.5178 18.7284 15.6121 21.3719 12.528C21.6388 12.2212 21.8031 11.8387 21.8419 11.434C22.0059 9.63798 22.3449 4.46398 20.9399 3.05998C19.5349 1.65598 14.3619 1.99398 12.5659 2.15898C12.1611 2.19776 11.7786 2.36209 11.4719 2.62898C8.38776 5.27219 5.48207 8.11675 2.77389 11.144Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default MiscellaneousAndSpecialtyRetailStoresIcon;
