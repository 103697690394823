const ComputerNetworkInformationServicesIcon = (props) => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 11C21 5.477 16.523 1 11 1C5.477 1 1 5.477 1 11C1 16.523 5.477 21 11 21" stroke="black"
          strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M11.9999 1.04999C11.9999 1.04999 14.9999 4.99999 14.9999 11M9.99988 20.95C9.99988 20.95 6.99988 17 6.99988 11C6.99988 4.99999 9.99988 1.04999 9.99988 1.04999M1.62988 14.5H10.9999M1.62988 7.49999H20.3699"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M20.879 16.917C21.373 17.221 21.342 17.96 20.834 18.018L18.267 18.309L17.116 20.621C16.888 21.08 16.183 20.855 16.066 20.287L14.811 14.171C14.712 13.691 15.144 13.389 15.561 13.646L20.879 16.917Z"
      stroke="black" strokeWidth="0.5"/>
  </svg>
);

export default ComputerNetworkInformationServicesIcon;
