import {billingBusinessTierConstants} from '../../constants';
import {objectHelpers, textHelpers} from '../../utils/helpers';

const {isEmptyObject} = objectHelpers;

const initializePendo = ({company, user} = {}) => {
  if (isEmptyObject(company) || isEmptyObject(user)) return;

  if (!window.pendo) {
    console.warn('Pendo is not loaded');
    return;
  }

  const {firstName, lastName} = textHelpers.getNameParts(user?.full_name);

  window.pendo.initialize({
    visitor: {
      id: user.id, // Required: Unique user ID
      email: user.email,
      firstName: firstName,
      lastName: lastName,
    },
    account: {
      id: company.id, // Required: Unique account ID
      accountName: company.name,
      businessTier: billingBusinessTierConstants.BASIC
    }
  });
}

export {
  initializePendo
}
