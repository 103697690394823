const FinesIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.14197 14C2.66797 15.325 2.21097 18.576 5.30597 19.384C7.36297 19.92 9.64297 20.487 10.941 20.806C11.464 20.936 11.726 21 12 21C12.274 21 12.536 20.936 13.06 20.806C14.357 20.487 16.637 19.92 18.694 19.384C21.789 18.576 21.332 15.324 19.858 14M2.07997 9.734C1.52997 12.578 4.00597 14 4.00597 14H19.995C19.995 14 22.471 12.578 21.92 9.734C21.573 7.943 17.729 5.588 14.93 4.09C13.573 3.363 12.894 3 12 3C11.106 3 10.427 3.363 9.06997 4.09C6.27097 5.588 2.42697 7.943 2.07997 9.734Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M10.3899 7.72997L11.4959 7.12797C11.6508 7.0443 11.824 7.00049 11.9999 7.00049C12.1759 7.00049 12.3491 7.0443 12.5039 7.12797L13.6099 7.72997C13.8999 7.88797 14.0629 8.20997 13.9769 8.52597C13.7589 9.32597 13.2249 10.596 11.9999 11C10.7749 10.596 10.2399 9.32497 10.0229 8.52597C9.93695 8.20997 10.0999 7.88797 10.3899 7.72997Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default FinesIcon;
