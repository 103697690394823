import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button, Space, Form, Input, Select} from 'antd';
import {EditFilled} from '@ant-design/icons';
import {
  StyledTransactionDetailsForm,
  StyledTransactionDetailsFormLink,
  StyledTransactionDetailsFormPaidDetails,
  StyledCard,
  StyledTag
} from './StyledTransactionDetailsForm';
import CompanyTagSelect from '../CompanyTagSelect';
import DetailsTable from '../DetailsTable';
import {CreditCardIcon, ExternalLinkIcon} from '../../../../icons';
import {cardsHelpers, objectHelpers, systemHelpers, transactionsHelpers} from '../../../../utils/helpers';
import {tagFamilyConstants, transactionTypesConstants} from '../../../../constants';
import routes from '../../../../routes/routes.json';
import {firebaseEvents} from '../../../../snippets/firebase';

const {Item} = Form;

const gObjProp = objectHelpers.getObjProp;

const TransactionDetailsForm = ({
  availableCardLink,
  edit,
  onSubmit,
  transaction,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'transactions']);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [initialFormValues, setInitialFormValues] = useState({
    category_tags: [],
    cardNumber: '',
    description: '',
    paidBy: '',
    tags: []
  });

  const transT = (key) => t(`transactions:${key}`);

  const getExpenseProp = (transaction, propName, defaultValue) => gObjProp(transaction?.expense || undefined, propName, defaultValue) || defaultValue;

  const isCardTransactionType = gObjProp(transaction, 'transaction_type') === transactionTypesConstants.CARD_TRANSACTION;
  const paidByAvatar = transactionsHelpers.getTransactionAuthor(transaction);
  const transactionType = transactionsHelpers.getTransactionType(transaction);

  useEffect(() => {
    const maskedCardNumber = gObjProp(transaction, 'masked_pan');
    const fieldValues = {
      cardNumber: maskedCardNumber ? cardsHelpers.hideCardNumber(maskedCardNumber) : '',
      category_tags: getExpenseProp(transaction, 'category_tags', []),
      description: getExpenseProp(transaction, 'description') || '',
      paidBy: gObjProp(transaction, 'user')?.full_name || '',
      tags: getExpenseProp(transaction, 'tags', []),
    };
    setInitialFormValues(fieldValues);
    form.setFieldsValue(fieldValues);
    isEditMode && setIsEditMode(false);
  }, [transaction]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleEdit = () => isCardTransactionType && setIsEditMode(true);

  const handleOnCancel = () => setIsEditMode(false);

  const handleOnSave = () => form.submit();

  const handleSubmit = (formData) => {
    const data = {
      category_tags: formData.category_tags,
      description: formData.description,
      tags: formData.tags,
    }
    if (onSubmit) {
      onSubmit(
        data,
        handleOnCancel,
        handleOnCancel
      )
    } else {
      handleOnCancel();
    }
  }

  const {cardNumber} = initialFormValues;

  const transactionDetails = isCardTransactionType ? [
    {
      key: 'card',
      label: t('card'),
      value: availableCardLink ? (
        <StyledTransactionDetailsFormLink
          onClick={() => systemHelpers.logEvent(firebaseEvents.TRANSACTIONS_NAVIGATE_TO_CARD_PAGE_FROM_TRANSACTION_DETAILS)}
          to={`${routes.cardsList}/${transaction?.user_id}/${transaction?.card_id}`}
        >
          <Space size='small'>
            {transactionType}
            <span className='link-icon'>
              <ExternalLinkIcon />
            </span>
          </Space>
        </StyledTransactionDetailsFormLink>
      ) : transactionType
    },
    {
      key: 'description',
      label: t('description'),
      value: initialFormValues.description
    },
    {
      key: 'paidBy',
      label: t('paidBy'),
      value: (
        <StyledTransactionDetailsFormPaidDetails className='flex-reverse'>
          {paidByAvatar}
        </StyledTransactionDetailsFormPaidDetails>
      )
    },
    {
      key: 'costCenter',
      label: t('costCenter'),
      value: initialFormValues.tags.map((tag, key) => <StyledTag key={key}>{tag}</StyledTag>)
    },
    {
      key: 'expenseCategory',
      label: t('expenseCategory'),
      value: initialFormValues.category_tags.map((tag, key) => <StyledTag key={key}>{tag}</StyledTag>)
    }
  ] : [
    {
      key: 'type',
      label: t('type'),
      value: transactionType
    },
  ];

  const extra = edit ? isCardTransactionType
    ? isEditMode ? (
      <Space size='middle'>
        <Button
          onClick={handleOnCancel}
        >
          {t('cancel')}
        </Button>
        <Button
          onClick={handleOnSave}
          type='primary'
        >
          {t('save')}
        </Button>
      </Space>
    ) : <Button icon={<EditFilled />} onClick={handleEdit} size='small' type='text' />
    : false : null;

  return (
    <StyledTransactionDetailsForm {...rest}>
      <StyledCard
        extra={extra}
        title={`${transT('transaction')} ${t('details')}`}
        type='inner'
      >
        <Form
          className={!isEditMode && 'd-none'}
          form={form}
          layout='vertical'
          onFinish={handleSubmit}
        >
          <Item
            label={t('card')}
            name='cardNumber'
          >
            <Select
              disabled={true}
              size='large'
            >
              <Select.Option
                value={cardNumber}
              >
                <CreditCardIcon /> {cardNumber}
              </Select.Option>
            </Select>
          </Item>
          <Item
            label={t('paidBy')}
            name='paidBy'
          >
            <Select
              disabled={true}
              size='large'
            >
              <Select.Option value={initialFormValues.paidBy}>
                <StyledTransactionDetailsFormPaidDetails>
                  {paidByAvatar}
                </StyledTransactionDetailsFormPaidDetails>
              </Select.Option>
            </Select>
          </Item>
          <Item
            label={t('description')}
            name='description'
          >
            <Input size='large' />
          </Item>
          <Item
            label={t('costCenter')}
            name='tags'
          >
            <CompanyTagSelect
              inputProps={{size: 'large'}}
              selected={initialFormValues.tags}
            />
          </Item>
          <Item
            label={t('expenseCategory')}
            name='category_tags'
          >
            <CompanyTagSelect
              tagFamily={tagFamilyConstants.EXPENSE}
              inputProps={{size: 'large'}}
              selected={initialFormValues.category_tags}
            />
          </Item>
        </Form>
        <DetailsTable
          className={isEditMode && 'd-none'}
          data={transactionDetails}
        />
      </StyledCard>
    </StyledTransactionDetailsForm>
  );
}

TransactionDetailsForm.propTypes = {
  availableCardLink: PropTypes.bool,
  edit: PropTypes.bool,
  onSubmit: PropTypes.func,
  transaction: PropTypes.object
}

TransactionDetailsForm.defaulpProps = {
  availableCardLink: true,
  edit: true
}

export default TransactionDetailsForm;
