const DirectMarketingCombinationCatalogAndRetailMerchantIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.25 9.94395C21.2488 10.5804 21.0505 11.2008 20.6823 11.7199C20.3142 12.239 19.7942 12.6314 19.194 12.843C18.8657 12.9662 18.5177 13.0289 18.167 13.028C17.5306 13.0268 16.9102 12.8284 16.391 12.4603C15.8719 12.0921 15.4796 11.5722 15.268 10.972C15.1446 10.6433 15.0819 10.295 15.083 9.94395C15.086 10.295 15.023 10.644 14.898 10.972C14.6865 11.572 14.2943 12.0918 13.7754 12.46C13.2565 12.8281 12.6362 13.0266 12 13.028C11.3638 13.0266 10.7435 12.8281 10.2246 12.46C9.70571 12.0918 9.31353 11.572 9.102 10.972C8.97862 10.6433 8.91594 10.295 8.917 9.94395C8.919 10.295 8.857 10.644 8.732 10.972C8.52041 11.5722 8.12809 12.0921 7.60896 12.4603C7.08984 12.8284 6.46942 13.0268 5.833 13.028C5.483 13.03 5.133 12.968 4.806 12.843C4.20578 12.6314 3.68584 12.239 3.31768 11.7199C2.94951 11.2008 2.7512 10.5804 2.75 9.94395L3.212 8.32095L4.322 5.15495C4.46343 4.7525 4.72632 4.40389 5.07436 4.15723C5.42239 3.91058 5.83842 3.77805 6.265 3.77795H17.735C18.1614 3.77826 18.5772 3.91088 18.9251 4.15752C19.2729 4.40416 19.5356 4.75267 19.677 5.15495L20.787 8.32095L21.25 9.94395Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M14.7344 20.2219H6.85996C6.59 20.222 6.32267 20.169 6.07323 20.0658C5.82379 19.9625 5.59713 19.8112 5.4062 19.6204C5.21527 19.4295 5.06381 19.2029 4.96048 18.9535C4.85714 18.7042 4.80396 18.4368 4.80396 18.1669V12.8429M8.91695 17.1389H15.083"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <g clip-path="url(#clip0_582_185)">
      <path
        d="M18 15.5699C18.26 15.8069 18.796 16.4543 18.8583 17.1489C18.9 17.6139 19.1317 18.0166 19.5 18.2103C19.6463 18.2869 19.814 18.3313 20 18.3333C20.2517 18.3359 20.506 18.1543 20.5053 17.9026C20.5053 17.8249 20.4923 17.7453 20.4723 17.6719C20.4387 17.5596 20.4485 17.4387 20.5 17.3333C20.7033 16.9143 21.1033 16.8016 21.42 16.5739C21.5603 16.4729 21.6887 16.3663 21.745 16.2806C21.9013 16.0439 22.0573 15.5703 21.9793 15.3333"
        stroke="black" strokeWidth="0.5"/>
      <path
        d="M23.3335 18.3333C23.2235 18.6436 23.1461 19.4583 21.9061 19.4713C21.9061 19.4713 21.6418 19.4713 21.3335 19.5446M20.4791 20.0919C20.2155 20.5886 20.3691 21.1263 20.4791 21.3333"
        stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
      <path
        d="M18.3334 20.8873C18.84 21.1802 19.4149 21.3341 20.0001 21.3333C21.8411 21.3333 23.3334 19.841 23.3334 18C23.3334 16.159 21.8411 14.6666 20.0001 14.6666C18.1591 14.6666 16.6667 16.159 16.6667 18C16.6667 18.607 16.8291 19.1766 17.1127 19.6666"
        stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
    </g>
    <defs>
      <clipPath id="clip0_582_185">
        <rect width="8" height="8" fill="white" transform="translate(16 14)"/>
      </clipPath>
    </defs>
  </svg>
);

export default DirectMarketingCombinationCatalogAndRetailMerchantIcon;
