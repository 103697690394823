import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import moment from 'moment/moment';
import React from 'react';
import {Avatar, Button, Dropdown, Space, Spin, Tooltip} from 'antd';
import ls from 'localstorage-slim';
import {InfoCircleOutlined, LoadingOutlined} from '@ant-design/icons';
import {
  cardLimitPeriodConstants,
  subscriptionCategoriesConstants as subsOptions,
  subscriptionBillTypesConstants,
  subscriptionBudgetStatusesConstants,
  subscriptionPaymentTypesConstants,
  subscriptionStatusesConstants,
  subscriptionDurationTypesConstants,
  subscriptionReviewTypesConstants,
  subscriptionFormFields,
  transactionsRequestLimit,
  subscriptionContractRenewalPeriodTypesConstants,
  subscriptionContractRenewalReminderTypesConstants,
  subscriptionPaymentFrequencyTypesConstants,
} from '../../constants';
import {ChevronDownIcon, EllipsisVerticalIcon} from '../../icons';
import routes from '../../routes/routes.json';
import {
  StyledSubscriptionTableTabMenuItemSpace
} from '../../components/pages/SubscriptionsPage/tabComponents/SubscriptionTable/StyledSubscriptionTable';
import EditButton from '../../components/EditButton';
import Usage from '../../components/pages/SubscriptionPage/Usage';
import SubscriptionStatusMark from '../../components/pages/SubscriptionsPage/SubscriptionStatusMark';
import ExpandedSubscriptionRow from '../../components/pages/SubscriptionsPage/tabComponents/ExpandedSubscriptionRow';
import StatusLine from '../../components/pages/SubscriptionsPage/StatusLine';
import {amountHelpers} from './amount.helpers';
import {cardsHelpers} from './cards.helpers';
import {dateHelpers} from './date.helpers';
import {elementHelpers} from './element.helpers';
import {OptionsList} from './form.helpers';
import {kycHelpers} from './kyc.helpers';
import {locationHelpers} from './location.helpers';
import {objectHelpers} from './object.helpers';
import {textHelpers} from './text.helpers';

const gObjProp = objectHelpers.getObjProp;

const {ABOUT_TO_RENEW, ACTIVE, AUDITED, CARD_ERROR, DELETED, INACTIVE, PENDING, RESTRICTED, TERMINATED,
  TRIAL, UNTRACKED, UNMANAGED} = subscriptionStatusesConstants;

const {OVERBUDGET} = subscriptionBudgetStatusesConstants;

const {CARD, FREE, NOT_APPLICABLE, WIRE} = subscriptionPaymentTypesConstants;

const storedTabColumnsPrefix = 'stored-subscriptions-tab-columns-v3';

const alwaysAvailableColumnKeys = ['vendor'];

const subscriptionStatuses = {
  [PENDING]: {
    key: 'subscriptionStatuses.waiting',
    color: '#D9D9D9'
  },
  [ACTIVE]: {
    key: 'subscriptionStatuses.active',
    color: '#52C41A'
  },
  [INACTIVE]: {
    key: 'subscriptionStatuses.halted',
    color: '#FFCC00'
  },
  [TERMINATED]: {
    key: 'subscriptionStatuses.deleted',
    color: '#FF7875'
  },
  [DELETED]: {
    key: 'subscriptionStatuses.deleted',
    color: '#FF7875'
  },
  [CARD_ERROR]: {
    key: 'subscriptionStatuses.cardError',
    color: '#FF7875'
  },
  [TRIAL]: {
    key: 'subscriptionStatuses.trial',
    color: '#1890FF'
  },
  [UNTRACKED]: {
    key: 'subscriptionStatuses.untracked',
    color: '#9493A6'
  },
  [AUDITED]: {
    key: 'subscriptionStatuses.audited',
    color: '#722ED1'
  },
  [RESTRICTED]: {
    key: 'subscriptionStatuses.restricted',
    color: '#FFCC00'
  },
  [UNMANAGED]: {
    key: 'subscriptionStatuses.unmanaged',
    color: '#D9D9D9'
  },
  [OVERBUDGET]: {
    key: 'subscriptionStatuses.overBudget',
    color: '#FAAD14'
  },
  [ABOUT_TO_RENEW]: {
    key: 'subscriptionStatuses.aboutToRenew',
    color: '#A020F0'
  },
};

const billTypeKeys = {
  [subscriptionBillTypesConstants.MONTHLY]: 'billTypes.monthly',
  [subscriptionBillTypesConstants.YEARLY]: 'billTypes.yearly',
  [subscriptionBillTypesConstants.PASS_AS_YOU_GO]: 'billTypes.fixed',
};

const durationTypeKeys = {
  [subscriptionDurationTypesConstants.MONTHS]: 'subscriptionDurationTypes.months',
  [subscriptionDurationTypesConstants.YEARS]: 'subscriptionDurationTypes.years',
};

const cardLimitPeriodTypeKeys = {
  [cardLimitPeriodConstants.MONTH]: 'cardLimitPeriodTypes.monthly',
  [cardLimitPeriodConstants.YEAR]: 'cardLimitPeriodTypes.yearly',
  [cardLimitPeriodConstants.ALL]: 'cardLimitPeriodTypes.fixed'
};

const contractRenewalPeriodTypeKeys = {
  [subscriptionContractRenewalPeriodTypesConstants.ONE_MONTH]: 'contractRenewalPeriodTypes.oneMonth',
  [subscriptionContractRenewalPeriodTypesConstants.THREE_MONTHS]: 'contractRenewalPeriodTypes.threeMonths',
  [subscriptionContractRenewalPeriodTypesConstants.SIX_MONTHS]: 'contractRenewalPeriodTypes.sixMonths',
  [subscriptionContractRenewalPeriodTypesConstants.TWELVE_MONTHS]: 'contractRenewalPeriodTypes.twelveMonths',
  [subscriptionContractRenewalPeriodTypesConstants.EIGHTEEN_MONTHS]: 'contractRenewalPeriodTypes.eighteenMonths',
  [subscriptionContractRenewalPeriodTypesConstants.TWENTY_FOUR_MONTHS]: 'contractRenewalPeriodTypes.twentyFourMonths',
  [subscriptionContractRenewalPeriodTypesConstants.THIRTY_SIX_MONTHS]: 'contractRenewalPeriodTypes.thirtySixMonths'
};

const contractRenewalReminderTypeKeys = {
  [subscriptionContractRenewalReminderTypesConstants.NEVER]: 'contractRenewalReminderTypes.never',
  [subscriptionContractRenewalReminderTypesConstants.TWO_WEEKS]: 'contractRenewalReminderTypes.twoWeeks',
  [subscriptionContractRenewalReminderTypesConstants.ONE_MONTH]: 'contractRenewalReminderTypes.oneMonth',
  [subscriptionContractRenewalReminderTypesConstants.TWO_MONTHS]: 'contractRenewalReminderTypes.twoMonths',
  [subscriptionContractRenewalReminderTypesConstants.THREE_MONTHS]: 'contractRenewalReminderTypes.threeMonths',
  [subscriptionContractRenewalReminderTypesConstants.SIX_MONTHS]: 'contractRenewalReminderTypes.sixMonths'
};

const paymentFrequencyTypeKeys = {
  [subscriptionPaymentFrequencyTypesConstants.ONE_MONTH]: 'paymentFrequencyTypes.oneMonth',
  [subscriptionPaymentFrequencyTypesConstants.THREE_MONTHS]: 'paymentFrequencyTypes.threeMonths',
  [subscriptionPaymentFrequencyTypesConstants.SIX_MONTHS]: 'paymentFrequencyTypes.sixMonths',
  [subscriptionPaymentFrequencyTypesConstants.TWELVE_MONTHS]: 'paymentFrequencyTypes.twelveMonths'
};

const paymentTypeKeys = {
  [CARD]: 'subscriptionPaymentTypes.1',
  [FREE]: 'subscriptionPaymentTypes.0',
  [WIRE]: 'subscriptionPaymentTypes.3',
  [NOT_APPLICABLE]: 'subscriptionPaymentTypes.2'
};

const subscriptionReviewTypeKeys = {
  [subscriptionReviewTypesConstants.FREE]: 'subscriptionReviewTypesConstants.free',
  [subscriptionReviewTypesConstants.PAID]: 'subscriptionReviewTypesConstants.paid',
};

const subscriptionStatusKeys = {
  [ACTIVE]: 'subscriptionStatuses.active',
  [TRIAL]: 'subscriptionStatuses.trial',
  // [UNTRACKED]: 'subscriptionStatuses.untracked'
};

const categories = {
  [subsOptions.ANALYTICS_TOOLS_SOFTWARE]: {
    key: 'subscriptionCategories.analytics_tools_&_software',
    color: '#EF7E32',
    bgColor: '#FDF2EA'
  },
  [subsOptions.ARTIFICIAL_INTELLIGENCE_SOFTWARE]: {
    key: 'subscriptionCategories.artificial_intelligence_software',
    color: '#C02323',
    bgColor: '#F8E9E9'
  },
  [subsOptions.AR_VR]: {
    key: 'subscriptionCategories.ar_vr_software',
    color: '#03AB93',
    bgColor: '#E5F6F4'
  },
  [subsOptions.B2B_MARKETPLACES]: {
    key: 'subscriptionCategories.b2b_marketplaces',
    color: '#19AADE',
    bgColor: '#E8F6FB'
  },
  [subsOptions.CAD_PLM_SOFTWARE]: {
    key: 'subscriptionCategories.cad_plm_software',
    color: '#AF4BCE',
    bgColor: '#F7EDFA'
  },
  [subsOptions.COLLABORATION_PRODUCTIVITY_SOFTWARE]: {
    key: 'subscriptionCategories.collaboration_&_productivity_software',
    color: '#19AADE',
    bgColor: '#E8F6FB'
  },
  [subsOptions.COMMERCE_SOFTWARE]: {
    key: 'subscriptionCategories.commerce_software',
    color: '#C02323',
    bgColor: '#F8E9E9'
  },
  [subsOptions.CONTENT_MANAGEMENT_SYSTEMS]: {
    key: 'subscriptionCategories.content_management_systems',
    color: '#EABD3B',
    bgColor: '#FCF8EB'
  },
  [subsOptions.CUSTOMER_SERVICE_SOFTWARE]: {
    key: 'subscriptionCategories.customer_service_software',
    color: '#03AB93',
    bgColor: '#E5F6F4'
  },
  [subsOptions.DATA_PRIVACY_SOFTWARE]: {
    key: 'subscriptionCategories.data_privacy_software',
    color: '#DB4CB2',
    bgColor: '#FBEDF7'
  },
  [subsOptions.DESIGN_SOFTWARE]: {
    key: 'subscriptionCategories.design_software',
    color: '#C02323',
    bgColor: '#F8E9E9'
  },
  [subsOptions.DEVELOPMENT_SOFTWARE]: {
    key: 'subscriptionCategories.development_software',
    color: '#176BA0',
    bgColor: '#E7F0F5'
  },
  [subsOptions.DIGITAL_ADVERTISING_PLATFORMS]: {
    key: 'subscriptionCategories.digital_advertising_platforms',
    color: '#EA7369',
    bgColor: '#FCF1F0'
  },
  [subsOptions.ERP_SOFTWARE]: {
    key: 'subscriptionCategories.erp_software',
    color: '#EA7369',
    bgColor: '#FCF1F0'
  },
  [subsOptions.GOVERNANCE_RISK]: {
    key: 'subscriptionCategories.governance_risk_&_compliance_software',
    color: '#AF4BCE',
    bgColor: '#F7EDFA'
  },
  [subsOptions.HOSTING_PROVIDERS]: {
    key: 'subscriptionCategories.hosting_providers',
    color: '#EF7E32',
    bgColor: '#FDF2EA'
  },
  [subsOptions.HR_SOFTWARE]: {
    key: 'subscriptionCategories.hr_software',
    color: '#AF4BCE',
    bgColor: '#F7EDFA'
  },
  [subsOptions.IOT_MANAGEMENT_PLATFORMS]: {
    key: 'subscriptionCategories.iot_management_platforms',
    color: '#EABD3B',
    bgColor: '#FCF8EB'
  },
  [subsOptions.IT_INFRASTRUCTURE_SOFTWARE]: {
    key: 'subscriptionCategories.it_infrastructure_software',
    color: '#DB4CB2',
    bgColor: '#FBEDF7'
  },
  [subsOptions.IT_MANAGEMENT_SOFTWARE]: {
    key: 'subscriptionCategories.it_management_software',
    color: '#176BA0',
    bgColor: '#E7F0F5'
  },
  [subsOptions.MARKETING_SOFTWARE]: {
    key: 'subscriptionCategories.marketing_software',
    color: '#EF7E32',
    bgColor: '#FDF2EA'
  },
  [subsOptions.OFFICE_SOFTWARE]: {
    key: 'subscriptionCategories.office_software',
    color: '#C02323',
    bgColor: '#F8E9E9'
  },
  [subsOptions.SALES_TOOLS]: {
    key: 'subscriptionCategories.sales_tools',
    color: '#03AB93',
    bgColor: '#E5F6F4'
  },
  [subsOptions.SECURITY_SOFTWARE]: {
    key: 'subscriptionCategories.security_software',
    color: '#19AADE',
    bgColor: '#E8F6FB'
  },
  [subsOptions.SUPPY_CHAIN_SOFTWARE]: {
    key: 'subscriptionCategories.supply_chain_&_logistics_software',
    color: '#AF4BCE',
    bgColor: '#F7EDFA'
  },
  [subsOptions.VERTICAL_INDUSTRY_SOFTWARE]: {
    key: 'subscriptionCategories.vertical_industry_software',
    color: '#19AADE',
    bgColor: '#E8F6FB'
  },
};

export const BillOptions = (activeValue) => OptionsList(billTypeKeys, activeValue);

export const SubscriptionReviewTypeOptions = (activeValue) => OptionsList(subscriptionReviewTypeKeys, activeValue);

export const PaymentOptions = (activeValue) => {
  const list = OptionsList(paymentTypeKeys, activeValue);
  return list.filter(i => i.value !== NOT_APPLICABLE).concat(list.filter(i => i.value === NOT_APPLICABLE));
};

export const CardLimitPeriodTypeOptions = (activeValue) => OptionsList(cardLimitPeriodTypeKeys, activeValue);

export const ContractRenewalPeriodTypeOptions = (activeValue) => OptionsList(contractRenewalPeriodTypeKeys, activeValue);

export const ContractRenewalReminderTypeOptions = (activeValue) => OptionsList(contractRenewalReminderTypeKeys, activeValue);

export const PaymentFrequencyTypeOptions = (activeValue) => OptionsList(paymentFrequencyTypeKeys, activeValue);

export const SubscriptionsCategoryOptions = () => {
  const [t] = useTranslation('main');
  let categoriesList = Object.entries(categories);
  categoriesList = categoriesList.map(category => {
    const value = category[0];
    const props = category[1];
    const {bgColor, color} = props;
    return {
      bgColor,
      color,
      value,
      label: t(props.key)
    }
  });
  categoriesList.sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });
  return categoriesList;
}

export const StatusOptions = (activeValue) => {
  const options = OptionsList(subscriptionStatusKeys, activeValue);
  return options.map(option => ({
    ...option,
    image: <StatusLine color={subscriptionsHelpers.getSubscriptionStatusColor(option.value)} />
  }));
};

export const subscriptionsHelpers = {
  getCardLimitType: (key) => gObjProp(cardLimitPeriodTypeKeys, key),
  getBillType: (key) => gObjProp(billTypeKeys, key),
  getContractRenewalPeriodType: (key) => gObjProp(contractRenewalPeriodTypeKeys, key),
  getDurationType: (key) => gObjProp(durationTypeKeys, key),
  getPaymentFrequencyType: (key) => gObjProp(paymentFrequencyTypeKeys, key),
  getPaymentType: (key) => gObjProp(paymentTypeKeys, key),
  getStatusType: (key) => {
    const status = gObjProp(subscriptionStatuses, key);
    return status ? status.key : undefined;
  },
  getNextPaymentDate: (subscription, outputFormat = 'DD MMM YYYY', emptyText = 'N.A.') => {
    const {expected_first_payment: exceptedFirstPaymentDate, next_payment: nextPaymentDate} = subscription;
    let date = objectHelpers.isNaV(nextPaymentDate) && exceptedFirstPaymentDate ? exceptedFirstPaymentDate : nextPaymentDate;
    if (date) {
      let momentDateObj = moment(dateHelpers.getTimestampDateObject(date));
      let today = moment().endOf('day');
      if (!momentDateObj.isAfter(today)) {
        momentDateObj
          .set({date: momentDateObj.date(), month: today.month(), year: today.year()})
          .add(1, 'M');
        return momentDateObj.format(outputFormat);
      }
      return dateHelpers.getTimestampDate(date, outputFormat);
    } else {
      return emptyText;
    }
  },
  getSubscriptionDate: (subs, key, placeholder = '') => {
    let date = gObjProp(subs, key);
    return date ? dateHelpers.getTimestampDate(date, 'DD MMM YYYY') : placeholder;
  },
  getSubscriptionStatusCode: (subscription) => {
    let status = subscription?.status;
    const budgetStatus = subscription?.budget_status;
    if (status === ACTIVE && budgetStatus === OVERBUDGET) status = budgetStatus;
    return status;
  },
  getSubscriptionStatus: ({statusCode}) => objectHelpers.getStatus(statusCode, subscriptionStatuses),
  getSubscriptionStatusColor: (status) => {
    const colors = {
      [ABOUT_TO_RENEW]: '#A020F0',
      [ACTIVE]: '#03AB93',
      [DELETED]: '#EA7369',
      [INACTIVE]: '#FFCC00',
      [OVERBUDGET]: '#FAAD14',
      [PENDING]: '#D9D9D9',
      [TRIAL]: '#515FFF',
      [UNTRACKED]: '#9493A6'
    }
    return colors[status] || '#9493A6'
  },
  getFormItemProps: (fieldErrors, propName, className, additionalProps = {}) => {
    const {className: cName, error, message} = fieldErrors[propName];
    return {
      className: `${className} ${cName}`,
      isRequired: gObjProp(additionalProps, 'isRequired', true),
      error,
      message
    }
  },
  getSubscriptionCategory: (category) => {
    return categories.hasOwnProperty(category) ? categories[category] : {
      bgColor: '#E5F6F4',
      color: '#03AB93',
      key: 'undefined'
    };
  },
  checkIsEnabledLoadMore: (availableDate, months) => {
    let isEnabled = false;
    if (availableDate) {
      const loadedMonthDate = moment().set({date: 1}).subtract(months, 'months');
      availableDate = moment(availableDate);
      availableDate.set({date: 1});
      loadedMonthDate.isBefore(availableDate);
      isEnabled = availableDate.isSameOrBefore(loadedMonthDate);
    }
    return isEnabled;
  },
  getExpandedRowRender: (record) => (
    <ExpandedSubscriptionRow
      subscription={record}
    />
  ),
  getExpandableProps: () => ({
    expandIcon: ({ expandable, expanded, onExpand, record }) => expandable && (
      <ChevronDownIcon
        className={`expanded-table-icon ${expanded ? 'expanded' : ''}`}
        onClick={e => onExpand(record, e)}
      />
    ),
    expandedRowRender: subscriptionsHelpers.getExpandedRowRender,
    expandRowByClick: true,
    rowExpandable: (record) => !subscriptionsHelpers.isAuthorizedSubscription(record)
  }),
  getTransactionRequestQuery: (query, subtractMonthsCount, disableMonthSelection = false) => {
    let data = { sort_order: 'desc', ...query };
    if (disableMonthSelection) {
      data = { limit: transactionsRequestLimit, ...data }
    } else {
      data = {
        ...subscriptionsHelpers.getYearMonthQueryParams(subtractMonthsCount),
        ...data
      }
    }
    return data;
  },
  getYearMonthQueryParams: (subtractMonthsCount) => {
    const currentDate = moment();
    currentDate.subtract(subtractMonthsCount, 'months');
    return {
      month: currentDate.month() + 1,
      year: currentDate.year(),
    }
  },
  getSubscriptionStatusMark: (data) => {
    const statusCode = subscriptionsHelpers.getSubscriptionStatusCode(data);
    return (
      <SubscriptionStatusMark statusCode={statusCode} />
    );
  },
  getSubscriptionOwner: (data) => {
    const getOwnerProp = (key) => gObjProp(data.owner, key);
    const fullName = getOwnerProp('full_name');
    const logo = getOwnerProp('logo');
    return (
      <Tooltip title={fullName}>
        <Avatar src={logo}>
          {textHelpers.getInitials(fullName)}
        </Avatar>
      </Tooltip>
    )
  },
  getUsageVariant: (value) => {
    let variant = 'empty';
    if (value > 1) {
      if (value <= 25) {
        variant = 'low';
      } else if (value <= 50 && value > 25) {
        variant = 'medium';
      } else {
        variant = 'high';
      }
    }
    return variant;
  },
  getLoginsCount: (value) => {
    let variant = subscriptionsHelpers.getUsageVariant(value);
    return (
      <div className='flex-center-center'>
        <Tooltip overlayInnerStyle={{textAlign: 'center'}} placement='bottom' title={value}>
          <Usage variant={variant} />
        </Tooltip>
      </div>
    );
  },
  getServicesOptionsList: (services, searchedText = '') => {
    let options = [...services];
    searchedText = subscriptionsHelpers.getSearchedDomainName(searchedText);
    if (
      locationHelpers.isValidUrl(searchedText) &&
      (
        !Boolean(services.length) ||
        services.find(s => s.domain === searchedText || s.name === searchedText) === undefined
      )
    ) {
      options = [
        {domain: searchedText, name: searchedText},
        ...options
      ];
    }
    return options;
  },
  getAvailableColumns: (columns, selectedColumns = []) =>
    elementHelpers.getAvailableTableColumns({
      alwaysAvailableColumnKeys,
      columns,
      selectedColumns
    }),
  getSettingsRightSideContent: ({columns = [], filterProps, selectedKeys, onChange}) =>
    elementHelpers.getSettingsRightSideContent({
      alwaysAvailableColumnKeys,
      columns,
      onChange,
      selectedKeys,
      rightSideContent: filterProps?.rightSideContent,
    }),
  getStoredTabColumns: (name) => ls.get(`${storedTabColumnsPrefix}-${name}`),
  storeTabColumns: (name, value) => ls.set(`${storedTabColumnsPrefix}-${name}`, value),
  getDefaultTableColumns: (tableKey, defaultKeys) => subscriptionsHelpers.getStoredTabColumns(tableKey) || defaultKeys,
  getServicesAvatarGroup: (services) => {
    const maxCount = 6;
    if (maxCount < 1) return ;

    return (
      <Avatar.Group maxCount={maxCount} size={40}>
        {services.map((s, key) => {
          const logo = s.logo_url;
          return (
            <Avatar
              key={key}
              src={logo || undefined}
              style={{backgroundColor: logo ? 'unset' : '#BFBFBF'}}
            >
              {textHelpers.getInitials(s.name || s.url)}
            </Avatar>
          )
        })}
      </Avatar.Group>
    )
  },
  getTooltipServices: (services) => {
    const maxCount = 6;
    const avatarStyle = {backgroundColor: '#BFBFBF'};
    if (maxCount < 1) return ;

    return (
      <>
        {services.map((s, key) => {
          const serviceName = s.name || s.url;
          return (
            <Space size={4} align='center' key={key}>
              <Avatar
                key={key}
                src={s.logo_url || undefined}
                size={16}
                style={avatarStyle}
              >
                {textHelpers.getInitials(serviceName)}
              </Avatar>
              <span>{serviceName}</span>
            </Space>
          )
        })}
      </>
    )
  },
  getDetailsFormExtra: ({t, isEnableEdit, isEditMode, setIsEditMode, handleOnSave}) => {
    return isEnableEdit ?
      isEditMode ? (
        <Space size='middle'>
          <Button
            onClick={() => setIsEditMode(false)}
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={handleOnSave}
            type='primary'
          >
            {t('save')}
          </Button>
        </Space>
      ) : (
        <EditButton
          className='edit-btn'
          onClick={() => isEnableEdit && setIsEditMode(true)}
        />
      ) : false;
  },
  isDeletedSubscription: (subscription) => [DELETED, TERMINATED].includes(subscription.status),
  isAuthorizedSubscription: (subscription) => ![AUDITED, DELETED, RESTRICTED, TERMINATED, UNTRACKED, UNMANAGED].includes(subscription.status),
  isAuditedStatus: (status) => [AUDITED, RESTRICTED, UNMANAGED].includes(status),
  handleLoadSubscriptionEmails: ({e, subscription, setSubscriptionEmails, subscriptionEmails, getSubscriptionEmails}) => {
    elementHelpers.stopPropagation(e);
    const subscriptionId = subscription.id;
    if (!subscriptionEmails.hasOwnProperty(subscriptionId)) {
      setSubscriptionEmails({...subscriptionEmails, loading: true});
      getSubscriptionEmails(
        subscriptionId,
        (data) => {
          setSubscriptionEmails({
            ...subscriptionEmails,
            loading: false, data: data.emails || []
          });
        },
        () => {
          setSubscriptionEmails({
            ...subscriptionEmails,
            loading: false, data: []
          });
        }
      );
    }
  },
  getDropdownMenuItem: ({key, icon, t, ...rest}) => {
    return ({
      key,
      label: (
        <StyledSubscriptionTableTabMenuItemSpace
          aling='center'
          size='small'
          {...rest}
        >
          {icon}{t(`subscriptions:actions.${key.toLowerCase()}`)}
        </StyledSubscriptionTableTabMenuItemSpace>
      )
    })
  },
  getDropdownMenu: (menuProps) => (
    <Dropdown
      menu={menuProps}
      placement='bottomRight'
    >
      <EllipsisVerticalIcon
        className='ellipsis-icon'
        onClick={elementHelpers.stopPropagation}
      />
    </Dropdown>
  ),
  isEnablePanelEdit: (statusCode, edit = true) => edit && ![CARD_ERROR, DELETED, PENDING, TERMINATED].includes(statusCode),
  getSubscriptionDetailsPageUrl: (subscription) => `${routes.subscriptionsList}/${subscription.id}`,
  isViewSubscription: ({viewsStatuses, view, subscription} = {}) => {
    const viewDetails = viewsStatuses.find(v => v.view === view);
    const statuses = viewDetails.statuses || [];
    return statuses.includes(subscription.status);
  },
  getServiceName: (subscription) => {
    const service = gObjProp(subscription, 'service');
    return gObjProp(service, 'name') || gObjProp(service, 'url');
  },
  getSubscriptionName: (subscription) => {
    const friendlyName = subscription?.friendly_name;
    return friendlyName || subscriptionsHelpers.getServiceName(subscription);
  },
  getSearchedDomainName: (value) => value ? value.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0] : '',
  getSubscriptionProductProps: ({isLoadingServices, t}) => ({
    autocomplete: {
      autoClearSearchValue: true
    },
    input: {
      allowClear: !isLoadingServices,
      placeholder: t('subscriptions:modal.addSingleSubscription.placeholder.product'),
      size: 'large',
      suffix: isLoadingServices && <Spin indicator={<LoadingOutlined spin />} size='small' />
    },
    tooltip: {
      icon: <InfoCircleOutlined />,
      overlayStyle: {fontSize: 13, width: 'max-content'},
      placement: 'right',
      title: t('subscriptions:modal.addSingleSubscription.tooltip.product')
    }
  }),
  getSpendingServicesAmount: (spending, amountKey = 'amount') => {
    return [...(spending?.services || []), ...(spending?.expenses || [])]
      .reduce((partialSum, a) => partialSum + (a?.card_id ? a.amount : a[amountKey]) || 0, 0)
  },
  getSpendingServicesList: (spending, amountKey = 'amount') => {
    return [...(spending?.services || []), ...(spending?.expenses || [])]
      .filter(s => s[amountKey])
      .map(s => ({service: {name: s.service, logo: s.service_logo}, value: s[amountKey]}))
  },
  calculateTooltipServices: (services) => {
    const actualServices = services.find(s => s.name === 'actual');
    const expectedServices = services.find(s => s.name === 'expected');

    if (actualServices && actualServices.data && expectedServices) {
      const servicesList = [...actualServices.data];
      actualServices.data = servicesList.map(service => {
        const {name, value} = service;
        const expectedSpend = expectedServices.data.find(d => d.name === name);
        return {
          ...service,
          direction: expectedSpend && expectedSpend.value !== value ? value > expectedSpend.value ? 'up' : 'down' : null
        }
      });
    }
    return services;
  },
  getBudgetFieldRules: (t) => ([
    {required: true},
    {
      type: 'number',
      min: 1,
      message: t('validation.lessOrEqual', {value: 1})
    }
  ]),
  getBudgetBilledValue: ({t, data}) => (
    <>
      <span className='amount'>{amountHelpers.getAmountWithCurrencyCode(gObjProp(data, subscriptionFormFields.budgetLimitFieldName))}</span>
      &nbsp;
      {t(subscriptionsHelpers.getBillType(gObjProp(data, subscriptionFormFields.billedFieldName)))}
    </>
  ),
  handleSearchSubscriptionService: ({onSearch, services, setServices, value}) => {
    const minServiceSearchSymbolsLength = 3;
    const clearServices = () => setServices({data: [], loading: false});
    value = subscriptionsHelpers.getSearchedDomainName(value);

    if (value.length < minServiceSearchSymbolsLength && Boolean(services.data.length)) {
      clearServices();
    } else if (value !== '' && onSearch) {
      setServices({...services, loading: true});
      onSearch(
        {query: value},
        (data) => setServices({data, loading: false}),
        clearServices
      );
    }
  },
  getDefaultContractRenewalFieldsValue: (billed) => {
    const now = dayjs();
    let contractRenewalDate = now.clone().add(1, 'year').startOf('year'); // Default to yearly
    let contractRenewalPeriod, contractRenewalReminder;

    if (billed === subscriptionBillTypesConstants.MONTHLY) {
      contractRenewalDate = now.clone().add(1, 'month').startOf('month');
      contractRenewalPeriod = subscriptionContractRenewalPeriodTypesConstants.ONE_MONTH;
      contractRenewalReminder = contractRenewalDate.subtract(2, 'week');
    } else if (billed === subscriptionBillTypesConstants.YEARLY) {
      contractRenewalPeriod = subscriptionContractRenewalPeriodTypesConstants.TWELVE_MONTHS;
      contractRenewalReminder = contractRenewalDate.subtract(3, 'month');
    }

    const isInvalidDate = (date) => {
      if (!date) return true;

      return (
        contractRenewalDate.isSame(date, 'day') ||
        contractRenewalDate.isBefore(date, 'day') ||
        date.isSame(now) ||
        date.isBefore(now)
      )
    }

    if (contractRenewalReminder) {
      contractRenewalReminder = dateHelpers.getNextWorkingDay(contractRenewalReminder);

      if (isInvalidDate(contractRenewalReminder)) {
        const nextDay = dateHelpers.getNextWorkingDay(now.clone().add(1, 'day'));
        contractRenewalReminder = isInvalidDate(nextDay) ? undefined : nextDay;
      }
    }

    return {
      contract_renewal_date: contractRenewalDate,
      contract_renewal_period: contractRenewalPeriod,
      contract_renewal_reminder: contractRenewalReminder,
    };
  },
  getPaymentFrequencyValue: (billed) => {
    let value;
    if (billed === subscriptionBillTypesConstants.MONTHLY) {
      value = subscriptionPaymentFrequencyTypesConstants.ONE_MONTH;
    } else if (billed === subscriptionBillTypesConstants.YEARLY) {
      value = subscriptionPaymentFrequencyTypesConstants.TWELVE_MONTHS;
    }
    return value;
  },
  getSubscriptionTotalPayment: (subscription) => {
    const billed = subscription?.billed;
    const total_key = {
      [subscriptionBillTypesConstants.MONTHLY]: 'total_payment_month',
      [subscriptionBillTypesConstants.YEARLY]: 'total_payment_year',
      [subscriptionBillTypesConstants.PASS_AS_YOU_GO]: 'total_payment_fixed',
    }[billed];
    return subscription[total_key] || 0;
  },
  getLabelTooltip: ({key, translation} = {}) => ({
    icon: <InfoCircleOutlined />,
    overlayStyle: {fontSize: 13, width: 'max-content'},
    placement: 'right',
    title: translation ? translation(`subscriptions:tooltips.${key}`) : key
  }),
  getPaymentOptionsWithTooltip: ({options = [], translation} = {}) => {
    if (!Array.isArray(options)) return [];

    const renderLabelWithTooltip = (label, translation) => (
      <Space>
        <span>{label}</span>
        <Tooltip
          overlayStyle={{ fontSize: 13, maxWidth: 300 }}
          title={translation('subscriptions:modal.addSingleSubscription.placeholder.card')}
        >
          <InfoCircleOutlined />
        </Tooltip>
      </Space>
    );

    return options.map(({ value, label, ...rest }) => ({
      ...rest,
      value,
      label: value === CARD ? renderLabelWithTooltip(label, translation) : label
    }));
  },
  updateCardLimitOnBudgetChange: ({
    budgetLimit,
    cardLimit,
    isCardPaymentType = false,
    isSelectedHardLimit = false,
    form,
  } = {}) => {
    if (!(isCardPaymentType && isSelectedHardLimit && budgetLimit !== cardLimit && form)) return;

    form.setFieldValue(subscriptionFormFields.cardLimitFieldName, budgetLimit);
  },
  updateCardLimitPeriodOnBillingChange: ({
    billedLimitPeriod,
    isCardPaymentType = false,
    isSelectedHardLimit = false,
    form,
  } = {}) => {
    if (!isCardPaymentType || !isSelectedHardLimit || !form) return;

    const cardLimitType = cardsHelpers.getCardLimitTypeFromBilled(billedLimitPeriod);
    form.setFieldValue(subscriptionFormFields.cardLimitPeriodFieldName, cardLimitType);
  },
  validateFormLimitValues: ({
    budgetLimit,
    cardLimit,
    form,
    paymentType,
    translate,
  } = {}) => {
    let validate = false;
    if (!form || !translate) return validate;

    const errors = [];

    if (paymentType !== subscriptionPaymentTypesConstants.FREE && !budgetLimit) {
      errors.push({
        name: subscriptionFormFields.budgetLimitFieldName,
        errors: [translate('validation.fieldIsRequired')],
      });
    }

    if (errors.length) {
      form.setFields(errors);
    } else {
      validate = true;
    }

    return validate;
  },
  contractDateFieldProps: () => ({
    ...kycHelpers.getDatePickerProps(),
    disabledDate: (current) => current && current < dayjs().endOf('day'),
    panelRender: dateHelpers.getDatePickerPanelRender,
    showToday: false,
    style: {width: '100%'}
  }),
  getContractDate: (date) => {
    if (!date) return null;

    return date.hour(8).minute(0).second(0).unix();
  }
}
