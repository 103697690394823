export const mainConstants = {
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_CURRENCIES: 'GET_CURRENCIES',
  GET_PERMISSION_MATRIX: 'GET_PERMISSION_MATRIX',
  SET_INITIAL_LOADING: 'SET_INITIAL_LOADING',
  SET_LOADING: 'SET_LOADING',
  SET_NAVIGATION_LOADING: 'SET_NAVIGATION_LOADING',
  COLLAPSE_NAVIGATION_MENU: 'COLLAPSE_NAVIGATION_MENU',
  CLOSE_KYC_WINDOW: 'CLOSE_KYC_WINDOW',
  CLOSE_LIGHT_KYC_WINDOW: 'CLOSE_LIGHT_KYC_WINDOW',
  OPEN_KYC_WINDOW: 'OPEN_KYC_WINDOW',
  OPEN_LIGHT_KYC_WINDOW: 'OPEN_LIGHT_KYC_WINDOW',
  CLEAR_MAIN_DATA: 'CLEAR_MAIN_DATA',
  SET_AVAILABLE_MODULES: 'SET_AVAILABLE_MODULES',
  SET_NAVIGATION_MENU_PROPS: 'SET_NAVIGATION_MENU_PROPS',
  DISPLAY_WALLET_DETAILS_INITIALLY: 'DISPLAY_WALLET_DETAILS_INITIALLY',
};
