const ProfessionalServicesIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.35086 15L6.71386 17.323C6.08586 19.615 5.77186 20.761 6.19086 21.388C6.33786 21.608 6.53486 21.784 6.76386 21.901C7.41586 22.233 8.42386 21.708 10.4389 20.658C11.1089 20.308 11.4449 20.134 11.8009 20.096C11.9332 20.0828 12.0665 20.0828 12.1989 20.096C12.5549 20.134 12.8899 20.309 13.5609 20.658C15.5759 21.708 16.5839 22.233 17.2359 21.901C17.4649 21.784 17.6619 21.608 17.8089 21.388C18.2289 20.761 17.9139 19.615 17.2859 17.323L16.6489 15"
      stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
    <path
      d="M5.49994 6.39694C4.90071 7.89379 4.83657 9.5516 5.31837 11.0903C5.80016 12.6289 6.79835 13.9541 8.14426 14.8419C9.49018 15.7296 11.1013 16.1256 12.7054 15.9628C14.3095 15.7999 15.8082 15.0884 16.9483 13.9483C18.0884 12.8082 18.7999 11.3095 18.9628 9.70539C19.1256 8.1013 18.7296 6.49018 17.8419 5.14426C16.9541 3.79835 15.6289 2.80016 14.0903 2.31837C12.5516 1.83657 10.8938 1.90071 9.39694 2.49994"
      stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
  </svg>
);

export default ProfessionalServicesIcon;
