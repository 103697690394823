const expenseCategoriesConstants = Object.freeze({
  ACCOMMODATION: 1,
  ADVERTISING: 2,
  BENEFITS: 3,
  BILLS_FEES: 4,
  CAR: 5,
  CLOUD_SERVICES: 6,
  ENTERTAINMENT: 7,
  EQUIPMENT: 8,
  INSURANCE: 9,
  IT_SERVICES: 10,
  MATERIALS: 11,
  MEALS: 12,
  OFFICE_SUPPLIES: 13,
  OTHER: 14,
  PROFESSIONAL_SERVICES: 15,
  REMOTE_WORK: 16,
  SHIPPING: 17,
  SUBSCRIPTIONS: 18,
  TEAM_ENABLEMENT: 19,
  TRAVEL: 20,
});

const mccCategoriesConstants = Object.freeze({
  MISCELLANEOUS_PUBLISHING_AND_PRINTING: 101,
  AIRLINES: 102,
  TRANSPORTATION_SUBURBAN: 103,
  PASSENGER_RAILWAYS: 104,
  TAXICABS: 105,
  COURIER_SERVICES_AIR_AND_GROUND: 106,
  TRAVEL_AGENCIES_AND_TOUR_OPERATORS: 107,
  TELECOMMUNICATION_EQUIPMENT: 108,
  TELECOMMUNICATION_SERVICES: 109,
  COMPUTER_NETWORK_INFORMATION_SERVICES: 110,
  SOFTWARE: 111,
  BOOKS_PERIODICALS_AND_NEWSPAPERS: 112,
  DEPARTMENT_STORES: 113,
  VARIETY_STORES: 114,
  MISCELLANEOUS_GENERAL_MERCHANDISE_STORES: 115,
  GROCERY_STORES_SUPERMARKETS: 116,
  MISCELLANEOUS_FOOD_STORES: 117,
  EQUIPMENT_FURNITURE_AND_HOME_FURNISHINGS_STORES: 118,
  ELECTRONICS_SALES: 119,
  COMPUTER_SOFTWARE_STORES: 120,
  RESTAURANTS: 121,
  DIGITAL_GOODS: 122,
  SOUVENIR_SHOPS: 123,
  DIRECT_MARKETING_COMBINATION_CATALOG_AND_RETAIL_MERCHANT: 124,
  DIRECT_MARKETING_CONTINUITY_SUBSCRIPTION_MERCHANTS: 125,
  FLORISTS: 126,
  MISCELLANEOUS_AND_SPECIALTY_RETAIL_STORES: 127,
  REAL_ESTATE_AGENTS_AND_MANAGERS_RENTALS: 128,
  HOTELS: 129,
  PHOTOGRAPHIC_STUDIOS: 130,
  ADVERTISING_SERVICES: 131,
  COMMERCIAL_ART_GRAPHICS_PHOTOGRAPHY: 132,
  EMPLOYMENT_AGENCIES_AND_TEMPORARY_HELP_SERVICES: 133,
  COMPUTER_PROGRAMMING_DATA_PROCESSING_AND_INTEGRATED_SYSTEM_DESIGN_SERVICES: 134,
  CONSULTING_MANAGEMENT_AND_PUBLIC_RELATIONS_SERVICES: 135,
  BUSINESS_SERVICES_NOT_ELSEWHERE_CLASSIFIED: 136,
  ATHLETIC_FIELDS_COMMERCIAL_SPORTS_PROFESSIONAL_SPORTS_CLUBS_SPORTS_PROMOTERS: 137,
  RECREATION_SERVICES: 138,
  SCHOOLS_AND_EDUCATIONAL_SERVICES: 139,
  PROFESSIONAL_SERVICES: 140,
  FINES: 141,
  TAX_PAYMENTS: 142,
  OTHERS: undefined
});

const subscriptionCategoriesConstants = Object.freeze({
  SALES_TOOLS: 1,
  MARKETING_SOFTWARE: 2,
  ANALYTICS_TOOLS_SOFTWARE: 3,
  ARTIFICIAL_INTELLIGENCE_SOFTWARE: 4,
  AR_VR: 5,
  B2B_MARKETPLACES: 6,
  CAD_PLM_SOFTWARE: 7,
  COLLABORATION_PRODUCTIVITY_SOFTWARE: 8,
  COMMERCE_SOFTWARE: 9,
  CONTENT_MANAGEMENT_SYSTEMS: 10,
  CUSTOMER_SERVICE_SOFTWARE: 11,
  DATA_PRIVACY_SOFTWARE: 12,
  DESIGN_SOFTWARE: 13,
  DEVELOPMENT_SOFTWARE: 14,
  DIGITAL_ADVERTISING_PLATFORMS: 15,
  ERP_SOFTWARE: 16,
  GOVERNANCE_RISK: 17,
  HOSTING_PROVIDERS: 18,
  HR_SOFTWARE: 19,
  IOT_MANAGEMENT_PLATFORMS: 20,
  IT_INFRASTRUCTURE_SOFTWARE: 21,
  IT_MANAGEMENT_SOFTWARE: 22,
  OFFICE_SOFTWARE: 23,
  SECURITY_SOFTWARE: 24,
  SUPPY_CHAIN_SOFTWARE: 25,
  VERTICAL_INDUSTRY_SOFTWARE: 26,
});

export {
  expenseCategoriesConstants,
  mccCategoriesConstants,
  subscriptionCategoriesConstants
};

