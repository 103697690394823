import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {
  StyledOverviewCounters,
  StyledOverviewCountersTotalCounter,
  StyledOverviewCountersValue,
} from './StyledOverviewCounters';
import TextLoader from '../../../ContentLoaders/TextLoader';
import {SubtractIcon} from '../../../../icons';
import actualLegendIconImage from '../../../../static/images/pages/overview/actual-legend-item.png';
import previousLegendIconImage from '../../../../static/images/pages/overview/previous-legend-item.png';
import {amountHelpers, objectHelpers} from '../../../../utils/helpers';

const OverviewCounters = ({
  children,
  actualTimeRange,
  enableBudgetCounter,
  data,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const trans = (key) => t(`subscriptions:overview.counters.${key}`);

  const getPrice = (value) => amountHelpers.getAmountWithCurrencyCode(value);

  const checkIsLoaded = (key) => objectHelpers.getObjProp(data[key], 'isLoaded', true);

  const getValue = (key) => {
    const isLoaded = checkIsLoaded(key);
    return isLoaded
      ? objectHelpers.getObjProp(data[key], 'value', 0)
      : <TextLoader width={80} y={0} height={26} />;
  }

  const getPriceValue = (key) => {
    const isLoaded = checkIsLoaded(key);
    const value = getValue(key)
    return isLoaded ? getPrice(value) : value;
  }

  const getLegendValue = (key, image) => (
    <Space
      align='center'
      size='small'
    >
      <img src={image} alt={key} />
      <StyledOverviewCountersValue>{getPriceValue(key)}</StyledOverviewCountersValue>
    </Space>
  );

  const budgetCounter = useMemo(() => {
    let element;
    if (enableBudgetCounter) {
      const overBudget = getValue('expected') - getValue('actual');
      element = overBudget < 0 ? (
        <StyledOverviewCountersTotalCounter
          className='budget-total-counter over-budged'
          title={`${t('over')} ${t('spending')}`}
          value={(
            <Space size='small' align='center'>
              <SubtractIcon />
              <span>{getPrice(Math.abs(overBudget))}</span>
            </Space>
          )}
        />
      ) : (
        <StyledOverviewCountersTotalCounter
          className='budget-total-counter'
          title={`${t('under')} ${t('spending')}`}
          value='-'
        />
      );
    }
    return element;
  }, [data, enableBudgetCounter]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledOverviewCounters {...rest}>
      <StyledOverviewCountersTotalCounter
        title={<>{trans('actual')} <span className='gray'>{actualTimeRange}</span></>}
        value={getLegendValue('actual', actualLegendIconImage)}
      />
      <StyledOverviewCountersTotalCounter
        title={trans('previousPeriod')}
        value={getLegendValue('expected', previousLegendIconImage)}
      />
      {budgetCounter}
      {children}
    </StyledOverviewCounters>
  );
}

const CounterPropsType = PropTypes.shape({
  isLoaded: PropTypes.bool.isRequired,
  value: PropTypes.any
}).isRequired;

OverviewCounters.propTypes = {
  actualTimeRange: PropTypes.string,
  data: PropTypes.shape({
    actual: CounterPropsType,
    expected: CounterPropsType,
  }).isRequired,
  enableBudgetCounter: PropTypes.bool
};

OverviewCounters.defaultProps = {
  enableBudgetCounter: true
}

export default OverviewCounters;
