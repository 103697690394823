const ElectronicsSalesIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.25 9.94395C21.2488 10.5804 21.0505 11.2008 20.6823 11.7199C20.3142 12.239 19.7942 12.6314 19.194 12.843C18.8657 12.9662 18.5177 13.0289 18.167 13.028C17.5306 13.0268 16.9102 12.8284 16.391 12.4603C15.8719 12.0921 15.4796 11.5722 15.268 10.972C15.1446 10.6433 15.0819 10.295 15.083 9.94395C15.086 10.295 15.023 10.644 14.898 10.972C14.6865 11.572 14.2943 12.0918 13.7754 12.46C13.2565 12.8281 12.6362 13.0266 12 13.028C11.3638 13.0266 10.7435 12.8281 10.2246 12.46C9.70571 12.0918 9.31353 11.572 9.102 10.972C8.97862 10.6433 8.91594 10.295 8.917 9.94395C8.919 10.295 8.857 10.644 8.732 10.972C8.52041 11.5722 8.12809 12.0921 7.60896 12.4603C7.08984 12.8284 6.46942 13.0268 5.833 13.028C5.483 13.03 5.133 12.968 4.806 12.843C4.20578 12.6314 3.68584 12.239 3.31768 11.7199C2.94951 11.2008 2.7512 10.5804 2.75 9.94395L3.212 8.32095L4.322 5.15495C4.46343 4.7525 4.72632 4.40389 5.07436 4.15723C5.42239 3.91058 5.83842 3.77805 6.265 3.77795H17.735C18.1614 3.77826 18.5772 3.91088 18.9251 4.15752C19.2729 4.40416 19.5356 4.75267 19.677 5.15495L20.787 8.32095L21.25 9.94395Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M13.5 20.2219H6.85996C6.59 20.222 6.32267 20.169 6.07323 20.0658C5.82379 19.9625 5.59713 19.8112 5.4062 19.6204C5.21527 19.4295 5.06381 19.2029 4.96048 18.9535C4.85714 18.7042 4.80396 18.4368 4.80396 18.1669V12.8429M8.91695 17.1389H14"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M18.1111 20.7778H15.4444C15.3266 20.7778 15.2135 20.731 15.1302 20.6476C15.0468 20.5643 15 20.4512 15 20.3334V16.7778C15 16.6599 15.0468 16.5469 15.1302 16.4635C15.2135 16.3802 15.3266 16.3334 15.4444 16.3334H18.1111M16.7778 22.5556H18.1111M21.2222 17.6667V17.6711M19.4444 16.3334C19.4444 16.2155 19.4913 16.1024 19.5746 16.0191C19.658 15.9357 19.771 15.8889 19.8889 15.8889H22.5556C22.6734 15.8889 22.7865 15.9357 22.8698 16.0191C22.9532 16.1024 23 16.2155 23 16.3334V22.5556C23 22.6735 22.9532 22.7865 22.8698 22.8699C22.7865 22.9532 22.6734 23 22.5556 23H19.8889C19.771 23 19.658 22.9532 19.5746 22.8699C19.4913 22.7865 19.4444 22.6735 19.4444 22.5556V16.3334Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M17.6667 20.7778V22.5556M20.7779 21.2223C20.7779 21.3402 20.8247 21.4532 20.908 21.5365C20.9914 21.6199 21.1044 21.6667 21.2223 21.6667C21.3402 21.6667 21.4532 21.6199 21.5366 21.5365C21.6199 21.4532 21.6667 21.3402 21.6667 21.2223C21.6667 21.1044 21.6199 20.9914 21.5366 20.908C21.4532 20.8247 21.3402 20.7778 21.2223 20.7778C21.1044 20.7778 20.9914 20.8247 20.908 20.908C20.8247 20.9914 20.7779 21.1044 20.7779 21.2223Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default ElectronicsSalesIcon;
