import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {mainActions} from '../../state/actions/main.actions';

const SystemDataPreloader = ({
  isAuthenticated,

  getCategories,
  getPermissionMatrix,
}) => {
  useEffect(() => {
    if (isAuthenticated) {
      getCategories();
      getPermissionMatrix();
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
    </>
  );
};

const mapStateToProps = ({ user }) => ({
  isAuthenticated: user.isAuth,
});

const mapDispatchToProps = {
  getCategories: mainActions.getCategories,
  getPermissionMatrix: mainActions.getPermissionMatrix,
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemDataPreloader);
