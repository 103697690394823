import {expenseConstants} from '../constants';

const expenseState = {
  expense: {},
};

const expenseReducer = (state = expenseState, action) => {
  const {type, data} = action;
  switch (type) {
    case expenseConstants.GET_EXPENSE:
      return {
        ...state,
        expense: data
      }
    default:
      return state
  }
};

export default expenseReducer;
