import styled from 'styled-components';
import {Space} from 'antd';

const StyledHardCardLimitSwitch = styled(Space)`
  .anticon-info-circle {
    color: ${props => props.theme.colors.neutral_6};
    
    &:hover {
      cursor: help;
    }
  }
`;

export {
  StyledHardCardLimitSwitch
}
