const SouvenirShopsIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 10C3 9.057 3 8.586 3.293 8.293C3.586 8 4.057 8 5 8H19C19.943 8 20.414 8 20.707 8.293C21 8.586 21 9.057 21 10V11.5C21 11.966 21 12.199 20.924 12.383C20.8223 12.6275 20.6277 12.8217 20.383 12.923C20.199 13 19.966 13 19.5 13C19.034 13 18.801 13 18.617 13.076C18.3725 13.1777 18.1783 13.3723 18.077 13.617C18 13.801 18 14.034 18 14.5V18C18 18.943 18 19.414 17.707 19.707C17.414 20 16.943 20 16 20H8C7.057 20 6.586 20 6.293 19.707C6 19.414 6 18.943 6 18V14.5C6 14.034 6 13.801 5.924 13.617C5.82229 13.3725 5.62773 13.1783 5.383 13.077C5.199 13 4.966 13 4.5 13C4.034 13 3.801 13 3.617 12.924C3.37246 12.8223 3.17826 12.6277 3.077 12.383C3 12.199 3 11.966 3 11.5V10Z"
      stroke="black" strokeWidth="0.5"/>
    <path
      d="M5 13H19M12 7.00001V20M12 7.00001L11.121 6.12101C10.0552 5.05463 8.75546 4.25137 7.325 3.77501C7.05953 3.68643 6.77681 3.6622 6.50014 3.70433C6.22347 3.74646 5.96078 3.85374 5.73371 4.01733C5.50664 4.18091 5.3217 4.39612 5.19414 4.64522C5.06657 4.89431 5.00003 5.17015 5 5.45001V5.63201C5 6.44901 5.523 7.17401 6.297 7.43201L8 8.00001M12 7.00001L12.879 6.12101C13.9448 5.05463 15.2445 4.25137 16.675 3.77501C16.9405 3.68643 17.2232 3.6622 17.4999 3.70433C17.7765 3.74646 18.0392 3.85374 18.2663 4.01733C18.4934 4.18091 18.6783 4.39612 18.8059 4.64522C18.9334 4.89431 19 5.17015 19 5.45001V5.63201C19 6.44901 18.477 7.17401 17.703 7.43201L16 8.00001"
      stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
  </svg>
);

export default SouvenirShopsIcon;
