const EquipmentFurnitureAndHomeFurnishingsStoresIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.25 9.94395C21.2488 10.5804 21.0505 11.2008 20.6823 11.7199C20.3142 12.239 19.7942 12.6314 19.194 12.843C18.8657 12.9662 18.5177 13.0289 18.167 13.028C17.5306 13.0268 16.9102 12.8284 16.391 12.4603C15.8719 12.0921 15.4796 11.5722 15.268 10.972C15.1446 10.6433 15.0819 10.295 15.083 9.94395C15.086 10.295 15.023 10.644 14.898 10.972C14.6865 11.572 14.2943 12.0918 13.7754 12.46C13.2565 12.8281 12.6362 13.0266 12 13.028C11.3638 13.0266 10.7435 12.8281 10.2246 12.46C9.70571 12.0918 9.31353 11.572 9.102 10.972C8.97862 10.6433 8.91594 10.295 8.917 9.94395C8.919 10.295 8.857 10.644 8.732 10.972C8.52041 11.5722 8.12809 12.0921 7.60896 12.4603C7.08984 12.8284 6.46942 13.0268 5.833 13.028C5.483 13.03 5.133 12.968 4.806 12.843C4.20578 12.6314 3.68584 12.239 3.31768 11.7199C2.94951 11.2008 2.7512 10.5804 2.75 9.94395L3.212 8.32095L4.322 5.15495C4.46343 4.7525 4.72632 4.40389 5.07436 4.15723C5.42239 3.91058 5.83842 3.77805 6.265 3.77795H17.735C18.1614 3.77826 18.5772 3.91088 18.9251 4.15752C19.2729 4.40416 19.5356 4.75267 19.677 5.15495L20.787 8.32095L21.25 9.94395Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M11.5 20.2219H6.85996C6.59 20.222 6.32267 20.169 6.07323 20.0658C5.82379 19.9625 5.59713 19.8112 5.4062 19.6204C5.21527 19.4295 5.06381 19.2029 4.96048 18.9535C4.85714 18.7042 4.80396 18.4368 4.80396 18.1669V12.8429M8.91695 17.1389H13"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M15.125 19.2857L15.0913 19.1576C14.6021 17.2946 14.3577 16.3633 14.8392 15.7191C14.8698 15.6786 14.9022 15.6393 14.9364 15.6013C15.4751 15 16.4835 15 18.5 15C20.5164 15 21.5249 15 22.064 15.6013C22.0976 15.6393 22.1297 15.6786 22.1603 15.7191C22.6418 16.3629 22.3974 17.2946 21.9083 19.1576L21.875 19.2857M14.9 21H22.1C22.3387 21 22.5676 20.9097 22.7364 20.7489C22.9052 20.5882 23 20.3702 23 20.1429C23 19.9155 22.9052 19.6975 22.7364 19.5368C22.5676 19.376 22.3387 19.2857 22.1 19.2857H14.9C14.6613 19.2857 14.4324 19.376 14.2636 19.5368C14.0948 19.6975 14 19.9155 14 20.1429C14 20.3702 14.0948 20.5882 14.2636 20.7489C14.4324 20.9097 14.6613 21 14.9 21Z"
      stroke="black" strokeWidth="0.5"/>
    <path d="M22 22V21M15 22V21" stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
  </svg>
);

export default EquipmentFurnitureAndHomeFurnishingsStoresIcon;
