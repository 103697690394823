import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Modal, Space} from 'antd';
import PropTypes from 'prop-types';
import {useLocation, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import AllTab from '../tabs/AllTab';
import ExternalTab from '../tabs/ExternalTab';
import DisabledTab from '../tabs/DisabledTab';
import InviteUserModal from '../InviteUserModal';
import DisableUserModal from '../DisableUserModal';
import AuthenticationWindow from '../../CardsPage/AuthenticationWindow';
import Tabs from '../../../Tabs';
import {StyledTableFiltersDivider} from '../../TransactionsPage/TableFilters/StyledTableFilters';
import {SCAActionsConstants, userStatusesConstants, userViewTypesConstants} from '../../../../constants';
import {teamActions} from '../../../../state/actions/team.actions';
import {firebaseEvents} from '../../../../snippets/firebase';
import routes from '../../../../routes/routes.json';
import {
  locationHelpers,
  objectHelpers,
  scaHelpers,
  systemHelpers,
  tabHelpers,
  teamsHelpers
} from '../../../../utils/helpers';
import {useHasAccess} from '../../../../hooks';

const {logEvent} = systemHelpers;
const {getObjProp: gObjProp} = objectHelpers;

const {DISABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION, ENABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION} = SCAActionsConstants;

const {DISABLED: DISABLED_STATUS, ACTIVE: ENABLED_STATUS} = userStatusesConstants;

const {ALL, ACTIVE, EXTERNAL, DISABLED} = userViewTypesConstants;
const tabKeys = {
  ACTIVE: 'ACTIVE',
  ALL: 'ALL',
  EXTERNAL: 'EXTERNAL',
  DISABLED: 'DISABLED'
}

const perPagePagination = 25;

const defaultTableData = {
  loaded: false,
  loadedPages: 1,
  pagination: null,
  page: 1,
  rows: []
}

const defaultQuery = {
  order_by: '-name',
  per_page: perPagePagination,
}

const defaultTabKey = tabKeys.ALL.toLowerCase();

const TeamTabs = ({
  dispatch,
  getEmployees,
  handleUpdateTotal,
  isSupport,
  employeeEmail,
  total,
  totalLoading,
  openInviteModal,
  createEmployee,
  deleteEmployee,
  updateEmployee,
  updateEmployeeStatus,
  resendInvite,
  ...rest
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [t] = useTranslation(['main', 'teams']);
  const [activeTabKey, setActiveTabKey] = useState(locationHelpers.getHash(location) || defaultTabKey);
  const [tableData, setTableData] = useState({
    [ACTIVE]: defaultTableData,
    [ALL]: defaultTableData,
    [DISABLED]: defaultTableData,
    [EXTERNAL]: defaultTableData
  });
  const [queries, setQueries] = useState({
    [ACTIVE]: defaultQuery,
    [ALL]: defaultQuery,
    [DISABLED]: defaultQuery,
    [EXTERNAL]: defaultQuery
  });
  const [loading, setLoading] = useState({
    [ACTIVE]: false,
    [ALL]: false,
    [DISABLED]: false,
    [EXTERNAL]: false
  });
  const [inviteModalProps, setInviteModalProps] = useState({loading: false, open: false});
  const [authWindowProps, setAuthWindowProps] = useState({open: false});
  const [isOpenDisableModal, setIsOpenDisableModal] = useState(false);
  const [activeAction, setActiveAction] = useState({action: '', email: '', isTerminateSubscriptions: false});
  const [selectedEmployee, setSelectedEmployee] = useState(undefined);
  const hasCreateAccess = useHasAccess('POST_COMPANY_TEAM_EMPLOYEE_CREATE');

  const trans = (key) => t(`teams:${key}`);

  useEffect(() => {
    loadData({
      initial: true,
      view: userViewTypesConstants[activeTabKey.toUpperCase()]
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    logEvent(
      firebaseEvents.TEAM_ACTIVE_TAB,
      {name: activeTabKey}
    );
  }, [activeTabKey]);

  const getLabel = (key) => tabHelpers.getLabel({key, total, t: trans});

  const startTableLoading = (view) => setLoading({...loading, [view]: true});

  const finishTableLoading = (view) => setLoading({...loading, [view]: false});

  const updateTotal = () => handleUpdateTotal && handleUpdateTotal();

  const loadData = ({view, query = null, initial = false}) => {
    const viewQuery = tabHelpers.getViewQuery({initial, query, queries, view});
    setQueries({
      ...queries,
      [view]: viewQuery
    });
    startTableLoading(view);

    getEmployees(
      {
        ...viewQuery,
        user_group: view
      },
      (response) => {
        tabHelpers.handleStoreTableData({tableData, setTableData, view, response, initial, rowsKey: 'users'});
        finishTableLoading(view);
      },
      () => finishTableLoading(view)
    )
  }

  const handleOnPaginate = (view, activePage) =>
    tabHelpers.handleOnPaginate({view, activePage, tableData, setTableData, loadDataFunc: loadData})

  const handleOnFilter = (view, filterQuery) =>
    tabHelpers.handleOnFilter({view, filterQuery, loadDataFunc: loadData, queries, setQueries});

  const handleOnTableChange = (view, pagination, filters, sorter, extra) =>
    tabHelpers.handleOnTableChange({view, pagination, filters, sorter, extra, loadDataFunc: loadData});

  const handleOnAddClick = () => setInviteModalProps({...inviteModalProps, open: true});

  const handleResendInvite = (email, modalProps) => {
    Modal.confirm({
      ...modalProps,
      onOk() {
        const view = userViewTypesConstants[activeTabKey.toUpperCase()];
        startTableLoading(view);
        resendInvite(
          email,
          null,
          () => {
            setTableData({
              ...tableData,
              [view]: {
                ...tableData[view],
                rows: [...tableData[view].rows.map(r => r.email === email ? teamsHelpers.getReInvitedUserData(r) : r)]
              }
            });
            finishTableLoading(view);
            logEvent(firebaseEvents.TEAM_USER_REINVITED);
          },
          () => finishTableLoading(view)
        )
      }
    });
  }

  const handleCancelDisableModal = () => setIsOpenDisableModal(false);

  const handleOkDisableModal = (_, {terminate}) => {
    const email = selectedEmployee?.email || '';
    setIsOpenDisableModal(false);
    setActiveAction({
      ...activeAction,
      action: DISABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION,
      email,
      isTerminateSubscriptions: terminate
    });
    onDisableUser({action: DISABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION, email, terminate});
  }

  const handleDeleteUser = (email, modalProps) => {
    Modal.confirm({
      ...modalProps,
      onOk() {
        const view = userViewTypesConstants[activeTabKey.toUpperCase()];
        startTableLoading(view);
        deleteEmployee(
          email,
          () => {
            loadData({view, initial: true});
            logEvent(firebaseEvents.TEAM_USER_DELETED);
            updateTotal();
          },
          () => finishTableLoading(view)
        )
      }
    });
  }

  const handleDisableUser = (employee) => {
    setIsOpenDisableModal(true);
    setSelectedEmployee(employee);
  }

  const handleEnableUser = (email, modalProps) => {
    Modal.confirm({
      ...modalProps,
      onOk() {
        setActiveAction({...activeAction, action: ENABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION, email});
        onEnableUser({action: ENABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION, email});
      }
    });
  }

  const handleOnActionClick = ({e, action, employee}) => {
    e.stopPropagation();
    const email = employee?.email || '';
    const getConfirmModalProps = (props) => teamsHelpers.getConfirmModalProps({t, employee, ...props})

    const actions = {
      delete: () => {
        handleDeleteUser(
          email,
          getConfirmModalProps({action: 'delete'})
        );
      },
      disable: () => {
        handleDisableUser(
          employee,
          getConfirmModalProps({action: 'disable'})
        )
      },
      enable: () => {
        handleEnableUser(
          email,
          getConfirmModalProps({action: 'enable'})
        );
      },
      invite: () => {
        handleResendInvite(
          email,
          getConfirmModalProps({action: 'invite'})
        );
      },
      'resend-invite': () => {
        handleResendInvite(
          email,
          getConfirmModalProps({action: 'resend invitation'})
        );
      },
    }
    actions.hasOwnProperty(action) && actions[action]();
  }

  const handleOnRoleChange = ({value, employee}) => {
    const email = gObjProp(employee, 'email');
    if (email) {
      updateEmployee(
        email,
        {role: value},
      );
    }
  }

  const handleOnRowClick = (e, record) => navigate(`${routes.team}/${record.email}`);

  const getTableDataValue = (view, key, defaultValue) => tableData.hasOwnProperty(view) ? tableData[view][key] : defaultValue;

  const inviteUserButton = (
    <Button
      onClick={handleOnAddClick}
      size='large'
      type='primary'
    >
      {trans('inviteUser')}
    </Button>
  );

  const getTabProps = (key, totalKey) => {
    const data = getTableDataValue(key, 'rows', []);
    return {
      data,
      filtersRightSideContent: hasCreateAccess && (
        <Space size={0}>
          <StyledTableFiltersDivider type='vertical' />
          {inviteUserButton}
        </Space>
      ),
      onActionClick: handleOnActionClick,
      onRoleChange: handleOnRoleChange,
      onFilter: (value) => handleOnFilter(key, value),
      isEditorSupport: isSupport,
      editorEmail: employeeEmail,
      tableProps: {
        loading: loading[key] || false,
        onChange: (pagination, filters, sorter, extra) => handleOnTableChange(key, pagination, filters, sorter, extra),
        onRow: (record) => ({
          onClick: (e) => handleOnRowClick(e, record)
        }),
        pagination: tabHelpers.getTablePagination({handleOnPaginate, tableData, total, totalKey, view: key, perPagePagination}),
      }
    }
  }

  const getTabs = () => {
    return [
      {
        key: 'all',
        label: getLabel('all'),
        children: (
          <AllTab {...getTabProps(ALL, 'all')} />
        )
      },
      {
        key: 'active',
        label: getLabel('active'),
        children: (
          <AllTab {...getTabProps(ACTIVE, 'active')} />
        )
      },
      {
        key: 'external',
        label: getLabel('external'),
        children: (
          <ExternalTab {...getTabProps(EXTERNAL, 'external')} />
        )
      },
      {
        key: 'disabled',
        label: getLabel('disabled'),
        children: (
          <DisabledTab {...getTabProps(DISABLED, 'disabled')} />
        )
      }
    ]
  }

  const onChangeTab = (key) => {
    const view = userViewTypesConstants[key.toUpperCase()];
    updateTab(key);

    if (!getTableDataValue(view, 'loaded', false)) {
      loadData({view, initial: true});
    }
  }

  const updateTab = (key) => {
    navigate(`${location.pathname}#${key}`);
    setActiveTabKey(key);
  }

  const handleCancelInviteModal = () => setInviteModalProps({...inviteModalProps, open: false});

  const handleOkInviteModal = (data, successCallback, errorCallback) => {
    setInviteModalProps({...inviteModalProps, loading: true});
    createEmployee(
      data,
      (resp) => {
        setInviteModalProps({...inviteModalProps, loading: false, open: false});
        logEvent(firebaseEvents.TEAM_USER_INVITED);
        updateTab(defaultTabKey);
        loadData({view: ALL, initial: true});
        updateTotal();
        successCallback && successCallback(resp);
      },
      (resp) => {
        setInviteModalProps({...inviteModalProps, loading: false});
        errorCallback && errorCallback(resp.response.data);
      }
    )
  }

  const scaResponseCallback = ({response, view}) => {
    scaHelpers.SCAResponseCallback({
      response,
      scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps}),
      errorCallback: () => finishTableLoading(view)
    });
  }

  const onDisableUser = ({
    action,
    email,
    terminate = undefined
  } = {}) => {
    const view = userViewTypesConstants[activeTabKey.toUpperCase()];
    const isTerminateUserSubscriptions = terminate !== undefined ? terminate : (activeAction.isTerminateSubscriptions || false);
    if (email === undefined) email = activeAction.email;
    startTableLoading(view);
    updateEmployeeStatus({
      headers: scaHelpers.getAuthHeaders(action || activeAction.action),
      email,
      data: {
        is_terminate_user_subscriptions: isTerminateUserSubscriptions,
        status: DISABLED_STATUS
      },
      successCallback: () => {
        // update manually users
        setTableData({
          ...tableData,
          [view]: {
            ...tableData[view],
            rows: [...tableData[view].rows.map(row => ({
              ...row,
              account_status: row.email !== email ? row.account_status : DISABLED_STATUS
            }))]
          }
        });
        updateTotal();
        finishTableLoading(view);
        logEvent(firebaseEvents.TEAM_USER_DISABLED);
      },
      errorCallback: (response) => scaResponseCallback({response, view})
    });
  }

  const onEnableUser = ({
    action,
    email
  } = {}) => {
    const view = userViewTypesConstants[activeTabKey.toUpperCase()];
    if (email === undefined) email = activeAction.email;
    startTableLoading(view);
    updateEmployeeStatus({
      headers: scaHelpers.getAuthHeaders(action || activeAction.action),
      email,
      data: {status: ENABLED_STATUS},
      successCallback: () => {
        // update manually users
        setTableData({
          ...tableData,
          [view]: {
            ...tableData[view],
            rows: view === DISABLED
              ? [...tableData[view].rows.filter(row => row.email !== email)]
              : [...tableData[view].rows.map(row => ({...row, account_status: row.email !== email ? row.account_status : ENABLED_STATUS}))]
          }
        });
        updateTotal();
        finishTableLoading(view);
        logEvent(firebaseEvents.TEAM_USER_ENABLED);
      },
      errorCallback: (response) => scaResponseCallback({response, view})
    });
  }

  const handleCancelAuthModal = () => setAuthWindowProps({...authWindowProps, open: false});

  const onSuccessAuth = () => {
    const {action} = activeAction;
    const actions = {
      [DISABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION]: onDisableUser,
      [ENABLE_USER_WITH_TREEZOR_ACCOUNT_ACTION]: onEnableUser,
    }
    handleCancelAuthModal();
    if (actions.hasOwnProperty(action)) actions[action]();
  }

  const tabs = getTabs();

  return (
    <>
      <Tabs
        {...rest}
        activeKey={activeTabKey}
        items={tabs}
        onChange={onChangeTab}
        type='card'
      />
      <InviteUserModal
        {...inviteModalProps}
        handleCancel={handleCancelInviteModal}
        handleOk={handleOkInviteModal}
      />

      <AuthenticationWindow
        {...authWindowProps}
        handleCancel={handleCancelAuthModal}
        onSuccess={onSuccessAuth}
        operationName={activeAction.action}
      />

      <DisableUserModal
        handleCancel={handleCancelDisableModal}
        handleOk={handleOkDisableModal}
        open={isOpenDisableModal}
      />
    </>
  );
}

TeamTabs.propTypes = {
  handleUpdateTotal: PropTypes.func,
  openInviteModal: PropTypes.bool,
  total: PropTypes.shape({
    active: PropTypes.number,
    all: PropTypes.number,
    disabled: PropTypes.number,
    external: PropTypes.number,
  }),
  totalLoading: PropTypes.bool
}

TeamTabs.defaultProps = {
  openInviteModal: false,
  totalLoading: false
}

const mapStateToProps = state => {
  const {employee, isSupport} = state.user;

  return {
    isSupport,
    employeeEmail: employee.email,
  }
}

const mapDispatchToProps = {
  createEmployee: teamActions.createEmployee,
  deleteEmployee: teamActions.deleteEmployee,
  getEmployees: teamActions.getEmployees,
  updateEmployee: teamActions.updateEmployee,
  resendInvite: teamActions.resendInvite,
  updateEmployeeStatus: teamActions.updateEmployeeStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamTabs);
