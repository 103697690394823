const ConsultingManagementAndPublicRelationsServicesIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9999 10.805C16.9999 10.459 16.9999 10.286 17.0519 10.132C17.2029 9.684 17.6019 9.511 18.0019 9.329C18.4499 9.124 18.6739 9.022 18.8969 9.004C19.1489 8.984 19.4019 9.038 19.6179 9.159C19.9039 9.319 20.1039 9.625 20.3079 9.873C21.2509 11.019 21.7229 11.592 21.8949 12.223C22.0349 12.733 22.0349 13.267 21.8949 13.776C21.6439 14.698 20.8489 15.47 20.2599 16.186C19.9589 16.551 19.8079 16.734 19.6179 16.841C19.3982 16.9628 19.1473 17.0168 18.8969 16.996C18.6739 16.978 18.4499 16.876 18.0009 16.671C17.6009 16.489 17.2029 16.316 17.0519 15.868C16.9999 15.714 16.9999 15.541 16.9999 15.195V10.805ZM6.99991 10.805C6.99991 10.369 6.98791 9.978 6.63591 9.672C6.50791 9.561 6.33791 9.484 5.99891 9.329C5.54991 9.125 5.32591 9.022 5.10291 9.004C4.43591 8.95 4.07691 9.406 3.69291 9.874C2.74891 11.019 2.27691 11.592 2.10391 12.224C1.96471 12.7323 1.96471 13.2687 2.10391 13.777C2.35591 14.698 3.15191 15.471 3.73991 16.186C4.11091 16.636 4.46591 17.047 5.10291 16.996C5.32591 16.978 5.54991 16.876 5.99891 16.671C6.33891 16.517 6.50791 16.439 6.63591 16.328C6.98791 16.022 6.99991 15.631 6.99991 15.196V10.805Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 9C5 5.686 8.134 3 12 3C15.866 3 19 5.686 19 9M19 17V17.8C19 19.567 17.21 21 15 21H13" stroke="black"
          strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default ConsultingManagementAndPublicRelationsServicesIcon;
