import React, {useMemo} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import AddInvoiceModal from '../../../../components/pages/TransactionsPage/AddInvoiceModal/AddInvoiceModal';
import {expenseActions} from '../../../../state/actions/expense.actions';
import {invoicesActions} from '../../../../state/actions/invoice.actions';
import {transactionActions} from '../../../../state/actions/transaction.actions';
import {
  objectHelpers,
  transactionsHelpers
} from '../../../../utils/helpers';
import {expenseInvoicesStatusConstants, expenseTypesConstants, invoiceTypeConstants} from '../../../../constants';


const InvoiceManager = ({
  addInvoiceModalProps,
  batchCreateInvoices,
  closeAddInvoiceModal,
  linkExpense,
  updateExpense,

  isOpenDetails,
  modalProps,
  onExpenseUpdate,
  selectedTransaction,
  successUpdateHandler,
}) => {

  const addInvoiceModalMode = useMemo(() => {
    return transactionsHelpers.getInvoiceModalMode({
      addInvoiceModalProps,
      isOpenDetails,
      selectedTransaction
    });
  }, [addInvoiceModalProps, isOpenDetails, selectedTransaction]);

  const handleOkAttachmentModal = (formData, successCallback, errorCallback) => {
    const transaction = objectHelpers.getObjProp(addInvoiceModalProps, 'transaction');

    if (transaction.expense) {
      const {invoice, file} = formData;
      let expense = {
        ...transaction.expense,
        ...formData
      };
      const expenseId = expense.id;
      if (formData.hasOwnProperty('expense_type')) {
        expense = {
          ...expense,
          expense_type: expenseTypesConstants[formData.expense_type]
        };
      }

      const handleLinkExpense = (invoiceId) => {
        linkExpense(
          invoiceId,
          {expense_id: expenseId},
          () => {
            updateExpense({
              id: expenseId,
              data: {expense_type: expenseTypesConstants[formData.expense_type]},
              propertyName: 'expense_type',
              successCallback: () => {
                onExpenseUpdate({
                  ...expense,
                  is_attachment_uploaded: true,
                  expense_invoice_status: expenseInvoicesStatusConstants.MATCHED
                }, 'attachment');
                closeAddInvoiceModal();
                successCallback && successCallback(file);
                successUpdateHandler && successUpdateHandler();
              },
              errorCallback: (resp) => errorCallback && errorCallback(resp),
            });
          },
          (resp) => errorCallback && errorCallback(resp)
        )
      }

      if (formData.expense_type === invoiceTypeConstants.NO_RECEIPT) {
        updateExpense({
          id: expenseId,
          data: {expense_type: expenseTypesConstants.NO_RECEIPT},
          propertyName: 'expense_type',
          successCallback: () => {
            onExpenseUpdate({
              ...expense,
              expense_invoice_status: undefined,
              is_attachment_uploaded: false
            }, 'attachment');
            successCallback && successCallback();
            successUpdateHandler && successUpdateHandler();
            closeAddInvoiceModal();
          },
          errorCallback: (resp) => errorCallback && errorCallback(resp)
        });
      } else if (file) {
        batchCreateInvoices(
          {invoices: [{base64_file: file.file, file_name: file.name}]},
          (invoices) => {
            if (invoices.length > 0) {
              handleLinkExpense(invoices[0].id);
            } else {
              successCallback && successCallback();
            }
          },
          (resp) => errorCallback && errorCallback(resp)
        )
      } else {
        handleLinkExpense(invoice);
      }
    }
  }

  return (
    <>
      <AddInvoiceModal
        mode={addInvoiceModalMode}
        onCancel={closeAddInvoiceModal}
        open={addInvoiceModalProps.open}
        transaction={addInvoiceModalProps.transaction}
        onOk={handleOkAttachmentModal}
        {...modalProps}
      />
    </>
  );
}

InvoiceManager.propTypes = {
  isOpenDetails: PropTypes.bool,
  modalProps: PropTypes.object,
  onExpenseUpdate: PropTypes.func,
  selectedTransaction: PropTypes.object,
  successUpdateHandler: PropTypes.func,
}

InvoiceManager.defaultProps = {
  isOpenDetails: false,
}

const mapStateToProps = state => {
  const {addInvoiceModalProps} = state.transaction;

  return {
    addInvoiceModalProps,
  }
}

const mapDispatchToProps = {
  batchCreateInvoices: invoicesActions.batchCreateInvoices,
  closeAddInvoiceModal: transactionActions.closeAddInvoiceModal,
  linkExpense: invoicesActions.linkExpense,
  updateExpense: expenseActions.updateExpense,
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceManager);
