const MiscellaneousPublishingAndPrintingIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22 12C22 14.828 22 16.243 21.121 17.121C20.48 17.763 19.554 17.936 18 17.983M6 17.983C4.447 17.936 3.52 17.763 2.879 17.121C2 16.243 2 14.828 2 12C2 9.172 2 7.757 2.879 6.879C3.757 6 5.172 6 8 6H16C18.828 6 20.243 6 21.121 6.879C21.421 7.179 21.619 7.541 21.749 8M9 10H6M19 15H5"
      stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
    <path
      d="M17.983 6C17.936 4.447 17.763 3.52 17.121 2.879C16.243 2 14.828 2 12 2C9.172 2 7.757 2 6.879 2.879C6.237 3.52 6.064 4.447 6.017 6M18 15V16C18 18.828 18 20.243 17.121 21.121C16.48 21.763 15.554 21.936 14 21.983M6 15V16C6 18.828 6 20.243 6.879 21.121C7.52 21.763 8.447 21.936 10 21.983"
      stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
  </svg>
);

export default MiscellaneousPublishingAndPrintingIcon;
