import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import {StyledOAuthCallbackPageLoading} from './StyledOAuthCallbackPage';
import VerifyPageContainer from '../../components/VerifyPageContainer';
import {serviceStatsActions} from '../../state/actions/serviceStats.actions';
import {systemHelpers} from '../../utils/helpers';

const OAuthCallbackPage = ({
  verifyOauth
}) => {
  const [t] = useTranslation(['main', 'quickStart']);
  const trans = (key) => t(`quickStart:oauthCallback.${key}`);
  const location = useLocation();
  const [verification, setVerification] = useState({
    loading: true,
    message: undefined,
    success: false
  });
  const verificationErrorMessage = useMemo(() => verification.message, [verification]);

  const isActiveSession = useMemo(() => systemHelpers.hasActiveSession(), []);

  useEffect(() => {
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));
    if (searchParams.state) {
      verifyOauth(
        'google',
        searchParams,
        () => setVerification({loading: false, success: true}),
        (err) => {
          const data = err?.response?.data;
          const message = data?.message || data?.detail;
          setVerification({loading: false, message, success: false});
        }
      );
    }
  }, [location, verifyOauth]);

  const errorMessage = (
    <div className='redirect-description'>
      {trans('errorDescription')}.
      {verificationErrorMessage && <><br/>{verificationErrorMessage}</>}
    </div>
  );

  const successMessage = isActiveSession ? (
    <>
      <p>{trans('description1')}</p>
      <div className='redirect-description'>
        {trans('description2')}
        <br/>
        <a href='/'>{trans('linkText')}</a>.
      </div>
    </>
  ) : (
    <div className='redirect-description'>
      {trans('publicDescription1')}<br/>
      {trans('publicDescription2')}<br/>
      {trans('publicDescription3')}
    </div>
  );

  return (
    <VerifyPageContainer>
      <h1>{trans('title1')}<br />{trans('title2')}</h1>
      {
        verification.loading
          ? <StyledOAuthCallbackPageLoading />
          : verification.success ? successMessage : errorMessage
      }
    </VerifyPageContainer>
  );
}

const mapDispatchToProps = {
  verifyOauth: serviceStatsActions.verifyOauth
}

export default connect(null, mapDispatchToProps)(OAuthCallbackPage);

