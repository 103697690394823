import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import dayjs from 'dayjs';
import {Button, DatePicker, Form, Input, Select, Upload} from 'antd';
import {FileOutlined} from '@ant-design/icons';
import {StyledContractDetails, StyledUploadSpace} from './StyledContractDetails';
import DetailsTable from '../../../../TransactionsPage/DetailsTable';
import {
  subscriptionStatusesConstants
} from '../../../../../../constants';
import {subscriptionActions} from '../../../../../../state/actions/subscription.actions';
import {
  ContractRenewalPeriodTypeOptions,
  dateHelpers,
  fileHelpers,
  formHelpers,
  locationHelpers,
  objectHelpers,
  subscriptionsHelpers
} from '../../../../../../utils/helpers';

const gObjProp = objectHelpers.getObjProp;
const {getUrlFilename} = locationHelpers;

const {Item} = Form;

const {INACTIVE} = subscriptionStatusesConstants;

const contractFileFieldName = 'contract_file';

const cardRelatedFields = [];
const dateFields = ['contract_renewal_date', 'contract_renewal_reminder'];
const datePickerProps = subscriptionsHelpers.contractDateFieldProps();

const ContractDetails = ({
  edit,
  subscription,
  dispatch,
  getSubscriptionAttachment,
  onSubmit,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [initialFormValues, setInitialFormValues] = useState({
    [contractFileFieldName]: '',
    contract_renewal_date: '',
    contract_renewal_period: undefined,
    contract_renewal_reminder: undefined,
    purchase_order: '',
  });
  const [isExportProgress, setIsExportProgress] = useState(false);
  const [allowFileClear, setAllowFileClear] = useState(false);
  const [file, setFile] = useState(null);
  const [contractDurationOptions, ] = useState(ContractRenewalPeriodTypeOptions());

  const fileType = Form.useWatch(contractFileFieldName, form);

  useEffect(() => {
    setAllowFileClear(fileType && fileType !== '');
  }, [fileType]);

  const gDataProp = (subscription, key, defaultValue) => gObjProp(subscription, key, defaultValue);

  const trans = (key) => t(`subscriptions:${key}`);
  const placeholder = (key) => trans(`placeholders.${key}`);

  const {purchase_order: purchaseOrder } = initialFormValues;

  const contractFile = useMemo(() => gDataProp(subscription, contractFileFieldName), [subscription]);

  const statusCode = useMemo(() => gDataProp(subscription, 'status'), [subscription]);

  const isPaused = useMemo(() => statusCode === INACTIVE, [statusCode]);

  const isEnableEdit = useMemo(() => subscriptionsHelpers.isEnablePanelEdit(statusCode, edit), [statusCode, edit]);

  useEffect(() => {
    const contractFile = gDataProp(subscription, contractFileFieldName);
    
    const fieldValues = {
      ...initialFormValues,
      contract_file: contractFile ? getUrlFilename(contractFile) : undefined,
      contract_renewal_date: getDate(subscription, 'contract_renewal_date'),
      contract_renewal_period: gDataProp(subscription, 'contract_renewal_period'),
      contract_renewal_reminder: getDate(subscription, 'contract_renewal_reminder'),
      purchase_order: gDataProp(subscription, 'purchase_order'),
      signing_date: getDate(subscription, 'signing_date'),
    };

    setInitialFormValues(fieldValues);
    form.setFieldsValue(fieldValues);
    isEditMode && setIsEditMode(false);
  }, [subscription]); // eslint-disable-line react-hooks/exhaustive-deps

  const getContractDate = (key) => {
    const value = gDataProp(initialFormValues, key);
    return value ? dayjs(value).format('DD/MM/YY') : '-'
  }

  const getDate = (data, key) => {
    const value = gDataProp(data, key);
    return value ? dayjs(dateHelpers.getTimestampDateObject(value)) : undefined
  }

  const handleDownloadFile = () => {
    const id = gDataProp(subscription, 'id');
    if (id) {
      setIsExportProgress(true);
      getSubscriptionAttachment(
        id,
        (data) => {
          fileHelpers.saveFile(data);
          setIsExportProgress(false)
        },
        () => setIsExportProgress(false)
      );
    }
  }

  const tooltip = (key) => subscriptionsHelpers.getLabelTooltip({key, translation: t});

  const details = [
    {
      key: 'renewalDate',
      label: trans('renewalDate'),
      value: getContractDate('contract_renewal_date')
    },
    {
      key: 'contractDuration',
      label: trans('contractDuration'),
      value: t(subscriptionsHelpers.getContractRenewalPeriodType(subscription?.contract_renewal_period))
    },
    {
      key: 'renewalAlert',
      label: trans('renewalAlert'),
      value: getContractDate('contract_renewal_reminder')
    },
    {
      key: 'purchase-order',
      label: trans('purchaseOrder'),
      value: purchaseOrder || '-'
    },
    {
      key: 'contract',
      label: t('contract'),
      value: contractFile ? (
        <Button
          className='contract-btn'
          icon={<FileOutlined />}
          loading={isExportProgress}
          onClick={handleDownloadFile}
          type='link'
        >
          {getUrlFilename(contractFile)}
        </Button>
      ) : '-'
    },
  ];

  const handleOnCancel = () => setIsEditMode(false);

  const handleOnSave = () => form.submit();

  const handleSubmit = async (data) => {
    if (onSubmit) {
      const updatedContractFile = data[contractFileFieldName];
      let submitData = formHelpers.getUpdatedFormValues({
        initialValues: initialFormValues,
        submittedValues: data,
        excludedFields: isPaused ? cardRelatedFields : []
      });

      dateFields.forEach(key => {
        if (submitData[key]) submitData = {...submitData, [key]: subscriptionsHelpers.getContractDate(submitData[key])}
      });

      // update contact file value if changed
      if (updatedContractFile !== contractFile) {
        if (updatedContractFile === '') {
          submitData = {...submitData, [contractFileFieldName]: null};
        } else if (file) {
          submitData = {...submitData, [contractFileFieldName]: fileHelpers.cutFileData(file.file)};
        }
      }

      if (Object.keys(submitData).length > 0) {
        onSubmit(
          submitData,
          handleOnCancel,
          handleOnCancel
        );
      } else {
        handleOnCancel();
      }
    } else {
      handleOnCancel();
    }
  }

  const extra = subscriptionsHelpers.getDetailsFormExtra(({t, isEnableEdit, isEditMode, setIsEditMode, handleOnSave}));

  const handleOnFileChange = async ({file}) => {
    const fileBase64 = await fileHelpers.getBase64(file.originFileObj);
    setFile({
      file: fileBase64,
      name: file.name
    });
    form.setFieldValue(contractFileFieldName, file.name);
  }

  return (
    <StyledContractDetails
      title={trans('contractTerms')}
      {...rest}
      extra={extra}
    >
      <Form
        className={!isEditMode && 'd-none'}
        initialValues={initialFormValues}
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <Item
          label={trans('renewalDate')}
          name='contract_renewal_date'
          tooltip={tooltip('renewalDate')}
        >
          <DatePicker
            {...datePickerProps}
            placeholder={placeholder('renewalDate')}
          />
        </Item>
        <Item
          label={trans('contractDuration')}
          name='contract_renewal_period'
          tooltip={tooltip('contractDuration')}
        >
          <Select
            options={contractDurationOptions}
            placeholder={placeholder('contractDuration')}
            size='large'
          />
        </Item>
        <Item
          label={trans('renewalAlert')}
          name='contract_renewal_reminder'
          tooltip={tooltip('renewalAlert')}
        >
          <DatePicker
            {...datePickerProps}
            placeholder={placeholder('renewalAlert')}
          />
        </Item>
        <Item
          label={trans('purchaseOrder')}
          name='purchase_order'
        >
          <Input
            placeholder={placeholder('purchaseOrder')}
            size='large'
          />
        </Item>
        <Item label={t('contract')}>
          <StyledUploadSpace>
            <Item
              name={contractFileFieldName}
              noStyle
            >
              <Input
                allowClear={allowFileClear}
                size='large'
                disabled={true}
              />
            </Item>
            <Upload
              accept='.jpeg,.png,.pdf'
              customRequest={() => null}
              onChange={handleOnFileChange}
              showUploadList={false}
            >
              <Button>
                {t('select')} {t('file')}
              </Button>
            </Upload>
          </StyledUploadSpace>
        </Item>
      </Form>
      <DetailsTable
        className={isEditMode && 'd-none'}
        data={details}
      />
    </StyledContractDetails>
  );
}

ContractDetails.propTypes = {
  edit: PropTypes.bool,
  subscription: PropTypes.shape({
    contract_file: PropTypes.string,
    contract_renewal_date: PropTypes.number,
    contract_renewal_period: PropTypes.number,
    contract_renewal_reminder: PropTypes.number,
    purchase_order: PropTypes.string,
    signing_date: PropTypes.number
  }),
  onSubmit: PropTypes.func
}

ContractDetails.defaultProps = {
  edit: true
}

const mapDispatchToProps = {
  getSubscriptionAttachment: subscriptionActions.getSubscriptionAttachment,
}

export default connect(null, mapDispatchToProps)(ContractDetails);

