import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import {MenuCollapseIcon, MenuExpandIcon} from '../../icons';
import {StyledMenuCollapseButton} from './StyledMenuCollapseButton';
import {SYSTEM_ANIMATION_DURATION} from '../../constants';

const transitionStyles = {
  entering: {opacity: 1},
  entered: {opacity: 1},
  exiting: {opacity: 0},
  exited: {opacity: 0}
}

const defaultStyle = {
  transition: `opacity ${SYSTEM_ANIMATION_DURATION}ms ease-in-out`,
  opacity: 0,
}

const MenuCollapseButton = ({
  variant,
  ...rest
}) => {
  const nodeRef = useRef(null);

  return (
    <StyledMenuCollapseButton
      type='text'
      {...rest}
    >
      <Transition nodeRef={nodeRef} in={variant === 'expand'} timeout={SYSTEM_ANIMATION_DURATION}>
        {state => (
          <div ref={nodeRef} style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}>
            <MenuCollapseIcon />
          </div>
        )}
      </Transition>
      <Transition nodeRef={nodeRef} in={variant === 'collapse'} timeout={SYSTEM_ANIMATION_DURATION}>
        {state => (
          <div ref={nodeRef} style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}>
            <MenuExpandIcon />
          </div>
        )}
      </Transition>
    </StyledMenuCollapseButton>
  );
}

MenuCollapseButton.propTypes = {
  variant: PropTypes.oneOf(['collapse', 'expand'])
}

MenuCollapseButton.defaultProps = {
  variant: 'collapse'
}


export default MenuCollapseButton;
