import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import SubscriptionTab from '../SubscriptionTab';
import VendorDetails from '../../VendorDetails';
import SubscriptionUsers from '../../tabComponents/SubscriptionUsers';
import {subscriptionsHelpers} from '../../../../../utils/helpers';
import SubscriptionRowActions from '../../tabComponents/SubscriptionRowActions';
import {subscriptionStatusesConstants} from '../../../../../constants';

const NeedReviewTab = ({
  handleAction,
  rowActions,
  tableProps,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const navigate = useNavigate();

  const trans = (key, options) => t(`subscriptions:${key}`, options);

  const actionsWidth = useMemo(() => {
    const actionsCount = rowActions && rowActions.length ? rowActions.length : 4;
    let width = 16 * 2;
    if (actionsCount > 0) {
      width += actionsCount * 24 + (actionsCount - 1) * 16;
    }
    return width;
  }, [rowActions]);

  const columns = [
    {
      dataIndex: 'vendor',
      title: t('product'),
      render: (_, record) => <VendorDetails data={record} />,
      sorter: true,
      width: '45%',
      ellipsis: true
    },
    {
      dataIndex: 'status',
      title: t('status'),
      render: (_, record) => subscriptionsHelpers.getSubscriptionStatusMark(record),
      sorter: true,
      width: 150
    },
    {
      align: 'right',
      dataIndex: 'users_count',
      title: t('users'),
      render: (_, record) => <SubscriptionUsers subscription={record} />,
      sorter: true
    },
    {
      align: 'center',
      dataIndex: 'logins_count',
      title: trans('usage'),
      render: (value) => subscriptionsHelpers.getLoginsCount(value),
      sorter: true
    },
    {
      dataIndex: 'actions',
      title: '',
      render: (_, record) => (
        <SubscriptionRowActions
          actions={rowActions}
          handleAction={handleAction}
          subscription={record}
        />
      ),
      width: actionsWidth
    }
  ];

  const handleOnRowClick = (e, record) => {
    if (record.status !== subscriptionStatusesConstants.AUDITED) {
      navigate(subscriptionsHelpers.getSubscriptionDetailsPageUrl(record));
    }
  }

  return (
    <SubscriptionTab
      {...rest}
      columns={columns}
      tableProps={{
        ...tableProps,
        onRow: (record) => ({
          onClick: (e) => handleOnRowClick(e, record)
        })
      }}
    />
  );
}

NeedReviewTab.propTypes = {
  handleAction: PropTypes.func,
  rowActions: PropTypes.arrayOf(PropTypes.string),
  tableProps: PropTypes.object,
}

export default NeedReviewTab;
