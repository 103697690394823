import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Button, DatePicker, Divider, Form, Input, Modal, Select} from 'antd';
import dayjs from 'dayjs';
import { CSSTransition } from 'react-transition-group';
import {Collapse, UnmountClosed} from 'react-collapse';
import {MinusOutlined, PlusOutlined} from '@ant-design/icons';
import {EuroIcon} from '../../../../icons';
import {
  subscriptionFormFields,
  subscriptionFormValues,
  subscriptionPaymentTypesConstants,
  subscriptionStatusesConstants,
} from '../../../../constants';
import {
  StyledAddSingleSubscriptionModal,
  StyledAddSingleSubscriptionModalSegmented,
  StyledAddSingleSubscriptionModalCardInfo,
  StyledAddSingleSubscriptionModalTransitionContainer,
  StyledAddSingleSubscriptionModalStep,
  StyledAddSingleSubscriptionModalAccordion,
} from './StyledAddSingleSubscriptionModal';
import CompanyTagSelect from '../../TransactionsPage/CompanyTagSelect/CompanyTagSelect';
import {StyledPaymentDetailsInput} from '../../SubscriptionPage/tabComponents/Overview/PaymentDetails/StyledPaymentDetails';
import {
  StyledBudgetDetailsFormSpace
} from '../../SubscriptionPage/tabComponents/Overview/BudgetDetails/StyledBudgetDetails';
import SubscriptionSelect from '../SubscriptionSelect';
import IncreaseLimitAlert from '../IncreaseLimitAlert';
import HardCardLimitSwitch from '../HardCardLimitSwitch';
import SpinSmall from '../../../SpinSmall';
import {
  cardsHelpers,
  dateHelpers,
  BillOptions,
  CardLimitPeriodTypeOptions,
  ContractRenewalPeriodTypeOptions,
  PaymentOptions,
  SubscriptionsCategoryOptions,
  subscriptionsHelpers,
  formHelpers,
  objectHelpers,
  teamsHelpers,
} from '../../../../utils/helpers';

const {Item} = Form;

const {CARD, FREE} = subscriptionPaymentTypesConstants;
const {
  billedFieldName,
  budgetLimitFieldName,
  cardLimitFieldName,
  cardLimitPeriodFieldName,
} = subscriptionFormFields;
const {
  defaultBilled,
  defaultBudgetLimitSubscription,
  defaultCardLimitPeriod
} = subscriptionFormValues;

const defaultFormValues = {
  [billedFieldName]: defaultBilled,
  [budgetLimitFieldName]: undefined,
  [cardLimitFieldName]: undefined,
  category: undefined,
  [cardLimitPeriodFieldName]: defaultCardLimitPeriod,
  owner: null,
  reason: '',
  service: '',
  tags: [],

  // contract renewal fields
  contract_renewal_date: undefined,
  contract_renewal_period: 0,
  contract_renewal_reminder: 0,
};

const minSearchSymbolsLength = 3;
const datePickerProps = subscriptionsHelpers.contractDateFieldProps();

const {getCardLimitTypeFromBilled} = cardsHelpers;
const {getContractDate} = subscriptionsHelpers;
const {isValidValue} = objectHelpers;

const AddSingleSubscriptionModal = ({
  dispatch,
  loading,
  employees,
  employee,
  isEnabledBanking,
  subscriptionCardLimit,
  handleClose,
  handleOk,
  handleSelectService,
  onSearch,
  open,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [form] = Form.useForm();
  const [paymentType, setPaymentType] = useState(isEnabledBanking ? CARD : FREE);
  const [initialFormValues, setInitialFormValues] = useState(defaultFormValues);
  const [billOptions, ] = useState(BillOptions());
  const [cardLimitPeriodTypeOptions] = useState(CardLimitPeriodTypeOptions());
  const [defaultPaymentOptions,] = useState(PaymentOptions());
  const [categoryOptions, ] = useState(SubscriptionsCategoryOptions());
  const [contractDurationOptions, ] = useState(ContractRenewalPeriodTypeOptions());
  const [isStartFormEdit, setIsStartFormEdit] = useState(false);
  const [services, setServices] = useState([]);
  const [isLoadingServices, setIsLoadingServices] = useState(false);
  const [step, setStep] = useState(1);
  const [isSelectedHardLimit, setIsSelectedHardLimit] = useState(false);
  const [collapsed, setCollapsed] = useState([]);

  const requiredRules = [{required: true, message: t('validation.fieldIsRequired')}]

  const paymentOptions = useMemo(() => {
    let options = subscriptionsHelpers.getPaymentOptionsWithTooltip({options: defaultPaymentOptions, translation: t});
    if (!isEnabledBanking) options = options.filter(o => o.value !== CARD);
    return options;
  }, [defaultPaymentOptions, isEnabledBanking, t]);

  const isCardPaymentType = useMemo(() => paymentType === CARD, [paymentType]);

  const isEnableBudget = useMemo(() => paymentType !== FREE, [paymentType]);

  const service = Form.useWatch('service', {form});
  const owner = Form.useWatch('owner', {form});

  const budgetLimit = Form.useWatch(budgetLimitFieldName, {form});
  const billedLimitPeriod = Form.useWatch(billedFieldName, {form});
  const cardLimit = Form.useWatch(cardLimitFieldName, {form});

  const isTooHighLimit = useMemo(() => {
    return cardsHelpers.isTooHighLimit({
      limit: cardLimit,
      maxLimit: subscriptionCardLimit
    });
  }, [cardLimit, subscriptionCardLimit]);

  useEffect(() => {
    const isSupportUser = teamsHelpers.isSupport(employee?.role);
    if (!isSupportUser) form.setFieldValue('owner', employee?.email);
  }, [employee]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    subscriptionsHelpers.updateCardLimitOnBudgetChange({
      budgetLimit,
      cardLimit,
      isCardPaymentType,
      isSelectedHardLimit,
      form,
    });
  }, [budgetLimit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    subscriptionsHelpers.updateCardLimitPeriodOnBillingChange({
      billedLimitPeriod,
      isCardPaymentType,
      isSelectedHardLimit,
      form
    });
  }, [billedLimitPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  const disabledCreateButton = useMemo(() => !isValidValue(owner) || !isValidValue(service) || loading || isTooHighLimit, [isTooHighLimit, loading, owner, service]);

  const vendorProps = useMemo(() => subscriptionsHelpers.getSubscriptionProductProps({isLoadingServices, t}), [isLoadingServices, t]);

  useEffect(() => {
    if (open) {
      const isSupportUser = teamsHelpers.isSupport(employee?.role);
      let formFieldValues = { ...defaultFormValues };
      if (!isSupportUser) {
        formFieldValues = {
          ...formFieldValues,
          owner: employee?.email
        };
      }
      setInitialFormValues(formFieldValues);
      form.setFieldsValue(formFieldValues);
      setPaymentType(isEnabledBanking ? CARD : FREE);
      setIsStartFormEdit(false);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const ownerOptions = useMemo(() =>
    formHelpers.getEmployeeOptions({employees, employeeEmail: employee.email, t}), [employees, employee, t]);

  const trans = (key) => t(`subscriptions:${key}`);

  const formT = (key) => trans(`modal.addSingleSubscription.${key}`);

  const placeholderT = (key) => formT(`placeholder.${key}`);

  const handleBack = () => setStep(1);

  const tooltip = (key) => subscriptionsHelpers.getLabelTooltip({key, translation: t})

  const tooltipT = (key) => trans(`tooltips.${key}`);

  const handleNext = () => {
    const validate = subscriptionsHelpers.validateFormLimitValues({
      budgetLimit,
      cardLimit,
      form,
      paymentType,
      translate: t
    });

    if (!validate) return;

    if (isCardPaymentType) {
      let contractRenewalFieldsValue = subscriptionsHelpers.getDefaultContractRenewalFieldsValue(billedLimitPeriod);
      form.setFieldsValue(contractRenewalFieldsValue);
      setStep(2);
    } else {
      form.submit();
    }
  }

  const getFooter = () => ({
    1: [
      <Button
        key='next'
        disabled={disabledCreateButton}
        onClick={() => !disabledCreateButton && handleNext()}
        size='large'
        type='primary'
      >
        {t(isCardPaymentType ? 'next' : 'create')}
      </Button>
    ],
    2: [
      <Button
        key='create'
        disabled={disabledCreateButton}
        onClick={() => !disabledCreateButton && form.submit()}
        size='large'
        type='primary'
      >
        {t('create')}
      </Button>,
      <Button
        disabled={loading}
        key='back'
        onClick={handleBack}
        size='large'
      >
        {t('back')}
      </Button>
    ]
  })[step] ?? undefined;

  const handleAfterOpenChange = (open) => {
    if (!open) {
      setStep(1);
      if (services.length) setServices([]);
      if (isSelectedHardLimit) setIsSelectedHardLimit(false);

      form.resetFields();
    }
  }

  const onFieldsChange = (changedFields) => {
    const serviceField = changedFields.find(f => f.name.includes('service'));
    const limitField = changedFields.find(({ name, errors }) =>
      (name.includes(cardLimitFieldName) || name.includes(budgetLimitFieldName)) && errors?.length
    );

    if (serviceField && handleSelectService) {
      handleSelectService(serviceField.value);
    }

    if (!isStartFormEdit) {
      setIsStartFormEdit(true);
    }

    if (limitField) {
      form.setFields([{name: limitField?.name[0], errors: []}]);
    }
  }

  const onChangeTags = (tags) => {
    form.setFieldValue('tags', tags);
    setInitialFormValues({...initialFormValues, tags});
  }

  const handleSubmit = (data) => {
    const getFieldValue = (key, defaultValue) => objectHelpers.getObjProp(data, key, defaultValue);
    const category = getFieldValue('category');
    const cardLimit = data[cardLimitFieldName];
    const contractRenewalDate = data?.contract_renewal_date;
    const {owner} = data;
    let formData = {
      owner: owner,
      status: subscriptionStatusesConstants.ACTIVE,
      payment_type: paymentType,
      reason: data.reason,
      service: data.service,
      tags: data.tags
    }

    if (category) {
      formData = {
        ...formData,
        category: getFieldValue('category')
      }
    }

    if (isEnableBudget) {
      formData = {
        ...formData,
        [billedFieldName]: getFieldValue(billedFieldName),
        [budgetLimitFieldName]: getFieldValue(budgetLimitFieldName, defaultBudgetLimitSubscription)
      }
    }

    if (isCardPaymentType) {
      formData = {
        ...formData,
        card_owner: owner,
        [cardLimitFieldName]: cardLimit || defaultBudgetLimitSubscription,
        [cardLimitPeriodFieldName]: getFieldValue(cardLimitPeriodFieldName, defaultCardLimitPeriod),
        expected_first_payment: dateHelpers.getMomentUnixTimestamp(dayjs()),

        // step 2
        contract_renewal_date: getContractDate(contractRenewalDate),
        contract_renewal_period: data.contract_renewal_period,
        contract_renewal_reminder: getContractDate(data.contract_renewal_reminder),
        payment_frequency: subscriptionsHelpers.getPaymentFrequencyValue(billedLimitPeriod)
      }
    }
    handleOk && handleOk(formData);
  }

  const clearServices = () => {
    setServices([]);
    setIsLoadingServices(false);
  }

  const handleOnSearch = (value) => {
    value = subscriptionsHelpers.getSearchedDomainName(value);
    if (value.length < minSearchSymbolsLength && Boolean(services.length)) {
      clearServices();
    } else if (value !== '' && onSearch) {
      setIsLoadingServices(true)
      onSearch(
        value,
        (services) => {
          setServices(services);
          setIsLoadingServices(false);
        },
        clearServices
      );
    }
  }

  const handleChangeHardLimit = (value) => {
    setIsSelectedHardLimit(value);
    if (value) {
      form.setFieldsValue({
        [cardLimitFieldName]: budgetLimit,
        [cardLimitPeriodFieldName]: getCardLimitTypeFromBilled(billedLimitPeriod)
      })
    }
  }

  const onClose = (e, handleAction = handleClose) => {
    const handleOnClose = () => {
      handleAction && handleAction();
      clearServices();
    }
    if (isStartFormEdit) {
      Modal.confirm({
        cancelText: t('no'),
        cancelButtonProps: {className: 'green-btn-outlined', size: 'large'},
        okButtonProps: {size: 'large', type: 'primary'},
        okText: t('yes'),
        onOk: handleOnClose,
        title: formT('confirm.title')
      });
    } else {
      handleOnClose();
    }
  }

  return (
    <StyledAddSingleSubscriptionModal
      afterOpenChange={handleAfterOpenChange}
      forceRender
      footer={getFooter()}
      onClose={onClose}
      open={open}
      title={`${t('add')} ${t('subscription')}`}
      width={472}
      {...rest}
    >
      <SpinSmall spinning={loading}>
        <Form
          initialValues={initialFormValues}
          onFieldsChange={onFieldsChange}
          onFinish={handleSubmit}
          layout='vertical'
          form={form}
          requiredMark={false}
        >
          <StyledAddSingleSubscriptionModalTransitionContainer>
            <StyledAddSingleSubscriptionModalStep className={`step1 ${step === 1 ? '' : 'move-left'}`}>
              <Item
                className={service !== '' && 'd-none-label'}
                label={t('product')}
                name='service'
                tooltip={vendorProps.tooltip}
              >
                <SubscriptionSelect
                  autocompleteProps={vendorProps.autocomplete}
                  inputProps={vendorProps.input}
                  minSearchSymbolsLength={minSearchSymbolsLength}
                  onSearch={handleOnSearch}
                  services={services}
                />
              </Item>
              <Item
                label={t('whatIsItFor')}
                name='reason'
              >
                <Input
                  placeholder={placeholderT('reason')}
                  size='large'
                />
              </Item>
              <Item
                label={t('Owner')}
                name='owner'
              >
                <Select
                  placeholder={placeholderT('owner')}
                  optionFilterProp='optionLabel'
                  options={ownerOptions}
                  size='large'
                  showSearch
                />
              </Item>
              <Item
                label={t('costCenter')}
                name='tags'
              >
                <CompanyTagSelect
                  inputProps={{
                    placeholder: placeholderT('costCenter'),
                    size: 'large'
                  }}
                  onChange={onChangeTags}
                  selected={initialFormValues.tags}
                />
              </Item>
              <Item label={t('paymentMethod')}>
                <StyledAddSingleSubscriptionModalSegmented
                  block={true}
                  onChange={setPaymentType}
                  options={paymentOptions}
                  size='large'
                  value={paymentType}
                />
              </Item>

              <UnmountClosed isOpened={isEnableBudget}>
                <StyledBudgetDetailsFormSpace size='middle'>
                  <Item
                    label={t('Budget')}
                    name={budgetLimitFieldName}
                    required
                  >
                    <StyledPaymentDetailsInput
                      addonBefore={<EuroIcon />}
                      min={1}
                      size='large'
                      type='number'
                    />
                  </Item>
                  <Item
                    label=' '
                    name={billedFieldName}
                    rules={requiredRules}
                  >
                    <Select
                      options={billOptions}
                      size='large'
                    />
                  </Item>
                </StyledBudgetDetailsFormSpace>
              </UnmountClosed>
              <Collapse isOpened={isEnableBudget && isCardPaymentType}>
                <HardCardLimitSwitch
                  checked={isSelectedHardLimit}
                  onChange={handleChangeHardLimit}
                  tooltipProps={{
                    title: <>{tooltipT('hardCardLimit1')}<br/>{tooltipT('hardCardLimit2')}</>
                  }}
                />
              </Collapse>
              <Collapse isOpened={isEnableBudget && isTooHighLimit && !collapsed.includes('1')}>
                <IncreaseLimitAlert
                  className='mt-20'
                  limit={subscriptionCardLimit}
                  handleBeforeRedirect={onClose}
                />
              </Collapse>
              <Divider />
              <StyledAddSingleSubscriptionModalAccordion
                ghost
                expandIconPosition='end'
                expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined /> }
                items={[
                  {
                    key: '1',
                    label: `${t('advanced')} ${t('settings')}`,
                    forceRender: true,
                    children: (
                      <>
                        <Collapse isOpened={isCardPaymentType}>
                          <StyledBudgetDetailsFormSpace size='middle'>
                            <Item
                              label={`${t('card')} ${t('limit')}`}
                              name={cardLimitFieldName}
                            >
                              <StyledPaymentDetailsInput
                                addonBefore={<EuroIcon />}
                                disabled={isSelectedHardLimit}
                                min={1}
                                size='large'
                                type='number'
                              />
                            </Item>
                            <Item
                              label=' '
                              name={cardLimitPeriodFieldName}
                              rules={requiredRules}
                            >
                              <Select
                                disabled={isSelectedHardLimit}
                                options={cardLimitPeriodTypeOptions}
                                size='large'
                              />
                            </Item>
                          </StyledBudgetDetailsFormSpace>
                        </Collapse>
                        <Collapse isOpened={isTooHighLimit}>
                          <IncreaseLimitAlert
                            limit={subscriptionCardLimit}
                            handleBeforeRedirect={onClose}
                          />
                        </Collapse>
                        <Item
                          label={t('category')}
                          name='category'
                        >
                          <Select
                            allowClear={true}
                            options={categoryOptions}
                            placeholder={placeholderT('category')}
                            size='large'
                          />
                        </Item>
                      </>
                    )
                  }
                ]}
                onChange={setCollapsed}
              />
            </StyledAddSingleSubscriptionModalStep>

            <CSSTransition in={step === 2} timeout={500} classNames='slide' unmountOnExit={false}>
              <StyledAddSingleSubscriptionModalStep className={`step2 ${step === 2 ? 'move-in' : 'hidden'}`}>
                <StyledAddSingleSubscriptionModalCardInfo align='start'>
                  {placeholderT('renewalDescription')}
                </StyledAddSingleSubscriptionModalCardInfo>
                <Item
                  label={trans('renewalDate')}
                  name='contract_renewal_date'
                  tooltip={tooltip('renewalDate')}
                >
                  <DatePicker
                    {...datePickerProps}
                    placeholder={placeholderT('renewalDate')}
                  />
                </Item>
                <Item
                  label={trans('contractDuration')}
                  name='contract_renewal_period'
                  tooltip={tooltip('contractDuration')}
                >
                  <Select
                    options={contractDurationOptions}
                    placeholder={placeholderT('contractDuration')}
                    size='large'
                  />
                </Item>
                <Item
                  label={trans('renewalAlert')}
                  name='contract_renewal_reminder'
                  tooltip={tooltip('renewalAlert')}
                >
                  <DatePicker
                    {...datePickerProps}
                    placeholder={placeholderT('renewalAlert')}
                  />
                </Item>
              </StyledAddSingleSubscriptionModalStep>
            </CSSTransition>
          </StyledAddSingleSubscriptionModalTransitionContainer>
        </Form>
      </SpinSmall>
    </StyledAddSingleSubscriptionModal>
  );
}

AddSingleSubscriptionModal.propTypes = {
  loading: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func,
  handleSelectService: PropTypes.func,
  onSearch: PropTypes.func
}

AddSingleSubscriptionModal.defaultProps = {
  loading: false
}

const mapStateToProps = state => {
  const {isEnabledBanking} = state.banking;
  const {employees} = state.company;
  const {employee} = state.user;
  const {subscriptionCardLimit} = cardsHelpers.getPaymentCardLimit(state);
  return {
    employees,
    employee,
    isEnabledBanking,
    subscriptionCardLimit
  }
}

export default connect(mapStateToProps, null)(AddSingleSubscriptionModal);
