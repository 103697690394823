import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Space, Tooltip} from 'antd'
import {connect} from 'react-redux';
import StyledCardDetailsButton from './StyledCardDetailsButton';
import {SCAActionsConstants} from '../../../../constants';
import AuthenticationWindow from '../../CardsPage/AuthenticationWindow';
import CardDetailsModal from '../../SubscriptionPage/tabComponents/Overview/CardDetailsModal';
import {EyeIcon} from '../../../../icons';
import {bankingActions} from '../../../../state/actions/banking.actions';
import {scaHelpers, systemHelpers} from '../../../../utils/helpers';
import {firebaseEvents} from '../../../../snippets/firebase';

const defaultPaymentDetailsData = {
  image: null,
  open: false,
  startTimer: false,
};

const SCA_OPERATION_NAME = SCAActionsConstants.DISPLAY_CARD_DETAILS_ACTION;

const CardDetailsButton = ({
  cardId,
  cardOwnerId,
  dispatch,
  getUserCardImages,
  getCompanyInformation,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'cards']);
  const [cardDetailsModalProps, setCardDetailsModalProps] = useState(defaultPaymentDetailsData);
  const [authWindowProps, setAuthWindowProps] = useState({open: false});

  const onEyeClick = (e) => {
    e.stopPropagation();
    setCardDetailsModalProps({...cardDetailsModalProps, open: true});
    loadCardDetails();
    systemHelpers.logEvent(firebaseEvents.VIEW_CARD_DETAILS_FROM_TABLE_ROW)
  }

  const loadCardDetails = () => {
    getUserCardImages({
      headers: scaHelpers.getAuthHeaders(SCA_OPERATION_NAME),
      userId: cardOwnerId,
      cardId,
      successCallback: (images) => {
        let image = null;
        if (images.length > 0) image = `data:image/png;base64,${images[0].file}`;
        setCardDetailsModalProps({
          ...cardDetailsModalProps,
          image,
          loading: false,
          open: true,
          startTimer: true
        });
        handleCloseAuthModal();
      },
      errorCallback: (response) => {
        scaHelpers.SCAResponseCallback({
          response,
          scaCallback: (scaProps) => setAuthWindowProps({...authWindowProps, ...scaProps})
        });
      }
    });
  }

  const onSuccessAuth = () => {
    handleCloseAuthModal();
    setCardDetailsModalProps({...cardDetailsModalProps, loading: true, open: true, startTimer: false});
    loadCardDetails();
  }

  const handleCloseAuthModal = () => setAuthWindowProps({...authWindowProps, open: false});

  const handleCloseCardDetailsModal = () => setCardDetailsModalProps({...cardDetailsModalProps, image: null, open: false});

  const handleCancelAuth = () => {
    handleCloseCardDetailsModal();
    handleCloseAuthModal();
  }

  return (
    <>
      <StyledCardDetailsButton {...rest}>
        <Tooltip
          placement='bottom'
          title={t('clickForDetails')}
        >
          <Space onClick={onEyeClick}>
            <EyeIcon />
          </Space>
        </Tooltip>
      </StyledCardDetailsButton>

      <AuthenticationWindow
        {...authWindowProps}
        handleCancel={handleCancelAuth}
        onSuccess={onSuccessAuth}
        operationName={SCA_OPERATION_NAME}
      />

      <CardDetailsModal
        {...cardDetailsModalProps}
        handleCancel={handleCloseCardDetailsModal}
      />
    </>
  );
}

CardDetailsButton.propTypes = {
  cardId: PropTypes.string,
  cardOwnerId: PropTypes.string
}

const mapDispatchToProps = {
  getUserCardImages: bankingActions.getUserCardImages
}

export default connect(null, mapDispatchToProps)(CardDetailsButton);
