import styled from 'styled-components';

const StyledDatePickerPanel = styled.div`
  position: relative;
  
  .ant-picker-header-super-prev-btn,
  .ant-picker-header-super-next-btn {
    display: none !important;
  }
`;

export {
  StyledDatePickerPanel
};
