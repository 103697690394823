const MiscellaneousFoodStoresIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.99996 7H3.68896C3.47699 6.99993 3.26742 7.04479 3.07406 7.13161C2.88069 7.21843 2.70793 7.34525 2.56714 7.5037C2.42636 7.66216 2.32075 7.84865 2.25729 8.05089C2.19383 8.25313 2.17394 8.46652 2.19896 8.677L3.55996 20.118C3.58883 20.361 3.70579 20.5849 3.8887 20.7475C4.07161 20.91 4.30776 20.9999 4.55246 21H19.7365C19.985 21 20.2246 20.9075 20.4087 20.7405C20.5927 20.5735 20.708 20.3439 20.732 20.0965L21.841 8.6445C21.8611 8.43631 21.8375 8.2262 21.7716 8.02768C21.7058 7.82915 21.5991 7.64659 21.4585 7.49173C21.3179 7.33687 21.1465 7.21312 20.9552 7.12844C20.764 7.04375 20.5571 7 20.348 7H19M2.49996 11H21.5M7.49996 14.5H16.5M8.49996 18H15.5"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M12 3C9.791 3 8 6.4625 8 10.7335C8 10.8225 8.00083 10.9113 8.0025 11H15.9975C15.9992 10.9113 16 10.8225 16 10.7335C16 6.4625 14.209 3 12 3Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default MiscellaneousFoodStoresIcon;
