import React, {useEffect, useMemo, useState} from 'react';
import {DatePicker, Form, Input, Select} from 'antd';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {StyledLegalRepresentativeInformationForm, StyledLegalRepresentativeInformationFormCheckbox} from './StyledLegalRepresentativeInformationForm';
import {StyledKYCModalContentColumnFormSpace, StyledKYCModalTitleNameSpace} from '../../../../KYCModal/StyledKYCModal';
import SpinSmall from '../../../../../SpinSmall';
import PhoneNumberInput from '../../../../../PhoneNumberInput';
import AntdCountriesSelect from '../../../../../Select/AntdCountriesSelect';
import {useLocation, useTaxCodeLabel} from '../../../../../../hooks';
import {kycHelpers, objectHelpers, systemHelpers} from '../../../../../../utils/helpers';
import {
  monthlyIncomeRangeOptions,
  occupationCategoryOptions,
  personalAssetsRangeOptions,
  titleOptions
} from '../../../../../../utils/options';

const {Item} = Form;

const initialFormValues = {
  address1: '',
  birthday: undefined,
  birth_country: undefined,
  city: '',
  country: undefined,
  monthly_income_range: monthlyIncomeRangeOptions[0].value,
  personal_assets_range: personalAssetsRangeOptions[0].value,
  firstname: '',
  email: '',
  lastname: '',
  nationality: undefined,
  phone: '',
  place_of_birth: '',
  postcode: '',
  tax_residence: undefined,
  tax_number: '',
  title: titleOptions[0].value,

  confirm_accept: false,
  confirm_seen: false,
}

const formSpaceProps = {
  align: 'start',
  size: 32
}

const countrySelectProps = kycHelpers.getCountrySelectProps();
const datePickerProps = kycHelpers.getDatePickerProps('birthday');


const LegalRepresentativeInformationForm = React.forwardRef(({
  dispatch,
  companyCountry,
  defaultFormValues,
  handleEnableSubmit,
  onSubmit,
  ...rest
}, ref) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation(['main', 'quickStart']);
  const [form] = Form.useForm();

  const {phone_code: defaultPhoneCode, code: defaultCountryCode} = useLocation();

  const taxCodeLabel = useTaxCodeLabel(form);

  const trzTermsUrl = useMemo(() => systemHelpers.getTrzTermsUrl(companyCountry), [companyCountry]);

  const termsOfConditionsLink = (
    <a href={trzTermsUrl} target='_blank' rel='noreferrer'>
      {t('treezorsTermsAndConditions')}
    </a>
  );

  const {
    label,
    lengthFieldMessage,
    placeholder,
    validationPhoneRules,
    validationPostCodeRules,
    validationRequiredRules,
    validationTaxRules,
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, formKey: 'lrInformation.tabs.fillTheForm'}), [t]);

  useEffect(() => {
    if (defaultCountryCode && objectHelpers.isEmptyObject(defaultFormValues)) {
      form.setFieldsValue({
        birth_country: defaultCountryCode,
        country: defaultCountryCode,
        nationality: defaultCountryCode
      });
    }
  }, [defaultCountryCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    defaultFormValues && form.setFieldsValue(defaultFormValues);
  }, [defaultFormValues, form]);

  const onFinish = (fields) => {
    if (onSubmit) {
      const {confirm_accept, confirm_seen, ...data} = fields;
      setLoading(true);
      onSubmit(
        data,
        () => setLoading(false),
        (errors) => {
          setLoading(false);
          form.setFields(errors);
        }
      );
    }
  }

  const onValuesChange = (changedFields, allValues) => {
    kycHelpers.handleOnValuesChangeLRForm({allValues, changedFields, handleEnableSubmit});
  }

  return (
    <StyledLegalRepresentativeInformationForm {...rest}>
      <SpinSmall spinning={loading}>
        <Form
          initialValues={initialFormValues}
          form={form}
          layout='vertical'
          onValuesChange={onValuesChange}
          onFinish={onFinish}
          ref={ref}
          requiredMark={false}
        >
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <StyledKYCModalTitleNameSpace {...formSpaceProps}>
              <Item
                label={label('title')}
                name='title'
                required
              >
                <Select
                  options={titleOptions}
                  size='large'
                />
              </Item>
              <Item
                label={label('firstName')}
                name='firstname'
                rules={[
                  ...validationRequiredRules,
                  {min: 2, message: lengthFieldMessage(2, 'min')}
                ]}
              >
                <Input
                  minLength={2}
                  maxLength={50}
                  placeholder={placeholder('firstName')}
                  size='large'
                />
              </Item>
            </StyledKYCModalTitleNameSpace>
            <Item
              label={label('lastName')}
              name='lastname'
              rules={[
                ...validationRequiredRules,
                {min: 2, message: lengthFieldMessage(2, 'min')}
              ]}
            >
              <Input
                minLength={2}
                maxLength={50}
                placeholder={placeholder('lastName')}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>

          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('dateOfBirth')}
              name='birthday'
              rules={validationRequiredRules}
            >
              <DatePicker
                {...datePickerProps}
                placeholder={placeholder('dateOfBirth')}
              />
            </Item>
            <Item
              label={label('placeOfBirth')}
              name='place_of_birth'
              rules={validationRequiredRules}
            >
              <Input
                placeholder={placeholder('placeOfBirth')}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>

          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('countryOfBirth')}
              name='birth_country'
              rules={validationRequiredRules}
            >
              <AntdCountriesSelect
                placeholder={placeholder('countryOfBirth')}
                {...countrySelectProps}
              />
            </Item>
            <Item
              label={label('nationality')}
              name='nationality'
              rules={validationRequiredRules}
            >
              <AntdCountriesSelect
                placeholder={placeholder('nationality')}
                {...countrySelectProps}
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('email')}
              name='email'
              rules={validationRequiredRules}
            >
              <Input
                placeholder={placeholder('email')}
                size='large'
                type='email'
              />
            </Item>
            <Item
              label={label('phoneNumber')}
              name='phone'
              rules={validationPhoneRules}
            >
              <PhoneNumberInput
                placeholder={placeholder('phoneNumber')}
                phoneCode={defaultPhoneCode}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('address')}
              name='address1'
              rules={validationRequiredRules}
            >
              <Input
                placeholder={placeholder('address')}
                size='large'
              />
            </Item>
            <Item
              label={label('city')}
              name='city'
              rules={validationRequiredRules}
            >
              <Input
                placeholder={placeholder('city')}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('country')}
              name='country'
              rules={validationRequiredRules}
            >
              <AntdCountriesSelect
                placeholder={placeholder('country')}
                {...countrySelectProps}
              />
            </Item>
            <Item
              label={label('postCode')}
              name='postcode'
              rules={validationPostCodeRules}
            >
              <Input
                placeholder={placeholder('postCode')}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('occupationCategory')}
              name='occupation_category'
              rules={validationRequiredRules}
              required
            >
              <Select
                placeholder={placeholder('occupationCategory')}
                options={occupationCategoryOptions}
                size='large'
              />
            </Item>
            <Item
              label={label('personalAssetsRange')}
              name='personal_assets_range'
              rules={validationRequiredRules}
              required
            >
              <Select
                placeholder={placeholder('personalAssetsRange')}
                options={personalAssetsRangeOptions}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('monthlyIncomeRange')}
              name='monthly_income_range'
              rules={validationRequiredRules}
              required
            >
              <Select
                placeholder={placeholder('monthlyIncomeRange')}
                options={monthlyIncomeRangeOptions}
                size='large'
              />
            </Item>
            <Item
              label={label('taxResidence')}
              name='tax_residence'
              rules={validationRequiredRules}
            >
              <AntdCountriesSelect
                placeholder={placeholder('taxResidence')}
                {...countrySelectProps}
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={taxCodeLabel}
              name='tax_number'
              rules={validationTaxRules}
            >
              <Input
                placeholder={placeholder('taxCode')}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          <Item
            className='checkbox-item'
            name='confirm_seen'
            valuePropName='checked'
            required
          >
            <StyledLegalRepresentativeInformationFormCheckbox>
              {t('quickStart:kyc.confirmations.confirmIHaveSeen')} {termsOfConditionsLink}
            </StyledLegalRepresentativeInformationFormCheckbox>
          </Item>
          <Item
            className='checkbox-item'
            name='confirm_accept'
            valuePropName='checked'
            required
          >
            <StyledLegalRepresentativeInformationFormCheckbox>
              {t('quickStart:kyc.confirmations.confirmIAccept')} {termsOfConditionsLink}
            </StyledLegalRepresentativeInformationFormCheckbox>
          </Item>
        </Form>
      </SpinSmall>
    </StyledLegalRepresentativeInformationForm>
  );
});

LegalRepresentativeInformationForm.propTypes = {
  defaultFormValues: PropTypes.object,
  handleEnableSubmit: PropTypes.func,
  onSubmit: PropTypes.func
}

const mapStateToProps = state => {
  return {
    companyCountry: state.company.country
  }
}

export default connect(mapStateToProps, null, null,{forwardRef: true})(LegalRepresentativeInformationForm)

