import React, {useEffect, useMemo, useState} from 'react';
import {Button, Checkbox, Form, Input, Select} from 'antd';
import {Collapse} from 'react-collapse';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useLocation, useTaxCodeLabel} from '../../../../hooks';
import {
  StyledUserNationalityTaxDetailsForm,
  StyledUserNationalityTaxDetailsFormUpload,
  StyledUserNationalityTaxDetailsFormUploadSpace
} from './StyledUserNationalityTaxDetailsForm';
import SpinSmall from '../../../SpinSmall';
import {StyledKYCModalContentColumnFormSpace} from '../../../quickStart/KYCModal/StyledKYCModal';
import AntdCountriesSelect from '../../../Select/AntdCountriesSelect';
import {
  monthlyIncomeRangeOptions,
  occupationCategoryOptions,
  personalAssetsRangeOptions
} from '../../../../utils/options';
import {kycHelpers, objectHelpers} from '../../../../utils/helpers';
import {bankingUserTypesConstants} from '../../../../constants';

const {LEGAL_REPRESENTATIVE, ULTIMATE_BENEFICIARY_OWNER} = bankingUserTypesConstants;

const {Item} = Form;

const initialFormValues = {
  address1: '',
  city: '',
  country: '',
  monthly_income_range: monthlyIncomeRangeOptions[0].value,
  occupation_category: undefined,
  personal_assets_range: personalAssetsRangeOptions[0].value,
  us_person_accepted: false,
  us_person_declaration: undefined,
  postcode: '',
  tax_number: '',
  tax_residence: undefined
}

const formSpaceProps = {
  align: 'start',
  size: 32
}

const acceptFileTypes = kycHelpers.getAcceptFileTypes();

const countrySelectProps = kycHelpers.getCountrySelectProps();

const UserNationalityTaxDetailsForm = React.forwardRef(({
  defaultFormValues,
  onSubmit,
  userType,
  ...rest
}, ref) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation(['main', 'userInvite']);

  const [form] = Form.useForm();
  const usPersonAccepted = Form.useWatch('us_person_accepted', form);
  const usPersonDeclaration = Form.useWatch('us_person_declaration', form);
  const taxCodeLabel = useTaxCodeLabel(form);

  const usPersonDeclarationFileName = useMemo(() => {
    let fileName;
    if (usPersonDeclaration && usPersonDeclaration.file) {
      fileName = usPersonDeclaration.file.name;
    }
    return fileName;
  }, [usPersonDeclaration]);

  const {code: defaultCountryCode} = useLocation();

  useEffect(() => {
    if (defaultCountryCode && objectHelpers.isEmptyObject(defaultFormValues)) {
      form.setFieldsValue({
        country: defaultCountryCode
      });
    }
  }, [defaultCountryCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    defaultFormValues && form.setFieldsValue(defaultFormValues);
  }, [defaultFormValues, form]);

  const {
    label,
    placeholder,
    validationPostCodeRules,
    validationRequiredRules,
    validationTaxRules,
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, fullFormKey: 'userInvite:steps.userNationalityTaxDetails'}), [t]);

  const onFinish = (fields) => {
    if (onSubmit) {
      setLoading(true);
      onSubmit(
        fields,
        () => setLoading(false),
        (errors) => {
          setLoading(false);
          form.setFields(errors);
        }
      );
    }
  }

  return (
    <StyledUserNationalityTaxDetailsForm {...rest}>
      <SpinSmall spinning={loading}>
        <Form
          initialValues={initialFormValues}
          form={form}
          layout='vertical'
          onFinish={onFinish}
          ref={ref}
          requiredMark={false}
        >
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('address')}
              name='address1'
              rules={validationRequiredRules}
            >
              <Input
                placeholder={placeholder('address')}
                size='large'
              />
            </Item>
            <Item
              label={label('city')}
              name='city'
              rules={validationRequiredRules}
            >
              <Input
                placeholder={placeholder('city')}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('country')}
              name='country'
              rules={validationRequiredRules}
            >
              <AntdCountriesSelect
                placeholder={placeholder('country')}
                {...countrySelectProps}
              />
            </Item>
            <Item
              label={label('postCode')}
              name='postcode'
              rules={validationPostCodeRules}
            >
              <Input
                placeholder={placeholder('postCode')}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('occupationCategory')}
              name='occupation_category'
              rules={validationRequiredRules}
              required
            >
              <Select
                placeholder={placeholder('occupationCategory')}
                options={occupationCategoryOptions}
                size='large'
              />
            </Item>
            <Item
              label={label('personalAssetsRange')}
              name='personal_assets_range'
              rules={validationRequiredRules}
              required
            >
              <Select
                placeholder={placeholder('personalAssetsRange')}
                options={personalAssetsRangeOptions}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={label('monthlyIncomeRange')}
              name='monthly_income_range'
              rules={validationRequiredRules}
              required
            >
              <Select
                placeholder={placeholder('monthlyIncomeRange')}
                options={monthlyIncomeRangeOptions}
                size='large'
              />
            </Item>
            <Item
              label={label('taxResidence')}
              name='tax_residence'
              rules={validationRequiredRules}
            >
              <AntdCountriesSelect
                placeholder={placeholder('taxResidence')}
                {...countrySelectProps}
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
            <Item
              label={taxCodeLabel}
              name='tax_number'
              rules={validationTaxRules}
            >
              <Input
                placeholder={placeholder('taxCode')}
                size='large'
              />
            </Item>
          </StyledKYCModalContentColumnFormSpace>
          {userType === ULTIMATE_BENEFICIARY_OWNER && (
            <>
              <StyledKYCModalContentColumnFormSpace {...formSpaceProps}>
                <Item
                  name='us_person_accepted'
                  valuePropName='checked'
                >
                  <Checkbox>{label('usPerson')}</Checkbox>
                </Item>
              </StyledKYCModalContentColumnFormSpace>
              <Collapse isOpened={usPersonAccepted}>
                <Item
                  label={label('usPersonDeclaration')}
                  name='us_person_declaration'
                  rules={usPersonAccepted && validationRequiredRules}
                >
                  <StyledUserNationalityTaxDetailsFormUpload
                    accept={acceptFileTypes}
                    customRequest={() => null}
                    showUploadList={false}
                    maxCount={1}
                    style={{width: '100%'}}
                  >
                    <StyledUserNationalityTaxDetailsFormUploadSpace
                      size='small'
                    >
                      <Input
                        placeholder={placeholder('usPersonDeclaration')}
                        value={usPersonDeclarationFileName}
                        size='large'
                      />
                      <Button size='large'>{t('select')} {t('file')}</Button>
                    </StyledUserNationalityTaxDetailsFormUploadSpace>
                  </StyledUserNationalityTaxDetailsFormUpload>
                </Item>
              </Collapse>
            </>
          )}
        </Form>
      </SpinSmall>
    </StyledUserNationalityTaxDetailsForm>
  );
});

UserNationalityTaxDetailsForm.propTypes = {
  userType: PropTypes.oneOf([LEGAL_REPRESENTATIVE, ULTIMATE_BENEFICIARY_OWNER])
}

UserNationalityTaxDetailsForm.defaultProps = {
  userType: LEGAL_REPRESENTATIVE
}

export default UserNationalityTaxDetailsForm;
