import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Tooltip} from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  StyledSubscriptionUsers,
  StyledSubscriptionUsersTooltipContent,
} from './StyledSubscriptionUsers';
import SubscriptionEmailsModal from '../../tabComponents/SubscriptionEmailsModal';
import {subscriptionActions} from '../../../../../state/actions/subscription.actions';
import {elementHelpers, objectHelpers, subscriptionsHelpers} from '../../../../../utils/helpers';

const SubscriptionUsers = ({
  dispatch,
  subscription,
  getSubscriptionEmails,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [subscriptionEmails, setSubscriptionEmails] = useState({loading: true, data: []});
  const [emailModalProps, setEmailModalProps] = useState({open: false, emails: []});

  const count = useMemo(() => objectHelpers.getObjProp(subscription, 'users_count'), [subscription]);

  const emptyListMessage = t('emptyList');

  const getEmails = () => ({
    emails: subscriptionEmails?.data || [],
    loading: subscriptionEmails?.loading || false
  });

  const handleLoadEmails = (e) => {
    if (!subscriptionEmails.loading) return;
    subscriptionsHelpers.handleLoadSubscriptionEmails({e, subscription, subscriptionEmails, setSubscriptionEmails, getSubscriptionEmails});
  }

  const handleSeeMore = (e, emails) => {
    elementHelpers.stopPropagation(e);
    setEmailModalProps({...emailModalProps, emails, open: true});
  }

  const handleCloseEmailModal = () => setEmailModalProps({...emailModalProps, open: false});

  const getUsersListValue = () => {
    const {emails, loading} = getEmails(subscription);
    const emailsCount = emails.length;
    const displayedEmailsCount = 3;
    const enabledSeeMoreButton = emailsCount > displayedEmailsCount;
    const displayedEmails = enabledSeeMoreButton ? emails.slice(0, displayedEmailsCount) : emails;
    const moreEmailsCount = emailsCount - displayedEmailsCount;

    return (loading ? `${t('Loading')}...` : (
      <StyledSubscriptionUsersTooltipContent size={10} direction='vertical'>
        {emailsCount ? (
          <>
            {displayedEmails.map(email => <span key={email} className='need-review-tab-email'>{email}</span>)}
            {enabledSeeMoreButton && (
              <Button
                type='link'
                onClick={(e) => handleSeeMore(e, emails)}
              >
                {t('seeMoreCount', {count: moreEmailsCount})}
              </Button>
            )}
          </>
        ) : emptyListMessage}
      </StyledSubscriptionUsersTooltipContent>
    ));
  }

  const onMouseEnter = (e) => count && handleLoadEmails(e);

  const title = getUsersListValue();

  return (
    <>
      <StyledSubscriptionUsers
        {...rest}
        onMouseEnter={onMouseEnter}
      >
        {count && count !== 0 ? (
          <Tooltip placement='bottom' title={title}>
            {count}
          </Tooltip>
        ) : 0}
      </StyledSubscriptionUsers>

      <SubscriptionEmailsModal
        {...emailModalProps}
        handleClose={handleCloseEmailModal}
        subscription={subscription}
      />
    </>
  );
}

SubscriptionUsers.propTypes = {
  subscription: PropTypes.object
}

const mapDispatchToProps = {
  getSubscriptionEmails: subscriptionActions.getSubscriptionEmails
}

export default connect(null, mapDispatchToProps)(SubscriptionUsers);
