import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Avatar, Button, Modal, Space} from 'antd';
import {ExclamationCircleFilled} from '@ant-design/icons';
import microsoftLogo from '../../../../../static/images/pages/settings/microsoft-logo-sso.png';
import googleLogo from '../../../../../static/images/pages/settings/google-logo-sso.png';
import {serviceStatsActions} from '../../../../../state/actions/serviceStats.actions';
import {
  StyledServicesInformationCard,
  StyledService,
  StyledServicesListSpace,
  StyledConnectedService,
  StyledConnectedServicesListCard
} from './StyledUsageMonitoring';
import CompanyInfo from '../../tabComponents/CompanyInfo';
import {firebaseEvents} from '../../../../../snippets/firebase';
import {objectHelpers, systemHelpers, textHelpers} from '../../../../../utils/helpers';

const logEvent = (eventName, serviceName) => systemHelpers.logEvent(eventName, {name: serviceName});

const UsageMonitoring = ({
  dispatch,
  connections,
  edit,
  generateAuthUrl,
  disconnectService,
  handleUpdateServices,
  ...rest
}) => {
  const [trans] = useTranslation(['settings', 'main']);
  const [connectionUrls, setConnectionUrls] = useState({});
  const [connectedServices, setConnectedServices] = useState([]);
  const [storedConnectionId, setStoredConnectionId] = useState(null);

  const t = (key) => trans(`tabs.usageMonitoring.${key}`);

  const mainT = (key) => trans(`main:${key}`);

  const services = [
    {logo: googleLogo, name: 'google', title: 'Google Workspace', disabled: false},
    {logo: microsoftLogo, name: 'microsoft', title: 'Office 365', disabled: true}
  ];

  useEffect(() => {
    let connectedServices = [];
    connections.forEach(c => {
      const service = services.find(s => s.name === c.name);
      if (service) connectedServices.push({...c, ...service});
    });
    setConnectedServices(connectedServices);
  }, [connections]); // eslint-disable-line react-hooks/exhaustive-deps

  const openWindowUrl = (url) => window.open(url, '_blank').focus();

  const handleConnect = (serviceName) => {
    const serviceUrl = objectHelpers.getObjProp(connectionUrls, serviceName);
    if (serviceUrl) {
      openWindowUrl(serviceUrl);
    } else {
      generateAuthUrl(
        serviceName,
        ({url}) => {
          openWindowUrl(url);
          setConnectionUrls({
            ...connectionUrls,
            [serviceName]: url
          });
          logEvent(firebaseEvents.COMPANY_SETTINGS_CONNECT_SSO, serviceName);
        }
      );
    }
  }

  const handleDisconnect = (serviceId, serviceName) => {
    Modal.confirm({
      title: t('disconnectWindow.title'),
      icon: <ExclamationCircleFilled />,
      content: t('disconnectWindow.description'),
      okText: mainT('yes'),
      okType: 'danger',
      cancelText: mainT('no'),
      cancelButtonProps: {size: 'large'},
      okButtonProps: {size: 'large'},
      onOk() {
        setStoredConnectionId(serviceId);
        disconnectService(
          serviceId,
          serviceName,
          (data) => {
            handleUpdateServices && handleUpdateServices(serviceId, data);
            logEvent(firebaseEvents.COMPANY_SETTINGS_DISCONNECT_SSO, serviceName);
          },
          () => setStoredConnectionId(null)
        );
      }
    });
  }

  return (
    <Space
      direction='vertical'
      size='large'
      style={{width: '100%'}}
      {...rest}
    >
      <StyledServicesInformationCard
        title={<CompanyInfo />}
      >
        <h3>{t('tabTitle')}</h3>
        <p>{t('description')}</p>

        <StyledServicesListSpace size='middle'>
          {services.map(({disabled, logo, name, title}) => {
            if (!edit) disabled = true;
            return (
              <StyledService
                key={name}
                className={disabled && 'disabled'}
                onClick={() => !disabled && handleConnect(name)}
              >
                <img src={logo} alt={title} />
                {title}
              </StyledService>
            )
          })}
        </StyledServicesListSpace>
      </StyledServicesInformationCard>

      {connectedServices.length > 0 && (
        <StyledConnectedServicesListCard
          title={t('connectionsTitle')}
        >
          {connectedServices.map(({domain, id, name, logo, title}) => (
            <StyledConnectedService
              className={storedConnectionId === id && 'disabled'}
              key={id}
            >
              <Space size='small'>
                <Avatar src={logo}>
                  {textHelpers.getInitials(title)}
                </Avatar>
                {domain}
              </Space>
              {edit && (
                <Button
                  onClick={() => handleDisconnect(id, name)}
                  type='text'
                  danger
                >
                  {mainT('disconnect')}
                </Button>
              )}
            </StyledConnectedService>
          ))}
        </StyledConnectedServicesListCard>
      )}
    </Space>
  );
}

UsageMonitoring.propTypes = {
  connections: PropTypes.arrayOf(
    PropTypes.shape({
      connected: PropTypes.bool.isRequired,
      domain: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  edit: PropTypes.bool,
  handleUpdateServices: PropTypes.func
}

UsageMonitoring.defaultProps = {
  connections: [],
  edit: true
}

const mapDispatchToProps = {
  generateAuthUrl: serviceStatsActions.generateAuthUrl,
  disconnectService: serviceStatsActions.revokeOauth
}

export default connect(null, mapDispatchToProps)(UsageMonitoring);
