import {request} from '../services/request';
import {expenseConstants} from '../constants';
import {backendEndpoints} from '../../api';
import {apiHelpers} from '../../utils/helpers';

const errorHandlerProps = {disableAlert: true};

const {getUrl} = apiHelpers;

const getExpense = (id, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_EXPENSE, {id}),
      successCallback: (data) => {
        dispatch({
          type: expenseConstants.GET_EXPENSE,
          data
        });
        success && success();
      },
      errorCallback: () => error && error()
    });
  }
}

const getExpenseAttachment = (id, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_EXPENSE_ATTACHMENT, {id}),
      successCallback: (data) => success && success(data),
      errorCallback: () => error && error(),
      errorHandlerProps
    });
  }
}

const updateExpense = ({id, data, propertyName, successCallback, errorCallback} = {}) => {
  return (dispatch) => {
    const endpoint = {
      'expense_type': backendEndpoints.PUT_EXPENSE_UPDATE_EXPENSE_TYPE
    }[propertyName] || backendEndpoints.PUT_EXPENSE_UPDATE;

    request.put({
      dispatch,
      url: getUrl(endpoint, {id}),
      data,
      successCallback,
      errorCallback
    });
  }
}

const getTransactionExpense = (cardId, transactionId, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: getUrl(backendEndpoints.GET_TRANSACTION_EXPENSE, {cardId, transactionId}),
      successCallback: (data) => success && success(data),
      errorCallback: (err) => error && error(err),
      errorHandlerProps
    });
  }
}

const getBatchExpenses = (data, success, error) => {
  return (dispatch) => {
    request.post({
      dispatch,
      url: backendEndpoints.POST_EXPENSES_BATCH,
      data,
      successCallback: (data) => success && success(data),
      errorCallback: (err) => error && error(err),
      errorHandlerProps
    });
  }
}

const downloadZipInvoices = (query, success, error) => {
  return (dispatch) => {
    request.get({
      dispatch,
      url: backendEndpoints.GET_EXPENSES_ZIP_INVOICES,
      query,
      successCallback: (data) => success && success(data),
      errorCallback: (err) => error && error(err)
    });
  }
}

export const expenseActions = {
  getExpense,
  getExpenseAttachment,
  getTransactionExpense,
  getBatchExpenses,

  updateExpense,
  downloadZipInvoices
};
