import React, {useEffect, useMemo, useState} from 'react';
import {Form, Input, Radio, Select, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {UnmountClosed} from 'react-collapse';
import {
  StyledLegalPersonInformation,
  StyledLegalPersonInformationAddon,
  StyledLegalPersonInformationText
} from './StyledLegalPersonInformation';
import SpinSmall from '../../../../../SpinSmall';
import PhoneNumberInput from '../../../../../PhoneNumberInput';
import {StyledKYCModalContentStepTitle} from '../../../../KYCModal/StyledKYCModal';
import {EuroIcon} from '../../../../../../icons';
import {bankingActions} from '../../../../../../state/actions/banking.actions';
import {companyActions} from '../../../../../../state/actions/company.actions';
import {
  formHelpers,
  kycHelpers,
  localizationHelpers,
  objectHelpers
} from '../../../../../../utils/helpers';
import {useLocation} from '../../../../../../hooks';
import {bankingUserTypesConstants} from '../../../../../../constants';
import {legalPersonOptions} from './legalPersonOptions';

const gObjProp = objectHelpers.getObjProp;

const {LEGAL_PERSON} = bankingUserTypesConstants;

const {Item} = Form;

const initialFormValues = {
  email: '',
  legal_annual_turn_over: legalPersonOptions.annualTurnover[0].value,
  legal_form: undefined,
  legal_net_income_range: legalPersonOptions.income[0].value,
  legal_number_of_employee_range: legalPersonOptions.employees[0].value,
  phone: ''
}

const LegalPersonInformation = React.forwardRef(({
  dispatch,
  companyCountry,
  companyEmail,
  companyName,
  editedUser,

  createLegalPerson,
  getCompanySettings,

  onSubmit,
  ...rest
}, ref) => {
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation(['main', 'quickStart']);
  const [form] = Form.useForm();
  const [countryCode, setCountryCode] = useState(undefined);
  const [error, setError] = useState(undefined);

  const activityOutsideEu = Form.useWatch('activity_outside_eu', form);

  const {phone_code: defaultPhoneCode} = useLocation();

  const companyTypeOptions = useMemo(() => kycHelpers.getLegalFormOptions(countryCode), [countryCode]);

  const {
    label,
    placeholder,
    validationPhoneRules,
    validationRequiredRules,
    title,
    tooltip
  } = useMemo(() => kycHelpers.getKYCFormDetails({t, formKey: 'lpInformation'}), [t]);

  useEffect(() => {
    if (companyCountry) {
      const countryCode = localizationHelpers.getCountryCodeByName(companyCountry);
      setCountryCode(countryCode.toUpperCase());
    } else {
      getCompanySettings();
    }
  }, [getCompanySettings, companyCountry]);

  useEffect(() => {
    if (companyEmail) form.setFieldValue('email', companyEmail);
  }, [companyEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFinish = (fields) => {
    let data = {
      ...fields,
      country: countryCode,
      legal_name: companyName,
      legal_sector: '6201',
      user_type: LEGAL_PERSON
    };

    setLoading(true);

    createLegalPerson(
      data,
      (user) => {
        error && setError(undefined);
        setLoading(false);
        onSubmit && onSubmit({userId: gObjProp(user, 'id')});
      },
      (data) => {
        const formErrors = formHelpers.getFormServerErrorFields(data);
        if (formErrors.length === 0 && data.detail.length > 0) {
          const {detail} = data;
          setError(typeof detail === 'string' ? detail : detail[0].msg);
        }
        form.setFields(formErrors);
        setLoading(false);
      }
    );
  }

  return (
    <StyledLegalPersonInformation
      direction='vertical'
      size={32}
      {...rest}
    >
      <StyledKYCModalContentStepTitle>
        {title}
      </StyledKYCModalContentStepTitle>
      <SpinSmall spinning={loading}>
        <Form
          initialValues={initialFormValues}
          form={form}
          layout='vertical'
          onFinish={onFinish}
          ref={ref}
          requiredMark={false}
        >
          <Item
            label={label('companyType')}
            name='legal_form'
            rules={validationRequiredRules}
          >
            <Select
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={companyTypeOptions}
              placeholder={placeholder('companyType')}
              size='large'
              showSearch
            />
          </Item>
          <Item
            label={label('businessEmail')}
            name='email'
            rules={validationRequiredRules}
            tooltip={tooltip('businessEmail')}
          >
            <Input
              placeholder={placeholder('businessEmail')}
              size='large'
              type='email'
            />
          </Item>
          <Item
            label={label('phoneNumber')}
            name='phone'
            rules={validationPhoneRules}
          >
            <PhoneNumberInput
              phoneCode={defaultPhoneCode}
              placeholder={placeholder('phoneNumber')}
              size='large'
            />
          </Item>
          <Item
            label={label('annualTurnover')}
            tooltip={tooltip('annualTurnover')}
          >
            <Space.Compact style={{width: '100%'}}>
              <StyledLegalPersonInformationAddon>
                <EuroIcon />
              </StyledLegalPersonInformationAddon>
              <Item
                name='legal_annual_turn_over'
                rules={validationRequiredRules}
                noStyle
              >
                <Select
                  options={legalPersonOptions.annualTurnover}
                  placeholder={placeholder('annualTurnover')}
                  size='large'
                />
              </Item>
            </Space.Compact>
          </Item>
          <Item
            label={label('annualIncome')}
            tooltip={tooltip('annualIncome')}
          >
            <Space.Compact style={{width: '100%'}}>
              <StyledLegalPersonInformationAddon>
                <EuroIcon />
              </StyledLegalPersonInformationAddon>
              <Item
                name='legal_net_income_range'
                rules={validationRequiredRules}
                noStyle
              >
                <Select
                  options={legalPersonOptions.income}
                  placeholder={placeholder('annualIncome')}
                  size='large'
                />
              </Item>
            </Space.Compact>
          </Item>
          <Item
            label={label('numberOfEmployees')}
            name='legal_number_of_employee_range'
            rules={validationRequiredRules}
            tooltip={tooltip('numberOfEmployees')}
          >
            <Select
              options={legalPersonOptions.employees}
              placeholder={placeholder('numberOfEmployees')}
              size='large'
            />
          </Item>
          <Item
            label={label('entitySanctionsQuestionnaire')}
            name='entity_sanctions_questionnaire'
            rules={validationRequiredRules}
          >
            <Select
              options={legalPersonOptions.entitySanctionsQuestionnaire}
              placeholder={placeholder('entitySanctionsQuestionnaire')}
              size='large'
            />
          </Item>
          <Item
            label={label('activityOutsideEu')}
            name='activity_outside_eu'
            rules={validationRequiredRules}
          >
            <Radio.Group
              options={legalPersonOptions.activityOutsideEu}
              size='large'
            />
          </Item>
          <UnmountClosed isOpened={activityOutsideEu}>
            <Item
              label={label('economicSanctions')}
              name='economic_sanctions'
              rules={validationRequiredRules}
            >
              <Radio.Group
                options={legalPersonOptions.economicSanctions}
                size='large'
              />
            </Item>
            <Item
              label={label('residentCountriesSanctions')}
              name='resident_countries_sanctions'
              rules={validationRequiredRules}
            >
              <Radio.Group
                options={legalPersonOptions.residentCountriesSanctions}
                size='large'
              />
            </Item>
            <Item
              label={label('involvedSanctions')}
              name='involved_sanctions'
              rules={validationRequiredRules}
            >
              <Radio.Group
                options={legalPersonOptions.involvedSanctions}
                size='large'
              />
            </Item>
          </UnmountClosed>

          <UnmountClosed isOpened={error !== undefined}>
            <StyledLegalPersonInformationText>
              {error}
            </StyledLegalPersonInformationText>
          </UnmountClosed>
        </Form>
      </SpinSmall>
    </StyledLegalPersonInformation>
  );
});

const mapStateToProps = state => {
  const {name, settings} = state.company;
  const companyCountry = gObjProp(settings, 'country');
  const companyEmail = gObjProp(settings, 'email');

  return {
    companyCountry,
    companyEmail,
    companyName: name
  }
}

const mapDispatchToProps = {
  createLegalPerson: bankingActions.createUser,
  getCompanySettings: companyActions.getCompanySettings
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(LegalPersonInformation);
