import React from 'react';
import {Space} from 'antd';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import TagManager from '../../tabComponents/TagManager';
import {firebaseEvents} from '../../../../../snippets/firebase';
import {tagFamilyConstants} from '../../../../../constants';


const TagsManagement = ({
  edit,
  dispatch,
  getCompanyTags,
  addNewCompanyTag,
  deleteCompanyTag,
  ...rest
}) => {
  const [trans] = useTranslation(['settings', 'main']);

  const t = (key) => trans(`tabs.tagsManagement.${key}`);

  const getTagManagerProps = (key) => {
    return {
      edit,
      description: t(`${key}.description`),
      inputPlaceholder: t(`${key}.inputPlaceholder`),
      title: t(`${key}.title`),
    }
  }

  return (
    <Space
      direction='vertical'
      size='large'
      {...rest}
    >
      <TagManager
        {...getTagManagerProps('costCenters')}
        logEventName={{
          add: firebaseEvents.COMPANY_SETTINGS_ADD_COST_CENTER_TAG,
          delete: firebaseEvents.COMPANY_SETTINGS_DELETE_COST_CENTER_TAG
        }}
        tagFamily={tagFamilyConstants.COST_CENTER}
      />
      <TagManager
        {...getTagManagerProps('expenseCategories')}
        logEventName={{
          add: firebaseEvents.COMPANY_SETTINGS_ADD_EXPENSE_CATEGORY_TAG,
          delete: firebaseEvents.COMPANY_SETTINGS_DELETE_EXPENSE_CATEGORY_TAG
        }}
        tagFamily={tagFamilyConstants.EXPENSE}
      />
    </Space>
  );
}

TagsManagement.propTypes = {
  edit: PropTypes.bool
}

TagsManagement.defaultProps = {
  edit: true
}

export default TagsManagement;
