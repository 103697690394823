export const titleOptions = [
  {label: 'Mr', value: 'M'},
  {label: 'Mrs', value: 'MME'},
  {label: 'Miss', value: 'MLLE'},
];

export const monthlyIncomeRangeOptions = [
  {label: '€0 - €999', value: 1},
  {label: '€1.000 - €1.800', value: 2},
  {label: '€1.801 - €3.500', value: 3},
  {label: '€3.501 - €5.000', value: 4},
  {label: '€5.001 - €6.500', value: 5},
  {label: '€6.501 - €10.000', value: 6},
  {label: 'Greater than €10.000', value: 7},
];

export const personalAssetsRangeOptions = [
  {label: 'None', value: 0},
  {label: '€0 - €15.000', value: 1},
  {label: '€15.001 - €50.000', value: 2},
  {label: '€50.001 - €150.000', value: 3},
  {label: '€150.001 - €350.000', value: 4},
  {label: '€350.001 - €750.000', value: 5},
  {label: '€750.001 - €1.500.000', value: 6},
  {label: 'Greater than €1.500.000', value: 7}
];

export const occupationCategoryOptions = [
  {label: 'Pupil, student or apprentice', value: 1},
  {label: 'Beneficiaries of unemployment assistance', value: 2},
  {label: 'Without professional activity', value: 3},
  {label: 'Executive and Intellectual Profession in the private sector', value: 4},
  {label: 'Employee in the private sector', value: 5},
  {label: 'Business owner', value: 6},
  {label: 'Executive and Intellectual Profession in the public sector', value: 7},
  {label: 'Employee and Officer in the public sector', value: 8},
  {label: 'Farmer', value: 9},
  {label: 'Self-employed', value: 10},
  {label: 'Craftsman', value: 11},
  {label: 'Shopkeeper and related professions', value: 12},
  {label: 'Freelance profession', value: 13},
  {label: 'Medical freelance professions (or medical practitioners)', value: 14},
  {label: 'Retired', value: 15},
];
