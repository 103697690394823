const CommercialArtGraphicsPhotographyIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 11V21H3V3L12.5 3" stroke="black" strokeWidth="0.5" strokeLinecap="round"/>
    <path
      d="M18.9955 6.52211C18.8641 6.67194 18.6999 6.79237 18.5147 6.87493C18.3294 6.9575 18.1275 7.00019 17.9231 7C17.5516 7 17.1952 6.85868 16.9325 6.60712C16.6698 6.35557 16.5222 6.01439 16.5222 5.65863V4.34137C16.5222 3.98561 16.6698 3.64443 16.9325 3.39288C17.1952 3.14132 17.5516 3 17.9231 3C18.1287 2.99982 18.3317 3.04302 18.5179 3.12652C18.704 3.21002 18.8686 3.33178 19 3.48312M16 4.51393H17.5667M16 5.49562H17.5667"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M18 9C20.2091 9 22 7.20914 22 5C22 2.79086 20.2091 1 18 1C15.7909 1 14 2.79086 14 5C14 7.20914 15.7909 9 18 9Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M3 17.5L8.3465 12.599C8.52671 12.4338 8.76117 12.3404 9.00558 12.3363C9.24999 12.3323 9.48742 12.4179 9.673 12.577L16 18M14 15.5L16.3865 13.1135C16.5567 12.9431 16.7827 12.8399 17.0229 12.8229C17.2631 12.8058 17.5014 12.8759 17.694 13.0205L21 15.5"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default CommercialArtGraphicsPhotographyIcon;
