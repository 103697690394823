import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Button, Space} from 'antd';
import {EditFilled, PlusOutlined} from '@ant-design/icons';
import {
  StyledInvoiceDetailsCard,
  StyledInvoiceDetailsCloseButton,
  StyledInvoiceDetailsFilePreview,
  StyledInvoiceDetailsAttachmentPreview,
  StyledInvoiceDetailsDownloadButton
} from './StyledInvoiceDetails';
import InvoiceAttachmentPreview from '../InvoiceAttachmentPreview';
import ImagePreviewTooltip from '../ImagePreviewTooltip';
import TransactionExpenseTypeForm from '../TransactionExpenseTypeForm/TransactionExpenseTypeForm';
import {CloseIcon, DownloadIcon, ReceiptDeclinedIcon} from '../../../../icons';
import {objectHelpers} from '../../../../utils/helpers';


const InvoiceDetails = ({
  attachment,
  isDisableClose,
  isDisableEdit,
  isEnableDownloadButton,
  loadingAttachment,
  transaction,
  onClose,
  onDownload,
  onEdit,
  onSubmit,
  ...rest
}) => {
  const [t] = useTranslation('main');

  const isExistAttachment = attachment && attachment.file;

  const isDefinedExpenseType = objectHelpers.isValidValue(transaction?.expense?.expense_type);

  const handleOnEdit = () => onEdit && onEdit(transaction);

  const handleDownload = (e) => onDownload && onDownload(e);

  const handleClose = (e) => {
    e.stopPropagation();
    onClose && onClose(e);
  }

  const extra = (
    <Space align='center' size='middle'>
      {(isExistAttachment && isEnableDownloadButton) && (
        <StyledInvoiceDetailsDownloadButton
          onClick={handleDownload}
          type='text'
        >
          <DownloadIcon /> {t('download')}
        </StyledInvoiceDetailsDownloadButton>
      )}
      {(!isDisableEdit && isDefinedExpenseType) && (
        <Button
          icon={<EditFilled />}
          onClick={handleOnEdit}
          size='small'
          type='text'
        />
      )}
    </Space>
  );

  const invoiceAttachment = (
    <InvoiceAttachmentPreview
      attachment={attachment}
    />
  );

  return (
    <>
      <StyledInvoiceDetailsCard
        title={t('invoice')}
        type='inner'
        extra={extra}
        {...rest}
      >
        {isExistAttachment ? (
          <Space direction='vertical' size='middle'>
            <TransactionExpenseTypeForm
              transaction={transaction}
              onSubmit={onSubmit}
            />
            <ImagePreviewTooltip
              overlayClassName='image-preview-tooltip-overlay single'
              trigger='hover'
              title={invoiceAttachment}
            >
              <StyledInvoiceDetailsFilePreview>
                <StyledInvoiceDetailsAttachmentPreview
                  attachment={attachment}
                  onClick={(e) => !isEnableDownloadButton && handleDownload(e)}
                />
                {!isDisableClose && (
                  <StyledInvoiceDetailsCloseButton
                    icon={<CloseIcon />}
                    onClick={handleClose}
                    shape='circle'
                    type='text'
                  />
                )}
              </StyledInvoiceDetailsFilePreview>
            </ImagePreviewTooltip>
          </Space>
        ) : isDisableEdit ? null : ((transaction && transaction.expense) && !loadingAttachment) && (
          <>
            {isDefinedExpenseType ? (
              <Space direction='vertical' size={0}>
                <ReceiptDeclinedIcon />
                <span>{t(`invoiceTypes.noReceipt`)}</span>
              </Space>
            ) : (
              <Button
                icon={<PlusOutlined />}
                onClick={handleOnEdit}
                type='text'
              >
                {t('add')}
              </Button>
            )}
          </>
        )}
      </StyledInvoiceDetailsCard>
    </>
  );
}

InvoiceDetails.propTypes = {
  attachment: PropTypes.shape({
    file: PropTypes.string,
    name: PropTypes.string
  }),
  isDisableEdit: PropTypes.bool,
  isDisableClose: PropTypes.bool,
  isEnableDownloadButton: PropTypes.bool,
  loadingAttachment: PropTypes.bool,
  onClose: PropTypes.func,
  onDownload: PropTypes.func,
  onEdit: PropTypes.func,
  onSubmit: PropTypes.func,
  transaction: PropTypes.object
}

InvoiceDetails.defaultProps = {
  isDisableEdit: false,
  isEnableDownloadButton: false,
  loadingAttachment: false
}

export default InvoiceDetails;
