import { useSelector } from 'react-redux';
import { shallowEqual } from 'react-redux';

export const useCurrentCompany = () => {
  return useSelector(
    ({ company }) => ({
      id: company?.id,
      name: company?.name ?? 'Unknown Company',
    }),
    shallowEqual // Prevents re-renders unless `id` or `name` changes
  );
};