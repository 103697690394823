import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Avatar, Button} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {
  StyledNavigationCompanyDetails,
  StyledNavigationDropdownContent,
  StyledNavigationDropdownMenu,
  StyledNavigationCompaniesList
} from './StyledNavigation';
import {SettingsIcon} from '../../icons';
import {useIsEmployeeChanged} from '../../hooks';
import {userActions} from '../../state/actions/user.actions';
import routes from '../../routes/routes.json';
import {elementHelpers, objectHelpers, textHelpers} from '../../utils/helpers';

const DropdownNavigation = React.forwardRef(({
  activeCompany,
  companies,
  enabledCompanyCreation,
  handleAddCompany,
  isAdmin,
  onCompanyChange,
  onMenuClick,

  changeCompany,
  disableEmployeeFromCompany,
  getCompanies,
  ...rest
}, ref) => {
  const [t] = useTranslation('main');
  const [selectedKeys, setSelectedKeys] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const isEmployeeChanged = useIsEmployeeChanged();

  const isEnabledFooter = useMemo(() => enabledCompanyCreation || Boolean(companies.length), [enabledCompanyCreation, companies]);

  const navItems = [
    {key: 'settings', label: 'settings', path: routes.settings, icon: <SettingsIcon />},
  ]
    .map(i => ({...i, label: t(`bottomMenuToggle.navigation.${i.label}`)}));

  useEffect(() => {
    if (isEmployeeChanged) {
      onCompanyChange && onCompanyChange();
      setSelectedKeys([]);
    }
  }, [isEmployeeChanged]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    elementHelpers.updateMenuItems({location, items: navItems, selectedKeys, setSelectedKeys});
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMenuClick = (e) => {
    const {key} = e;
    const item = navItems.find(i => i.key === key);
    if (item) navigate(item.path);
    setSelectedKeys([key]);
    onMenuClick && onMenuClick();
  }

  const getCompanyDetails = ({company, ...rest}) => {
    const logo = objectHelpers.getObjProp(company, 'logo_url');
    const name = objectHelpers.getObjProp(company, 'name');
    return (
      <StyledNavigationCompanyDetails {...rest}>
        <Avatar
          src={logo}
          size={32}
        >
          {textHelpers.getInitials(name)}
        </Avatar>
        {name}
      </StyledNavigationCompanyDetails>
    )
  }

  const onCompanyClick = (id) => {
    onMenuClick && onMenuClick();
    changeCompany(
      id,
      (company) => {
        disableEmployeeFromCompany(true, company);
        getCompanies();
      }
    );
  }

  return (
    <StyledNavigationDropdownContent ref={ref} {...rest}>
      <header>
        {getCompanyDetails({company: activeCompany})}
        {isAdmin && (
          <StyledNavigationDropdownMenu
            items={navItems}
            onClick={handleMenuClick}
            selectedKeys={selectedKeys}
          />
        )}
      </header>
      {isEnabledFooter && (
        <footer>
          {companies.length > 0 && (
            <StyledNavigationCompaniesList>
              {companies.map(company =>
                getCompanyDetails({
                  company,
                  key: company.company_id,
                  onClick: () => onCompanyClick(company.company_id)
                }))}
            </StyledNavigationCompaniesList>
          )}
          {enabledCompanyCreation && (
            <Button
              onClick={(e) => handleAddCompany && handleAddCompany(e)}
              icon={<PlusOutlined/>}
              size='large'
            >
              {t('add')} {t('company')}
            </Button>
          )}
        </footer>
      )}
    </StyledNavigationDropdownContent>
  );
});

DropdownNavigation.propTypes = {
  enabledCompanyCreation: PropTypes.bool,
  handleAddCompany: PropTypes.func,
  onCompanyChange: PropTypes.func,
  onMenuClick: PropTypes.func
}

DropdownNavigation.defaultProps = {
  enabledCompanyCreation: true,
}

const mapStateToProps = state => {
  const {companies, isAdmin, employee} = state.user;
  let activeCompany;
  let userCompanies = [];
  if (employee.hasOwnProperty('company_id')) {
    userCompanies = companies
      .filter(c => c.account_enabled)
      .filter(c => {
        if (c.company_id !== employee.company_id) {
          return c;
        } else {
          activeCompany = c;
          return false
        }
      });
  }

  return {
    activeCompany,
    companies: userCompanies,
    isAdmin
  }
}

const mapDispatchToProps = {
  changeCompany: userActions.changeCompany,
  disableEmployeeFromCompany: userActions.disableEmployeeFromCompany,
  getCompanies: userActions.getCompanies
}

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(DropdownNavigation);
