import React from 'react';

const WireIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.032 2.3448C11.7324 2.12011 11.371 1.99902 11 1.99902C10.629 1.99902 10.2676 2.12011 9.968 2.3448L2.547 7.90512C1.57 8.63636 2.073 10.2221 3.281 10.2241H15V10.2159H18V10.2231H18.719C19.927 10.2211 20.429 8.63534 19.453 7.90409L12.032 2.3448ZM11 5.34783C11.2652 5.34783 11.5196 5.45603 11.7071 5.64863C11.8946 5.84124 12 6.10247 12 6.37485C12 6.64724 11.8946 6.90846 11.7071 7.10107C11.5196 7.29367 11.2652 7.40188 11 7.40188C10.7348 7.40188 10.4804 7.29367 10.2929 7.10107C10.1054 6.90846 10 6.64724 10 6.37485C10 6.10247 10.1054 5.84124 10.2929 5.64863C10.4804 5.45603 10.7348 5.34783 11 5.34783ZM10 17.4082V20.9997H2.75C2.55109 20.9997 2.36032 20.9186 2.21967 20.7741C2.07902 20.6296 2 20.4337 2 20.2294V19.7159C2.00053 19.1034 2.23782 18.5162 2.65972 18.0833C3.08162 17.6503 3.65361 17.4072 4.25 17.4072L10 17.4082Z"
      fill="#B5B8BF"/>
    <path
      d="M14.3426 16.5412C14.4429 16.6459 14.4993 16.7878 14.4993 16.9358C14.4993 17.0838 14.4429 17.2257 14.3426 17.3304L13.8283 17.8664H15.7499C15.892 17.8664 16.0282 17.9252 16.1287 18.0299C16.2292 18.1347 16.2856 18.2767 16.2856 18.4248C16.2856 18.5729 16.2292 18.7149 16.1287 18.8196C16.0282 18.9243 15.892 18.9831 15.7499 18.9831H13.8283L14.3426 19.5192C14.3952 19.5703 14.4375 19.6319 14.4667 19.7004C14.496 19.7689 14.5118 19.8428 14.513 19.9178C14.5143 19.9928 14.5011 20.0673 14.4741 20.1368C14.4472 20.2063 14.4071 20.2695 14.3562 20.3225C14.3053 20.3755 14.2447 20.4173 14.178 20.4454C14.1113 20.4735 14.0398 20.4873 13.9679 20.4859C13.896 20.4846 13.825 20.4682 13.7593 20.4377C13.6936 20.4072 13.6344 20.3632 13.5854 20.3083L12.1567 18.8193C12.0564 18.7147 12 18.5727 12 18.4248C12 18.2768 12.0564 18.1349 12.1567 18.0302L13.5854 16.5412C13.6858 16.4367 13.822 16.3779 13.964 16.3779C14.106 16.3779 14.2422 16.4367 14.3426 16.5412ZM19.6574 16.5412C19.7579 16.4367 19.894 16.3779 20.036 16.3779C20.178 16.3779 20.3142 16.4367 20.4146 16.5412L21.8433 18.0302C21.9436 18.1349 22 18.2768 22 18.4248C22 18.5727 21.9436 18.7147 21.8433 18.8193L20.4146 20.3083C20.3656 20.3632 20.3064 20.4072 20.2407 20.4377C20.175 20.4682 20.104 20.4846 20.0321 20.4859C19.9602 20.4873 19.8887 20.4735 19.822 20.4454C19.7553 20.4173 19.6947 20.3755 19.6438 20.3225C19.5929 20.2695 19.5528 20.2063 19.5259 20.1368C19.4989 20.0673 19.4857 19.9928 19.487 19.9178C19.4882 19.8428 19.504 19.7689 19.5333 19.7004C19.5625 19.6319 19.6048 19.5703 19.6574 19.5192L20.1717 18.9831H18.2501C18.108 18.9831 17.9718 18.9243 17.8713 18.8196C17.7708 18.7149 17.7144 18.5729 17.7144 18.4248C17.7144 18.2767 17.7708 18.1347 17.8713 18.0299C17.9718 17.9252 18.108 17.8664 18.2501 17.8664H20.1717L19.6574 17.3304C19.5571 17.2257 19.5007 17.0838 19.5007 16.9358C19.5007 16.7878 19.5571 16.6459 19.6574 16.5412Z"
      fill="#B5B8BF"/>
    <rect x="16" y="11.2432" width="2" height="3.08108" rx="0.5" fill="#B5B8BF"/>
    <rect x="12" y="11.2432" width="2" height="4.1081" rx="0.5" fill="#B5B8BF"/>
    <rect x="8" y="11.2432" width="2" height="5.13513" rx="0.5" fill="#B5B8BF"/>
    <rect x="4" y="11.2432" width="2" height="5.13513" rx="0.5" fill="#B5B8BF"/>
  </svg>
);

export default WireIcon;
