import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Select} from 'antd';
import {StyledInvoiceTypeSelect, StyledInvoiceTypeSelectLabel} from './StyledInvoiceTypeSelect';
import {transactionsHelpers} from '../../../../utils/helpers';

const InvoiceTypeSelect = ({
  defaultValue,
  enabledEmpty,
  onChange,
  value,
  ...rest
}) => {
  const [elementValue, setElementValue] = useState(defaultValue);
  const [t] = useTranslation('main');

  const options = useMemo(() => transactionsHelpers.getInvoiceTypeOptions({enabledEmpty, t}), [enabledEmpty, t]);

  useEffect(() => setElementValue(value), [value]);

  const handleOnChange = (value, e) => {
    setElementValue(value);
    onChange && onChange(e, value);
  }

  return (
    <StyledInvoiceTypeSelect {...rest}>
      <StyledInvoiceTypeSelectLabel>
        {t('document')} {t('type')}
      </StyledInvoiceTypeSelectLabel>
      <Select
        onChange={handleOnChange}
        options={options}
        value={elementValue}
        size='large'
      />
    </StyledInvoiceTypeSelect>
  );
}

InvoiceTypeSelect.propTypes = {
  enabledEmpty: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any
}

InvoiceTypeSelect.defaultProps = {
  enabledEmpty: true,
  totalLoading: false
}


export default InvoiceTypeSelect;
