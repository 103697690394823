const defaultStatus = {
  key: null,
  color: '#ededed'
};

export const objectHelpers = {
  arraysIsEqual: (arr1, arr2) => {
    try {
      return JSON.stringify(arr1) === JSON.stringify(arr2)
    } catch (e) {
      return false
    }
  },
  getObjProp: (obj, propName, defaultValue = '') => {
    if (obj && obj.hasOwnProperty(propName)) {
      return obj[propName]
    } else {
      return defaultValue
    }
  },
  getOptionLabel: (options = [], value) => value !== null && value !== undefined ? options.find(o => o.value === value)?.label ?? '-' : undefined,
  getStatus: (statusKey, statusType) => statusType.hasOwnProperty(statusKey) ? statusType[statusKey] : defaultStatus,
  isEmptyObject: (obj) => objectHelpers.arraysIsEqual(obj, {}),
  isEmptyQuery: (query) => query ? !Boolean(Object.keys(query).filter(key => query[key] && query[key].length).length) : true,
  isNaV: (value) => [null, undefined].includes(value),
  isValidValue: (value) => value !== undefined && value !== null && value !== '',
  range: (start, end) => (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start),
  trimAllValues: (fields = {}) => {
    return Object.keys(fields).reduce((prev, next) => Object.assign(prev, {
      [next]: fields[next] ? fields[next].trim() : fields[next]
    }), {});
  },
  omitInvalidValues: (obj) => Object.fromEntries(Object.entries(obj).filter(([_, value]) => objectHelpers.isValidValue(value))),
  getSearchParams: (searchParams) => {
    const paramsObject = {};
    searchParams.forEach((value, key) => {
      if (paramsObject[key]) {
        // If key already exists, convert it to an array (if not already) and add the new value
        paramsObject[key] = Array.isArray(paramsObject[key])
          ? [...paramsObject[key], value]
          : [paramsObject[key], value];
      } else {
        // If key doesn't exist, just assign the value
        paramsObject[key] = value;
      }
    });
    return paramsObject;
  },
  removeKeysFromObject: (obj, excludeKeys) => Object.fromEntries(Object.entries(obj).filter(([key]) => !excludeKeys.includes(key))),
}
