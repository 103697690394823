import React from 'react';
import {Switch, Tooltip} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {StyledHardCardLimitSwitch} from './StyledHardCardLimitSwitch';

const HardCardLimitSwitch = ({
  checked,
  onChange,
  switchProps,
  tooltipProps,
  total,
  ...rest
}) => {
  const [t] = useTranslation(['main']);

  return (
    <StyledHardCardLimitSwitch align='center' size={10} {...rest}>
      <Switch
        {...switchProps}
        checked={checked}
        onChange={onChange}
      />
      <span>{t('hardCardLimit')}</span>
      <Tooltip
        overlayStyle={{maxWidth: 368}}
        {...tooltipProps}
      >
        <InfoCircleOutlined size={14} />
      </Tooltip>
    </StyledHardCardLimitSwitch>
  )
}

HardCardLimitSwitch.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  switchProps: PropTypes.object,
  tooltipProps: PropTypes.object,
}

HardCardLimitSwitch.defaultProps = {
  disabled: false,
}

export default HardCardLimitSwitch;
