import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useLocation, useParams} from 'react-router-dom';
import {StyledPublicSubscriptionUpdatePageLoading} from './StyledPublicSubscriptionUpdatePage';
import VerifyPageContainer from '../../components/VerifyPageContainer';
import {subscriptionActions} from '../../state/actions/subscription.actions';
import {systemHelpers} from '../../utils/helpers';
import {localStorageKeysConstants} from '../../constants';
import routes from '../../routes/routes.json';

const {COMPANY_ID} = localStorageKeysConstants;

const PublicSubscriptionUpdatePage = ({
  getSubscriptionPublic,
  updateSubscriptionPublic
}) => {
  const [t] = useTranslation('main');
  const location = useLocation();
  const {subscriptionId} = useParams();
  const [verification, setVerification] = useState({
    loading: true,
    message: undefined,
    success: false
  });
  const verificationErrorMessage = useMemo(() => verification.message, [verification]);

  const validationT = (key) => t(`validation.${key}`);

  const errorCallback = () => {
    setVerification({loading: false, message: validationT('invalidToken'), success: false});
  }

  useEffect(() => {
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));
    const {period, token} = searchParams;
    if (token && period) {
      getSubscriptionPublic({
        token,
        successCallback: (data) => {
          const companyId = data?.company_id;
          updateSubscriptionPublic({
            data: { period: parseInt(period) },
            errorCallback,
            token,
            successCallback: () => {
              const isActiveSession = systemHelpers.hasActiveSession();

              if (!isActiveSession) {
                return window.location.replace(routes.login);
              }

              // Store companyId if available
              companyId && localStorage.setItem(COMPANY_ID, companyId);

              // Redirect to the subscriptions list
              window.location.replace(`${routes.subscriptionsList}/${subscriptionId}`);
            }
          })
        },
        errorCallback
      });
    } else {
      setVerification({
        loading: false,
        message: validationT('invalidUrl'),
        success: false
      });
    }
  }, [location, getSubscriptionPublic]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <VerifyPageContainer>
      {
        verification.loading
          ? <StyledPublicSubscriptionUpdatePageLoading/>
          : !verification.success && (
          <div className='redirect-description'>
            {verificationErrorMessage}
          </div>
        )
      }
    </VerifyPageContainer>
  );
}

const mapDispatchToProps = {
  getSubscriptionPublic: subscriptionActions.getSubscriptionPublic,
  updateSubscriptionPublic: subscriptionActions.updateSubscriptionPublic,
}

export default connect(null, mapDispatchToProps)(PublicSubscriptionUpdatePage);
