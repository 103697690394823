const systemHashKeysConstants = Object.freeze({
  ADD_MULTIPLE_SUBSCRIPTIONS: '#add-multiple-subscriptions',
  ADD_SINGLE_SUBSCRIPTION: '#add-single-subscription',
  OPEN_BANK_ACCOUNT_DETAILS: '#open-bank-account-popup',
  TRANSACTIONS_INVOICE_MISSING: '#transactions-invoice-missing',
  UNMATCHED_INVOICES: '#unmatched-invoices'
});

const popupSettingsConstants = Object.freeze({
  TRANSACTIONS_INVOICE_MISSING: 'transactions_invoice_missing',
  UNMATCHED_INVOICES: 'unmatched_invoices',
});

// Animation duration (in milliseconds)
const SYSTEM_ANIMATION_DURATION = 300;

export {
  popupSettingsConstants,
  systemHashKeysConstants,
  SYSTEM_ANIMATION_DURATION,
};
