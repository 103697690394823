import {Button} from 'antd';
import styled from 'styled-components';

const StyledAddFundsDetailsButton = styled(Button)`
  background-color: ${props => props.theme.colors.neutral_1};
  min-width: 96px;
  padding-left: 4px;
  padding-right: 4px;
`;

export {
  StyledAddFundsDetailsButton
};
