import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Avatar, Button, Space} from 'antd';
import PropTypes from 'prop-types';
import {
  StyledVariations,
  StyledVariationsItemDescription,
  StyledVariationsItemDescriptionItem,
  StyledVariationsItemLabel,
  StyledVariationsItemLabelDetails,
  StyledVariationsItemLabelDetailsDescription,
  StyledVariationsItemLabelDetailsTitle,
} from './StyledVariations';
import {ReactComponent as NewLabelSvg} from '../../../../static/images/pages/overview/new-label.svg';
import {ReactComponent as OverPricedArrowSvg} from '../../../../static/images/pages/overview/red-price-arrow.svg';
import {amountHelpers, textHelpers} from '../../../../utils/helpers';

const getAmountWithCurrencyCode = (value, showFractionalPart) => amountHelpers.getAmountWithCurrencyCode(value, undefined, undefined, true, showFractionalPart);

const Variations = ({
  data,
  handleButtonClick,
  ...rest
}) => {
  const [t] = useTranslation('main');

  const collapseItems = useMemo(() => {
    return data.map((item, key) => {
      const {logo, name} = item.service;
      const {actual, expected} = item;
      const amount =  (expected - actual) * -1;
      const isOverPriced = actual > expected;
      const isBelowPriced = expected > actual;
      const isNew = item?.is_new || false;
      let className = isOverPriced ? 'over-price' : isBelowPriced ? 'below-price' : '';
      let priceClassName = `price ${className}`;
      return {
        className,
        key,
        label: (
          <StyledVariationsItemLabel>
            <Space size='small' align='center'>
              <Avatar src={logo}>
                {textHelpers.getInitials(name)}
              </Avatar>
              <StyledVariationsItemLabelDetails>
                <StyledVariationsItemLabelDetailsTitle>
                  {name}
                </StyledVariationsItemLabelDetailsTitle>
                <StyledVariationsItemLabelDetailsDescription>
                  {isNew && <NewLabelSvg />}
                  <Space className={priceClassName} size='small' align='center'>
                    {isOverPriced && <OverPricedArrowSvg/>}
                    <span>{getAmountWithCurrencyCode(amount)}</span>
                  </Space>
                </StyledVariationsItemLabelDetailsDescription>
              </StyledVariationsItemLabelDetails>
            </Space>
          </StyledVariationsItemLabel>
        ),
        children: (
          <StyledVariationsItemDescription>
            <Space size={4} direction='vertical'>
              <StyledVariationsItemDescriptionItem>
                {t('actual')}:
                <span className={priceClassName}>
                  {getAmountWithCurrencyCode(actual)}
                </span>
              </StyledVariationsItemDescriptionItem>
              <StyledVariationsItemDescriptionItem>
                {t('previous')}:
                <span className='price'>
                  {getAmountWithCurrencyCode(expected)}
                </span>
              </StyledVariationsItemDescriptionItem>
            </Space>
            <Button
              onClick={(e) => handleButtonClick && handleButtonClick(e, item)}
              type='primary'
            >
              {t('showMore')}
            </Button>
          </StyledVariationsItemDescription>
        )
      }
    })
  }, [data, handleButtonClick, t]);

  return (
    <StyledVariations
      ghost={true}
      {...rest}
      items={collapseItems}
    />
  );
}

Variations.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      actual: PropTypes.number,
      budget: PropTypes.number,
      expected: PropTypes.number,
      service: PropTypes.shape({
        logo: PropTypes.string,
        name: PropTypes.string
      }),
      subscription_id: PropTypes.string
    })
  ),
  handleButtonClick: PropTypes.func
}

Variations.defaultProps = {
  data: []
}

export default Variations;
