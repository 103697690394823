import { useMemo } from 'react';
import {useSelector} from 'react-redux';
import {objectHelpers} from '../utils/helpers';

export const useIsEmployeeLoaded = () => {
  const currentUser = useSelector((state) => state.user.employee);

  return useMemo(() => !objectHelpers.isEmptyObject(currentUser), [currentUser]);
};

