import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import BalancePageHeader from '../BalancePageHeader';
import InvoiceForward from '../../InvoicesPage/InvoiceForward';
import PageDocumentDetails from '../../../PageDocumentDetails';
import { bankingActions } from '../../../../state/actions/banking.actions';
import { companyActions } from '../../../../state/actions/company.actions';

const TransactionsPageHeaderWrapper = ({
  getCards,
  getEmployees,
  getTags,
  isAdmin,
}) => {
  const [t] = useTranslation(['main', 'transactions']);

  const {breadcrumbs, pageTitle} = useMemo(() => {
    let breadcrumbsTitle = t('transactions');
    const pageTitle = t(`pageTitles.${isAdmin ? 'transactionsList' : 'allTransactions'}`);
    if (!isAdmin) breadcrumbsTitle = `${t('all')} ${breadcrumbsTitle}`;
    return {
      breadcrumbs: [{title: breadcrumbsTitle}],
      pageTitle
    }
  }, [isAdmin, t]);

  useEffect(() => {
    getCards();
    getEmployees();
    getTags();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <PageDocumentDetails title={pageTitle} />
      <BalancePageHeader breadcrumbs={breadcrumbs} />
      {!isAdmin && <InvoiceForward />}
    </>
  );
}

const mapStateToProps = state => {
  const isAdmin = state.user.isAdmin;
  return {
    isAdmin,
  }
}

const mapDispatchToProps = {
  getCards: bankingActions.getCardsList,
  getEmployees: companyActions.getEmployees,
  getTags: companyActions.getTags,
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPageHeaderWrapper);
