const billingBusinessTierConstants = Object.freeze({
  BASIC: 'basic'
});

const rewardStatusesConstants = Object.freeze({
  READY: 'READY',
  CLAIMED: 'CLAIMED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  VOIDED: 'VOIDED'
});

export {
  billingBusinessTierConstants,
  rewardStatusesConstants,
};

