import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Button, Form, Select} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Collapse, UnmountClosed} from 'react-collapse';
import moment from 'moment';
import {EuroIcon} from '../../../../icons';
import {
  subscriptionFormFields,
  subscriptionFormValues,
  subscriptionPaymentTypesConstants,
  subscriptionReviewTypesConstants,
} from '../../../../constants';
import {StyledAuthorizeSubscriptionModal,} from './StyledAuthorizeSubscriptionModal';
import {
  StyledAddSingleSubscriptionModalSegmented,
} from '../AddSingleSubscriptionModal/StyledAddSingleSubscriptionModal';
import CompanyTagSelect from '../../TransactionsPage/CompanyTagSelect/CompanyTagSelect';
import {StyledPaymentDetailsInput} from '../../SubscriptionPage/tabComponents/Overview/PaymentDetails/StyledPaymentDetails';
import {StyledBudgetDetailsFormSpace} from '../../SubscriptionPage/tabComponents/Overview/BudgetDetails/StyledBudgetDetails';
import HardCardLimitSwitch from '../HardCardLimitSwitch';
import IncreaseLimitAlert from '../IncreaseLimitAlert';
import SubscriptionInfo from '../../SubscriptionPage/SubscriptionInfo';
import SpinSmall from '../../../SpinSmall';
import {
  cardsHelpers,
  dateHelpers,
  BillOptions,
  CardLimitPeriodTypeOptions,
  PaymentOptions,
  SubscriptionReviewTypeOptions,
  formHelpers,
  objectHelpers,
  subscriptionsHelpers
} from '../../../../utils/helpers';

const {Item} = Form;

const {CARD, FREE} = subscriptionPaymentTypesConstants;
const {
  billedFieldName,
  budgetLimitFieldName,
  cardLimitPeriodFieldName,
  cardLimitFieldName,
} = subscriptionFormFields;

const {
  defaultBudgetLimitSubscription,
  defaultBilled,
  defaultCardLimitPeriod
} = subscriptionFormValues;

const defaultFormValues = {
  [billedFieldName]: defaultBilled,
  [budgetLimitFieldName]: undefined,
  [cardLimitFieldName]: undefined,
  [cardLimitPeriodFieldName]: defaultCardLimitPeriod,
  contract_renewal_date: undefined,
  owner: null,
  tags: []
};

const {getCardLimitTypeFromBilled} = cardsHelpers;

const AuthorizeSubscriptionModal = ({
  dispatch,
  loading,
  employees,
  employeeEmail,
  isEnabledBanking,
  subscriptionCardLimit,
  handleClose,
  handleOk,
  open,
  subscription,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [form] = Form.useForm();
  const [paymentType, setPaymentType] = useState(isEnabledBanking ? CARD : FREE);
  const [initialFormValues, setInitialFormValues] = useState(defaultFormValues);
  const [ownerOptions, setOwnerOptions] = useState([]);
  const [billOptions, ] = useState(BillOptions());
  const [cardLimitPeriodTypeOptions] = useState(CardLimitPeriodTypeOptions());
  const [defaultPaymentOptions,] = useState(PaymentOptions());
  const [defaultSubscriptionTypeOptions, ] = useState(SubscriptionReviewTypeOptions());
  const [subscriptionType, setSubscriptionType] = useState(subscriptionReviewTypesConstants.FREE);
  const [isSelectedHardLimit, setIsSelectedHardLimit] = useState(false);

  const paymentOptions = useMemo(() => {
    let options = subscriptionsHelpers.getPaymentOptionsWithTooltip({options: defaultPaymentOptions, translation: t});
    if (!isEnabledBanking) options = options.filter(o => o.value !== CARD);
    return options;
  }, [defaultPaymentOptions, isEnabledBanking, t]);

  const subscriptionTypeOptions = useMemo(() => {
    let options = [...defaultSubscriptionTypeOptions];
    if (!isEnabledBanking) options = options.filter(o => o.value !== subscriptionReviewTypesConstants.PAID);
    return options;
  }, [defaultSubscriptionTypeOptions, isEnabledBanking]);

  const isCardPaymentType = useMemo(() => paymentType === CARD, [paymentType]);

  const isPaidSubscriptionType = useMemo(() => subscriptionType === subscriptionReviewTypesConstants.PAID, [subscriptionType]);

  const budgetLimit = Form.useWatch(budgetLimitFieldName, {form});
  const billedLimitPeriod = Form.useWatch(billedFieldName, {form});
  const cardLimit = Form.useWatch(cardLimitFieldName, {form});

  const isTooHighLimit = useMemo(() => {
    let isHigh = false;
    if (isCardPaymentType && cardLimit) {
      isHigh = cardsHelpers.isTooHighLimit({
        limit: cardLimit,
        maxLimit: subscriptionCardLimit
      });
    }
    return isHigh;
  }, [cardLimit, isCardPaymentType, subscriptionCardLimit]);

  const disabledCreateButton = useMemo(() => loading || isTooHighLimit, [loading, isTooHighLimit]);

  useEffect(() => {
    form.setFieldValue('owner', employeeEmail);
  }, [employeeEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (open) {
      const formFieldValues = {
        ...defaultFormValues,
        owner: employeeEmail
      };
      setInitialFormValues(formFieldValues);
      form.setFieldsValue(formFieldValues);
      setPaymentType(isEnabledBanking ? CARD : FREE);
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const options = formHelpers.getEmployeeOptions({employees, employeeEmail, t});
    setOwnerOptions(options);
  }, [employees]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    subscriptionsHelpers.updateCardLimitOnBudgetChange({
      budgetLimit,
      cardLimit,
      isCardPaymentType,
      isSelectedHardLimit,
      form,
    });
  }, [budgetLimit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    subscriptionsHelpers.updateCardLimitPeriodOnBillingChange({
      billedLimitPeriod,
      isCardPaymentType,
      isSelectedHardLimit,
      form
    });
  }, [billedLimitPeriod]); // eslint-disable-line react-hooks/exhaustive-deps

  const trans = (key) => t(`subscriptions:${key}`);

  const formT = (key) => trans(`modal.addSingleSubscription.${key}`);

  const placeholderT = (key) => formT(`placeholder.${key}`);

  const tooltipT = (key) => trans(`tooltips.${key}`);

  const requiredRules = [{required: true, message: t('validation.fieldIsRequired')}]

  const footer = (
    <Button
      disabled={disabledCreateButton}
      onClick={() => !disabledCreateButton && form.submit()}
      size='large'
      type='primary'
    >
      {t('create')}
    </Button>
  );

  const onChangeTags = (tags) => {
    form.setFieldValue('tags', tags);
    setInitialFormValues({...initialFormValues, tags});
  }

  const onFieldsChange = (changedFields) => {
    const limitField = changedFields.find(({ name, errors }) =>
      (name.includes(cardLimitFieldName) || name.includes(budgetLimitFieldName)) && errors?.length
    )?.name[0];

    if (limitField) {
      form.setFields([{name: limitField?.name[0], errors: []}]);
    }
  }

  const handleChangeHardLimit = (value) => {
    setIsSelectedHardLimit(value);
    if (value) {
      form.setFieldsValue({
        [cardLimitFieldName]: budgetLimit,
        [cardLimitPeriodFieldName]: getCardLimitTypeFromBilled(billedLimitPeriod)
      })
    }
  }

  const handleAfterOpenChange = (open) => {
    if (!open) form.resetFields();
  }

  const handleSubmit = (data) => {
    if (isPaidSubscriptionType) {
      const validate = subscriptionsHelpers.validateFormLimitValues({
        budgetLimit,
        cardLimit,
        form,
        paymentType,
        translate: t
      });

      if (!validate) return;
    }

    const getFieldValue = (key, defaultValue) => objectHelpers.getObjProp(data, key, defaultValue) || defaultValue;
    const contractRenewalDate = data.contract_renewal_date;
    const {owner} = data;
    let formData = {
      owner: owner,
      payment_type: isPaidSubscriptionType ? paymentType : FREE,
      tags: data.tags
    }

    if (isPaidSubscriptionType) {
      formData = {
        ...formData,
        [budgetLimitFieldName]: getFieldValue(budgetLimitFieldName, defaultBudgetLimitSubscription),
        [billedFieldName]: getFieldValue(billedFieldName),
        contract_renewal_date: contractRenewalDate ? dateHelpers.getMomentUnixTimestamp(contractRenewalDate) : contractRenewalDate,
      }
      if (isCardPaymentType) {
        formData = {
          ...formData,
          [cardLimitFieldName]:getFieldValue(cardLimitFieldName, defaultBudgetLimitSubscription),
          [cardLimitPeriodFieldName]: getFieldValue(cardLimitPeriodFieldName, defaultCardLimitPeriod),
          card_owner: owner,
          expected_first_payment: dateHelpers.getMomentUnixTimestamp(moment())
        }
      }
    }

    handleOk && handleOk(formData);
  }

  const onClose = (e) => handleClose && handleClose(e);

  return (
    <StyledAuthorizeSubscriptionModal
      afterOpenChange={handleAfterOpenChange}
      forceRender
      footer={footer}
      onClose={onClose}
      open={open}
      title={`${trans('authorize')} ${trans('subscription')}`}
      width={472}
      {...rest}
    >
      <SpinSmall spinning={loading}>
        <Form
          initialValues={initialFormValues}
          onFieldsChange={onFieldsChange}
          onFinish={handleSubmit}
          layout='vertical'
          form={form}
          requiredMark={false}
        >
          <SubscriptionInfo
            data={subscription}
            enabledStatus={false}
          />
          <StyledAddSingleSubscriptionModalSegmented
            block={true}
            onChange={setSubscriptionType}
            options={subscriptionTypeOptions}
            size='large'
            value={subscriptionType}
          />
          <Item
            label={t('Owner')}
            name='owner'
          >
            <Select
              options={ownerOptions}
              size='large'
            />
          </Item>
          <Item
            label={t('costCenter')}
            name='tags'
            tooltip={{ icon: <InfoCircleOutlined /> }}
          >
            <CompanyTagSelect
              inputProps={{
                placeholder: placeholderT('costCenter'),
                size: 'large'
              }}
              onChange={onChangeTags}
              selected={initialFormValues.tags}
            />
          </Item>

          <UnmountClosed isOpened={isPaidSubscriptionType}>
            <Item
              label={t('paymentMethod')}
              required
            >
              <StyledAddSingleSubscriptionModalSegmented
                block={true}
                onChange={setPaymentType}
                options={paymentOptions}
                size='large'
                value={paymentType}
              />
            </Item>
            <StyledBudgetDetailsFormSpace size='middle'>
              <Item
                label={t('Budget')}
                name={budgetLimitFieldName}
                required
              >
                <StyledPaymentDetailsInput
                  addonBefore={<EuroIcon />}
                  min={1}
                  size='large'
                  type='number'
                />
              </Item>
              <Item
                label=' '
                name={billedFieldName}
                rules={requiredRules}
              >
                <Select
                  options={billOptions}
                  size='large'
                />
              </Item>
            </StyledBudgetDetailsFormSpace>

            <Collapse isOpened={isTooHighLimit}>
              <IncreaseLimitAlert
                limit={subscriptionCardLimit}
                handleBeforeRedirect={onClose}
              />
            </Collapse>

            <UnmountClosed isOpened={isCardPaymentType}>
              <Item>
                <HardCardLimitSwitch
                  checked={isSelectedHardLimit}
                  onChange={handleChangeHardLimit}
                  tooltipProps={{
                    title: <>{tooltipT('hardCardLimit1')}<br/>{tooltipT('hardCardLimit2')}</>
                  }}
                />
              </Item>
              <StyledBudgetDetailsFormSpace size='middle'>
                <Item
                  label={`${t('card')} ${t('limit')}`}
                  name={cardLimitFieldName}
                  required
                >
                  <StyledPaymentDetailsInput
                    addonBefore={<EuroIcon />}
                    min={1}
                    size='large'
                    type='number'
                  />
                </Item>
                <Item
                  label=' '
                  name={cardLimitPeriodFieldName}
                  rules={requiredRules}
                >
                  <Select
                    options={cardLimitPeriodTypeOptions}
                    size='large'
                  />
                </Item>
              </StyledBudgetDetailsFormSpace>
            </UnmountClosed>
          </UnmountClosed>
        </Form>
      </SpinSmall>
    </StyledAuthorizeSubscriptionModal>
  );
}

AuthorizeSubscriptionModal.propTypes = {
  subscription: PropTypes.object,
  loading: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOk: PropTypes.func
}

AuthorizeSubscriptionModal.defaultProps = {
  loading: false
}

const mapStateToProps = state => {
  const {isEnabledBanking} = state.banking;
  const {employees} = state.company;
  const {employee} = state.user;
  const {subscriptionCardLimit} = cardsHelpers.getPaymentCardLimit(state);
  return {
    employees,
    employeeEmail: employee.email,
    isEnabledBanking,
    subscriptionCardLimit
  }
}

export default connect(mapStateToProps, null)(AuthorizeSubscriptionModal);

