const BusinessServicesNotElsewhereClassifiedIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.1399 14.85C16.6964 14.625 17.6859 14.326 17.9839 14.687C18.3059 15.0775 17.8989 15.9255 17.5239 16.584"
          stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.89893 15.1115C6.77843 15.81 9.37593 16.879 12.1429 16.879C14.7809 16.879 16.3914 15.923 17.2264 15.339"
          stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M16.2011 6.53748H7.79907C7.52293 6.53748 7.29907 6.76133 7.29907 7.03748V12.1965C7.29907 12.4726 7.52293 12.6965 7.79907 12.6965H16.2011C16.4772 12.6965 16.7011 12.4726 16.7011 12.1965V7.03748C16.7011 6.76133 16.4772 6.53748 16.2011 6.53748Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M13.6637 6.53746V5.58396C13.6637 5.34527 13.5688 5.11635 13.4001 4.94756C13.2313 4.77878 13.0024 4.68396 12.7637 4.68396H11.2367C10.998 4.68396 10.7691 4.77878 10.6003 4.94756C10.4315 5.11635 10.3367 5.34527 10.3367 5.58396V6.53746"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default BusinessServicesNotElsewhereClassifiedIcon;
