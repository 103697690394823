import {InfoCircleOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
import {isValidPhoneNumber} from 'libphonenumber-js';
import legalForms from '../../i18n/legal_forms.json';
import {bankingDocumentTypes} from '../../constants';
import {TaxRegexp, ZipRegexp} from '../regexp';
import {formHelpers} from './form.helpers';
import {localizationHelpers} from './localization.helpers';
import {objectHelpers} from './object.helpers';
import {
  monthlyIncomeRangeOptions,
  occupationCategoryOptions,
  personalAssetsRangeOptions
} from '../options';

const {DRIVING_LICENSE, IDENTIFY_CARD, PASSPORT} = bankingDocumentTypes;

const {getOptionLabel} = objectHelpers;

const gObjProp = (data, key, defaultValue) => data ? data[key] || defaultValue : defaultValue;

export const kycHelpers = {
  getAcceptFileTypes: () => '.doc,.jpg,.jpeg,.png,.pdf',
  getCountrySelectProps: () => ({
    size: 'large',
    showSearch: true
  }),
  getDatePickerProps: (propName) => {
    let startDate = dayjs();
    let defaultPickerValue = undefined;
    if (propName === 'birthday') {
      startDate = startDate.subtract(20, 'year');
      defaultPickerValue = startDate;
    }
    return {
      defaultPickerValue,
      disabledDate: (current) => current.isAfter(startDate),
      format: 'DD/MM/YYYY',
      size: 'large'
    }
  },
  getDocumentsFormValues: (documents) => {
    let data = {};
    documents.forEach((d, index) => {
      data[`document-${index + 1}`] = d.uploaded;
    });
    return data;
  },
  getDocumentTypeId: (verifySelectedType) => {
    const documentTypes = {
      'driving-license': DRIVING_LICENSE,
      'id-card': IDENTIFY_CARD,
      'passport': PASSPORT
    }
    return documentTypes[verifySelectedType] || undefined
  },
  getVerifyDocumentType: (documentId) => {
    const documentTypes = {
      [DRIVING_LICENSE]: 'driving-license',
      [PASSPORT]: 'passport',
      [IDENTIFY_CARD]: 'id-card'
    }
    return documentTypes[documentId] || undefined
  },
  getKYCFormDetails: ({t, formKey, fullFormKey}) => {
    const trans = (key, params) => t(`${fullFormKey || `quickStart:kyc.steps.${formKey}`}.${key}`, params);

    const validationRequiredRules = [{required: true, message: t('main:validation.fieldIsRequired')}];
    const lengthFieldMessage = (value, type = 'min') => t(`main:validation.${type}LengthValidation`, {value});

    return {
      description: trans(`description`),
      info: (key, params) => trans(`info.${key}`, params),
      label: (key, params) => trans(`labels.${key}`, params),
      placeholder: (key, params) => trans(`placeholders.${key}`, params),
      lengthFieldMessage,
      validationPhoneRules: [
        {
          message: t('auth:validation.phoneIsIncorrect'),
          validator: (_, value) => isValidPhoneNumber(value) ? Promise.resolve() : Promise.reject()
        }
      ],
      validationPostCodeRules: [
        ...validationRequiredRules,
        {min: 4, message: lengthFieldMessage(4, 'min')},
        {max: 10, message: lengthFieldMessage(150, 'max')},
        {pattern: ZipRegexp, message: t('main:validation.zipCodeInvalid')}
      ],
      validationTaxRules: [
        ...validationRequiredRules,
        {min: 4, message: lengthFieldMessage(4, 'min')},
        {max: 18, message: lengthFieldMessage(18, 'max')},
        {pattern: TaxRegexp, message: t('main:validation.taxInvalid')}
      ],
      validationRequiredRules,
      title: trans(`title`),
      tooltip: (key) => ({ title: trans(`tooltips.${key}`), icon: <InfoCircleOutlined /> }),
    }
  },
  getTaxResidenceFormData: (fields) => {
    let taxPayerId = fields.tax_number;
    if (typeof taxPayerId === 'string') taxPayerId = taxPayerId.trim();
    return {
      country: fields.tax_residence.toUpperCase(),
      tax_payer_id: taxPayerId
    }
  },
  getUserCompanyFields: (legalPerson) => {
    const gObjProp = (key) => objectHelpers.getObjProp(legalPerson, key);
    return {
      address1: gObjProp('address1'),
      city: gObjProp('city'),
      country: gObjProp('country'),
      postcode: gObjProp('postcode'),
    }
  },
  isSupportedCountry: (countryCode) => {
    let supported = false;
    if (countryCode && typeof(countryCode) === 'string') {
      supported = (legalForms[countryCode.toUpperCase()] || []).length > 0;
    }
    return supported;
  },
  getLegalFormOptions: (countryCode) => {
    const items = countryCode ? legalForms[countryCode.toUpperCase()] || [] : [];
    const maxLengthName = 80;
    return items
      .filter(i => i.legal_form_api)
      .map(i => {
        const {legal_form_api: value} = i;
        let label = i.name;
        if (label.length > maxLengthName) {
          label = `${label.substring(0, maxLengthName)}...`;
        }
        return {
          label,
          value
        }
      });
  },
  getKYCInvitePageUrl: (key) => `${window.location.origin}/user-invite/?key=${key}`,
  getTaxNumberFormErrors: (response, t) => {
    return [{name: 'tax_number', errors: [response?.detail || t('main:serverErrorMessage')]}]
  },
  getUserCreateFormErrors: (response, t) => {
    let formErrors = formHelpers.getFormServerErrorFields(response);
    if (formErrors.length === 0 && typeof (response.detail) === 'object' && response.detail.length > 0) {
      formErrors = [{name: 'email', errors: [response.detail[0].msg || t('main:serverErrorMessage')]}]
    }
    return formErrors;
  },
  getFileFormErrors: ({name, response, t}) => {
    let errors = [];
    if (new Blob([response.config.data]).size > 5000000) {
      errors = [{name, errors: [t('main:supportingImageSizeError')]}]
    } else if (response.hasOwnProperty('message')) {
      errors = [{name, errors: [response.message]}];
    }
    return errors;
  },
  handleOnValuesChangeLRForm: ({allValues, changedFields, handleEnableSubmit}) => {
    const confirmFields = ['confirm_seen', 'confirm_accept'];
    const isChangedConfirmField = Object.keys(changedFields).some(item => confirmFields.includes(item))
    if (isChangedConfirmField && handleEnableSubmit) {
      const enable = confirmFields.map(field => allValues[field]).every(v => v === true);
      handleEnableSubmit(enable);
    }
  },
  getEmployeeDefaultFormValues: (employee) => {
    let defaultFormValues = {};
    if (employee) {
      defaultFormValues = {
        email: gObjProp(employee, 'email'),
      }
    }
    return defaultFormValues;
  },
  getTaxCodeLabelByCountryCode: ({t, taxResidence}) => {
    let label = t('main:taxCode');
    const taxCodeCountryLabel = localizationHelpers.getTaxCodeByCountry(taxResidence);
    if (taxCodeCountryLabel) label = `${label} - ${taxCodeCountryLabel}`;
    return label;
  },
  getOccupationCategory: (value) => getOptionLabel(occupationCategoryOptions, value),
  getMonthlyIncomeRange: (value) => getOptionLabel(monthlyIncomeRangeOptions, value),
  getPersonalAssetsRangeLabel: (value) => getOptionLabel(personalAssetsRangeOptions, value),
}