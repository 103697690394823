import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { firebaseAnalytics, firebaseEvents } from '../../snippets/firebase';
import { initializePendo } from '../../snippets/pendo';
import { useAvailableDataLoading, useCurrentCompany } from '../../hooks';
import { cardsHelpers, locationHelpers, objectHelpers, systemHelpers } from '../../utils/helpers';
import { bankingActions } from '../../state/actions/banking.actions';
import { companyActions } from '../../state/actions/company.actions';
import { mainActions } from '../../state/actions/main.actions';
import { overviewActions } from '../../state/actions/overview.actions';
import { transactionActions } from '../../state/actions/transaction.actions';
import { userActions } from '../../state/actions/user.actions';
import { history } from '../../state/history';
import { availableModulesConstants, localStorageKeysConstants } from '../../constants';
import routesList from '../../routes/routes.json';

const isProduction = systemHelpers.isProduction();

const {CARDS, TRANSACTIONS, SUBSCRIPTIONS} = availableModulesConstants;

let interval = null;

const UserCompanyInitializer = ({
  employee,
  isAdmin,
  isAuthenticated,
  user,

  getCompanyCardState,
  getCompanyCurrentCurrency,
  getCompanyDetails,
  getEmployeePopupSettings,
  getEmployeeProfile,
  getLastTransaction,
  getNotificationList,
  getSubscriptionsTotal,
  getUserAccessToCards,
  getUserCardList,
  getUserProfile,
  markedCheckedAuth,
  setAvailableModules,
  updateCardVerificationStatus,

  handleDisableDataLoading,
}) => {
  const [startPathname, setStartPathname] = useState(null);

  const availableDataLoading = useAvailableDataLoading();

  const currentCompany = useCurrentCompany();

  const companyId = useMemo(() => currentCompany?.id, [currentCompany]);

  const clearNotificationInterval = () => {
    if (interval) clearInterval(interval);
  }

  useEffect(() => {
    if (isAuthenticated) {
      firebaseAnalytics.setUserProperties({
        email: objectHelpers.getObjProp(user, 'email'),
        name: objectHelpers.getObjProp(user, 'full_name')
      });
      firebaseAnalytics.logEvent(firebaseEvents.USER_AUTHENTICATED);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (!isProduction) return;

    if (isAuthenticated) {
      initializePendo({
        company: currentCompany,
        user: employee
      });
    }
  }, [currentCompany?.id, employee, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAuthenticated && typeof startPathname === 'string' && startPathname?.trim()) {
      history.push(startPathname);
    }
  }, [isAuthenticated, startPathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isAuthenticated) {
      clearNotificationInterval();
      return;
    }

    const { pathname } = history.location;
    const isAuthRoute = [routesList.signUp, routesList.login, routesList.forgotPin].includes(pathname);

    if (!isAuthRoute) {
      history.push(routesList.overview);
      setStartPathname(pathname);
    }
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (companyId) {
      getLastTransaction();
      getNotificationList();
      getEmployeePopupSettings();
      getCompanyCurrentCurrency();
      clearNotificationInterval();
      interval = setInterval(getNotificationList, 60000);
    }
  }, [currentCompany?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const token = localStorage.getItem(localStorageKeysConstants.TOKEN);
    if (!token) {
      markedCheckedAuth();
      return;
    }

    const { pathname } = history.location;
    const hideLoader = !pathname.startsWith('/email/verification');
    const isPublicPath = locationHelpers.isActivePathInList(
      pathname,
      systemHelpers.getPublicPaths()
    );

    if (isPublicPath) {
      handleDisableDataLoading?.();
      return;
    }

    if (availableDataLoading) getEmployeeProfile();
    getUserProfile(hideLoader);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  // initialize banking user
  useEffect(() => {
    if (objectHelpers.isEmptyObject(employee)) return;

    const {id} = employee;
    const bankingModules = [CARDS, SUBSCRIPTIONS];

    const checkUserAccess = () => getUserAccessToCards(id);

    const handleCompanyState = (companyState) => {
      const isEnabledBanking = cardsHelpers.checkIsAvailableBanking(companyState);
      if (isEnabledBanking) bankingModules.push(TRANSACTIONS);
      if (isAdmin) {
        setAvailableModules(bankingModules);
        isEnabledBanking && checkUserAccess();
        return;
      }

      getCardList(id, handleCardListResponse);
    };

    const handleCardListResponse = (data) => {
      if (data.length > 0) {
        setAvailableModules(bankingModules);
        checkUserAccess();
        return;
      }

      // If no cards exist, proceed with verification
      finishCardVerificationProcess();
      getSubscriptionsTotal(null, handleSubscriptionsResponse);
    };

    const handleSubscriptionsResponse = (subscriptions) => {
      if ((subscriptions?.total || 0) > 0) {
        setAvailableModules([SUBSCRIPTIONS]);
      }
    };

    getCompanyCardState(handleCompanyState, () => {
      finishCardVerificationProcess();
      setAvailableModules([]);
    });

    getCompanyDetails();
  }, [employee]); // eslint-disable-line react-hooks/exhaustive-deps

  const finishCardVerificationProcess = () => updateCardVerificationStatus(false, null, false);

  const getCardList = (id, successFunc, errorFunc) => {
    getUserCardList(
      id,
      null,
      (data) => successFunc && successFunc(data),
      (data) => errorFunc && errorFunc(data)
    );
  }

  return (
    <></>
  );
};

const mapStateToProps = ({ user }) => ({
  employee: user.employee,
  isAdmin: user.isAdmin,
  isAuthenticated: user.isAuth,
  user: user.user,
});

const mapDispatchToProps = {
  getCompanyCardState: bankingActions.getCompanyState,
  getCompanyCurrentCurrency: companyActions.getCompanyCurrentCurrency,
  getCompanyDetails: bankingActions.getCompanyDetails,
  getEmployeePopupSettings: userActions.getEmployeePopupSettings,
  getEmployeeProfile: userActions.getEmployeeProfile,
  getLastTransaction: transactionActions.getLastTransaction,
  getNotificationList: userActions.getNotificationList,
  getSubscriptionsTotal: overviewActions.getOverviewSubscriptionsTotals,
  getUserAccessToCards: bankingActions.getUserAccessToCards,
  getUserCardList: bankingActions.getUserCardList,
  getUserProfile: userActions.getUserProfile,
  markedCheckedAuth: userActions.markedCheckedAuth,
  setAvailableModules: mainActions.setAvailableModules,
  updateCardVerificationStatus: bankingActions.updateCardVerificationStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCompanyInitializer);
