import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import VendorDetails from '../../VendorDetails';
import SubscriptionTab from '../SubscriptionTab';
import {subscriptionsHelpers} from '../../../../../utils/helpers';
import {subscriptionViewTypesConstants} from '../../../../../constants';

const {DELETED} = subscriptionViewTypesConstants;

const defaultSelectedColumns = ['status', 'vendor'];

const DeletedTab = ({
  filterProps,
  tableProps,
  handleAction,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const navigate = useNavigate();
  const [selectedColumns, setSelectedColumns] = useState(subscriptionsHelpers.getDefaultTableColumns(DELETED, defaultSelectedColumns));

  const columns = [
    {
      dataIndex: 'vendor',
      title: t('product'),
      render: (_, record) => <VendorDetails data={record} />,
      sorter: true
    },
    {
      dataIndex: 'status',
      title: t('status'),
      render: (_, record) => subscriptionsHelpers.getSubscriptionStatusMark(record),
      sorter: true
    },
  ];

  const availableColumns = useMemo(() => subscriptionsHelpers.getAvailableColumns(columns, selectedColumns), [selectedColumns]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnRowClick = (e, record) => navigate(subscriptionsHelpers.getSubscriptionDetailsPageUrl(record));

  const onChangeSelectedItems = (value) => {
    setSelectedColumns(value);
    subscriptionsHelpers.storeTabColumns(DELETED, value);
  }

  const getRightSideContent = () => subscriptionsHelpers.getSettingsRightSideContent({
    columns,
    filterProps,
    onChange: onChangeSelectedItems,
    selectedKeys: selectedColumns
  });

  return (
    <SubscriptionTab
      {...rest}
      columns={availableColumns}
      filterProps={{
        ...filterProps,
        rightSideContent: getRightSideContent()
      }}
      tableProps={{
        ...tableProps,
        onRow: (record) => ({
          onClick: (e) => handleOnRowClick(e, record)
        })
      }}
    />
  );
}

DeletedTab.propTypes = {
  data: PropTypes.array,
  filterProps: PropTypes.object,
  tableProps: PropTypes.object,
  handleAction: PropTypes.func
}

DeletedTab.defaultProps = {
  data: []
}

export default DeletedTab;
