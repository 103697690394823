import styled from 'styled-components';
import Table from '../../../../../Table';
import {bordersStyles} from '../../../../../../utils/styles';

const StyledTransactionTable = styled(Table)`
  .transaction-type {
    align-items: center;
    display: flex;

    svg {
      height: 24px;
      width: 24px;

      path {
        fill: ${props => props.theme.colors.neutral_6};
      }
    }
  }

  .transaction-icon {
    display: inline-flex;
    svg {
      height: 24px;
      width: 24px;

      path {
        &:not([fill-rule="evenodd"]) {
          fill: ${props => props.theme.colors.neutral_6};
        }
      }
    }
  }
    
  .returned-price {
    color: ${props => props.theme.colors.neutral_7};
    text-decoration: line-through;
  }
`;

const StyledTableContainer = styled.div`
  ${bordersStyles.default};
  
  background-color: ${props => props.theme.colors.neutral_1};
  padding: 24px;
`;

const TotalSpend = styled.div`
  margin-bottom: 24px;
  font-size: 16px;
  color: ${props => props.theme.colors.neutral_7};

  .price {
    color: ${props => props.theme.colors.neutral_10};
  }
`;

export {
  StyledTransactionTable,
  StyledTableContainer,
  TotalSpend
}
