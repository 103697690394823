import {cardLimitPeriodConstants} from './cards.constants';

const subscriptionBillTypesConstants = Object.freeze({
  YEARLY: 0,
  MONTHLY: 1,
  PASS_AS_YOU_GO: 2
});

const subscriptionDurationTypesConstants = Object.freeze({
  YEARS: 0,
  MONTHS: 1
});

const subscriptionPaymentTypesConstants = Object.freeze({
  FREE: 0,
  CARD: 1,
  NOT_APPLICABLE: 2,
  WIRE: 3
});

const subscriptionReviewTypesConstants = Object.freeze({
  FREE: 0,
  PAID: 1,
});

const subscriptionViewTypesConstants = Object.freeze({
  DELETED: 0,
  AUTHORIZED: 1,
  NEED_REVIEW: 2,
  UNMANAGED: 3,
  RESTRICTED: 4,
  ALL: 5
});

const subscriptionStatusesConstants = Object.freeze({
  PENDING: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  TERMINATED: 3,
  DELETED: 4,
  TRIAL: 5,
  UNTRACKED: 6,
  AUDITED: 7,
  CARD_ERROR: 8,
  UNMANAGED: 9,
  RESTRICTED: 10,
  ABOUT_TO_RENEW: 11,
});

const subscriptionBudgetStatusesConstants = Object.freeze({
  ACTIVE: 'active',
  OVERBUDGET: 'over-budget'
});

const subscriptionActionConstants = Object.freeze({
  ACTIVATE_DELETED: 'ACTIVATE_DELETED',
  ACTIVATE_PAUSED: 'ACTIVATE_PAUSED',
  AUTHORIZE: 'AUTHORIZE',
  CARD_RETRY: 'CARD_RETRY',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  IGNORE: 'IGNORE',
  PAUSE: 'PAUSE',
  RESTRICT: 'RESTRICT',
});

const subscriptionRowActionConstants = Object.freeze({
  AUTHORIZE: 'AUTHORIZE',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  IGNORE: 'IGNORE',
  RESTRICT: 'RESTRICT',
  VIEW: 'VIEW',
});

const subscriptionFormFields = {
  billedFieldName: 'billed',
  budgetLimitFieldName: 'custom_limit',
  cardLimitFieldName: 'budget_limit',
  cardLimitPeriodFieldName: 'card_limit_period',
  datePickerFormat: 'DD/MM/YYYY',
  hardLimitFieldName: 'hard_limit',
  limitPeriodShortFieldName: 'limit_period',
  paymentTypeFieldName: 'payment_type',
  statusFieldName: 'status'
}

const subscriptionFormValues = {
  defaultBilled: subscriptionBillTypesConstants.MONTHLY,
  defaultBudgetLimitCard: 5000,
  defaultBudgetLimitSubscription: 10000,
  defaultCardLimitPeriod: cardLimitPeriodConstants.MONTH
}

const subscriptionContractRenewalPeriodTypesConstants = Object.freeze({
  ONE_MONTH: 1,
  THREE_MONTHS: 3,
  SIX_MONTHS: 6,
  TWELVE_MONTHS: 12,
  EIGHTEEN_MONTHS: 18,
  TWENTY_FOUR_MONTHS: 24,
  THIRTY_SIX_MONTHS: 36,
});

const subscriptionContractRenewalReminderTypesConstants = Object.freeze({
  NEVER: 0,
  TWO_WEEKS: 5,
  ONE_MONTH: 1,
  TWO_MONTHS: 2,
  THREE_MONTHS: 3,
  SIX_MONTHS: 6,
});

const subscriptionPaymentFrequencyTypesConstants = Object.freeze({
  ONE_MONTH: 1,
  THREE_MONTHS: 3,
  SIX_MONTHS: 6,
  TWELVE_MONTHS: 12,
});

export {
  subscriptionBillTypesConstants,
  subscriptionDurationTypesConstants,
  subscriptionFormFields,
  subscriptionFormValues,
  subscriptionPaymentTypesConstants,
  subscriptionReviewTypesConstants,
  subscriptionViewTypesConstants,
  subscriptionStatusesConstants,
  subscriptionBudgetStatusesConstants,
  subscriptionActionConstants,
  subscriptionRowActionConstants,
  subscriptionContractRenewalPeriodTypesConstants,
  subscriptionContractRenewalReminderTypesConstants,
  subscriptionPaymentFrequencyTypesConstants,
};
