import React, {useEffect, useState} from 'react';
import {Input} from 'antd';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {StyledTagManager, StyledTag} from './StyledTagManager';
import {systemHelpers} from '../../../../../utils/helpers';
import {companyActions} from '../../../../../state/actions/company.actions';

const logEvent = (eventName, tagName) => systemHelpers.logEvent(eventName, {name: tagName});

const TagManager = ({
  companyId,
  description,
  inputPlaceholder,
  edit,
  logEventName,
  tagFamily,
  title,

  dispatch,
  addTags,
  deleteTags,
  getTags,

  ...rest
}) => {
  const [tagName, setTagName] = useState('');
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);

  const handleLoadTags = () => {
    getTags({
      query: {tag_family: tagFamily},
      successCallback: setTags
    });
  }

  useEffect(() => {
    if (companyId) {
      handleLoadTags();
    }
  }, [companyId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddTag = () => {
    if (!edit) return;
    setLoading(true);
    addTags({
      data: {
        tags: [{
          tag_name: tagName,
          tag_family: tagFamily,
        }]
      },
      successCallback: () => {
        handleLoadTags();
        setLoading(false);
        setTagName('');

        const eventName = logEventName?.add;
        if (eventName) logEvent(eventName, tagName);
      },
      errorCallback: () => setLoading(false)
    });
  }

  const handleDeleteTag = (tag) => {
    if (!edit) return;
    deleteTags({
      ids: [tag.id],
      successCallback: () => {
        handleLoadTags();

        const eventName = logEventName?.delete;
        if (eventName) logEvent(eventName, tag.tag_name);
      }
    });
  }

  const onChange = (e) => setTagName(e.target.value);

  const onKeyDown = (e) => {
    if (e.keyCode === 13 && tagName) handleAddTag();
  }

  return (
    <StyledTagManager
      title={title}
      {...rest}
    >
      {description && <p>{description}</p>}
      <Input
        disabled={!edit || loading}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={inputPlaceholder}
        value={tagName}
        size='large'
      />
      <div className='tags-list'>
        {tags.map(tag => (
          <StyledTag
            closable={edit}
            key={tag.id}
            onClose={() => handleDeleteTag(tag)}
          >
            {tag.tag_name}
          </StyledTag>
        ))}
      </div>
    </StyledTagManager>
  );
}

TagManager.propTypes = {
  description: PropTypes.string,
  edit: PropTypes.bool,
  inputPlaceholder: PropTypes.string,
  logEventName: PropTypes.shape({
    add: PropTypes.string,
    delete: PropTypes.string
  }),
  tagFamily: PropTypes.string,
  title: PropTypes.string,
}

TagManager.defaultProps = {
  edit: true
}

const mapStateToProps = state => {
  const {id} = state.company;
  return {
    companyId: id,
  }
}

const mapDispatchToProps = {
  addTags: companyActions.addTags,
  deleteTags: companyActions.deleteTags,
  getTags: companyActions.getTags,
}

export default connect(mapStateToProps, mapDispatchToProps)(TagManager);
