import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import UnmatchedInvoicesModal from '../pages/InvoicesPage/UnmatchedInvoicesModal';
import InvoiceMissingModal from '../pages/TransactionsPage/InvoiceMissingModal';
import routes from '../../routes/routes.json';
import {popupSettingsConstants, systemHashKeysConstants} from '../../constants';
import {mainActions} from '../../state/actions/main.actions';
import {userActions} from '../../state/actions/user.actions';

const {OPEN_BANK_ACCOUNT_DETAILS, TRANSACTIONS_INVOICE_MISSING, UNMATCHED_INVOICES} = systemHashKeysConstants;

const defaultModalProps = {loading: false, open: false};

const HashWindowManager = ({
  isAuthenticated,
  children,
  companyState,
  popupSettings,

  displayWalletDetailsInitially,
  getEmployeePopupSettings,
  updateEmployeePopupSettings,
}) => {
  const navigate = useNavigate();
  const [unmatchedInvoicesModalProps, setUnmatchedInvoicesModalProps] = useState(defaultModalProps);
  const [transactionsInvoiceMissingModalProps, setTransactionsInvoiceMissingModalProps] = useState(defaultModalProps);

  const { hash, search } = useLocation();

  const handleOpenModal = (setter, path) => {
    if (!isAuthenticated) return;

    setter?.({ ...defaultModalProps, open: true });
    path && navigate(path);
  }

  useEffect(() => {
    if (hash) {
      const hashCallbacks = {
        [OPEN_BANK_ACCOUNT_DETAILS]: handleOpenBankAccountDetails,
        [TRANSACTIONS_INVOICE_MISSING]: handleTransactionsInvoiceMissing,
        [UNMATCHED_INVOICES]: handleUnmatchedInvoices,
      }
      if (hashCallbacks.hasOwnProperty(hash)) {
        hashCallbacks[hash](hash);
      }
    }
  }, [hash, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!popupSettings) return; // Exit if popupSettings is null/undefined

    for (const key in popupSettings) {
      if (popupSettings[key]) {
        const hashKey = Object.entries(popupSettingsConstants).find(([_, value]) => value === key)?.[0];
        const hashValue = systemHashKeysConstants[hashKey];

        if (hashValue) {
          navigate(`/${hashValue}`);
          return; // Exit early after first valid navigation
        }
      }
    }
  }, [popupSettings]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCancelInvoiceMissingModal = () => setTransactionsInvoiceMissingModalProps(defaultModalProps);

  const handleCancelUnmatchedInvoicesModal = () => setUnmatchedInvoicesModalProps(defaultModalProps);

  const handleTransactionsInvoiceMissing = () => {
    const queryParam = search ? `${search}&has_invoice=false` : '?has_invoice=false';
    handleOpenModal(setTransactionsInvoiceMissingModalProps, `${routes.transactionsList}${queryParam}`);
    updatePopupState([popupSettingsConstants.TRANSACTIONS_INVOICE_MISSING, popupSettingsConstants.UNMATCHED_INVOICES]);
  }

  const handleUnmatchedInvoices = () => {
    handleOpenModal(setUnmatchedInvoicesModalProps, `${routes.invoicesList}${search}#unmatched`);
    updatePopupState([popupSettingsConstants.UNMATCHED_INVOICES]);
  }

  const handleOpenBankAccountDetails = () => {
    displayWalletDetailsInitially();
    navigate('/');
  }

  const updatePopupState = (keys = []) => {
    updateEmployeePopupSettings({
      data: {'popup_settings': keys},
      successCallback: getEmployeePopupSettings
    });
  }

  const isOpen = (getter) => companyState && getter?.open;

  return (
    <>
      {children}

      <InvoiceMissingModal
        {...transactionsInvoiceMissingModalProps}
        onCancel={handleCancelInvoiceMissingModal}
        onOk={handleCancelInvoiceMissingModal}
        open={isOpen(transactionsInvoiceMissingModalProps)}
      />

      <UnmatchedInvoicesModal
        {...unmatchedInvoicesModalProps}
        onCancel={handleCancelUnmatchedInvoicesModal}
        onOk={handleCancelUnmatchedInvoicesModal}
        open={isOpen(unmatchedInvoicesModalProps)}
      />
    </>
  );
};

const mapStateToProps = ({banking, user}) => ({
  companyState: banking?.companyState,
  isAuthenticated: user?.isAuth,
  popupSettings: user?.popupSettings,
});

const mapDispatchToProps = {
  displayWalletDetailsInitially: mainActions.displayWalletDetailsInitially,
  getEmployeePopupSettings: userActions.getEmployeePopupSettings,
  updateEmployeePopupSettings: userActions.updateEmployeePopupSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(HashWindowManager);
