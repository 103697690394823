const AirlinesIcon = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.16691 5.50036L4.66664 3.99982C4.66664 3.99982 10.1665 5.83336 13.3336 7.66691L16.86 4.14055C17.3861 3.61445 18.0554 3.24955 18.7934 3.14891C19.3563 3.072 20.0468 3 20.667 3L21 3.333C21 3.95236 20.928 4.64373 20.8511 5.20664C20.7505 5.94464 20.3855 6.61391 19.8595 7.14082L16.3331 10.6672C18.1666 13.8335 20.0002 19.3334 20.0002 19.3334L18.4996 20.8331C18.4996 20.8331 15.5002 16.5 12.9998 13.9996C12.9998 13.9996 11.1475 15.186 8.33373 18.0005C8.33373 18.0005 8.66673 19.3334 8.66673 20.667L8.33373 21C8.33373 21 7.00009 19.3334 5.83336 18.1666C4.66664 16.9999 3 15.6671 3 15.6671L3.333 15.3333C4.66664 15.3333 6.00027 15.6663 6.00027 15.6663C8.814 12.8525 10.0004 11.0002 10.0004 11.0002C7.5 8.49982 3.16691 5.50036 3.16691 5.50036Z"
      stroke="black" strokeWidth="0.5"/>
  </svg>

);

export default AirlinesIcon;
