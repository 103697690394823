import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {StyledInvoiceForward, StyledCopyField} from './StyledInvoiceForward';
import Spin from '../../../Spin';
import {alertActions} from '../../../../state/actions/alert.actions';
import {companyActions} from '../../../../state/actions/company.actions';
import {transactionsHelpers} from '../../../../utils/helpers';

const {getInvoiceEmail} = transactionsHelpers;

const defaultEmailProps = {
  loading: true,
  value: undefined
}

const InvoiceForward = ({
  dispatch,
  getCompanyInvoiceEmail,
  invoiceEmail,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'invoices']);
  const [emailProps, setEmailProps] = useState(defaultEmailProps);

  useEffect(() => {
    if (invoiceEmail) {
      setEmailProps({...emailProps, loading: false, value: invoiceEmail});
    } else {
      setEmailProps({...emailProps, loading: true});
      getCompanyInvoiceEmail(
        (settings) => {
          setEmailProps({
            ...emailProps,
            value: getInvoiceEmail(settings),
            loading: false
          })
        },
        () => setEmailProps({...defaultEmailProps, loading: false})
      )
    }
  }, [invoiceEmail, getCompanyInvoiceEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCopy = () => alertActions.success(`${t('copied')}!`);

  const {loading, value: email} = emailProps;

  return (
    <Spin
      size='xsmall'
      spinning={emailProps.loading}
    >
      <StyledInvoiceForward
        {...rest}
      >
        {!loading && (
          <>
            {email ? (
              <>
                <p>{t('invoices:invoiceForwardingDescription')}:</p>
                <StyledCopyField
                  onCopy={onCopy}
                  value={email}
                />
              </>
            ) : (
              <p>{t('invoiceEmailEmptyDescription')}</p>
            )}
          </>
        )}
      </StyledInvoiceForward>
    </Spin>
  );
}

const mapDispatchToProps = {
  getCompanyInvoiceEmail: companyActions.getCompanyInvoiceEmail,
}

const mapStateToProps = state => {
  return {
    invoiceEmail: getInvoiceEmail(state.company.settings)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceForward);
