import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Form, Select} from 'antd';
import {Collapse} from 'react-collapse';
import {StyledBudgetDetails, StyledBudgetDetailsFormSpace} from './StyledBudgetDetails';
import {StyledPaymentDetailsInput} from '../PaymentDetails/StyledPaymentDetails';
import HardCardLimitSwitch from '../../../../SubscriptionsPage/HardCardLimitSwitch';
import DetailsTable from '../../../../TransactionsPage/DetailsTable';
import {
  subscriptionStatusesConstants,
  subscriptionFormFields
} from '../../../../../../constants';
import {EuroIcon} from '../../../../../../icons';
import {
  BillOptions,
  objectHelpers,
  subscriptionsHelpers
} from '../../../../../../utils/helpers';

const {Item} = Form;

const {INACTIVE} = subscriptionStatusesConstants;
const {billedFieldName, budgetLimitFieldName, hardLimitFieldName} = subscriptionFormFields;

const cardRelatedFields = [];

const BudgetDetails = ({
  defaultHardLimitValue,
  edit,
  enabledHardLimit,
  onSubmit,
  subscription,
  dispatch,
  ...rest
}) => {
  const [t] = useTranslation(['main', 'subscriptions']);
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const [billOptions, ] = useState(BillOptions());
  const [initialFormValues, setInitialFormValues] = useState({
    [billedFieldName]: '',
    [budgetLimitFieldName]: ''
  });
  const [isSelectedHardLimit, setIsSelectedHardLimit] = useState(false);

  const gDataProp = (subscription, key, defaultValue) => objectHelpers.getObjProp(subscription, key, defaultValue);

  const trans = (key) => t(`subscriptions:${key}`);

  const tooltipT = (key) => trans(`tooltips.${key}`);

  const statusCode = gDataProp(subscription, 'status');
  const isPaused = statusCode === INACTIVE;

  useEffect(() => {
    const fieldValues = {
      ...initialFormValues,
      [billedFieldName]: gDataProp(subscription, billedFieldName),
      [budgetLimitFieldName]: gDataProp(subscription, budgetLimitFieldName) || 0
    };
    setInitialFormValues(fieldValues);
    form.setFieldsValue(fieldValues);
    isEditMode && setIsEditMode(false);
  }, [subscription]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => setIsSelectedHardLimit(defaultHardLimitValue), [defaultHardLimitValue]);

  const isEnableEdit = subscriptionsHelpers.isEnablePanelEdit(statusCode, edit);

  const disabledField = (value) => isPaused ? cardRelatedFields.includes(value) : false;

  const details = [
    {
      key: 'value',
      label: t('value'),
      value: subscriptionsHelpers.getBudgetBilledValue({t, data: initialFormValues})
    }
  ];

  const requiredErrorMessage = t('validation.fieldIsRequired');

  const handleOnCancel = () => setIsEditMode(false);

  const handleOnSave = () => form.submit();

  const handleSubmit = (fields) => {
    if (onSubmit) {
      onSubmit(
        { ...fields, [hardLimitFieldName]: isPaused || !enabledHardLimit ? false : isSelectedHardLimit},
        handleOnCancel,
        handleOnCancel
      );
    } else {
      handleOnCancel();
    }
  }

  const extra = subscriptionsHelpers.getDetailsFormExtra(({t, isEnableEdit, isEditMode, setIsEditMode, handleOnSave}));

  return (
    <StyledBudgetDetails
      title={t('Budget')}
      {...rest}
      extra={extra}
    >
      <Form
        className={!isEditMode && 'd-none'}
        initialValues={initialFormValues}
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <StyledBudgetDetailsFormSpace size='middle'>
          <Item
            label={trans('budgetValue')}
            name={budgetLimitFieldName}
            required
          >
            <StyledPaymentDetailsInput
              addonBefore={<EuroIcon />}
              disabled={disabledField(budgetLimitFieldName)}
              min={1}
              size='large'
              type='number'
            />
          </Item>
          <Item
            label=' '
            name={billedFieldName}
            rules={[{required: true, message: requiredErrorMessage}]}
          >
            <Select
              disabled={disabledField(billedFieldName)}
              options={billOptions}
              size='large'
            />
          </Item>
        </StyledBudgetDetailsFormSpace>
        <Collapse isOpened={enabledHardLimit}>
          <HardCardLimitSwitch
            checked={isSelectedHardLimit}
            onChange={setIsSelectedHardLimit}
            switchProps={{disabled: isPaused}}
            tooltipProps={{
              title: <>{tooltipT('hardCardLimit1')}<br/>{tooltipT('hardCardLimit2')}</>
            }}
          />
        </Collapse>
      </Form>
      <DetailsTable
        className={isEditMode && 'd-none'}
        data={details}
      />
    </StyledBudgetDetails>
  );
}

BudgetDetails.propTypes = {
  card: PropTypes.object,
  edit: PropTypes.bool,
  enabledHardLimit: PropTypes.bool,
  subscription: PropTypes.object,
  onSubmit: PropTypes.func
}

BudgetDetails.defaultProps = {
  edit: true,
  enabledHardLimit: false,
}

export default BudgetDetails;
