import {
  localStorageKeysConstants,
  TRZ_TERMS,
} from '../../constants';
import {firebaseAnalytics} from '../../snippets/firebase';
import {helpers as servicesHelpers} from '../../state/services/helpers';
import {objectHelpers} from './object.helpers';
import routes from '../../routes/routes.json';
import routesList from '../../routes/routes.json';

const {COMPANY_ID, TOKEN} = localStorageKeysConstants;

export const systemHelpers = {
  checkIfAvailableFeature: (feature) => {
    const availableFeatures = process.env.REACT_APP_AVAILABLE_FEATURES;
    if (availableFeatures) {
      const features = process.env.REACT_APP_AVAILABLE_FEATURES.split(',');
      return features.includes(feature);
    } else {
      return false;
    }
  },
  getMobileAppPath: (path) => {
    const releaseChannel = process.env.REACT_APP_MOBILE_APP_RELEASE_CHANNEL;
    const additionalPath = releaseChannel ? `?release-channel=${releaseChannel}` : '';
    const link = process.env.REACT_APP_MOBILE_APP_LINK;
    const linkPath = `/${path}${additionalPath}`;
    return link ? `${link}/--${linkPath}` : `h1card:/${linkPath}`;
  },
  getWebsocketUrl: (variant) => {
    const socketUrls = {
      authentication: process.env.REACT_APP_AUTHENTICATION_WEBSOCKET_ENDPOINT,
      backend: process.env.REACT_APP_WEBSOCKET_ENDPOINT
    }
    return socketUrls[variant] || undefined;
  },
  getAuthDetails: () => ({
    companyId: localStorage.getItem(COMPANY_ID),
    token: objectHelpers.getObjProp(JSON.parse(localStorage.getItem(TOKEN)), 'accessToken')
  }),
  getWebsocketOptions: () => ({
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  }),
  checkAvailableTokenTTL: (token, needParse = true) => {
    const data = needParse ? servicesHelpers.parseJWT(token) : token;
    const ttl = objectHelpers.getObjProp(data, 'token_ttl', 0);
    return ttl ? new Date().getTime() < ttl * 1000 : false;
  },
  getTrzTermsUrl: (locale) => {
    const defaultLink = TRZ_TERMS.default;
    let termsLink;
    if (locale && typeof locale === 'string') {
      locale = locale.toUpperCase();
      termsLink = TRZ_TERMS.hasOwnProperty(locale) ? TRZ_TERMS[locale] : TRZ_TERMS.default
    } else {
      termsLink = defaultLink;
    }
    return termsLink;
  },
  logEvent: (eventName, eventParams) => firebaseAnalytics.logEvent(eventName, eventParams),
  getUserBreadcrumbs: ({defaultBreadcrumbs, isAdmin, isEnabledUserOverview, t}) => {
    let breadcrumbs = [];
    if (isAdmin) {
      breadcrumbs = defaultBreadcrumbs;
    } else if (defaultBreadcrumbs.length > 1) {
      breadcrumbs = [...defaultBreadcrumbs.slice(1)];
      if (isEnabledUserOverview) {
        breadcrumbs = [
          {title: t('main:home'), href: routes.overview},
          ...breadcrumbs
        ];
      }
    }
    return breadcrumbs;
  },
  hasActiveSession: () => Boolean(localStorage.getItem(COMPANY_ID) && localStorage.getItem(TOKEN)),
  isProduction: () => process.env.NODE_ENV === 'production',
  getPublicPaths: () => {
    return [
      routesList.verifyIdentity, routesList.userInvite, routesList.oauthCallback, routesList.publicSubscriptionUpdate,routesList.quickGoogleConnect
    ]
  },
}
