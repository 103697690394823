import styled from 'styled-components';

const StyledInvoiceMissingModal = styled.div`
  p {
    line-height: 28px;
    margin: 0;
    svg {
      height: 22px;
      width: 22px;
    }
  }
`;


export {
  StyledInvoiceMissingModal,
};
