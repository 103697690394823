const AdvertisingServicesIcon = (props) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.78393 19.283L7.53293 20.033C6.90093 20.411 6.58493 20.601 6.26093 20.67C5.82093 20.764 5.36393 20.707 4.96093 20.51C4.66293 20.363 4.40293 20.102 3.88093 19.58C3.36093 19.06 3.09993 18.799 2.95393 18.501C2.75619 18.0979 2.69983 17.64 2.79393 17.201C2.86193 16.876 3.05193 16.559 3.42993 15.927L7.78593 8.64499C8.50393 7.44499 8.86293 6.84499 9.35593 6.58399C9.78893 6.35399 10.2909 6.29299 10.7659 6.40899C11.3099 6.54199 11.8039 7.03699 12.7919 8.02599L15.4279 10.664C16.4159 11.653 16.9099 12.147 17.0439 12.69C17.1599 13.166 17.0979 13.669 16.8689 14.102C16.6079 14.596 16.0089 14.955 14.8089 15.674L13.5389 16.435M8.78393 19.283C8.97807 19.6808 9.26449 20.0265 9.61931 20.2913C9.97412 20.556 10.3871 20.7321 10.8237 20.8049C11.2604 20.8777 11.7081 20.8451 12.1297 20.7097C12.5512 20.5744 12.9342 20.3403 13.2469 20.027C13.7134 19.5599 13.9987 18.9424 14.052 18.2844C14.1053 17.6265 13.9241 16.971 13.5389 16.435M8.78393 19.283L13.5389 16.435"
        stroke="black" strokeWidth="0.5"/>
      <path d="M20.0069 4.40396L17.5419 6.86996M21.2509 9.87696H18.7609M14.5369 3.15796V5.64996" stroke="black"
            strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default AdvertisingServicesIcon;
