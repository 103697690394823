import React from 'react';
import {
  StyledDatePickerPanel
} from './StyledDatePickerPanel';

const DatePickerPanel = ({
  children,
  ...rest
}) => {

  return (
    <StyledDatePickerPanel {...rest}>
      {children}
    </StyledDatePickerPanel>
  );
}

export default DatePickerPanel;
