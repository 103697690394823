import {objectHelpers} from './object.helpers';

export const locationHelpers = {
  getUrlFilename: (url, fileNameMaxLength = 18) => {
    let filename = url.replace(/#.*$/, '').replace(/\?.*$/, '').split('/');
    filename = filename[filename.length - 1];
    if (filename.length > fileNameMaxLength) {
      const splitFileName = filename.split('.');
      filename = `${splitFileName[0].slice(0, fileNameMaxLength)}...${splitFileName[1]}`;
    }
    return filename;
  },
  isValidUrl: (url) => {
    const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
    return regex.test(url);
  },
  getLocationHash: (location) => {
    let {hash} = location;
    if (hash) {
      hash = hash.replace('#', '');
    }
    return hash;
  },
  getHash: (location) => {
    let hash = null;
    if (location) {
      hash = location.hash.replace('#', '').split('?')[0];
    }
    return hash;
  },
  getSearchParams: (params = {}) => {
    let value;
    let query = {};
    Object.keys(params).forEach(key => {
      value = params[key];
      if (!['', null, undefined].includes(value)) {
        query = { ...query, [key]: value };
      }
    });
    return query;
  },
  getSearchParamsFromLocation: (location) => {
    const {hash, search} = location;
    let query = search;
    if (hash) {
      const spittedUrl = hash.split('?');
      query = spittedUrl.length > 1 ? spittedUrl[1] : '';
    }
    return Object.fromEntries(new URLSearchParams(query));
  },
  isActivePathInList: (activePath, paths = []) => {
    if (!activePath) return false;

    const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    const uuidSimplifiedLength = 32;
    const activeSegments = activePath.split('/');

    return paths.some((route) => {
      if (route === activePath || activePath.startsWith(route)) return true;

      const routeSegments = route.split('/');
      if (routeSegments.length !== activeSegments.length) return false;

      // Normalize dynamic segments (UUID placeholders)
      const normalizedRoute = routeSegments.map((segment, index) =>
        segment.startsWith(':') && (uuidPattern.test(activeSegments[index]) || activeSegments[index].length === uuidSimplifiedLength)
          ? ''
          : segment
      );

      const normalizedActive = activeSegments.map((segment, index) =>
        routeSegments[index].startsWith(':') ? '' : segment
      );

      return objectHelpers.arraysIsEqual(normalizedRoute, normalizedActive);
    });
  }
}
