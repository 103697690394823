const CourierServiceAirAndGroundIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 10.8572L8 6.28574M13.1337 4.64802L18.848 7.91317C19.198 8.11309 19.4889 8.40203 19.6912 8.75066C19.8936 9.09929 20.0001 9.49523 20 9.89831V15.2446C20.0001 15.6477 19.8936 16.0436 19.6912 16.3922C19.4889 16.7409 19.198 17.0298 18.848 17.2297L13.1337 20.4949C12.7884 20.6921 12.3977 20.7959 12 20.7959C11.6023 20.7959 11.2116 20.6921 10.8663 20.4949L5.152 17.2297C4.80199 17.0298 4.51108 16.7409 4.30877 16.3922C4.10645 16.0436 3.99993 15.6477 4 15.2446V9.89831C3.99993 9.49523 4.10645 9.09929 4.30877 8.75066C4.51108 8.40203 4.80199 8.11309 5.152 7.91317L10.8663 4.64802C11.2116 4.45079 11.6023 4.34705 12 4.34705C12.3977 4.34705 12.7884 4.45079 13.1337 4.64802Z"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path
      d="M5.5 10.5L11.3451 13.4171C11.6781 13.5964 12.0504 13.6902 12.4286 13.6902C12.8067 13.6902 13.179 13.5964 13.512 13.4171L19.8571 10M12.4286 14V20.5"
      stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default CourierServiceAirAndGroundIcon;
