const firebaseEvents = {
  // Overview
  OVERVIEW_CHANGE_VIEW_TYPE: 'overview_change_view_type',
  OVERVIEW_USE_FILTER: 'overview_use_filter',

  // Subscriptions
  SUBSCRIPTIONS_ACTIVE_TAB: 'subscriptions_active_tab',
  SUBSCRIPTIONS_CREATE_SINGLE_SUBSCRIPTION: 'subscriptions_create_single_subscription',
  SUBSCRIPTIONS_CREATE_MULTIPLE_SUBSCRIPTIONS: 'subscriptions_create_multiple_subscriptions',
  SUBSCRIPTIONS_AUTHORIZE_SUBSCRIPTION_FROM_TABLE_ROW: 'subscriptions_authorize_subscription_from_table_row',
  SUBSCRIPTIONS_DELETE_SUBSCRIPTION_FROM_TABLE_ROW: 'subscriptions_delete_subscription_from_table_row',
  SUBSCRIPTIONS_EDIT_SUBSCRIPTION_FROM_TABLE_ROW: 'subscriptions_edit_subscription_from_table_row',
  SUBSCRIPTIONS_IGNORE_SUBSCRIPTION_FROM_TABLE_ROW: 'subscriptions_ignore_subscription_from_table_row',
  SUBSCRIPTIONS_RESTRICT_SUBSCRIPTION_FROM_TABLE_ROW: 'subscriptions_restrict_subscription_from_table_row',
  SUBSCRIPTIONS_USE_FILTER: 'subscriptions_use_filter',

  // Subscription details
  SUBSCRIPTIONS_SUBSCRIPTION_ACTIVATE: 'subscriptions_subscription_activate',
  SUBSCRIPTIONS_SUBSCRIPTION_ACTIVATE_CARD: 'subscriptions_subscription_activate_card',
  SUBSCRIPTIONS_SUBSCRIPTION_ADD_CARD: 'subscriptions_subscription_add_card',
  SUBSCRIPTIONS_SUBSCRIPTION_AUTHORIZE: 'subscriptions_subscription_authorize',
  SUBSCRIPTIONS_SUBSCRIPTION_CHANGE_PAYMENT_METHOD: 'subscriptions_subscription_change_payment_method',
  SUBSCRIPTIONS_SUBSCRIPTION_DEACTIVATE_CARD: 'subscriptions_subscription_deactivate_card',
  SUBSCRIPTIONS_SUBSCRIPTION_DELETE: 'subscriptions_subscription_delete',
  SUBSCRIPTIONS_SUBSCRIPTION_DISABLE: 'subscriptions_subscription_disable',
  SUBSCRIPTIONS_SUBSCRIPTION_EDIT: 'subscriptions_subscription_edit',
  SUBSCRIPTIONS_SUBSCRIPTION_EDIT_PAYMENT_METHOD: 'subscriptions_subscription_edit_payment_method',
  SUBSCRIPTIONS_SUBSCRIPTION_EDIT_PAYMENT_DETAILS: 'subscriptions_subscription_edit_payment_details',
  SUBSCRIPTIONS_SUBSCRIPTION_UPDATE_SERVICE: 'subscriptions_subscription_update_service',
  SUBSCRIPTIONS_SUBSCRIPTION_VIEW_TRANSACTIONS_TAB: 'subscriptions_subscription_view_transactions_tab',
  SUBSCRIPTIONS_SUBSCRIPTION_VIEW_USAGE_REPORT_TAB: 'subscriptions_subscription_view_usage_report_tab',

  // Cards
  CARDS_ACTIVE_CARD: 'cards_activate_card',
  CARDS_CREATE_NEW_PERSONAL_CARD: 'cards_create_new_personal_card',
  CARDS_CREATE_NEW_SUBSCRIPTION_CARD: 'cards_create_new_subscription_card',
  CARDS_DEACTIVATE_CARD: 'cards_deactivate_card',
  CARDS_DELETE_CARD: 'cards_delete_card',
  CARDS_EDIT_CARD_DETAILS: 'cards_edit_card_details',
  CARDS_EDIT_CARD_LIMITS: 'cards_edit_card_limits',
  CARDS_OPEN_TRANSACTION_DETAILS: 'cards_open_transaction_details',
  CARDS_UPDATE_TRANSACTION_INVOICE: 'cards_update_transaction_invoice',
  CARDS_USE_CARDS_TABLE_FILTER: 'cards_use_cards_table_filter',

  // Transactions
  TRANSACTIONS_EDIT_TRANSACTION_DETAILS: 'transactions_edit_transaction_details',
  TRANSACTIONS_DOWNLOAD_ZIP_INVOICES: 'transactions_download_zip_invoices',
  TRANSACTIONS_EXPORT_CSV: 'transactions_export_csv',
  TRANSACTIONS_EXPORT_PDF: 'transactions_export_pdf',
  TRANSACTIONS_EXPORT_PDF_FOR_CERTAIN_MONTH: 'transactions_export_pdf_for_certain_month',
  TRANSACTIONS_NAVIGATE_TO_CARD_PAGE_FROM_TRANSACTION_DETAILS: 'transactions_navigate_to_card_page_from_transaction_details',
  TRANSACTIONS_NAVIGATE_TO_SUBSCRIPTION_PAGE_FROM_TRANSACTION_DETAILS: 'transactions_navigate_to_subscription_page_from_transaction_details',
  TRANSACTIONS_UPDATE_TRANSACTION_INVOICE: 'transactions_update_transaction_invoice',

  // Teams
  TEAM_USER_DELETED: 'team_user_deleted',
  TEAM_USER_DISABLED: 'team_user_disabled',
  TEAM_USER_ENABLED: 'team_user_enabled',
  TEAM_USER_INVITED: 'team_user_invited',
  TEAM_USER_REINVITED: 'team_user_reinvited',
  TEAM_ACTIVE_TAB: 'team_active_tab',

  // Invoices
  INVOICES_ACTIVE_TAB: 'invoices_active_tab',
  INVOICES_ADD_NEW: 'invoices_add_new',
  INVOICES_DELETE_INVOICE: 'invoices_delete_invoice',
  INVOICES_DOWNLOAD_INVOICE: 'invoices_download_invoice',
  INVOICES_EXPORT: 'invoices_export',
  INVOICES_EDIT_INVOICE_DETAILS: 'invoices_edit_invoice_details',
  INVOICES_EDIT_INVOICE_TRANSACTION: 'invoices_edit_invoice_transaction',

  // Settings
  COMPANY_SETTINGS_ADD_COST_CENTER_TAG: 'company_settings_add_cost_center_tag',
  COMPANY_SETTINGS_ADD_EXPENSE_CATEGORY_TAG: 'company_settings_add_expense_category_tag',
  COMPANY_SETTINGS_CONNECT_SSO: 'company_settings_connect_sso',
  COMPANY_SETTINGS_DELETE_COST_CENTER_TAG: 'company_settings_delete_cost_center_tag',
  COMPANY_SETTINGS_DELETE_EXPENSE_CATEGORY_TAG: 'company_settings_delete_expense_category_tag',
  COMPANY_SETTINGS_DISCONNECT_SSO: 'company_settings_disconnect_sso',
  COMPANY_SETTINGS_EDIT_DETAILS: 'company_settings_edit_details',
  COMPANY_SETTINGS_DOWNLOAD_BILLING: 'company_settings_download_billing',

  PROFILE_SETTINGS_EDIT_DETAILS: 'profile_settings_edit_details',
  PROFILE_SETTINGS_UPDATE_EMAIL_NOTIFICATIONS: 'profile_settings_update_email_notifications',

  // Incognito user
  // login actions
  USER_AUTHENTICATED: 'user_authenticated',
  LOGIN_PHONE_ENTERED: 'login_phone_entered',
  LOGIN_CODE_ENTERED: 'login_code_entered',
  // registration actions
  REGISTRATION_CODE_ENTERED: 'registration_code_entered',
  REGISTRATION_PERSONAL_INFORMATION_ENTERED: 'registration_personal_information_entered',
  REGISTRATION_PASSWORD_CREATED: 'registration_password_created',
  REGISTRATION_PHONE_NUMBER_ENTERED: 'registration_phone_number_entered',
  // forgot password actions
  FORGOT_PASSWORD_CODE_ENTERED: 'forgot_password_code_entered',
  FORGOT_PASSWORD_PASSWORD_CREATED: 'forgot_password_password_created',
  FORGOT_PASSWORD_PHONE_NUMBER_ENTERED: 'forgot_password_phone_number_entered',
  // quick google connect
  QUICK_GOOGLE_CONNECT_GENERATE_URL_SUCCESS: 'quick_google_connect_generate_url_success',
  QUICK_GOOGLE_CONNECT_GENERATE_URL_ERROR: 'quick_google_connect_generate_url_error',

  // Other
  ADD_FUND_BUTTON_CLICK: 'add_fund_button_click',
  VIEW_CARD_DETAILS: 'view_card_details',
  VIEW_CARD_DETAILS_FROM_TABLE_ROW: 'view_card_details_from_table_row',
  SCA_ENROLLMENT_COMPLETED: 'sca_enrollment_completed',
  USER_CODE_AUTH: 'user_code_auth',
  USER_SCA_AUTH: 'user_sca_auth',
}

export {
  firebaseEvents
}