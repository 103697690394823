import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import TagSelect from '../../../TagSelect';
import {companyActions} from '../../../../state/actions/company.actions';
import {tagFamilyConstants} from '../../../../constants';
import {formHelpers, objectHelpers} from '../../../../utils/helpers';

const {COST_CENTER, EXPENSE} = tagFamilyConstants;

const CompanyTagSelect = ({
  addTags,
  getTags,
  costCenterTags,
  expenseTags,
  isAdmin,
  selected,
  tagFamily,
  ...rest
}) => {

  const [tagOptions, setTagOptions] = useState([]);

  const getTagFamilyOptions = () => {
    const optionsList = {
      [COST_CENTER]: costCenterTags,
      [EXPENSE]: expenseTags
    };
    return optionsList[tagFamily] ?? [];
  };

  useEffect(() => {
    let options = getTagFamilyOptions();

    if (options.length !== tagOptions.length) {
      options = formHelpers.getTagOptions(options);
      setTagOptions(options);
    }
  }, [costCenterTags, expenseTags]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const tagFamilyOptions = formHelpers.getTagOptions(getTagFamilyOptions());

    // Filter out selected values that are not in the existing tagOptions
    const missedOptions = selected
      .filter(value => !tagFamilyOptions.some(option => option.value === value))
      .map(value => ({ label: value, value }));

    const newTagOptions = [...tagFamilyOptions, ...missedOptions];

    if (!objectHelpers.arraysIsEqual(tagOptions, newTagOptions)) {
      setTagOptions(newTagOptions);
    }
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddNewTag = (value) => {
    if (isAdmin) {
      addTags({
        data: {
          tags: [{tag_name: value, tag_family: tagFamily}]
        },
        successCallback: getTags
      });
    }
  }

  return (
    <TagSelect
      {...rest}
      onAdd={handleAddNewTag}
      options={tagOptions}
      isEnableAdd={isAdmin}
      selected={selected}
    />
  );
}

CompanyTagSelect.propTypes = {
  selected: PropTypes.array,
  tagFamily: PropTypes.oneOf(Object.values(tagFamilyConstants))
}

CompanyTagSelect.defaultProps = {
  selected: [],
  tagFamily: COST_CENTER
}

const mapDispatchToProps = {
  addTags: companyActions.addTags,
  getTags: companyActions.getTags
}

const mapStateToProps = state => {
  const {costCenterTags, expenseTags} = state.company;
  let {isAdmin} = state.user;
  return {
    costCenterTags,
    expenseTags,
    isAdmin,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyTagSelect);
