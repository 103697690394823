import {transactionConstants} from '../constants';

const detailsBalanceDetails = {
  currency: 'EUR',
  value: 0,
  isLoaded: false,
  isRequested: false
};

const defaultWireDetails = {
  bic: undefined,
  creationDate: undefined,
  iban: undefined,
  isLoaded: false,
  walletId: undefined
};

const transactionState = {
  addInvoiceModalProps: {
    transaction: undefined,
    open: false
  },
  balanceDetails: detailsBalanceDetails,
  lastTransaction: null,
  transactions: [],
  wireDetails: defaultWireDetails,
  companyBankingUserId: undefined
};

const transactionReducer = (state = transactionState, action) => {
  const {type, data} = action;
  switch (type) {
    case transactionConstants.GET_TRANSACTION_LIST:
      return {
        ...state,
        transactions: data
      }
    case transactionConstants.GET_LAST_TRANSACTION:
      return {
        ...state,
        lastTransaction: data
      }
    case transactionConstants.CLEAR_TRANSACTIONS:
      return {
        ...state,
        transactions: []
      }
    case transactionConstants.SET_WIRE_DETAILS:
      return {
        ...state,
        wireDetails: data
      }
    case transactionConstants.SET_BALANCE_DETAILS:
      return {
        ...state,
        balanceDetails: data
      }
    case transactionConstants.SET_COMPANY_BANKING_USER_ID:
      return {
        ...state,
        companyBankingUserId: data.id
      }
    case transactionConstants.OPEN_ADD_INVOICE_MODAL:
      return {
        ...state,
        addInvoiceModalProps: {
          ...state.addInvoiceModalProps,
          ...data
        }
      }
    case transactionConstants.CLOSE_ADD_INVOICE_MODAL:
      return {
        ...state,
        addInvoiceModalProps: {
          ...state.addInvoiceModalProps,
          open: false
        }
      }
    case transactionConstants.CLEAR_TRANSACTIONS_BANKING_DETAILS:
      return {
        ...state,
        companyBankingUserId: undefined,
        balanceDetails: detailsBalanceDetails,
        lastTransaction: null,
        wireDetails: defaultWireDetails
      }
    default:
      return state;
  }
};

export default transactionReducer;
