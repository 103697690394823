import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {SwitchTransition} from 'react-transition-group';
import {Link, useNavigate} from 'react-router-dom';
import {FadeTransition, PageContainer} from '../../components/pages/AuthPage/PageContainer';
import CodeAuthForm from '../../components/pages/AuthPage/CodeAuthForm';
import PhoneAuthForm from '../../components/pages/AuthPage/PhoneAuthForm';
import {authActions} from '../../state/actions/auth.actions';
import {userActions} from '../../state/actions/user.actions';
import {firebaseEvents} from '../../snippets/firebase';
import {helpers as servicesHelpers} from '../../state/services/helpers';
import CompanyInviteRegisterModal from '../../components/quickStart/CompanyInviteRegisterModal';
import {StyledLoginPage, StyledLoginPageImageContent} from './StyledLoginPage';
import {StyledImageTransition} from '../../components/pages/AuthPage/PageContainer/StyledPageContainer';
import PageDocumentDetails from '../../components/PageDocumentDetails';
import BackgroundStep1SvgImage from '../../static/images/pages/login/background-step-1.svg';
import {ReactComponent as Step1SvgImage} from '../../static/images/pages/login/step-1.svg';
import {ReactComponent as Step2SvgImage} from '../../static/images/pages/login/step-2.svg';
import {localStorageKeysConstants} from '../../constants';
import routes from '../../routes/routes.json';
import {systemHelpers} from '../../utils/helpers';

const {logEvent} = systemHelpers;

const signUpUrl = 'https://www.withless.com/book';

const LoginPage = ({
  login,
  verification,
  getEmployeeProfile,
  getUserProfile,
  emailVerificationToken,
  companyInviteToken,
  setCompanyInviteToken,
  showRegisterModal,
  showCompanyInviteRegister
}) => {
  const [trans] = useTranslation(['auth', 'main']);
  const navigate = useNavigate();
  const t = (key) => trans(`login.${key}`);
  const [step, setStep] = useState(1);
  const [loginData, setLoginData] = useState(null);
  const [token, setToken] = useState({
    isValid: false,
    needRegister: false
  });
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [showInviteMessage, setShowInviteMessage] = useState(false);
  const [authParams, setAuthParams] = useState({
    phone_number: undefined,
    session: undefined
  });
  const [phoneAuthFormProps, setPhoneAuthFormProps] = useState({
    loading: false,
    disabled: false
  });
  const [codeAuthFormProps, setCodeAuthFormProps] = useState({
    disabled: false,
    errors: null
  });

  const phoneNumber = authParams.phone_number;


  const clearInviteToken = () => {
    setCompanyInviteToken(null);
    showCompanyInviteRegister();
  }

  useEffect(() => {
    if (companyInviteToken) {
      const data = servicesHelpers.parseJWT(companyInviteToken);
      const availableTTL = systemHelpers.checkAvailableTokenTTL(data, false);
      if (availableTTL) {
        setToken({
          isValid: true,
          needRegister: !data.is_user_exists
        });
        setOpenInviteModal(true);
      } else {
        setToken({
          ...token,
          isValid: false
        });
        setOpenInviteModal(true);
      }
    }
  }, [companyInviteToken]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPhoneAuth = (data, successCallback, errorCallback) => {
    setLoginData(data);
    setPhoneAuthFormProps({...phoneAuthFormProps, loading: true, disabled: true});
    login(
      data,
      ({session}) => {
        logEvent(firebaseEvents.LOGIN_PHONE_ENTERED);
        setStep(2);
        setAuthParams({
          phone_number: data.phone_number,
          session
        });
        successCallback && successCallback();
        setPhoneAuthFormProps({...phoneAuthFormProps, loading: false, disabled: false});
      },
      (err) => {
        errorCallback && errorCallback(err);
        setPhoneAuthFormProps({...phoneAuthFormProps, loading: false, disabled: false});
      }
    );
  }

  const onCodeAuth = (data) => {
    setCodeAuthFormProps({...codeAuthFormProps, disabled: true});
    verification(
      {
        ...authParams,
        ...data
      },
      () => {
        getEmployeeProfile();
        getUserProfile();
        logEvent(firebaseEvents.LOGIN_CODE_ENTERED);
        if (phoneNumber) localStorage.setItem(localStorageKeysConstants.PHONE_NUMBER, btoa(phoneNumber));
        navigate('/');
        setCodeAuthFormProps({...codeAuthFormProps, disabled: false});
      },
      (errors) => setCodeAuthFormProps({...codeAuthFormProps, disabled: false, errors})
    );
  }

  const onResend = (successCallback, errorCallback) => onPhoneAuth(loginData, successCallback, errorCallback);

  const message = (key) => <p className='help-text'>{t(key)}</p>;

  const handleOkInviteModal = () => {
    setOpenInviteModal(false);
    showCompanyInviteRegister();
    navigate('/sign-up');
  }

  const handleSignIn = () => {
    setOpenInviteModal(false);
    showCompanyInviteRegister();
    setShowInviteMessage(true);
  }

  const handleCloseInviteModal = () => {
    clearInviteToken();
    setOpenInviteModal(false);
  }

  const onBack = () => {
    setStep(step - 1);
    if (codeAuthFormProps.errors) setCodeAuthFormProps({...codeAuthFormProps, errors: null});
  }

  const {isValid, needRegister} = token;

  const showInviteModal = showRegisterModal && openInviteModal;

  const imageContent = ({
    1: (
      <StyledLoginPageImageContent
        backgroundImage={BackgroundStep1SvgImage}
        className={`step-${step}`}
      >
        <Step1SvgImage/>
      </StyledLoginPageImageContent>
    ),
    2: (
      <StyledLoginPageImageContent className={`step-${step}`}>
        <Step2SvgImage/>
      </StyledLoginPageImageContent>
    ),
  })[step] || <Step2SvgImage/>;

  const formContent = ({
    1: (
      <>
        <h3>{t('welcomeBack')}!</h3>
        {emailVerificationToken && message('emailVerification')}
        {!showInviteModal && (((isValid && !needRegister) || showInviteMessage)) && message('companyInvitation')}
        <PhoneAuthForm
          {...phoneAuthFormProps}
          buttonText={t('buttonText')}
          initialValues={{phone_number: phoneNumber}}
          onSubmit={onPhoneAuth}
          passwordProps={{
            iconRender: () => <Link to={routes.forgotPin}>{t('forgotLinkText')}</Link>
          }}
        />
        <p className='help-text'>
          {t('dontHaveAnAccountYet')} <a href={signUpUrl} target='_blank' rel='noreferrer'>{t('signUp')}</a>
        </p>
      </>
    ),
    2: (
      <>
        <h3>{trans('enterCode')}</h3>
        <p>{t('enterThe6DigitCode')} <span className='bold'>{phoneNumber}</span></p>
        <CodeAuthForm
          {...codeAuthFormProps}
          autofocus
          onSuccess={onCodeAuth}
          onResend={onResend}
          onBack={onBack}
        />
      </>
    ),
  })[step] || null;

  const transitionProps = {
    key: step,
    mountOnEnter: true,
    timeout: 250,
    unmountOnExit: true,
  }

  return (
    <StyledLoginPage>
      <PageDocumentDetails title={trans('main:pageTitles.login')} />
      <PageContainer
        imageContent={
          <StyledImageTransition>
            <SwitchTransition>
              <FadeTransition {...transitionProps}>
                {imageContent}
              </FadeTransition>
            </SwitchTransition>
          </StyledImageTransition>
        }
        formContent={
          <SwitchTransition>
            <FadeTransition {...transitionProps}>
              <div className='form-content'>
                {formContent}
              </div>
            </FadeTransition>
          </SwitchTransition>
        }
      />

      <CompanyInviteRegisterModal
        open={showInviteModal}
        isValid={isValid}
        handleOk={handleOkInviteModal}
        handleSignIn={handleSignIn}
        handleClose={handleCloseInviteModal}
      />
    </StyledLoginPage>
  );
}

const mapStateToProps = state => {
  const {companyInviteToken, emailVerificationToken, showRegisterModal} = state.auth;
  return {
    emailVerificationToken,
    companyInviteToken,
    showRegisterModal
  }
}

const mapDispatchToProps = {
  getEmployeeProfile: userActions.getEmployeeProfile,
  getUserProfile: userActions.getUserProfile,
  login: authActions.login,
  verification: authActions.codeVerification,
  setCompanyInviteToken: authActions.setCompanyInviteToken,
  showCompanyInviteRegister: authActions.showCompanyInviteRegister
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
