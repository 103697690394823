import React from 'react';
import {ExclamationCircleFilled} from '@ant-design/icons';
import {Modal, Space, Tooltip} from 'antd';
import {
  cardPaymentStatusesConstants,
  invoiceTypeConstants,
  expenseTypesConstants,
  transactionTypesConstants,
  mccCategoriesConstants
} from '../../constants';
import {
  FileInvoiceIcon,
  ReceiptDeclinedIcon,
  ReceiptIcon,
  TransactionAddIcon,
  TransactionIcon,
  WireIcon,
} from '../../icons';
import {
  AdvertisingServicesIcon,
  AirlinesIcon,
  AthleticFieldsCommercialSportsProfessionalSportsClubsSportsPromotersIcon,
  BooksPeriodicalsAndNewspapersIcon,
  BusinessServicesNotElsewhereClassifiedIcon,
  CommercialArtGraphicsPhotographyIcon,
  ComputerNetworkInformationServicesIcon,
  ComputerProgrammingDataProcessingAndIntegratedSystemDesignServicesIcon,
  ComputerSoftwareStoresIcon,
  ConsultingManagementAndPublicRelationsServicesIcon,
  CourierServiceAirAndGroundIcon,
  DepartmentStoresIcon,
  DigitalGoodsIcon,
  DirectMarketingCombinationCatalogAndRetailMerchantIcon,
  ElectronicsSalesIcon,
  EmploymentAgenciesAndTemporaryHelpServicesIcon,
  EquipmentFurnitureAndHomeFurnishingsStoresIcon,
  FinesIcon,
  FloristsIcon,
  GroceryStoresSupermarketsIcon,
  HotelsIcon,
  MiscellaneousAndSpecialtyRetailStoresIcon,
  MiscellaneousFoodStoresIcon,
  MiscellaneousGeneralMerchandiseStoresIcon,
  MiscellaneousPublishingAndPrintingIcon,
  OtherIcon,
  PassengerRailwaysIcon,
  PhotographicStudiosIcon,
  ProfessionalServicesIcon,
  RealEstateAgentsAndManagersRentalsIcon,
  RecreationServicesIcon,
  RestaurantsIcon,
  SchoolsAndEducationalServicesIcon,
  SoftwareIcon,
  SouvenirShopsIcon,
  SubscriptionMerchantsIcon,
  TaxicabsIcon,
  TaxIcon,
  TelecommunicationEquipmentIcon,
  TelecommunicationServicesIcon,
  TransportationSuburbanIcon,
  TravelAgenciesAndTourOperatorsIcon,
  VarietyStoresIcon,
} from '../../icons/mccCategories';
import UserDetails from '../../components/UserDetails';
import CreditCardDetails from '../../components/pages/CardsPage/CreditCardDetails';
import {amountHelpers} from './amount.helpers';
import {colorHelpers} from './color.helpers';
import {objectHelpers} from './object.helpers';
import {textHelpers} from './text.helpers';

const {CARD_TRANSACTION} = transactionTypesConstants;

const {AUTHORIZATION_ACCEPTED, CLEARED, DECLINED, REFUND, REVERSED, SETTLED} = cardPaymentStatusesConstants;

const gMV = amountHelpers.getAmountWithCurrencyCode;

const transactionDetailsMaxWidth = `${472 + 24}px`;
const tableCollapseWidth = `calc(100% - ${transactionDetailsMaxWidth})`;

const cardPaymentStatuses = {
  [AUTHORIZATION_ACCEPTED]: {
    key: 'cardPaymentStatuses.accepted',
    color: '#52C41A'
  },
  [CLEARED]: {
    key: 'cardPaymentStatuses.cleared',
    color: '#1890FF'
  },
  [DECLINED]: {
    key: 'cardPaymentStatuses.declined',
    color: '#FF7875'
  },
  [REFUND]: {
    key: 'cardPaymentStatuses.refund',
    color: '#722ED1'
  },
  [REVERSED]: {
    key: 'cardPaymentStatuses.reversed',
    color: '#FFCC00'
  },
  [SETTLED]: {
    key: 'cardPaymentStatuses.settled',
    color: '#52C41A'
  },
};

export const transactionsHelpers = {
  additionalTransactionsGrouping: (transactions, summarizeKeyName) => transactions.map((t, key) => (
    (key !== 0 && key !== transactions.length - 1 && transactions[key + 1][summarizeKeyName])
      ? {...t, isLatest: true} : t)
  ),
  getAnimationStyles: ({
    defaultTableWidth = '100%',
    duration
  } = {}) => {
    const defaultStyle = {
      transition: `width ${duration}ms ease-in-out`
    };
    return {
      details: {
        default: defaultStyle,
        entering: {width: transactionDetailsMaxWidth},
        entered: {width: transactionDetailsMaxWidth},
        exiting: {width: 0},
        exited: {width: 0}
      },
      table: {
        default: defaultStyle,
        entering: {width: tableCollapseWidth},
        entered: {width: tableCollapseWidth},
        exiting: {width: defaultTableWidth},
        exited: {width: defaultTableWidth}
      }
    }
  },
  isReturnedTransaction: (transaction) => transaction && [DECLINED, REVERSED].includes(transaction.payment_status),
  getTransactionAmount: ({transaction, variant, groupingKey = 'isMonthGrouping'}) => {
    if (transaction && typeof transaction === 'object') {
      let className = '';
      let {amount} = transaction;
      if (transaction[groupingKey]) {
        className = 'total-amount';
        amount = transaction[variant] || 0;
        if (amount > 0 && variant === 'outcome') className = `${className} danger-text`;
        if (variant === 'outcome' && Math.sign(amount) > 0) amount = amount * -1;
        return <span className={className}>{gMV(amount)}</span>;
      } else {
        const isIncoming = transaction.is_incoming || false;
        if ((isIncoming && variant !== 'income') || (!isIncoming && variant !== 'outcome')) return '';
        const price = gMV(amount);
        const isReturnedTransaction = transactionsHelpers.isReturnedTransaction(transaction);
        if (!isIncoming && !isReturnedTransaction) className = `${className} danger-text`;
        if (isReturnedTransaction) className = `${className} returned-price`;
        return amount ? isIncoming ? price : <span className={className}>-{gMV(Math.abs(amount))}</span> : null;
      }
    } else {
      return null
    }
  },
  getTransactionAuthor: (transaction) => {
    if (transaction && typeof transaction === 'object' && transaction.transaction_type === CARD_TRANSACTION) {
      return (
        <UserDetails
          email={false}
          user={transaction.user}
        />
      );
    } else {
      return null;
    }
  },
  getTransactionSource: (transaction) => {
    let value = '';
    if (typeof transaction === 'object') {
      const description = objectHelpers.getObjProp(transaction, 'description');
      const source = objectHelpers.getObjProp(transaction, 'source');
      if (source) {
        value = source;
      } else if (description && description !== '') {
        value = textHelpers.cutString(description.split(' ')[0], 20)
      }
    }
    return value;
  },
  getTransactionSourceWithIcon: ({
    categories = [],
    enabledTooltip = true,
    transaction,
  }) => {
    if (transaction?.isMonthGrouping || transaction?.isYearlyGrouping) return ;

    let value = transactionsHelpers.getTransactionSource(transaction);
    const mccCode = transaction?.mcc_code;
    const category = (
      categories.find(c => c.mcc_code === mccCode) ||
      categories.find(c => objectHelpers.isNaV(c.mcc_code))
    )?.category;

    if (category) {
      const categoryIcon = transactionsHelpers.getTransactionCategoryIcon(category?.id);
      value = (
        <Space align='center' className='d-flex'>
          <div className='d-flex'>
            {enabledTooltip ? (
              <Tooltip
                title={category?.name || ''}
                trigger='hover'
              >
                <div className='d-flex'>{categoryIcon}</div>
              </Tooltip>
            ): categoryIcon}
          </div>
          <div>{value}</div>
        </Space>
      );
    }
    return value;
  },
  getTransactionStatus: (status) => {
    const statuses = {...cardPaymentStatuses};
    Object.keys(statuses).forEach(key => {
      statuses[key].bgColor = colorHelpers.hex2rgba(statuses[key].color, 0.15);
    })
    return objectHelpers.getStatus(status, statuses);
  },
  getTransactionType: (transaction) => {
    if (transaction && typeof transaction === 'object') {
      return (
        <span className='transaction-type'>
          {transaction.transaction_type === CARD_TRANSACTION ? (
            <>
              <CreditCardDetails
                cardNumber={transaction.masked_pan}
                cardNumberClassName='card-number'
              />
            </>
          ) : <WireIcon />}
        </span>
      )
    } else {
      return null;
    }
  },
  getTransactionAttachmentIcon: ({ transaction, ...rest }) => {
    if (!transaction?.expense) return null;

    const { onClick } = rest;
    const { expense_type } = transaction.expense;
    const isDefinedExpenseType = objectHelpers.isValidValue(expense_type);

    const icon =
      Object.values(expenseTypesConstants).includes(expense_type)
        ? <TransactionIcon />
        : <TransactionAddIcon />;

    return (
      <span
        className='transaction-icon'
        {...rest}
        onClick={(e) => !isDefinedExpenseType && onClick?.({ e, transaction })}
        style={{ cursor: isDefinedExpenseType ? 'default' : 'pointer' }}
      >
        {icon}
      </span>
    );
  },
  getTransactionCategoryIcon: (value) => {
    return {
      [mccCategoriesConstants.ADVERTISING_SERVICES]: <AdvertisingServicesIcon />,
      [mccCategoriesConstants.AIRLINES]: <AirlinesIcon />,
      [mccCategoriesConstants.ATHLETIC_FIELDS_COMMERCIAL_SPORTS_PROFESSIONAL_SPORTS_CLUBS_SPORTS_PROMOTERS]: <AthleticFieldsCommercialSportsProfessionalSportsClubsSportsPromotersIcon />,
      [mccCategoriesConstants.BOOKS_PERIODICALS_AND_NEWSPAPERS]: <BooksPeriodicalsAndNewspapersIcon />,
      [mccCategoriesConstants.BUSINESS_SERVICES_NOT_ELSEWHERE_CLASSIFIED]: <BusinessServicesNotElsewhereClassifiedIcon />,
      [mccCategoriesConstants.COMMERCIAL_ART_GRAPHICS_PHOTOGRAPHY]: <CommercialArtGraphicsPhotographyIcon />,
      [mccCategoriesConstants.COMPUTER_NETWORK_INFORMATION_SERVICES]: <ComputerNetworkInformationServicesIcon />,
      [mccCategoriesConstants.COMPUTER_PROGRAMMING_DATA_PROCESSING_AND_INTEGRATED_SYSTEM_DESIGN_SERVICES]: <ComputerProgrammingDataProcessingAndIntegratedSystemDesignServicesIcon />,
      [mccCategoriesConstants.COMPUTER_SOFTWARE_STORES]: <ComputerSoftwareStoresIcon />,
      [mccCategoriesConstants.CONSULTING_MANAGEMENT_AND_PUBLIC_RELATIONS_SERVICES]: <ConsultingManagementAndPublicRelationsServicesIcon />,
      [mccCategoriesConstants.COURIER_SERVICES_AIR_AND_GROUND]: <CourierServiceAirAndGroundIcon />,
      [mccCategoriesConstants.DEPARTMENT_STORES]: <DepartmentStoresIcon />,
      [mccCategoriesConstants.DIGITAL_GOODS]: <DigitalGoodsIcon />,
      [mccCategoriesConstants.DIRECT_MARKETING_COMBINATION_CATALOG_AND_RETAIL_MERCHANT]: <DirectMarketingCombinationCatalogAndRetailMerchantIcon />,
      [mccCategoriesConstants.DIRECT_MARKETING_CONTINUITY_SUBSCRIPTION_MERCHANTS]: <SubscriptionMerchantsIcon />,
      [mccCategoriesConstants.ELECTRONICS_SALES]: <ElectronicsSalesIcon />,
      [mccCategoriesConstants.EMPLOYMENT_AGENCIES_AND_TEMPORARY_HELP_SERVICES]: <EmploymentAgenciesAndTemporaryHelpServicesIcon />,
      [mccCategoriesConstants.EQUIPMENT_FURNITURE_AND_HOME_FURNISHINGS_STORES]: <EquipmentFurnitureAndHomeFurnishingsStoresIcon />,
      [mccCategoriesConstants.FINES]: <FinesIcon />,
      [mccCategoriesConstants.FLORISTS]: <FloristsIcon />,
      [mccCategoriesConstants.GROCERY_STORES_SUPERMARKETS]: <GroceryStoresSupermarketsIcon />,
      [mccCategoriesConstants.HOTELS]: <HotelsIcon />,
      [mccCategoriesConstants.MISCELLANEOUS_AND_SPECIALTY_RETAIL_STORES]: <MiscellaneousAndSpecialtyRetailStoresIcon />,
      [mccCategoriesConstants.MISCELLANEOUS_FOOD_STORES]: <MiscellaneousFoodStoresIcon />,
      [mccCategoriesConstants.MISCELLANEOUS_GENERAL_MERCHANDISE_STORES]: <MiscellaneousGeneralMerchandiseStoresIcon />,
      [mccCategoriesConstants.MISCELLANEOUS_PUBLISHING_AND_PRINTING]: <MiscellaneousPublishingAndPrintingIcon />,
      [mccCategoriesConstants.PASSENGER_RAILWAYS]: <PassengerRailwaysIcon />,
      [mccCategoriesConstants.PHOTOGRAPHIC_STUDIOS]: <PhotographicStudiosIcon />,
      [mccCategoriesConstants.PROFESSIONAL_SERVICES]: <ProfessionalServicesIcon />,
      [mccCategoriesConstants.REAL_ESTATE_AGENTS_AND_MANAGERS_RENTALS]: <RealEstateAgentsAndManagersRentalsIcon />,
      [mccCategoriesConstants.RECREATION_SERVICES]: <RecreationServicesIcon />,
      [mccCategoriesConstants.RESTAURANTS]: <RestaurantsIcon />,
      [mccCategoriesConstants.SCHOOLS_AND_EDUCATIONAL_SERVICES]: <SchoolsAndEducationalServicesIcon />,
      [mccCategoriesConstants.SOFTWARE]: <SoftwareIcon />,
      [mccCategoriesConstants.SOUVENIR_SHOPS]: <SouvenirShopsIcon />,
      [mccCategoriesConstants.TAXICABS]: <TaxicabsIcon />,
      [mccCategoriesConstants.TAX_PAYMENTS]: <TaxIcon />,
      [mccCategoriesConstants.TELECOMMUNICATION_EQUIPMENT]: <TelecommunicationEquipmentIcon />,
      [mccCategoriesConstants.TELECOMMUNICATION_SERVICES]: <TelecommunicationServicesIcon />,
      [mccCategoriesConstants.TRANSPORTATION_SUBURBAN]: <TransportationSuburbanIcon />,
      [mccCategoriesConstants.TRAVEL_AGENCIES_AND_TOUR_OPERATORS]: <TravelAgenciesAndTourOperatorsIcon />,
      [mccCategoriesConstants.VARIETY_STORES]: <VarietyStoresIcon />,
    }[value] ?? <OtherIcon />;
  },
  getWireDetails: (details) => {
    const gObjProp = (propName) => objectHelpers.getObjProp(details, propName);
    const getFormattedValue = (value) => value ? value.match(/.{1,4}/g).join(' ') : '';

    return {
      bic: getFormattedValue(gObjProp('bic')),
      iban: getFormattedValue(gObjProp('iban'))
    };
  },
  getInvoiceEmail: (settings) => {
    let email = null;
    const gObjProp = (propName) => objectHelpers.getObjProp(settings, propName);

    if (settings) email = gObjProp('invoice_email_alias') || gObjProp('invoice_email') || null;
    return email;
  },
  addFixedDetailsWindowScrollEventListener: ({detailsWindowFixedHeight, isOpenDetails, isFixedDetailsWindow, setIsFixedDetailsWindow}) => {
    const setScroll = (e) => {
      let scrolled = false;
      if (!isOpenDetails) return;
      if (e.target.scrollTop >= detailsWindowFixedHeight) scrolled = true;
      if (isFixedDetailsWindow !== scrolled) setIsFixedDetailsWindow(scrolled);
    };
    document.querySelector('#app-page-container')?.addEventListener('scroll', setScroll);
    return () => {
      document.querySelector('#app-page-container')?.removeEventListener('scroll', setScroll);
    };
  },
  getGroupedTransactions: (transactions, totals = []) => {
    const daysList = {};
    const datePropName = 'created_date';
    const summarizeKeyName = 'isMonthGrouping';
    let groupedTransactions = [];
    let lastAdded;

    // define transactions months
    transactions.forEach(transaction => {
      const date = transaction[datePropName].slice(0, 7);
      const {is_incoming: isIncoming} = transaction;
      const amount = Number(transaction.amount) || 0;
      const income = isIncoming ? amount : 0;
      const outcome = isIncoming ? 0 : amount;
      const isReturnedTransaction = transactionsHelpers.isReturnedTransaction(transaction);
      if (!isReturnedTransaction) {
        if (daysList.hasOwnProperty(date)) {
          daysList[date] = {
            income: daysList[date].income + income,
            outcome: daysList[date].outcome + outcome
          }
        } else {
          daysList[date] = {
            income,
            outcome
          }
        }
      }
    });

    // add grouping rows to transactions list
    transactions.forEach(t => {
      const date = t[datePropName].slice(0, 7);
      if (lastAdded !== date && daysList.hasOwnProperty(date)) {
        let groupedTransaction = {
          id: date,
          [summarizeKeyName]: true,
          [datePropName]: date,
          ...daysList[date]
        }
        const totalByDate = totals.find(t => `${t.year}-${t.month}` === date);
        if (totalByDate) {
          groupedTransaction = {
            ...groupedTransaction,
            income: totalByDate?.total_in,
            outcome: totalByDate?.total_out
          }
        }
        lastAdded = date;
        groupedTransactions.push(groupedTransaction);
      }
      groupedTransactions.push(t);
    });
    groupedTransactions = transactionsHelpers.additionalTransactionsGrouping(groupedTransactions, summarizeKeyName);
    return groupedTransactions;
  },
  getInvoiceTypeOptions: ({t, enabledEmpty = false}) => {
    let options = [
      {
        icon: <FileInvoiceIcon />,
        label: t(`invoiceTypes.invoice`),
        value: invoiceTypeConstants.INVOICE
      },
      {
        icon: <ReceiptIcon />,
        label: t(`invoiceTypes.receipt`),
        value: invoiceTypeConstants.RECEIPT
      }
    ];
    if (enabledEmpty) {
      options = [
        ...options,
        {
          icon: <ReceiptDeclinedIcon />,
          label: t(`invoiceTypes.noReceipt`),
          value: invoiceTypeConstants.NO_RECEIPT
        }
      ];
    }
    return options;
  },
  getExpenseTypeOptions: ({t}) => {
    return [
      {
        label: t(`expenseTypes.invoice`),
        value: expenseTypesConstants.INVOICE
      },
      {
        label: t(`expenseTypes.receipt`),
        value: expenseTypesConstants.RECEIPT
      },
      {
        label: t(`expenseTypes.noReceipt`),
        value: expenseTypesConstants.NO_RECEIPT
      }
    ];
  },
  isMonthlyGroupedRow: (row) => row.isMonthGrouping,
  getTransactionsWithExpense: ({employees, expenses, transactions}) => {
    return transactions.map(t => {
      const {expense_id: expenseId, user_id: userId} = t;
      const user = employees.find(e => e.employee_id === userId);
      const expense = expenses.find(e => e.id === expenseId);
      return {
        ...t,
        expense,
        user
      }
    });
  },
  getRowClassName: ({
    groupedPropName,
    index,
    record,
    selectedRowIndex
  }) => {
    let className = '';
    if (record[groupedPropName]) className += ' grouped-row';
    if (index === selectedRowIndex) className += ' selected-row';
    return className;
  },
  getUpdatedBatchExpensesList: (expenses, updatedExpense, actionType) => {
    return expenses.map(expense => {
      if (expense.id !== updatedExpense.id) {
        return expense;
      } else {
        let expenseObj = {
          ...expense,
          ...updatedExpense
        }
        if (actionType !== 'attachment') {
          expenseObj = {
            ...expenseObj,
            budget: updatedExpense.budget,
            fund: updatedExpense.fund,
            description: updatedExpense.description,
            tags: updatedExpense.tags
          }
        }
        return expenseObj;
      }
    });
  },
  getInvoiceModalMode: ({addInvoiceModalProps, isOpenDetails, selectedTransaction}) => {
    return isOpenDetails && objectHelpers.arraysIsEqual(selectedTransaction, addInvoiceModalProps.transaction) ? 'edit' : 'add';
  },
  removeInvoiceFromTransaction: ({
    t,
    transaction,
    successCallback,
    errorCallback,
    unlinkExpense,
    onExpenseUpdate
  }) => {
    if (transaction.expense) {
      Modal.confirm({
        title: t('transactions:modal.removeInvoice.title'),
        icon: <ExclamationCircleFilled />,
        content: t('transactions:modal.removeInvoice.description'),
        okText: t('main:yes'),
        okType: 'danger',
        cancelText: t('main:no'),
        cancelButtonProps: {size: 'large'},
        okButtonProps: {size: 'large'},
        onCancel() {
          errorCallback && errorCallback();
        },
        onOk() {
          const {expense} = transaction;
          unlinkExpense(
            {expense_id: expense.id},
            () => {
              onExpenseUpdate({
                ...expense,
                expense_type: null,
                expense_invoice_status: undefined,
                is_attachment_uploaded: false
              }, 'attachment');
              successCallback && successCallback();
            },
            (resp) => errorCallback && errorCallback(resp)
          )
        }
      });
    } else {
      errorCallback && errorCallback();
    }
  },
}
